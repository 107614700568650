import * as clone from 'clone';

import { EditShiftTemplateDTO } from '../types/dto/edit-shift-template';
import { EditShiftTemplateForm } from "../types/forms";

export class EditShiftTemplateAdapter
{
    private _form: EditShiftTemplateForm;
    private _dto: EditShiftTemplateDTO;

    constructor(form: EditShiftTemplateForm)
    {
        this._form = clone(form);
    }

    private _adapt()
    {
        return {
            clockInTime: this._form.clockInTime,
            endTime: this._form.endTime,
            startTime: this._form.startTime
        } as EditShiftTemplateDTO;
    }

    get dto()
    {
        if(!this._dto)
            this._dto = this._adapt();

        return this._dto;
    }
}