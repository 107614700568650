import { createAction, props } from '@ngrx/store';

import { type } from 'src/app/state/actions/helpers/action-type';
import { ApproveTimecardDTO } from './dto/approve-timecard';
import { FetchNextTimecardsPageDTO } from './dto/fetch-next-timecards-page';
import { FetchPageDTO } from './dto/fetch-page';
import { FetchTimecardsDTO } from './dto/fetch-timecards';
import { FetchTimecardDTO } from './dto/fetch-timecard';
import { RejectTimecardDTO } from './dto/reject-timecard';

const t = type('timecards');

export const fetchTimecards = createAction(t.pending('fetch_timecards'), props<FetchTimecardsDTO.Pending>());
export const fetchTimecardsSuccess = createAction(t.success('fetch_timecards'), props<FetchTimecardsDTO.Success>());
export const fetchTimecardsFailed = createAction(t.failed('fetch_timecards'), props<FetchTimecardsDTO.Failed>());

export const fetchNextTimecardsPage = createAction(t.pending('fetch_next_timecards_page'), props<FetchNextTimecardsPageDTO.Pending>());
export const fetchNextTimecardsPageSuccess = createAction(t.success('fetch_next_timecards_page'), props<FetchNextTimecardsPageDTO.Success>());
export const fetchNextTimecardsPageFailed = createAction(t.failed('fetch_next_timecards_page'), props<FetchNextTimecardsPageDTO.Failed>());

export const fetchPage = createAction(t.pending('fetch_page'), props<FetchPageDTO.Pending>());
export const fetchPageSuccess = createAction(t.success('fetch_page'), props<FetchPageDTO.Success>());
export const fetchPageFailed = createAction(t.failed('fetch_page'), props<FetchPageDTO.Failed>());

export const approveTimecard = createAction(t.pending('approve_timecard'), props<ApproveTimecardDTO.Pending>());
export const approveTimecardSuccess = createAction(t.success('approve_timecard'), props<ApproveTimecardDTO.Success>());
export const approveTimecardFailed = createAction(t.failed('approve_timecard'), props<ApproveTimecardDTO.Failed>());

export const rejectTimecard = createAction(t.pending('reject_timecard'), props<RejectTimecardDTO.Pending>());
export const rejectTimecardSuccess = createAction(t.success('reject_timecard'), props<RejectTimecardDTO.Success>());
export const rejectTimecardFailed = createAction(t.failed('reject_timecard'), props<RejectTimecardDTO.Failed>());

export const fetchTimecard = createAction(t.pending('fetch_timecard'), props<FetchTimecardDTO.Pending>());
export const fetchTimecardSuccess = createAction(t.success('fetch_timecard'), props<FetchTimecardDTO.Success>());
export const fetchTimecardFailed = createAction(t.failed('fetch_timecard'), props<FetchTimecardDTO.Failed>());

export const removeTimecardFromStore = createAction(t.sync('remove_timecard_from_store'), props<{timecardId: string}>());
