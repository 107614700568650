<div class="shift-form-wrapper" (click)="onClose.emit()">
  <div class="shift-form-popup" (click)="$event.stopPropagation()">
    <div class="popup-header"  [ngClass]="{insta: shift?.isInstaShyft || (create && createShiftType === 'insta')}">
      <span>{{ create ? ('Create ' + (createShiftType === 'insta' ? 'InstaSHYFT' : 'regular shift')) : (shift?.isInstaShyft ? 'Edit InstaSHYFT' : 'Edit regular shift') }}</span>
    </div>
    <div class="form-block" [formGroup]="form">
      <div class="input-wrapper">
        <app-input
          type="date"
          label="Date"
          formControlName="date"
          (switchSubmit)="switchSubmit($event)"
          [disabled]="disabledInputs"
          labelColor="text"
        ></app-input>
      </div>
      <div class="input-wrapper">
        <app-input
          type="select"
          label="Shift time"
          formControlName="shiftTemplate"
          [selectData]="shiftsTemplates"
          (switchSubmit)="switchSubmit($event)"
          [disabled]="disabledInputs"
          labelColor="text"
          (addShiftTimeModal)="showAddShiftTimeModal = $event;"
          [customShiftSelected]="customShiftSelected"
        ></app-input>
        <app-shift-time-modal
          *ngIf="showAddShiftTimeModal"
          (onClose)="showAddShiftTimeModal=$event; clearSelection()"
          (onSave)="saveNewShiftTime($event)"
        >
        </app-shift-time-modal>
      </div>
      <div class="input-wrapper">
        <app-input
          type="select"
          label="Position"
          formControlName="license"
          [selectData]="licenses"
          (switchSubmit)="switchSubmit($event)"
          [disabled]="disabledInputs"
          labelColor="text"
          placeholder="Select shift position"
          [selectLicenses]="true"
        ></app-input>
      </div>
      <div class="input-wrapper">
        <app-input
          type="money"
          label="Nurse hourly pay rate ($)"
          formControlName="rate"
          [rateValid]="rateValid"
          (switchSubmit)="switchSubmit($event)"
          [disabled]="disabledInputs"
          labelColor="text"
        ></app-input>
        <app-facility-bill-rate
            [facility]="facility"
            [state]="state"
            [hourlyRate]="form.get('rate').value"
            [ngClass]="{ 'additional-margin': !form.get('rate').valid }"
          >
          </app-facility-bill-rate>
      </div>
      <div class="input-wrapper auto-confirmation" *ngIf="!shift?.isInstaShyft && (createShiftType != 'insta') ">
        <div class="label-block"  [ngClass]="{disabled: disabledInputs}">
          <span>Select nurses for auto-confirmation</span>
          <mat-icon svgIcon="info-2" customToolTip
          [contentTemplate]="autoConfirmation" [offsetY]="-8"></mat-icon>
          <ng-template #autoConfirmation>
            <div class="tooltip-block">
              <span>Fill shifts faster with auto-confirmation. Save your team time and effort by selecting which nurses should be automatically confirmed for this shift.&nbsp;<a
                  (click)="openLearnMore()" class="nowrap">Learn more <mat-icon svgIcon="externalLink"></mat-icon></a></span>
            </div>
          </ng-template>
          <div class="new" *ngIf="displayNewBadge">New</div>
        </div>
        <app-select-input [values]="autoConfirmOptions" key="value" [control]="autoConfirmGroupsControl" [optionTemplateRef]="optionTemplate" [selectedTemplateRef]="selectedTemplate" [disabled]="disabledInputs" [editShift]="true">
          <ng-template  #selectedTemplate>
            <span class="selectedTemplate-text" [ngClass]="{disabled: disabledInputs}">{{autoConfirmOptionsObj[autoConfirmGroupsControl.value]}}</span>
          </ng-template>
          <ng-template #optionTemplate let-option>
            <div class="autoConfirmOptionTemplate-block">
              <span class="name">{{ option.valueName }}</span>
              <span class="text">{{ option.text }}</span>
            </div>
          </ng-template>
        </app-select-input>
      </div>
      <div class="input-wrapper">
        <app-input
          type="spin"
          label="Nurses required (max: 15)"
          formControlName="slots"
          (switchSubmit)="switchSubmit($event)"
          labelColor="text"
          [maxlength]="2"
          [validators]="['slots']"
        ></app-input>
      </div>
      <div class="input-wrapper show-description-block" [ngClass]="{disabled: disabledInputs}">
        <input type="checkbox" [formControl]="showDescription">
        <span class="text">
          Add shift description
        </span>

      </div>
      <div class="input-wrapper" *ngIf="showDescription.value">
        <app-input
          [textAreaAutosize]="true"
          type="textArea"
          label="Description (optional)"
          formControlName="description"
          labelColor="text"
          placeholder="Eg. free meal, underground parking, etc"
          [validators]="['description']"
          [disabled]="disabledInputs"
        ></app-input>
      </div>
    </div>
    <div class="buttons">
      <button (click)="onClose.emit()" fill="outline" color="border-base">Cancel</button>
      <button [disabled]="!form.valid" [ngClass]="{disabled: !form.valid}" (click)="onSubmit()">{{create ? 'Publish' : 'Save'}}</button>
    </div>
  </div>
</div>
