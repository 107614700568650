import * as clone from 'clone';

import { RequestChangeDTO } from "../types/dto";
import { RequestChangeForm } from "../types/forms";

export class RequestChangeAdapter
{
    private _form: RequestChangeForm;
    private _dto: RequestChangeDTO;

    constructor(form: RequestChangeForm)
    {
        this._form = clone(form);
    }

    private _adapt()
    {
        return {
            contactNumber: this._form.phone,
            description: this._form.description,
            fieldName: this._form.field
        } as RequestChangeDTO;
    }

    get dto()
    {
        if(!this._dto)
            this._dto = this._adapt();

        return this._dto;
    }
}