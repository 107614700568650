import * as clone from 'clone';

import { FacilityModel } from "src/app/models/facility";


import { EditFacilityOvertimeForm } from '../types/forms/edit-facility-overtime';

export class EditFacilityOvertimeAdapter
{
    private _form: EditFacilityOvertimeForm;
    private _facility: FacilityModel;

    private _adaptedFacility: FacilityModel;

    constructor(form: EditFacilityOvertimeForm, facility: FacilityModel)
    {
        this._form = clone(form);
        this._facility = clone(facility);
    }

    private _adapt()
    {
        return {
            ...this._facility,
            denyOvertimeApplicants: this._form.denyOvertimeApplicants,
            denyInstashyftOvertimeApplicants: this._form.denyInstashyftOvertimeApplicants
        } as FacilityModel;
    }

    get model()
    {
        if(!this._adaptedFacility)
            this._adaptedFacility = this._adapt();
        
        return this._adaptedFacility;
    }
}