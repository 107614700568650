<div class="insta-wrapper">
    <div class="insta-block">
      <div class="line">
        <div class="dot"></div>
        <span class="insta-name">InstaSHYFT</span>
      </div>
      <div [ngSwitch]="tooltipType">
        <div *ngSwitchCase="'regularTooltip'">
          <div class="line">
            <span class="text">Nurses are automatically confirmed for</span>
          </div>
          <div class="line">
            <span class="text">InstaSHYFTs. You will not need to confirm any</span>
          </div>
          <div class="line">
            <span class="text">nurses who apply to these shifts.</span>
          </div>
        </div>
        <div *ngSwitchCase="'appliedTooltip'">
          <div class="line">
            <span class="text">Nurses are automatically confirmed for</span>
          </div>
          <div class="line">
            <span class="text"> InstaSHYFTs, so there will not be any nurses in</span>
          </div>
          <div class="line">
            <span class="text">the <em>applied</em> status.</span>
          </div>
        </div>
        <div *ngSwitchCase="'allowTooltip'">
          <div class="line">
            <span class="text">In order to allow overtime applicants for</span>
          </div>
          <div class="line">
            <span class="text"> InstaSHYFTS you need to enable InstaSHYFTs at </span>
          </div>
          <div class="line">
            <span class="text"> your facility. Go to the InstaSHYFT tab to</span>
          </div>
          <div class="line">
            <span class="text"> enable.</span>
          </div>
        </div>
      </div>
  </div>
</div>