import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';

import { filter, take } from 'rxjs/operators';

import { State } from 'src/app/state/interfaces';
import { selectToken, selectRemember } from 'src/app/state/selectors/auth';

import { AppService } from '../app.service';

@Injectable({ providedIn: 'root' })
export class AuthAccessor
{
    constructor(app: AppService, private store: Store<State>)
    {
        app.expose('accessors', 'auth', this);
    }

    get token$() { return this.store.select(selectToken).pipe(filter(t => t !== 'NOT_SET')); }
    get remember$() { return this.store.select(selectRemember); }
}
