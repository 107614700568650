import * as clone from 'clone';

import { FacilityModel } from "./facility.model";

export class Facility implements FacilityModel
{
    private _model: FacilityModel;

    constructor(model: FacilityModel)
    {
        this._model = clone(model);
    }

    get model() { return this._model; }

    get address() { return this._model.address; }

    get adminRateLimits() { return this._model.adminRateLimits; }

    get avatar() { return this._model.avatar; }

    get contactData() { return this._model.contactData; }

    get clockInRadius() { return this._model.clockInRadius; }

    get defaultRateLimits() { return this._model.defaultRateLimits; }

    get displayName() { return this._model.displayName; }

    get isTest() { return this._model.isTest; }

    get managers() { return this._model.managers; }

    get name() { return this._model.name; }

    get rateLimits() { return this._model.rateLimits; }

    get shiftTemplates() { return this._model.shiftTemplates; }

    get supervisors() { return this._model.supervisors; }

    get timezone() { return this._model.timezone; }

    get description() { return this._model.description; }

    get _id() { return this._model._id; }

    get noStripe() { return this._model.noStripe; }

    get stripeId() { return this._model.stripeId; }

    get denyOvertimeApplicants() { return this._model.denyOvertimeApplicants; }

    get processingFee() { return this._model.processingFee}

    get stripeSourceType() { return this._model.stripeSourceType}

    get deactivationStatus() { return this._model.deactivationStatus}

    get instaShyftsDisabled() { return this._model.instaShyftsDisabled}

    get denyInstashyftOvertimeApplicants() { return this._model.denyInstashyftOvertimeApplicants}

    get feeCharged() { return this._model.feeCharged}

    get instaShyftDefault() { return this._model.instaShyftDefault}
}
