import { ManagerState, MANAGER_FEATURE_KEY } from 'src/app/modules/manager/state/interfaces';

import { AuthState, AUTH_FEATURE_KEY } from './auth';
import { UserState, USER_FEATURE_KEY } from './user';

export interface State
{
    [AUTH_FEATURE_KEY]: AuthState;
    [USER_FEATURE_KEY]: UserState;

    [MANAGER_FEATURE_KEY]?: ManagerState;
}