import { createReducer, on } from '@ngrx/store';
import { ReminderActions } from '../actions/reminder/reminder-action-types';

import { RemindersState } from '../interfaces';

export const initialRemindersState: RemindersState = Object.freeze<RemindersState>({
  models: [],
});

export const remindersReducer = createReducer(
  initialRemindersState,
  on(ReminderActions.addReminderIntoQueue, (state, { reminder  }) => {
    let models = [...state.models];
    models.push(reminder)
    return {
      models,
    }
  }),
  on(ReminderActions.removeFirstFromQueue, (state) => {
    let models = [...state.models];
    models.shift();
    return {
      models,
    }
  }),

);

