import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";

import { of } from "rxjs";
import { catchError, exhaustMap, map, share } from "rxjs/operators";

import { HolidaysService } from "../../services/api/holidays.service";
import { HolidaysActions } from "../actions/holidays/notifications-action-types";

@Injectable()
export class HolidaysEffects
{
    constructor(private actions$: Actions, private api: HolidaysService) { }

    fetchHolidays$ = createEffect(() => this.actions$.pipe(
        ofType(HolidaysActions.fetchHolidays),
        exhaustMap(() =>
            this.api.fetchAll()
                .pipe(
                    map(({ models }) => HolidaysActions.fetchHolidaysSuccess({ models })),
                    catchError(e => of(HolidaysActions.fetchHolidaysFailed({ error: e })))
                )
        ),
        share()
    ));
}
