import { createSelector } from "@ngrx/store";

import { State } from "src/app/state/interfaces";

import { MANAGER_FEATURE_KEY, DNR_FEATURE_KEY } from "../interfaces";

const state = (s: State) => s[MANAGER_FEATURE_KEY][DNR_FEATURE_KEY];

export const selectDnr = createSelector(state, (state) => state.models);

