import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';

import { State } from 'src/app/state/interfaces';

import {
  selectChat,
  selectChats,
  selectChatByShift,
  selectChatsIds,
  selectMessagesForChat,
  selectPastChats,
  selectPastChatsIds,
} from '../../state/selectors/chats';

@Injectable()
export class ChatsAccessor {
  constructor(private store: Store<State>) {}

  get chatsIds$() {
    return this.store.select(selectChatsIds);
  }
  get chats$() {
    return this.store.select(selectChats);
  }

  get pastChatsIds$() {
    return this.store.select(selectPastChatsIds);
  }
  get pastChats$() {
    return this.store.select(selectPastChats);
  }

  chat$(id: string) {
    return this.store.select(selectChat, { id });
  }

  chatByShift$(id: string) {
    return this.store.select(selectChatByShift, { id });
  }
  selectChat(id: string) {
    return this.store.select(selectChat, { id });
  }

  selectMessages(id: string) {
    return this.store.select(selectMessagesForChat, { id });
  }
  messages$(id: string)
  {
      return this.store.select(selectMessagesForChat, { id });
  }
}
