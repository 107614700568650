import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { Checkboxes } from '../../../constants/filters/shifts/checkboxes-group-model';
import { CheckboxesData } from '../../../types/filters/checkboxes-data';
import { ShiftFiltersForm, TimecardFiltersForm } from '../../../types/forms';


@Component({
  selector: 'app-filters',
  templateUrl: './filters.component.html',
  styleUrls: ['./filters.component.scss'],
})
export class FiltersComponent implements OnInit {
  @Input() checkboxesGroupModel: Checkboxes[];
  @Input() checkboxesData: CheckboxesData;
  @Input() filterForm: UntypedFormGroup;
  @Input() defaultFormValue: TimecardFiltersForm | ShiftFiltersForm;
  @Input() showPrintBtn: boolean;
  @Input() type = 'shift';

  @Output() submitFilterForm = new EventEmitter();
  @Output() clearFilterForm = new EventEmitter();
  @Output() printShifts = new EventEmitter();

  showClearBtn = false;
  formCtrlSub: Subscription;

  get checkboxesForm() {
    return this.filterForm.get('checkboxes') as UntypedFormGroup;
  }
  get rangeForm() {
    return this.filterForm.get('range') as UntypedFormGroup;
  }

  constructor(private fb: UntypedFormBuilder) {}
  ngOnInit(): void {
    this.filterForm.valueChanges.pipe(debounceTime(1000)).subscribe((x) => {
      this.submitFilterForm.emit(x);
    });
    this.filterForm.valueChanges.pipe().subscribe((x) => {
      this.showClearBtn = this.clearBtnStatusUpd(x);
    });
  }

  clearBtnStatusUpd(form) {
    let flag = false;
    if (form.id) {
      flag = true;
    }
    if (
      form.range.startTimeFrom !== this.defaultFormValue.range.startTimeFrom ||
      form.range.startTimeTo !== this.defaultFormValue.range.startTimeTo
    ) {
      flag = true;
    }
    Object.keys(form.checkboxes).map((v) => {
      if (form.checkboxes[v].length > 0) {
        flag = true;
      }
    });

    return flag;
  }
}
