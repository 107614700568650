import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { AppService } from '../../../../services/app.service';

@Injectable({ providedIn: 'root' })
export class ToastService {
  constructor(app: AppService, private toastr: ToastrService) {
    app.expose('services', 'toast', this);
  }

  // private _buttons(buttons: ToastButton[])
  // {
  //     return (buttons || []).concat({ icon: 'close-sharp', role: 'cancel' });
  // }
  async create(
    message: string,
    title: string,
    type: 'success' | 'danger' | 'info' | 'warning' | 'reminder' = 'danger',
    button: boolean,
    action?: () => any,
    buttonText?: string
  ) {
    let options = {
      positionClass: 'toast-top-center',
      //disableTimeOut: true,
      timeOut: 8000,
      type,
      button,
      toastClass: 'toastr-' + type,
      buttonText
    }
    let t = await this.toastr
      .show(message, title, options).onAction.subscribe((x) => {
        action();
      });
  }
  async createReminder(reminderData){
    let options = {
      positionClass: 'toast-top-right',
      disableTimeOut: true,
      type: 'reminder',
      toastClass: 'toastr-reminder',
      tapToDismiss: false,
      reminderData
    }
    let t = await this.toastr
      .show('', '', options).onAction.subscribe((x) => {
      });
  }
  clearToaster(){
    this.toastr.clear();
  }
}
