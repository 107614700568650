import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';

import { State } from 'src/app/state/interfaces';

import { selectHolidays } from '../../state/selectors/holidays';

@Injectable()
export class HolidaysAccessor
{
    constructor(private store: Store<State>) { }

    get holidays$() { return this.store.select(selectHolidays); }
}
