import { Injectable } from '@angular/core';
import { DateTime } from 'luxon';

@Injectable({
  providedIn: 'root',
})
export class NewBadgeService {

  private releaseDate = DateTime.fromISO('2024-09-10');
shouldDisplayNewBadge(): boolean {
    const now = DateTime.now();
    const daysDifference = now.diff(this.releaseDate, 'days').days;
    return daysDifference <= 30;
  }
}
