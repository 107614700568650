import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { HolidayModel } from 'src/app/models/holiday';

import { AppService } from 'src/app/services/app.service';
import { environment } from 'src/environments/environment';

@Injectable()
export class HolidaysService
{
    private endpoint = environment.apiUrl;

    constructor(app: AppService, private http: HttpClient)
    {
        app.expose('api', 'holidays', this);
    }

    fetchAll()
    {
        return this.http.get<{ count: number, models: HolidayModel[] }>(`${this.endpoint}/holidays`);
    }
}
