import { DefaultRateLimits } from "../..//types/models/facility/default-rate-limits";
import { Address } from "../../types/models/address";
import { ContactData, FACILITY_DEACTIVATION_STATUSES, RateLimits } from "../../types/models/facility";
import { ShiftTemplate } from "../../types/models/shift";

import { ManagerModel } from "../manager/manager.model";

export interface FacilityModel
{
    address: Address;
    adminRateLimits: RateLimits;
    avatar: string;
    contactData: ContactData;
    clockInRadius: number;
    defaultRateLimits: DefaultRateLimits;
    displayName?: string;
    isTest: boolean;
    managers: ManagerModel[];
    name: string;
    rateLimits: RateLimits;
    shiftTemplates: ShiftTemplate[];
    supervisors: ManagerModel[];
    timezone: string;
    _id: string;
    description: string;
    noStripe: boolean;
    stripeId: string;
    denyOvertimeApplicants?: boolean;
    denyInstashyftOvertimeApplicants?: boolean;
    processingFee: number;
    stripeSourceType?: 'card' | 'bank';
    deactivationStatus?: FACILITY_DEACTIVATION_STATUSES;
    instaShyftsDisabled?: boolean;
    feeCharged: number;
    instaShyftDefault: boolean;
}

export function getFacilityStatusClass(model: FacilityModel)
{
    if (model.deactivationStatus){
        switch (model.deactivationStatus) {
            case 'On hold':
                return 'pill-on-hold';
            case 'Disabled':
                return 'pill-disabled';
            case 'Deactivated':
                return 'pill-deactivated';
            default:
                return 'pill-disabled';
        }
    }else return 'pill-active';
}
