import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';

@Component({
  selector: 'app-simple-range-datepicker',
  templateUrl: './simple-range-datepicker.component.html',
  styleUrls: ['./simple-range-datepicker.component.scss']
})
export class SimpleRangeDatepickerComponent implements OnInit {
  @Input() range: UntypedFormGroup;
  date = this.fb.group({
    startTimeFrom: {},
    startTimeTo: {},
  });
  showPlacaholder = true;
  constructor(private fb: UntypedFormBuilder) { }

  ngOnInit(): void {
  }
  dateChange(type: string, event: MatDatepickerInputEvent<Date>) {
    if (event.value) {
      this.range.controls[type].setValue(event.value.toISOString());
    }
  }
}
