import { SortDTO } from './../../../../../types/dto/sort';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';

import { asapScheduler } from 'rxjs';
import { take } from 'rxjs/operators';

import { Chain } from 'src/app/classes/async-chain';
import { TIMECARDS_PAGE_SIZE } from 'src/app/constants/pagination';
import { AppService } from 'src/app/services/app.service';
import { State } from 'src/app/state/interfaces';

import { TimecardsFiltersAdapter } from '../../../adapters/timecards-filters.adapter';
import { TimecardBindings } from '../../../bindings';

import { TimecardsEffects } from '../../../state/effects/timecards.effects';
import { TimecardFiltersForm } from '../../../types/forms';
import { TimecardsAccessor } from '../../accessors/timecards.accessor';
import { MessagesService } from '../../messages.service';
import { LoaderService } from '../../ui/loader.service';
import { TimecardsBinder } from './timecards.binder';
import { TimecardsActions } from '../../../state/actions/timecards/timecards-action-types';

type P<K extends keyof TimecardBindings> = Parameters<TimecardBindings[K]>;

@Injectable()
export class TimecardsFacade {
  private _approveChain = (...args: P<'approve'>) =>
    new Chain({ debug: !this.app.isProduction, id: 'approve' })
      .add('sync', 'loader', () => this.loader.create('Loading...'))
      .add('sync', 'approve', () => this.approveTimecard(args[0]))
      .add('sync', 'loader', () => this.loader.hide())
      .add('sync', '', ({ approve }) =>
        this.messages.add(
          approve.type === TimecardsActions.approveTimecardSuccess.type
            ? 'Success! Your change has been updated.'
            : approve.error.error.message,
            '',
          approve.type === TimecardsActions.approveTimecardSuccess.type ? 'success' : 'danger',
        ),
      );

  private _rejectChain = (...args: P<'reject'>) =>
    new Chain({ debug: !this.app.isProduction, id: 'reject' })
      .add('sync', 'loader', () => this.loader.create('Loading...'))
      .add('sync', 'reject', () => this.rejectTimecard(args[0], args[1], args[2]))
      .add('sync', 'loader', () => this.loader.hide())
      .add('sync', '', ({ reject }) =>
        this.messages.add(
          reject.type === TimecardsActions.rejectTimecardSuccess.type
            ? 'Success! Your change has been updated.'
            : reject.error.error.message,
            '',
            reject.type === TimecardsActions.rejectTimecardSuccess.type ? 'success' : 'danger',
        ),
      );

  private _binder = new TimecardsBinder(this.accessor, {
    approve: (...args: P<'approve'>) => this._approveChain(...args).execute(),
    reject: (...args: P<'reject'>) => this._rejectChain(...args).execute(),
  });

  constructor(
    private app: AppService,
    private store: Store<State>,
    private _effects: TimecardsEffects,
    private accessor: TimecardsAccessor,
    private messages: MessagesService,
    private loader: LoaderService,
  ) {
    app.expose('facades', 'timecards', this);
  }

  get binder() {
    return this._binder;
  }

  get effects() {
    return this._effects;
  }

  get state() {
    return this.accessor;
  }

  fetchTimecards(filters: TimecardFiltersForm) {
    asapScheduler.schedule(() =>
      this.store.dispatch(
        TimecardsActions.fetchTimecards({ filters: new TimecardsFiltersAdapter(filters).dto }),
      ),
    );
    return this.effects.fetchTimecards$.pipe(take(1));
  }

  fetchNextTimecardsPage(filters: TimecardFiltersForm) {
    asapScheduler.schedule(() =>
      this.store.dispatch(
        TimecardsActions.fetchNextTimecardsPage({
          filters: new TimecardsFiltersAdapter(filters).dto,
        }),
      ),
    );
    return this.effects.fetchNextTimecardsPage$.pipe(take(1));
  }

  fetchPage(
    filters: TimecardFiltersForm,
    sort: SortDTO,
    page: number,
    limit = TIMECARDS_PAGE_SIZE,
  ) {

    asapScheduler.schedule(() =>
      this.store.dispatch(
        TimecardsActions.fetchPage({
          filters: new TimecardsFiltersAdapter(filters).dto,
          limit,
          page,
          sort
        }),
      ),
    );

    return this.effects.fetchPage$.pipe(take(1));
  }
  fetchTimecard(timecardId){
    asapScheduler.schedule(()=>
      this.store.dispatch(TimecardsActions.fetchTimecard({timecardId}))
    );
    return this.effects.fetchTimecard$.pipe(take(1));
  }

  private approveTimecard(timecardId: string) {
    asapScheduler.schedule(() =>
      this.store.dispatch(TimecardsActions.approveTimecard({ timecardId })),
    );
    return this.effects.approveTimecard$.pipe(take(1));
  }

  private rejectTimecard(timecardId: string, reason: string, reasonText: string) {
    asapScheduler.schedule(() =>
      this.store.dispatch(TimecardsActions.rejectTimecard({ timecardId, reason, reasonText })),
    );
    return this.effects.rejectTimecard$.pipe(take(1));
  }
}
