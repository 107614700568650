import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { environment } from '../../../environments/environment';
import { MessagesService } from 'src/app/modules/manager/services/messages.service';

@Injectable({ providedIn: 'root' })
export class AuthService {
  private endpoint = environment.apiUrl;

  constructor(private http: HttpClient, private messages: MessagesService) {}

  login(
    email: string,
    password: string,
    rememberLogin: boolean,
    isAgreed: boolean = true,
  ) {
    return this.http
      .post<{ accessToken: string; refreshToken: string }>(
        `${this.endpoint}/auth/login`,
        { email, password, isAgreed, rememberLogin, app: ['web'] },
      )
  }

  refreshToken() {
    return this.http.post<{ accessToken: string; refreshToken: string }>(
      `${this.endpoint}/auth/refresh`, {}
    );
  }
  forgotPassword(email: string) {
    return this.http.get(`${this.endpoint}/auth/forgot-password/${email}`);
  }

  resetPassword(_id: string, password: string, token: string) {
    return this.http.post(`${this.endpoint}/auth/reset-password`, { _id, newPassword: password, token });
  }
}
