import { License } from 'src/app/types/license';
import { ShiftType } from 'src/app/types/shift-type';
import { TimecardStatus } from 'src/app/types/timecard-status';

export interface TimecardFiltersCheckboxes {
  licenses: License[];
  shiftTypes?: ShiftType[];
  statuses?: TimecardStatus[];
}
export interface TimecardFiltersRange {
  startTimeFrom: string;
  startTimeTo: string;
}
export interface TimecardFiltersForm {
  id?: string;
  range: TimecardFiltersRange;
  checkboxes: TimecardFiltersCheckboxes;
}

export function defaultTimecardsFilters(): TimecardFiltersForm {
  return {
    checkboxes: {
      licenses: [],
      shiftTypes: [],
      statuses: []
    },
    id: undefined,
    range: {
      startTimeFrom: new Date(
        new Date().setDate(new Date().getDate() - 14),
      ).toISOString(),
      startTimeTo: new Date().toISOString(),
    }

  };
}
