import { createReducer, on } from '@ngrx/store';

import { FiltersState } from '../interfaces';
import * as ShiftsFilters from '../../types/forms/shift-filters';
import * as TimecardsFilters from '../../types/forms/timecard-filters';
import { FiltersActions } from '../actions/filters/filters-action-types';

export const initialFiltersState: FiltersState =
  Object.freeze<FiltersState>({
    shifts: ShiftsFilters.defaultFilters(),
    timecards: TimecardsFilters.defaultTimecardsFilters()
  });

  export const filtersReducer  = createReducer(
  initialFiltersState,

  on(FiltersActions.setShiftsFilters, (state, { filters }) => ({
    ...state,
    shifts: filters,
  })),
  on(FiltersActions.setTimecardsFilters, (state, { filters }) => ({
    ...state,
    timecards: filters,
  })),
);
