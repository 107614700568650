import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ComponentsModule } from './components/components.module';
import { DirectivesModule } from './directives/directives.module';


@NgModule({
    imports: [
        CommonModule,
        ComponentsModule,
        DirectivesModule,
    ],
    exports: [
        ComponentsModule,
        DirectivesModule,
    ]
})
export class SharedModule { }
