import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms';

@Component({
  selector: 'app-modal-box',
  templateUrl: './modal-box.component.html',
  styleUrls: ['./modal-box.component.scss'],
})
export class ModalBoxComponent {
  @Input() titleText = '';
  @Input() text = '';
  @Input() okButtonText = 'Ok';
  @Input() cancelButtonText = 'Cancel';
  @Input() color = '';
  @Input() type: 'modal' | 'submit' | 'twoAction';
  @Input() checkbox = false;
  @Input() checkboxText = '';
  @Input() titleIcon = '';
  @Input() checkRequiredToSubmit: boolean = false;


  @Output() closeAction = new EventEmitter();
  @Output() modalAction = new EventEmitter();
  @Output() secondModalAction = new EventEmitter();
  @Output() modalActionWithCheckbox = new EventEmitter();
  @Output() modalCloseActionWithCheckbox = new EventEmitter();
  

  form: UntypedFormGroup = this.fb.group({checkbox: new UntypedFormControl()});
  
  constructor(private fb: UntypedFormBuilder) {}

  onModalClose(event) {
    event.stopPropagation();
    if(this.checkbox){
      this.modalCloseActionWithCheckbox.emit(this.form.get('checkbox').value)
    }else{

      this.closeAction.emit();
    }
  }
  onOkClick(event){
    if(this.checkbox){
      this.modalActionWithCheckbox.emit(this.form.get('checkbox').value)
    }else{
      this.modalAction.emit();
    }
    this.onModalClose(event);
  }

}
