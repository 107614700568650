import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { parametrify } from 'src/app/helpers/parametrify';
import { DnrModel } from 'src/app/models/dnr';
import { FacilityModel } from 'src/app/models/facility';
import { AddToDnr } from 'src/app/types/forms/add-nurse-to-dnr-form';
import { RemoveFromDnr } from 'src/app/types/forms/remove-nurse-from-dnr-form';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class NurseService {

  private readonly endpoint = environment.apiUrl;

  constructor(private readonly http: HttpClient) {}

  sendNurseAttachments(id: string) {
    return this.http.get(`${this.endpoint}/users/${id}/documents`);
  }
  addNurseToDnr(form: AddToDnr){

    return this.http.post<DnrModel[]>(
      `${this.endpoint}/dnr`,
      form,
    );
  }
  removeNurseFromDnr(form: RemoveFromDnr){
    const params = new HttpParams({
      fromObject: {...form}});
    return this.http.delete(
      `${this.endpoint}/dnr/by-user`,
      { params },
    );
  }
  fetchDnrByUser(nurse : string, facility: string) {
    const params = new HttpParams({
      fromObject: {
        facility,
        nurse
    }});
    return this.http.get(`${this.endpoint}/dnr/by-user`, {
      params,
    });
  }
  fetchAllDnrByUser(nurse : string) {
    let populate = ["createdBy", "facility"];
        const params = new HttpParams({
            fromObject: {
                nurse,
                populate,
                ...parametrify({
                    skip: 0,
                    limit: 100
                }),

            }
        });
        return this.http.get<DnrModel[]>(`${this.endpoint}/dnr/`, {
            params,
        });
  }
  fetchDnrHistory(nurse : string, facility: string) {
    const params = new HttpParams({
      fromObject: {
        facility,
        nurse,
        ...parametrify({skip: 0,
          limit: 100}),
        
    }});
    return this.http.get<DnrModel[]>(`${this.endpoint}/dnr/history`, {
      params,
    });
  }
  fetchDnrUsers(facility: string) {
    const params = new HttpParams({
      fromObject: {
        facility,
        ...parametrify({skip: 0,
          limit: 500}),
    }});
    return this.http.get<DnrModel[]>(`${this.endpoint}/dnr`, {
      params,
    });
  }
  
  checkUpcomingShifts(nurseId: string) {
    return this.http.get(`${this.endpoint}/users/${nurseId}/check-upcoming-shifts`);
  }
  checkUpcomingShiftsInAllFacilities(nurseId: string) {
    return this.http.get<FacilityModel[]>(`${this.endpoint}/fm-api/nurses/${nurseId}/future-shifts`);
  }
}
