import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl } from '@angular/forms';
import { MatAutocompleteSelectedEvent, MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { MatChipInputEvent } from '@angular/material/chips';
import { MatOption } from '@angular/material/core';
import { MatSelect } from '@angular/material/select';
import { debounceTime } from 'rxjs';

@Component({
  selector: 'app-facility-search-select',
  templateUrl: './facility-search-select.component.html',
  styleUrls: ['./facility-search-select.component.scss']
})
export class FacilitySearchSelectComponent implements OnInit, OnChanges {

  @Input('formGroup') formGroup = null;
  @Input('controlName') controlName: string;
  @Input('selectedOptions') selectedOptions = [];
  @Input('optionKey') optionKey: string;
  @Input('optionResult') optionResult: string;
  @Input('results') results = [];
  @Input() selectedFacilityId: string;
  @Input() alreadyDnrFacilities: string[];
  @Input() label: string;

  @Output() searchQuery = new EventEmitter<string>();
  @Output() onPanelClose = new EventEmitter<any>();

  @ViewChild(MatAutocompleteTrigger) autoCompleteTrigger: MatAutocompleteTrigger;
  @ViewChild('searchInput') searchInput: ElementRef;
  @ViewChild('select') select: MatSelect;

  searchInputValue: string = '';
  separatorKeysCodes: number[] = [ENTER, COMMA];
  allSelected = false;

  get fControl() {
    return this.formGroup?.get(this.controlName);
  };
  searchForm = this.fb.group({
    q: new UntypedFormControl('')
  })
  triggerText = '';
  disabledFacilityObj: {
    [id: string]: {
      disabled: boolean,
      tooltip: boolean
    }
  } = {};
  filteredFacilities = [];

  constructor(private fb: UntypedFormBuilder) { }

  ngOnInit(): void {
    this.filteredFacilities = this.removeSelectedFacility(this.results);
    this.searchForm.valueChanges.pipe(debounceTime(1000)).subscribe((x) => {
      this.searchFacility(x);
      this.generateDisabledFacilityObj();
      this.selectDefault();
    });
    this.generateDisabledFacilityObj();
    this.selectDefault();
  }
  ngOnChanges(changes: SimpleChanges): void {
    if(changes.results){
      this.filteredFacilities = this.removeSelectedFacility(this.results);
      this.generateDisabledFacilityObj();
      this.selectDefault();
    }
    if(changes.alreadyDnrFacilities){
      this.generateDisabledFacilityObj();
      this.selectDefault();
    }
  }
  removeSelectedFacility(facilities){
    return facilities.filter(f => { return (f._id != this.selectedFacilityId) })
  }
  generateDisabledFacilityObj() {
    
    this.alreadyDnrFacilities.forEach(f => {
      this.disabledFacilityObj = {...this.disabledFacilityObj, [f]: {
        disabled: true,
        tooltip: true
      }} 
    });
  }
  selectDefault() {
    let selectedArr = this.formGroup.get(this.controlName).value;
    Object.keys(this.disabledFacilityObj).forEach(fId=>{
      if(selectedArr.indexOf(fId) <= -1){
        selectedArr.push(fId);
      }
    })
    this.formGroup.get(this.controlName).setValue(selectedArr);
  }
  getFacilitiesWithoutDefault() {
    let filteredArr = this.formGroup.get(this.controlName)?.value.filter(f => { return ((f != this.selectedFacilityId) && f != 'all') });

    return filteredArr;
  }
  selected(event: MatAutocompleteSelectedEvent): void {
    const index = this.selectedOptions.findIndex(o => o[this.optionKey] === event.option.value[this.optionKey]);
    if (index === -1) {
      this.selectedOptions.push(event.option.value);
      this.searchInput.nativeElement.value = '';
      this.fControl.patchValue(this.optionResult ? event.option.value[this.optionResult] : event.option.value);
    }
  }
  add(event: MatChipInputEvent) {
    if (this.results[0]) {
      this.selectedOptions.push(this.results[0]);
      this.fControl.patchValue(this.optionResult ? this.results[0][this.optionResult] : this.results[0]);
    }
    this.autoCompleteTrigger.openPanel();

  }
  toggledSelect(event) {
    if (event) {
      this.searchInput.nativeElement.focus();
    }
    this.onPanelClose.emit(!event);
  }
  selectFirstOption(option) {
    const index = this.selectedOptions.findIndex(o => o[this.optionKey] === option[this.optionKey]);

    if (index === -1) {
      this.selectedOptions.push(option);
      this.searchInput.nativeElement.value = '';
      this.fControl.patchValue(this.optionResult ? option[this.optionResult] : option);
    }
  }
  onSelect() {
    const filteredFacilities = this.getFacilitiesWithoutDefault();
    if (filteredFacilities.length) {
      let firstFacilityName = this.results.find(f => { return f._id === filteredFacilities[0] })?.name;
      this.triggerText = firstFacilityName + ((filteredFacilities.length > 1) ? ' and ' + (filteredFacilities.length - 1) + ' more' : '')

    } else {
      this.triggerText = '';
    }
  }
  toggleAllSelection() {
    this.allSelected = !this.allSelected;
    if (this.allSelected) {
      let allValues = this.results.map(result => this.optionResult ? result[this.optionResult] : result);
      allValues.push('all');
      this.formGroup.get(this.controlName).setValue(allValues);
      this.onSelect();
    } else {
      this.formGroup.get(this.controlName).setValue([])
      this.onSelect();

    }
    this.selectDefault();
  }
  searchFacility(e) {
    let value = e.q.replace(/\s+/, '');
    if (!value) {
      this.filteredFacilities = this.removeSelectedFacility(this.results);
      return;
    }
    this.filteredFacilities = this.removeSelectedFacility(this.results).filter((f) => {
      if (
        f.name.replace(/\s+/, '').toLowerCase().includes(value.toLowerCase())
      ) {
        return true;
      }
      return false;
    });

  }
}
