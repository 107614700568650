import { DateTime } from 'luxon';
import * as clone from 'clone';

import { TimecardStatus } from "../../types/timecard-status";
import { Shift } from "../shift";

import { TimecardModel } from "./timecard.model";

export class Timecard implements TimecardModel {
    protected _model: TimecardModel;

    protected _startTime: DateTime;
    protected _endTime: DateTime;
    protected _shift: Shift;
    protected _status: TimecardStatus;
    protected _statusData: { color: string, suffix: 'on' | 'since', date: string, datetime: string };

    constructor(model: TimecardModel) {
        this._model = clone(model);

        this._startTime = DateTime.fromISO(this.startTime, { zone: model.shift.facility?.timezone });
        this._endTime = this.endTime ? DateTime.fromISO(this.endTime, { zone: model.shift.facility?.timezone }) : undefined;

        this._shift = new Shift(model.shift);
        this._status = Timecard.getTimecardStatus(model);

        const fd = (s: string) => DateTime.fromISO(s, { zone: model.shift.facility?.timezone }).toFormat('M.d.yyyy');
        const fdt = (s: string) => DateTime.fromISO(s, { zone: model.shift.facility?.timezone }).toFormat('M/d/yyyy h:mm a');

        switch (this._status) {
            case 'pending':
                this._statusData = { color: 'warning', suffix: 'since', date: fd(this.submittedAt), datetime: fdt(this.submittedAt) };
                break;
            case 'rejected':
                this._statusData = { color: 'danger', suffix: 'on', date: fd(this.rejectedAt), datetime: fdt(this.rejectedAt) };
                break;
            case 'in progress':
                this._statusData = { color: 'primary', suffix: 'since', date: fd(this.startTime), datetime: fdt(this.startTime) };
                break;
            case 'approved':
                this._statusData = { color: 'success', suffix: 'on', date: fd(this.approvedAt), datetime: fdt(this.approvedAt) };
                break;
        }
    }

    get model() { return clone(this._model); }

    get approvedAt() { return this._model.approvedAt; }

    get approvedBy() { return this._model.approvedBy; }

    get baseEarnings() { return this._model.baseEarnings; }

    get createdBy() { return this._model.createdBy; }

    get deletedAt() { return this._model.deletedAt; }

    get deletedBy() { return this._model.deletedBy; }

    get deletedReason() { return this._model.deletedReason; }

    get duration() { return this._model.duration; }

    get earningsManager() { return this._model.earningsManager; }

    get earningsNurse() { return this._model.earningsNurse; }

    get endTime() { return this._model.endTime; }

    get facilityTotals() { return this._model.facilityTotals; }

    get hourlyRateManager() { return this._model.hourlyRateManager; }

    get hourlyRateNurse() { return this._model.hourlyRateNurse; }

    get isApproved() { return this._model.isApproved; }

    get isAutoApproved() { return this._model.isAutoApproved; }

    get isAutoStopped() { return this._model.isAutoStopped; }

    get isCreatedManually() { return this._model.isCreatedManually; }

    get isHoliday() { return this._model.isHoliday; }

    get isManualPay() { return this._model.isManualPay; }

    get isOvertime() { return this._model.isOvertime; }

    get isOfflinePayment() { return this._model.isOfflinePayment; }

    get isPaid() { return this._model.isPaid; }

    get isPresubmitted() { return this._model.isPresubmitted; }

    get isRejected() { return this._model.isRejected; }

    get isSameDayPay() { return this._model.isSameDayPay; }

    get isSubmitted() { return this._model.isSubmitted; }

    get isLatePayroll() { return this._model.isLatePayroll; }

    get lateCancellation() { return this._model.lateCancellation; }

    get nurse() { return this._model.nurse; }

    get mealBreaks() { return this._model.mealBreaks; }

    get paidAt() { return this._model.paidAt; }

    get rejectedAt() { return this._model.rejectedAt; }

    get rejectReason() { return this._model.rejectReason; }

    get rejectReasonOther() { return this._model.rejectReasonOther; }

    get shift() { return this._model.shift; }

    get startTime() { return this._model.startTime; }

    get submittedAt() { return this._model.submittedAt; }

    get submitProblem() { return this._model.submitProblem; }

    get facilityNurse() { return this._model.facilityNurse; }

    get _id() { return this._model._id; }

    get id() { return this._id; }

    /* HELPERS */

    get start() { return this._startTime; }

    get end() { return this._endTime; }

    get statusData() { return this._statusData; }

    get timespan() {
        return this.startTime && this.endTime &&
            `${DateTime.fromISO(this.startTime).toFormat('h:mm a')} - ${DateTime.fromISO(this.endTime).toFormat('h:mm a')}`;
    }

    get shiftObj() { return this._shift; }

    get status() { return this._status; }

    get history() { return this.model.history; }

    get isResubmitted() { return this.model.isResubmitted}

    get clockOutData() { return this.model.clockOutData}

    get createdAt() { return this._model.createdAt }


    static getTimecardStatus(timecard: TimecardModel): TimecardStatus {
        if (!timecard.startTime) return null;
        if(timecard.isOfflinePayment) return 'approved';
        if (!timecard.isPresubmitted) return 'in progress';
        else if (!timecard.isSubmitted) return 'in progress';
        else {
            if (!timecard.isRejected && !timecard.isApproved) return 'pending';
            if (timecard.isRejected) return 'rejected';

            if (timecard.isPaid) return 'approved';
            else if (timecard.isApproved) return 'approved';

            return null;
        }
    }
}
