
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';

import { asapScheduler } from 'rxjs';
import { take } from 'rxjs/operators';

import { AppService } from 'src/app/services/app.service';
import { State } from 'src/app/state/interfaces';

import { SendMessageAdapter } from '../../../adapters/send-message.adapter';
import { ChatsEffects } from '../../../state/effects/chats.effects';
import { SendMessageForm } from '../../../types/forms/send-message';
import { ChatsAccessor } from '../../accessors/chats.accessor';
import { ChatsBinder } from './chats.binder';
import { ChatBindings } from '../../../bindings';
import { actionMap } from 'src/app/helpers/rx-operators';
import { FetchMessagesDTO } from '../../../state/actions/chat/dto/fetch-messages';
import { ChatActions } from '../../../state/actions/chat/chat-action-types';

type P<K extends keyof ChatBindings> = Parameters<ChatBindings[K]>;

@Injectable()
export class ChatsFacade {

  private _binder = new ChatsBinder(this.accessor, {
  });
  constructor(
    private app: AppService,
    private store: Store<State>,
    private accessor: ChatsAccessor,
    private _effects: ChatsEffects,
  ) {
    app.expose('facades', 'chats', this);
  }

  get effects() {
    return this._effects;
  }

  get binder() {
    return this._binder;
  }

  get state() {
    return this.accessor;
  }

  fetchChats(filter) {
    asapScheduler.schedule(() =>
      this.store.dispatch(ChatActions.fetchChats({ filter })),
    );

    return this.effects.fetchChats$.pipe(
      take(1),
    );
  }

  fetchNextChatsPage(filter) {
    asapScheduler.schedule(() => this.store.dispatch(ChatActions.fetchNextChatsPage({ filter })));
    return this.effects.fetchNextChatsPage$.pipe(take(1));
  }

  fetchPastChats(filter) {
    asapScheduler.schedule(() => this.store.dispatch(ChatActions.fetchPastChats({ filter })));
    return this.effects.fetchPastChats$.pipe(
      take(1),
    );
  }

  fetchNextPastChatsPage(filter) {
    asapScheduler.schedule(() => this.store.dispatch(ChatActions.fetchNextPastChatsPage({ filter })));
    return this.effects.fetchNextPastChatsPage$.pipe(take(1));
  }

  fetchChat(id: string) {
    asapScheduler.schedule(() => this.store.dispatch(ChatActions.fetchChat({ id })));
    return this.effects.fetchChat$.pipe(take(1));
  }

  fetchMessages(chatId: string) {
    asapScheduler.schedule(() =>
      this.store.dispatch(ChatActions.fetchMessages({ chatId })),
    );
    return this.effects.fetchMessages$.pipe(take(1),
    actionMap<FetchMessagesDTO.Success, FetchMessagesDTO.Failed>(ChatActions.fetchMessagesSuccess.type));
  }

  fetchNextMessagesPage(chatId: string) {
    asapScheduler.schedule(() =>
      this.store.dispatch(ChatActions.fetchNextMessagesPage({ chatId })),
    );
    return this.effects.fetchNextMessagesPage$.pipe(take(1));
  }

  read(id: string) {
    asapScheduler.schedule(() => this.store.dispatch(ChatActions.read({ id })));
    return this.effects.read$.pipe(take(1));
  }

  sendMessage(form: SendMessageForm) {
    asapScheduler.schedule(() =>
      this.store.dispatch(
        ChatActions.sendMessage({ dto: new SendMessageAdapter(form).dto }),
      ),
    );
    return this.effects.sendMessage$.pipe(take(1));
  }
  fetchChatByMetadata(shiftId: string, nurseId)
    {
        asapScheduler.schedule(() => this.store.dispatch(ChatActions.fetchChatByMetadata({ shiftId, nurseId })));
        return this.effects.fetchChatByMetadata$.pipe(take(1));
    }
}
