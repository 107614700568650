export type InstaStatus = 'isInsta' | "isNotInsta";

export function getIsInstaShyftValue(instaStatuses: InstaStatus[]): boolean {
    if(instaStatuses?.includes("isInsta") && instaStatuses?.includes("isNotInsta")) {
      return undefined;
    }
    else if(instaStatuses?.includes("isInsta")){
      return true;
    }else if(instaStatuses?.includes("isNotInsta")) {
      return false;
    }
    return undefined;
  }