import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';

import { of, pipe } from 'rxjs';
import {
  catchError,
  exhaustMap,
  filter,
  map,
  share,
  switchMap,
  switchMapTo,
  take,
  withLatestFrom,
} from 'rxjs/operators';
import {
  CHATS_PAGE_SIZE,
  MESSAGES_PAGE_SIZE,
} from 'src/app/constants/pagination';
import { ChatsAccessor } from '../../services/accessors/chats.accessor';

import { FacilitiesAccessor } from '../../services/accessors/facilities.accessor';
import { ChatsService } from '../../services/api/chats.service';
import { ChatActions } from '../actions/chat/chat-action-types';
@Injectable()
export class ChatsEffects {
  constructor(
    private actions$: Actions,
    private api: ChatsService,
    private accessor: ChatsAccessor,
    private facilitiesAccessor: FacilitiesAccessor,
  ) {}

  fetchChats$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ChatActions.fetchChats),
      exhaustMap(({ filter: chatFilter }) =>
        this.facilitiesAccessor.activeFacility$.pipe(
          filter((v) => !!v),
          take(1),
          switchMap((f) =>
            this.api.fetchChats({ facility: f._id, pastChats: false, ...(chatFilter ? {licenses: chatFilter} : {}) }).pipe(
              map(({ models, totalCount }) =>
                ChatActions.fetchChatsSuccess({
                  models,
                  isDone: models.length >= totalCount,
                }),
              ),
              catchError((e) => of(ChatActions.fetchChatsFailed({ error: e }))),
            ),
          ),
        ),
      ),
      share(),
    ),
  );

  fetchNextChatsPage$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ChatActions.fetchNextChatsPage),
      withLatestFrom(
        this.accessor.chatsIds$,
        this.facilitiesAccessor.activeFacility$,
      ),
      exhaustMap(([{filter: chatFilter}, chats, f]) =>
        this.api
          .fetchChats(
            { facility: f._id, pastChats: false,  ...(chatFilter ? {licenses: chatFilter} : {}) },
            { skip: chats.length, limit: CHATS_PAGE_SIZE },
          )
          .pipe(
            map(({ models, totalCount }) =>
              ChatActions.fetchNextChatsPageSuccess({
                models,
                isDone: models.length + chats.length >= totalCount,
              }),
            ),
            catchError((e) => of(ChatActions.fetchNextChatsPageFailed({ error: e }))),
          ),
      ),
      share(),
    ),
  );

  fetchPastChats$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ChatActions.fetchPastChats),
      withLatestFrom(this.facilitiesAccessor.activeFacility$),
      exhaustMap(([{filter: chatFilter}, f]) =>
        this.api.fetchChats({ facility: f._id, pastChats: true,  ...(chatFilter ? {licenses: chatFilter} : {}) }).pipe(
          map(({ models, totalCount }) =>
            ChatActions.fetchPastChatsSuccess({
              models,
              isDone: models.length >= totalCount,
            }),
          ),
          catchError((e) => of(ChatActions.fetchPastChatsFailed({ error: e }))),
        ),
      ),
      share(),
    ),
  );

  fetchNextPastChatsPage$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ChatActions.fetchNextPastChatsPage),
      withLatestFrom(
        this.accessor.pastChatsIds$,
        this.facilitiesAccessor.activeFacility$,
      ),
      exhaustMap(([{filter: chatFilter}, chats, f, ]) =>
        this.api
          .fetchChats(
            { facility: f._id, pastChats: true, ...(chatFilter ? {licenses: chatFilter} : {}) },
            { skip: chats.length, limit: CHATS_PAGE_SIZE },
          )
          .pipe(
            map(({ models, totalCount }) =>
              ChatActions.fetchNextPastChatsPageSuccess({
                models,
                isDone: chats.length + models.length >= totalCount,
              }),
            ),
            catchError((e) => of(ChatActions.fetchNextPastChatsPageFailed({ error: e }))),
          ),
      ),
      share(),
    ),
  );

  fetchChat$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ChatActions.fetchChat),
      exhaustMap(({ id }) =>
        this.api.fetchChat(id).pipe(
          map((model) => ChatActions.fetchChatSuccess({ model })),
          catchError((e) => of(ChatActions.fetchChatFailed({ error: e }))),
        ),
      ),
      share(),
    ),
  );

  deleteChat$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ChatActions.deleteChat),
      exhaustMap(({ id }) =>
        this.api.deleteChat(id).pipe(
          map(() => ChatActions.deleteChatSuccess({ id })),
          catchError((e) => of(ChatActions.deleteChatFailed({ error: e }))),
        ),
      ),
      share(),
    ),
  );

  read$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ChatActions.read),
      exhaustMap(({ id }) =>
        this.api.read(id).pipe(
          map((model) => ChatActions.readSuccess({ model })),
          catchError((e) => of(ChatActions.readFailed({ error: e }))),
        ),
      ),
      share(),
    ),
  );

  sendMessage$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ChatActions.sendMessage),
      exhaustMap(({ dto }) =>
        this.api.sendMessage(dto).pipe(
          map((message) => ChatActions.sendMessageSuccess({ message })),
          catchError((e) => of(ChatActions.sendMessageFailed({ error: e }))),
        ),
      ),
      share(),
    ),
  );

  fetchMessages$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ChatActions.fetchMessages),
      exhaustMap(({ chatId }) =>
        this.api.fetchMessages(chatId).pipe(
          map(({ models, totalCount }) =>
            ChatActions.fetchMessagesSuccess({
              chatId,
              models,
              isDone: models.length >= totalCount,
            }),
          ),
          catchError((e) => of(ChatActions.fetchMessagesFailed({ error: e }))),
        ),
      ),
      share(),
    ),
  );

  fetchNextMessagesPage$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ChatActions.fetchNextMessagesPage),
      exhaustMap(({ chatId }) =>
        this.accessor.selectMessages(chatId).pipe(
          take(1),
          switchMap((messages) =>
            this.api
              .fetchMessages(chatId, {
                skip: messages.length,
                limit: MESSAGES_PAGE_SIZE,
              })
              .pipe(
                map(({ models, totalCount }) =>
                  ChatActions.fetchNextMessagesPageSuccess({
                    chatId,
                    models,
                    isDone: models.length + messages.length >= totalCount,
                  }),
                ),
                catchError((e) =>
                  of(ChatActions.fetchNextMessagesPageFailed({ error: e })),
                ),
              ),
          ),
        ),
      ),
      share(),
    ),
  );
  fetchChatByMetadata$ = createEffect(() => this.actions$.pipe(
    ofType(ChatActions.fetchChatByMetadata),
    exhaustMap(({ shiftId, nurseId }) =>
        this.api.fetchChatByMetadata(shiftId, nurseId)
            .pipe(
                map(model => ChatActions.fetchChatByMetadataSuccess({ model })),
                catchError(e => of(ChatActions.fetchChatByMetadataFailed({ error: e })))
            )
    ),
    share()
));
}
