import * as clone from 'clone';

import { FacilityModel } from "src/app/models/facility";
import { EditFacilityInstashyftsForm } from '../types/forms/edit-facility-instashyfts/edit-facility-instashyfts-form';

export class EditFacilityInstashyftsAdapter
{
    private _form: EditFacilityInstashyftsForm;
    private _facility: FacilityModel;

    private _adaptedFacility: FacilityModel;

    constructor(form: EditFacilityInstashyftsForm, facility: FacilityModel)
    {
        this._form = clone(form);
        this._facility = clone(facility);
    }

    private _adapt()
    {
        return {
            ...this._facility,
            instaShyftsDisabled: this._form.instaShyftsDisabled,
            instaShyftDefault: this._form.instaShyftDefault
        } as FacilityModel;
    }

    get model()
    {
        if(!this._adaptedFacility)
            this._adaptedFacility = this._adapt();

        return this._adaptedFacility;
    }
}
