import { UntypedFormGroup } from '@angular/forms';
import {
  Component,
  Input,
  OnChanges,
  OnInit,
  ViewEncapsulation,
} from '@angular/core';
import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { Checkboxes } from 'src/app/modules/manager/constants/filters/shifts/checkboxes-group-model';
import { CheckboxesData } from 'src/app/modules/manager/types/filters/checkboxes-data';

@Component({
  selector: 'app-checkboxes-filter',
  templateUrl: './checkboxes-filter.component.html',
  styleUrls: ['./checkboxes-filter.component.scss'],
})
export class CheckboxesFilterComponent implements OnInit, OnChanges {
  @Input() checkboxesGroupModel: Checkboxes[];
  @Input() form: UntypedFormGroup;
  @Input() checkboxesData: CheckboxesData;
  @Input() createShift: boolean = false;
  largeScreen = false;
  selectedCategories: string[] = [];
  constructor(public breakpointObserver: BreakpointObserver) {}
  public showFlag = false;
  ngOnInit(): void {
    Object.keys(this.checkboxesData).map((k) => {
      this.onToggle(k);
    });
    this.breakpointObserver
      .observe(['(min-width: 1920px)'])
      .subscribe((state: BreakpointState) => {
        if (state.matches) {
          this.largeScreen = true;
        } else {
          this.largeScreen = false;
        }
      });
  }
  ngOnChanges(changes) {
    if (changes.checkboxesData) {
      this.selectedCategories = [];
    }
  }
  onClick() {
    this.showFlag = !this.showFlag;
  }
  onCheckClick(type, value) {
    this.checkboxesData[type][value] = !this.checkboxesData[type][value];
    this.onToggle(type);
  }
  onToggle(type) {
    let arr = [];
    Object.keys(this.checkboxesData[type]).map((k) => {
      if (this.checkboxesData[type][k]) {
        arr.push(k);
      }
    });
    if (this.form.controls[type]) {
      this.form.controls[type].setValue(arr);
    }
    this.selectedCategories = [];
    Object.keys(this.checkboxesData).map((k) => {
      let flag = false;

      Object.keys(this.checkboxesData[k]).map((v) => {
        if (this.checkboxesData[k][v]) {
          flag = true;
        }
      });
      if (flag) {
        this.selectedCategories.push(k);
      }
    });
  }
}
