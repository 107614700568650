<div class="nurse-popup-wrapper" (click)="closePopup.emit()">
</div>
<div class="nurse-popup" (keydown.Space)="$event.stopImmediatePropagation();" (click)="$event.stopPropagation()">
  <ng-container *ngIf="nurse && facility$ | async as facility">

    <div class="ap-header">
      <span>Profile</span>
      <div (click)="closePopup.emit()">
        <mat-icon class="close">close</mat-icon>
      </div>
    </div>
    <div class="avatar-bg-block"></div>
    <div class="ap-avatar-block" style="background-image: url({{nurse.avatar}});"></div>
    <button class="mark-as-trusted-btn" fill="none" *ngIf="(!facilityNurse?.trusted?.isTrusted) && !isDnr" (click)="onMarkAsTrusted()"><mat-icon svgIcon="badgeCheck" class="trusted-icon" ></mat-icon> Mark as trusted</button>
    <div class="ap-name-container">
      <div class="ap-name-block">
        <div class="name-row">
          <div class="ap-name">{{ nurse.name.first }} {{ nurse.name.last }}
            <span>
              <mat-icon svgIcon="badgeCheck" class="trusted-icon" *ngIf="facilityNurse?.trusted?.isTrusted && !isDnr"></mat-icon>
            </span>
          </div>
        </div>
        <p class="ap-licenses">
          <ng-container *ngFor="let license of licenses">
            <span class="license {{ license }}">{{ license }}</span>
          </ng-container>
        </p>
      </div>

      <div class="ap-status-block">
        <div class="dnr-badge" *ngIf="isDnr">
          <span><span style="font-weight: 500">DNR</span> since {{dnrDate}}</span>
        </div>
        <div class="status-badge" [ngClass]="applicant.status" *ngIf="applicant">
          <mat-icon [svgIcon]="statusModel[applicant.status].icon">ligature</mat-icon>
          <span>{{ statusModel[applicant.status].text}}</span>
        </div>
        <div class="status-badge suspended"  *ngIf="!applicant && nurse.isDisabled">
          <mat-icon svgIcon="suspended">ligature</mat-icon>
          <span>Suspended</span>
        </div>
        
      </div>
    </div>
    <div class="nurse-distance-container">
      <div class="distance-block" *ngIf="nurse.address">
        <mat-icon svgIcon="home"></mat-icon>
        <span>{{distanceToFacility}} mi</span>
      </div>
      <div class="time-block" *ngIf="timeLabel" (click)="onTimeClick()">
        <ng-container *ngIf="timeLabel.type != 'none'">
          <mat-icon svgIcon="clock2" [ngClass]="{clockedOut: timeLabel.type === 'clockedOut'}"
            *ngIf="timeLabel.type != 'lastWorked'"></mat-icon>
          <mat-icon svgIcon="lastWorkedDay" class="lastWorked" *ngIf="timeLabel.type === 'lastWorked'"></mat-icon>
          <span class="time-label">
            {{timeLabel.text}}
            <span class="time">
              {{timeLabel.time}}
            </span>
          </span>
        </ng-container>
      </div>
    </div>
    <div class="ap-buttons-block">
      <ng-container *ngIf="applicant && (applicant.status != 'suspended')">
        <div class="ap-buttons-subblock" *ngIf="
            applicant.status === 'applied' || applicant.status === 'confirmed'
          ">
          <button fill="outline" color="border-base" class="ap-cancel-button" (click)="onCancel()" [ngClass]="{
              disabled: oldShift
            }" [disabled]="oldShift ? 'disabled' : null">
            {{ applicant.status === 'applied' ? 'Decline' : 'Cancel' }}
          </button>
          <button class="ap-confirm-button" [color]="applicant.isInOvertime ? 'rn' : 'primary'" [ngClass]="{
              disabled: (applicant.status !== 'applied') || oldShift
            }" [disabled]="(applicant.status !== 'applied') || oldShift ? 'disabled' : null"
            (click)="onConfirm(facility)" *ngIf="facility.deactivationStatus != 'Disabled'">
            Confirm
          </button>
        </div>
      </ng-container>
      <ng-container *ngIf="(applicant && (applicant.status != 'suspended')) || fromNurseDirectory" ></ng-container>
      <button class="ap-chat-button" color="chat-btn" fill="solid" (click)="goToChat()">
        Chat with {{ nurse.name.first }}
      </button>
    </div>
    <div class="tabs-block">
      <div class="tabs">
        <button fill="none" class="tab-btn" (click)="activeTab = 'details'"
          [ngClass]="{active: activeTab === 'details'}">Details</button>
        <button fill="none" class="tab-btn" (click)="onShiftPreferencesTabOpen()"
          [ngClass]="{active: activeTab === 'shiftPreferences'}">Shift preferences</button>
        <button fill="none" class="tab-btn" (click)="onShiftNotesTabOpen()"
          [ngClass]="{active: activeTab === 'notes'}">Notes</button>
      </div>
      <div class="tabs-content" *ngIf="activeTab === 'details'">
        <div class="info-row">
          <div class="info-block">
            <span class="name">Phone number</span>
            <span class="value">{{phone}}</span>
          </div>
        </div>
        <div class="info-row">
          <div class="info-block">
            <span class="name">Returning</span>
            <span class="value">{{facilityNurse.isReturning ? 'Yes' : 'No'}}</span>
          </div>
          <div class="info-block">
            <span class="name">Shifts worked at facility</span>
            <span class="value">{{facilityNurse.workedShiftsNumber ? facilityNurse.workedShiftsNumber : '-'}}</span>
          </div>
        </div>
        <div class="info-row">
          <div class="info-block">
            <span class="name">Last shift</span>
            <span class="value">{{lastShift}}</span>
          </div>
          <div class="info-block">
            <span class="name">Next shift</span>
            <span class="value">{{nextShift}}</span>
          </div>
        </div>
        <div class="ap-attachments-block">
          <span class="ap-attachments-title">Attachments</span>
          <div class="ap-attachment-block" (click)="sendAttachments()">
            <div class="icon"><mat-icon svgIcon="clip"></mat-icon></div>
            <span class="name">{{ nurse.name.first }} {{ nurse.name.last }} credentials</span>
            <div class="ap-attachment-line"></div>
            <span class="ap-attachment-link">Send to email</span>
          </div>
        </div>
        <div class="ap-dnr-wrapper">
          <div class="ap-dnr-block" *ngIf="!isDnr">
            <div class="ap-dnr-text-block">
              <span class="tile">DNR (do not return)</span>
              <span class="text">Prevent {{ nurse.name.first }} from viewing or picking up shifts at your
                facility.</span>
            </div>
            <button fill="outline-mono" color="danger" (click)="showModal = true; modalType = 'add'">DNR nurse</button>
          </div>
          <div class="ap-dnr-block" *ngIf="isDnr">
            <div class="ap-dnr-text-block">
              <span class="tile">Remove DNR</span>
              <span class="text">{{ nurse.name.first }} will be able to view and apply to shifts at your facility</span>
            </div>
            <button fill="outline-mono" (click)="showModal = true; modalType = 'remove'">Remove DNR</button>
          </div>
        </div>
        <div (click)="showModal = true; modalType = 'history'" class="view-history-btn"><mat-icon
            [svgIcon]="'logs'"></mat-icon> View DNR history</div>
        <app-dnr-modal *ngIf="showModal" (onClose)="showModal = false" [nurse]="nurse" [facility]="facility"
          [type]="modalType" (addNurseToDnr)="addNurseToDnr($event)"
          (removeNurseFromDnr)="removeNurseFromDnr($event)"></app-dnr-modal>
        <div class="remove-from-trusted-block" *ngIf="facilityNurse?.trusted?.isTrusted && !isDnr">
          <div class="remove-from-trusted-info">
            <div class="remove-from-trusted-title">
              <mat-icon svgIcon="badgeCheck"></mat-icon>
              <span>Remove from trusted</span>
            </div>
            <span class="text">{{nurse.name.first}} will no longer show up as a trusted nurse for your facility.</span>
          </div>
          <button fill="outline-mono" (click)="showRemoveFromTrusted = true">Remove from trusted</button>
        </div>
      </div>
      <div class="tabs-content" *ngIf="activeTab === 'shiftPreferences'">
        <div class="no-preferences" *ngIf="!nurse.shiftPreferences">
          <img src="../../../../../../assets/img/no-preferences.svg" alt="">
          <span class="np-title">This nurse hasn't shared their shift preferences yet </span>
          <span class="np-text">Once this nurse shares their shift preferences, it will appear here and in the ‘Nurses’
            tab.</span>
          <span class="np-text last-request" *ngIf="lastShiftPreferencesRequestDate">Last request sent on {{lastShiftPreferencesRequestDate}}</span>
          <button (click)="onRequestShiftPreferences()">Request {{nurse.name.first}} to share shift preferences</button>
        </div>
        <div class="preferences-block" *ngIf="nurse.shiftPreferences">
          <div class="preferences-week-block">
            <div class="preferences-name-block">
              <mat-icon svgIcon="dayShift-01" class="shift-type-icon"></mat-icon>
              <span>Day shifts</span>
              <mat-icon [svgIcon]="'info-2'" class="info-icon" customToolTip [contentTemplate]="dayShifts"
                [offsetY]="-16" [offsetX]="60"></mat-icon>
              <ng-template #dayShifts>
                <div class="info-tooltip">
                  <span>Day shifts start between <b>4am</b> and <b>10am</b></span>
                </div>
              </ng-template>
            </div>
            <div class="week-block">
              <div *ngFor="let day of weekDays; let i = index" class="day"
                [ngClass]="{active: nurse.shiftPreferences.shiftTimesAndDays.dayShift.type[i]}">{{day}}</div>
            </div>
          </div>
          <div class="preferences-week-block">
            <div class="preferences-name-block">
              <mat-icon svgIcon="eveningShift-01" class="shift-type-icon"></mat-icon>
              <span>Evening shifts</span>
              <mat-icon [svgIcon]="'info-2'" class="info-icon" customToolTip [contentTemplate]="eveningShifts"
                [offsetY]="-16" [offsetX]="60"></mat-icon>
              <ng-template #eveningShifts>
                <div class="info-tooltip">
                  <span>Evening shifts start between <b>10am</b> and <b>6pm</b></span>
                </div>
              </ng-template>
            </div>
            <div class="week-block">
              <div *ngFor="let day of weekDays; let i = index" class="day"
                [ngClass]="{active: nurse.shiftPreferences.shiftTimesAndDays.eveningShift.type[i]}">{{day}}</div>
            </div>
          </div>
          <div class="preferences-week-block">
            <div class="preferences-name-block">
              <mat-icon svgIcon="nightShift-01" class="shift-type-icon"></mat-icon>
              <span>Night shifts</span>
              <mat-icon [svgIcon]="'info-2'" class="info-icon" customToolTip [contentTemplate]="nightShifts"
                [offsetY]="-16" [offsetX]="60"></mat-icon>
              <ng-template #nightShifts>
                <div class="info-tooltip">
                  <span>Night shifts start between <b>6pm</b> and <b>4am</b></span>
                </div>
              </ng-template>
            </div>
            <div class="week-block">
              <div *ngFor="let day of weekDays; let i = index" class="day"
                [ngClass]="{active: nurse.shiftPreferences.shiftTimesAndDays.nightShift.type[i]}">{{day}}</div>
            </div>
          </div>
        </div>
      </div>
      <div class="tabs-content" *ngIf="activeTab === 'notes'">

        <div class="notes-block">
          <div class="notes-header">
            <div class="top-block">
              <div class="count-block">
                <span class="label">Notes</span>
                <span class="value">{{notes.length}}</span>
              </div>
              <div class="buttons-block">
                <button fill="none" *ngIf="!addNote" (click)="addNote = true">
                  + Add note
                </button>
              </div>
            </div>
            <div class="input-block" *ngIf="addNote" [formGroup]="createNoteForm">
              <div class="input-row">
                <img src="{{manager.avatar}}" alt="" class="avatar">

                <textarea placeholder="Add your note..." matInput cdkTextareaAutosize #autosize="cdkTextareaAutosize" [ngClass]="{error: !createNoteForm.valid && createNoteForm.dirty}"
                  cdkAutosizeMinRows="1" formControlName="note"></textarea>
              </div>
              <span class="error-label" *ngIf="!createNoteForm.valid && createNoteForm.dirty">
                Note must be at least 10 and at most 280 characters
              </span>
              <div class="buttons-row">

                <button fill="outline" color="border-base" (click)="addNote = false" class="cancel">Cancel</button>
                <button class="add-note" (click)="onAddNote()" [disabled]="!createNoteForm.valid" [ngClass]="{disabled: !createNoteForm.valid}">Add note</button>
              </div>

            </div>
          </div>
          <div class="notes-list" *ngIf="notes.length">
            <div class="note" *ngFor="let note of notes; let i = index">
              <div class="left">
                <ng-container>
                  <img src="{{note.avatar}}" alt="" class="avatar" *ngIf="note.action === 'addNote'">
                  <div class="trusted badge" *ngIf="note.action === 'addToTrusted'">
                    <mat-icon svgIcon="badgeCheck"></mat-icon>
                  </div>
                  <div class="untrusted badge" *ngIf="note.action === 'removeFromTrusted'">
                    <mat-icon svgIcon="badgeCheck"></mat-icon>
                  </div>
                  <div class="dnr badge" *ngIf="note.action === 'addToDnr'">
                    <span>DNR</span>
                  </div>
                  <div class="remove-from-dnr badge" *ngIf="note.action === 'removeFromDnr'">
                    <span>DNR</span>
                  </div>
                </ng-container>
                <div class="v-line"></div>
              </div>
              <div class="right" [ngClass]="{'with-badge': note.action != 'addNote'}">
                <div class="name-block">
                  <div class="info">
                    <span class="name">
                      {{note.name}}
                    </span>
                    <span class="action-type">
                      <ng-container *ngIf="note.action === 'addNote'">
                        &nbsp;added note
                      </ng-container>
                      <ng-container *ngIf="note.action === 'addToTrusted'">
                        &nbsp;marked <span class="name"> </span> as trusted
                      </ng-container>
                      <ng-container *ngIf="note.action === 'removeFromTrusted' && note.name?.length">
                        &nbsp;removed <span class="name"> {{note.nurse.name.first}}</span> from trusted
                      </ng-container>
                      <ng-container *ngIf="note.action === 'removeFromTrusted' && !note.name?.length">
                        Removed <span class="name"> {{note.nurse.name.first}}</span> from trusted
                      </ng-container>
                      <ng-container *ngIf="note.action === 'addToDnr'">
                        &nbsp;DNR <span class="name"> {{note.nurse.name.first}}</span>
                      </ng-container>
                      <ng-container *ngIf="note.action === 'removeFromDnr'">
                        &nbsp;removed <span class="name"> {{note.nurse.name.first}}</span> from DNR
                      </ng-container>
                    </span>
                  </div>
                  <div class="action-block">
                    <ng-container *ngIf="note.my && (note.action === 'addNote') && !editing">
                      <button fill="none" class="more-btn" #trigger="cdkOverlayOrigin" cdkOverlayOrigin
                        (click)="notesOnMoreClick($event, i)">
                        <mat-icon [ngClass]="{active: note.showMoreModal}" >more_vert</mat-icon>
                      </button>
                      <!-- [ngClass]="{ active: showMore }"
    
                      [cdkConnectedOverlayOpen]="showMore"
                       (overlayOutsideClick)="onMoreClick($event)" -->
                      <ng-template cdkConnectedOverlay [cdkConnectedOverlayOrigin]="trigger"
                        [cdkConnectedOverlayOpen]="note.showMoreModal" (overlayOutsideClick)="notesOnMoreClose(i)">
                        <div class="edit-modal">
                          <button fill="none" (click)="onNoteEdit(i)"><span>Edit</span></button>
                          <button fill="none" (click)="onNoteDelete(i)" class="delete"><span>Delete</span></button>
                        </div>
                      </ng-template>
                    </ng-container>
                  </div>
                </div>
                <div class="date-time-block">
                  <span class="date">
                    {{note.date}}
                  </span>
                  <span class="edited" *ngIf="note.edited">Edited</span>
                </div>
                <div class="text" *ngIf="note.text && !note.isEditing">
                  <span>
                    {{note.text}}
                  </span>
                </div>
                <div class="edited-area" [formGroup]="editingForm" *ngIf="note.isEditing">
                  <textarea placeholder="Add your note..." matInput cdkTextareaAutosize #autosize="cdkTextareaAutosize"
                    cdkAutosizeMinRows="1" formControlName="note" [ngClass]="{error: !editingForm.valid && editingForm.dirty}"></textarea>
                  <span class="error-label" *ngIf="!editingForm.valid && editingForm.dirty">
                    Note must be at least 10 and at most 280 characters
                  </span>
                  <div class="buttons-row">
                    <button fill="outline" color="border-base" (click)="onCancelEdit(i)" class="cancel">Cancel</button>
                    <button class="add-note" (click)="onNoteSave(i)" [disabled]="!editingForm.valid && editingForm.dirty" [ngClass]="{disabled: !editingForm.valid && editingForm.dirty}">Save</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>
  </ng-container>
</div>
<app-remove-from-trusted-popup
    *ngIf="showRemoveFromTrusted"
    (modalClose)="showRemoveFromTrusted = false"
    (onRemove)="removeFromTrusted($event)"
    [nurseData]="facilityNurse"
>
</app-remove-from-trusted-popup>
