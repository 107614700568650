import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl, UntypedFormBuilder, Validators } from '@angular/forms';
import { DateTime } from 'luxon';
import { filter, take } from 'rxjs/operators';
import { licensesObjSort } from 'src/app/helpers/licensesSort';
import { FacilityWithBindings, ShiftWithBindings } from '../../../bindings';
import { ServicedStatesAccessor } from '../../../services/accessors/serviced-states.accessor';
import { FacilitiesService } from '../../../services/api/facilities.service';
import { ShiftsFacade } from '../../../services/facades/shifts/shifts.facade';
import { MessagesService } from '../../../services/messages.service';
import { LoaderService } from '../../../services/ui/loader.service';
import { CreateShiftForm, EditShiftForm } from '../../../types/forms';
import { CdkTextareaAutosize } from '@angular/cdk/text-field';
import { RateValidation, SelectData } from '../input/input.component';
import { UserAccessor } from 'src/app/services/accessors/user.accessor';
import { ServicedStateModel } from 'src/app/models/serviced-state';
import * as moment from 'moment';
import { ShiftTemplateDefinition } from 'src/app/types/models/shift/shift-template-definition';
import { AUTO_CONFIRM_OPTIONS, AUTO_CONFIRM_OPTIONS_OBJ } from '../../../constants/auto-confirmation-options';
import { IntercomService } from '../../../services/intercom.service';
import { NewBadgeService } from '../../../services/new-badge.service';


@Component({
  selector: 'app-create-edit-shift',
  templateUrl: './create-edit-shift.component.html',
  styleUrls: ['./create-edit-shift.component.scss'],
})
export class CreateEditShiftComponent implements OnInit {
  @Input() shift: ShiftWithBindings;
  @Input() create: boolean = false;
  @Input() shiftTimeId: string = '';
  @Input() shiftDate: string = '';
  @Input() facility: FacilityWithBindings;
  @Input() customTempShift: { id: string, label: string };
  @Input() createShiftType: string = 'regular';
  
  @Output() onClose = new EventEmitter();
  @Output() onForceUpd = new EventEmitter();
  @ViewChild('autosize') autosize: CdkTextareaAutosize;

  disabledInputs = false;
  submitButton = true;
  licenses: SelectData[] = [];
  state$ = this.servicedStatesAccessor.activeState;
  shiftsTemplates: SelectData[] = [];
  rateValid: RateValidation = {
    min: 0,
    max: 0,
  };
  form = this.fb.group({
    slots: [1, [Validators.required, Validators.max(15)]],
    rate: [0],
    date: ['', [Validators.required]],
    license: ['', [Validators.required]],
    shiftTemplate: ['', [Validators.required]],
    description: ['', [Validators.minLength(10), Validators.maxLength(280)]],
    insta: [false],
    autoConfirmGroups: ['none']
  });
  defaultRate = 0;
  doNotShowInstaShyftMessage = false;
  state: ServicedStateModel;
  showAddShiftTimeModal = false;
  customShiftSelected: string = null;
  isTempShiftTemplate: boolean = false;
  shiftTemplateDefinition: ShiftTemplateDefinition;
  customShift: SelectData;
  showDescription = new FormControl(false);
  autoConfirmOptions = AUTO_CONFIRM_OPTIONS;
  autoConfirmOptionsObj = AUTO_CONFIRM_OPTIONS_OBJ;
  displayNewBadge = false;
  get autoConfirmGroupsControl(): FormControl {
    return this.form.get('autoConfirmGroups') as FormControl;
  }

  constructor(
    private servicedStatesAccessor: ServicedStatesAccessor,
    private facilitiesService: FacilitiesService,
    private fb: UntypedFormBuilder,
    private shiftsFacade: ShiftsFacade,
    private messages: MessagesService,
    private loader: LoaderService,
    private userAccessor: UserAccessor,
    private intercomService: IntercomService,
    private newBadgeService: NewBadgeService
  ) {}

  ngOnInit(): void {
    this.displayNewBadge = this.newBadgeService.shouldDisplayNewBadge();
    if(this.shift?.description){
      this.showDescription.setValue(true);
    }
    if(this.shift?.autoConfirmGroups?.length){
      this.autoConfirmGroupsControl.setValue(this.shift.autoConfirmGroups[0])
    }
    if(this.create && this.createShiftType != 'insta'){
      this.autoConfirmGroupsControl.setValue('trusted')
    }
    this.userAccessor.user$.pipe(take(1)).subscribe((u) => {
      if (u.doNotShowInstaShyftMessage || (u.position != 'facility-manager')) {
        this.doNotShowInstaShyftMessage = true;
      }
    });
    this.state$
      .pipe(
        filter((v) => !!v),
        take(1),
      )
      .subscribe((s) => {
        this.state = s;
        this.licenses = [];
        s.servicedLicenses.map((l) => {
          this.licenses.push({
            id: l.name,
            text: l.name,
          });
        });
        this.licenses = licensesObjSort(this.licenses);
      });
    this.generateShiftsTemplates();
    this.facilitiesService.getInstashyftStats(this.facility._id).pipe(take(1)).subscribe((s)=> {
      //logic for "new" tag
      const featureReleaseDateCheck = DateTime.now().toUTC() >= DateTime.fromISO(s.featureReleaseDate).toUTC().plus({ days: 21 });
    
    });
    //this.setRateLimits(this.form.controls.license.value);
    this.form.controls.shiftTemplate.setValue(this.shiftTimeId);
    this.form.get('license').valueChanges.subscribe((x) => {
      this.setRateLimits();
    });
    this.form.get('date').valueChanges.subscribe((x) => {
      this.setRateLimits();
    });
    if (this.shiftDate && this.create) {
      this.form.controls.date.setValue(this.shiftDate);
    }
    if (!this.create && this.shift) {
      const shiftDate = DateTime.fromISO(this.shift.shiftData.shiftFrom, {
        zone: this.shift.facility.timezone,
      }).toFormat('yyyy-MM-dd');
      this.form.controls.date.setValue(shiftDate);
      this.form.controls.description.setValue(this.shift.description);
      this.form.controls.license.setValue(this.shift.license);
      this.form.controls.rate.setValue(this.shift.shiftData.rate);
      this.form.controls.shiftTemplate.setValue(
        this.shift.shiftData.shiftTemplate._id,
      );
      this.form.controls.slots.setValue(this.shift.slots);
      this.shift.applicants.forEach((applicant) => {
        if (applicant.status === 'applied' || applicant.status === 'confirmed') {
          this.disabledInputs = true;
          this.showDescription.disable();
        }
      });
    }
  }
  generateShiftsTemplates() {
    this.facility.shiftTemplates.forEach((value) => {
      let from = moment(value.startTime, 'h:mm A').format('h:mma');
      let to = moment(value.endTime, 'h:mm A').format('h:mma');
      let template = {
        id: value._id,
        text: from + ' - ' + to,
      };
      this.shiftsTemplates.push(template);    
    });
    if(this.customTempShift && this.create) {
      this.shiftsTemplates.push({
        id: this.customTempShift.id,
        text: this.customTempShift.label.toLowerCase()
      });
    }
    if(this.shift?.shiftData.shiftTemplate.isDisposable) {
      let from = moment(this.shift.shiftData.shiftTemplate.startTime, 'h:mm A').format('h:mma');
      let to = moment(this.shift.shiftData.shiftTemplate.endTime, 'h:mm A').format('h:mma');
      this.shiftsTemplates.push({
        id: this.shift.shiftData.shiftTemplate._id,
        text: from + ' - ' + to,
      });
    }
    this.shiftsTemplates.push({id: 'createCustom', text: 'Add a new shift time', addNew: true});
  }
  switchSubmit(e) {
    this.submitButton = e;
  }
  setRateLimits() {
    const license = this.form.get('license').value;
    let shiftDate;
    if(!license) return;
    if(!this.create) { 
      shiftDate = DateTime.fromISO(this.form.get('date').value).toFormat('yyyy-MM-dd');
    } else {
      shiftDate = this.shiftDate;
    }
    let shiftDateDay = DateTime.fromFormat(shiftDate, 'yyyy-MM-dd').weekday;
    let defaultRateLimit;
    if (shiftDateDay == 6 || shiftDateDay == 7) {
      this.rateValid.max = this.facility.rateLimits.weekend[license].max;
      this.rateValid.min = this.facility.rateLimits.weekend[license].min;
      defaultRateLimit = this.facility.defaultRateLimits.weekend[license];
    } else {
      this.rateValid.max = this.facility.rateLimits.weekday[license].max;
      this.rateValid.min = this.facility.rateLimits.weekday[license].min;
      defaultRateLimit = this.facility.defaultRateLimits.weekday[license];
    }
    if(this.create){
      this.form.controls.rate.setValue(defaultRateLimit);
    }
    this.form.controls.rate.setValue(
      parseFloat(this.form.controls.rate.value),
    );
  }
  onSubmit() {
    if (this.create) {
      this.createShift();
    } else {
      this.editShift();
    }
  }
  editShift() {
    let editShiftForm: EditShiftForm = {};
    if(this.disabledInputs){
      editShiftForm.slots = parseInt(this.form.get('slots').value);
    }else{
      editShiftForm = this.form.value;
      editShiftForm.slots = parseInt(this.form.get('slots').value);
      editShiftForm.rate = parseFloat(this.form.get('rate').value);
      if(!this.shift.isInstaShyft){
        editShiftForm.autoConfirmGroup = this.autoConfirmGroupsControl.value;
      }
      if(this.isTempShiftTemplate) { 
        delete editShiftForm.shiftTemplate; 
        editShiftForm.shiftTemplateDefinition = this.shiftTemplateDefinition;
      }
      if(this.shift.isInstaShyft){
        delete editShiftForm.autoConfirmGroup;
      }
    }
    this.shift
      .edit(editShiftForm)
      .pipe(take(1))
      .subscribe(() => {
        this.onClose.emit();
        this.onForceUpd.emit();
      });
  }
  createShift() {
    this.loader.create('Loading...');
    let createShiftsForm: CreateShiftForm[] = [];
    if(this.createShiftType === 'insta'){
      this.form.get('insta').setValue(true);
    }
    createShiftsForm[0] = {
      date: this.form.get('date').value,
      slots: parseInt(this.form.get('slots').value),
      description: this.form.get('description').value,
      license: this.form.get('license').value,
      shiftTemplate: this.form.get('shiftTemplate').value,
      rate: parseFloat(this.form.get('rate').value),
      isInstaShyft: this.form.get('insta').value,
      autoConfirmGroup: this.autoConfirmGroupsControl.value
    };
    if(this.isTempShiftTemplate) { 
      delete createShiftsForm[0].shiftTemplate; 
      createShiftsForm[0].shiftTemplateDefinition = this.shiftTemplateDefinition;
    }

    this.shiftsFacade
      .createShifts(createShiftsForm)
      .pipe(take(1))
      .subscribe((data: any) => {
        if (data.type === '[SHIFTS] CREATE_SHIFTS_SUCCESS') {
          this.messages.add(
            'Your shifts have been published. View your shifts.',
            '',
            'success',
          );
          this.onClose.emit();
          this.onForceUpd.emit();
        } else {
          this.messages.add(data.error.error.message, '', 'danger');
        }
        this.loader.hide();
      });
  }

  clearSelection() {
    this.form.controls.shiftTemplate.setValue('');
    this.customShiftSelected = null;
  }

  saveNewShiftTime(event) {
    let displayStartTime = moment(event.startTime, 'h:mm A').format('h:mma');
    let displayEndTime = moment(event.endTime, 'h:mm A').format('h:mma');
    let startTime = moment(displayStartTime, 'h:mma').format('HH:mm');
    let endTime = moment(displayEndTime, 'h:mma').format('HH:mm');
    let shiftTemplate = event;

    this.showAddShiftTimeModal = false;

    this.isTempShiftTemplate = true;
   
    this.shiftTemplateDefinition = {
      startTime: startTime,
      endTime: endTime,
      clockInTime: event.clockInTime,
      icon: 'timer'
    }

    let timeString =
      displayStartTime +
      ' - ' +
      displayEndTime;

    this.customShift = {
      id: shiftTemplate?.id,
      text: timeString,
    };
        
    this.shiftsTemplates.unshift(this.customShift);
    this.shiftsTemplates = [...this.shiftsTemplates];

    this.customShiftSelected = shiftTemplate?.id;
  }
  openLearnMore(){
    this.intercomService.openArticle('10211786');
  }
}
