export function formatPhone(phone: string)
{
  if(!phone) return undefined;
    const normalizedPhone = phone.replace(/\D/, '');

    if (normalizedPhone.length < 10)
        return phone;
    else if (normalizedPhone.length == 10)
        return normalizedPhone.replace(/^(\d{3})(\d{3})(\d{4})$/, '$1-$2-$3');
    else return normalizedPhone.replace(/^(\d+)(\d{3})(\d{3})(\d{4})$/, '+$1 $2-$3-$4');
}
export function formatPhoneForManager(phoneNumber: string)
{
    let digits = phoneNumber.replace(/\D/g, '');
    if(digits[0] === '1'){
        digits = digits.substring(1);
    }
    if (digits.length < 10) {
        const match = digits.match(/^(\d{1,3})(\d{1,3})?(\d{0,4})?$/);
        if (match) {
            let formattedNumber = `(${match[1]}`;
            if (match[2]) {
                formattedNumber += `) ${match[2]}`;
                if (match[3]) {
                    formattedNumber += `-${match[3]}`;
                }
            } else {
                formattedNumber += ')';
            }
            return formattedNumber;
        }
    } else {
        const match = digits.match(/^(\d{3})(\d{3})(\d{4})(\d*)$/);
        if (match) {
            return `(${match[1]}) ${match[2]}-${match[3]}${match[4] ? match[4] : ''}`;
        }
    }
    return phoneNumber;
}
