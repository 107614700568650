import * as clone from 'clone';

import { AddSupervisorDTO } from "../types/dto";
import { AddSupervisorForm } from "../types/forms";

export class AddSupervisorAdapter
{
    private _form: AddSupervisorForm;
    private _dto: AddSupervisorDTO;

    constructor(form: AddSupervisorForm)
    {
        this._form = clone(form);
    }

    private _adapt()
    {
        return {
            email: this._form.email,
            name: {
                first: this._form.firstName,
                last: this._form.lastName
            },
            ...(this._form?.jobTitle ? {fmJobTitle: this._form.jobTitle} : {})
        } as AddSupervisorDTO;
    }

    get dto()
    {
        if(!this._dto)
            this._dto = this._adapt();

        return this._dto;
    }
}