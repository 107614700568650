import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { asapScheduler } from 'rxjs';
import { take } from 'rxjs/operators';
import { AppService } from 'src/app/services/app.service';
import { State } from 'src/app/state/interfaces';
import { HolidaysActions } from '../../../state/actions/holidays/notifications-action-types';
import { HolidaysEffects } from '../../../state/effects/holidays.effects';
import { HolidaysAccessor } from '../../accessors/holidays.accessor';

@Injectable()
export class HolidaysFacade
{
    constructor(
        app: AppService,
        private store: Store<State>,
        private accessor: HolidaysAccessor,
        private _effects: HolidaysEffects
    )
    {
        app.expose('facades', 'holidays', this);
    }

    get effects() { return this._effects; }

    get state() { return this.accessor; }

    fetchHolidays()
    {
        asapScheduler.schedule(() => this.store.dispatch(HolidaysActions.fetchHolidays()));
        return this.effects.fetchHolidays$.pipe(take(1));
    }
}
