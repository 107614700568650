import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';

import { selectChatsCount, selectShiftsCount, selectUpdatedShifts } from 'src/app/modules/manager/state/selectors/notifications';
import { State } from 'src/app/state/interfaces';

@Injectable()
export class NotificationsAccessor
{
    constructor(private store: Store<State>) { }

    get chatsUnreadCount$() { return this.store.select(selectChatsCount); }
    get shiftsUpdatedCount$() { return this.store.select(selectShiftsCount); }
    get updatedShifts$() { return this.store.select(selectUpdatedShifts); }

}
