export const APPLICANT_STATUSES = {
  applied: {
    text: 'Applied',
    icon: 'mdPlusCircle-01',
  },
  confirmed: {
    text: 'Confirmed',
    icon: 'mdCheckCircle-01',
  },
  declined: {
    text: 'Declined',
    icon: 'mdStop-01',
  },
  calledOut: {
    text: 'Called out',
    icon: 'mdExclamationCircle-01',
  },
  cancelled: {
    text: 'Canceled',
    icon: 'mdXCircle-01',
  },
  suspended: {
    text: 'Suspended',
    icon: 'suspended',
  },
};
