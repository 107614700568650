import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import {
  CHATS_PAGE_SIZE,
  MESSAGES_PAGE_SIZE,
} from 'src/app/constants/pagination';
import { parametrify } from 'src/app/helpers/parametrify';
import { ChatMessageModel, ChatModel } from 'src/app/models/chat';
import { PaginationDTO, SortDTO } from 'src/app/types/dto';

import { environment } from 'src/environments/environment';
import { FetchChatsDTO } from '../../types/dto';
import { SendMessageDTO } from '../../types/dto/send-message';

@Injectable()
export class ChatsService {
  private endpoint = environment.apiUrl;

  constructor(private http: HttpClient) {}

  fetchChats(
    dto: FetchChatsDTO,
    pagination: PaginationDTO = { skip: 0, limit: CHATS_PAGE_SIZE },
    sort: SortDTO = { sort: '_id', order: 'desc' },
  ) {
    const params = new HttpParams({
      fromObject: {
        ...parametrify(dto),
        ...parametrify(pagination),
        ...parametrify(sort),
        populate: ['nurse'],
      },
    });

    return this.http
      .get<ChatModel[]>(`${this.endpoint}/chats`, {
        params,
        observe: 'response',
      })
      .pipe(
        map((response) => ({
          models: response.body,
          totalCount: parseInt(response.headers.get('X-Total-Count')) || 0,
        })),
      );
  }
  fetchLastThreeChats(
    dto: FetchChatsDTO,
    pagination: PaginationDTO = { skip: 0, limit: 3 },
    sort: SortDTO = { sort: 'lastMessage._id', order: 'desc' },
  ) {
    const params = new HttpParams({
      fromObject: {
        ...parametrify(dto),
        ...parametrify(pagination),
        ...parametrify(sort),
        populate: ['nurse'],
      },
    });

    return this.http.get<ChatModel[]>(`${this.endpoint}/chats`, {
      params,
    });
  }
  fetchChat(
    id: string,
    pagination: PaginationDTO = { skip: 0, limit: MESSAGES_PAGE_SIZE },
  ) {
    const params = new HttpParams({
      fromObject: {
        ...parametrify(pagination),
        populate: ['nurse', 'shift.shiftData.shiftTemplate'],
      },
    });

    return this.http.get<ChatModel>(`${this.endpoint}/chats/${id}`, { params });
  }
  fetchChatByMetadata(shiftId: string, nurseId: string) {
    let params = {
      shift: shiftId,
      nurse: nurseId,
    };
    return this.http.get<ChatModel>(`${this.endpoint}/chats/bymetadata/`, {
      params,
    });
  }
  deleteChat(id: string) {
    return this.http.delete(`${this.endpoint}/chats/${id}`);
  }

  read(id: string) {
    const params = new HttpParams({
      fromObject: {
        populate: ['nurse', 'shift.shiftData.shiftTemplate'],
      },
    });
    return this.http.get<ChatModel>(`${this.endpoint}/chats/${id}/read`, {params});
  }

  sendMessage(dto: SendMessageDTO) {
    return this.http.post<ChatMessageModel>(
      `${this.endpoint}/chats/message`,
      dto,
    );
  }

  fetchMessages(
    id: string,
    pagination: PaginationDTO = { limit: MESSAGES_PAGE_SIZE, skip: 0 },
  ) {
    const params = new HttpParams({
      fromObject: {
        ...parametrify(pagination),
      },
    });

    return this.http
      .get<ChatMessageModel[]>(`${this.endpoint}/chats/${id}/messages`, {
        params,
        observe: 'response',
      })
      .pipe(
        map((response) => ({
          models: response.body,
          totalCount: parseInt(response.headers.get('X-Total-Count')) || 0,
        })),
      );
  }
}
