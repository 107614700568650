<div class="shift-time-modal-wrapper">
    <div class="shift-time-modal-block" (click)="$event.stopPropagation()">
        <div class="shift-time-content">
            <div class="title">
                <span>Add a new shift time</span>
            </div>
            <div class="shift-time-select" [formGroup]="newShiftForm">
                <div class="input-container">
                    <app-input
                        type="customTime"
                        label="Shift start"
                        labelColor="text"
                        formControlName="startTime"
                        [error]="!newShiftForm.get('startTime').valid && (newShiftForm.get('startTime').touched || newShiftForm.get('startTime').dirty)"
                    ></app-input>
                    <div *ngIf="!newShiftForm.get('startTime').valid && (newShiftForm.get('startTime').touched || newShiftForm.get('startTime').dirty)" class="error-block text">
                        <span>Shift duration must be between 2 hours and 16 hours</span>
                    </div>
                </div>
                <div class="input-container">
                    <app-input
                        label="Shift end"
                        labelColor="text"
                        type="customTime"
                        formControlName="endTime"
                        [error]="!newShiftForm.get('endTime').valid && (newShiftForm.get('endTime').touched || newShiftForm.get('endTime').dirty)"
                    ></app-input>
                    <div *ngIf="!newShiftForm.get('endTime').valid && (newShiftForm.get('endTime').touched || newShiftForm.get('endTime').dirty)" class="error-block text">
                        <span>Shift duration must be between 2 hours and 16 hours</span>
                    </div>
                </div>
                <div class="input-container">
                    <app-input
                        label="Clock in"
                        labelColor="text"
                        type="text"
                        formControlName="clockInTime"
                        labelTooltipText="Minutes before shift"
                        [error]="!newShiftForm.get('clockInTime').valid && (newShiftForm.get('clockInTime').touched || newShiftForm.get('clockInTime').dirty)"
                    ></app-input>
                    <div *ngIf="!newShiftForm.get('clockInTime').valid && (newShiftForm.get('clockInTime').touched || newShiftForm.get('clockInTime').dirty)" class="error-block text">
                        <span>Clock in time must be 120 minutes or less</span>
                    </div>
                </div>
            </div>
            <div class="buttons-block">
                <button fill="outline" color="border-base"  class="cancel-button" (click)="cancel();">Cancel</button>
                <button color="primary" [ngClass]="{disabled:!newShiftForm.valid && (newShiftForm.touched || newShiftForm.dirty)}" class="save-button" [disabled]="!newShiftForm.valid && (newShiftForm.touched || newShiftForm.dirty)"(click)="onSaveNewShiftClick()">Save a new shift time</button>
            </div>
        
        </div>
        
    </div>
</div>
