import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";

import { of } from "rxjs";
import { catchError, exhaustMap, map, share, withLatestFrom } from "rxjs/operators";

import { TIMECARDS_PAGE_SIZE } from "src/app/constants/pagination";
import { clamp } from "src/app/helpers/math";

import { FacilitiesAccessor } from "../../services/accessors/facilities.accessor";
import { TimecardsAccessor } from "../../services/accessors/timecards.accessor";
import { TimecardsService } from "../../services/api/timecards.service";

import { FilterService } from '../../services/filter.service';
import { TimecardsActions } from "../actions/timecards/timecards-action-types";

@Injectable()
export class TimecardsEffects
{
    constructor(
        private actions$: Actions,
        private api: TimecardsService,
        private accessor: TimecardsAccessor,
        private facilitiesAccessor: FacilitiesAccessor,
        private filterService: FilterService,
    ) { }

    fetchTimecards$ = createEffect(() => this.actions$.pipe(
        ofType(TimecardsActions.fetchTimecards),
        withLatestFrom(this.facilitiesAccessor.activeFacility$),
        exhaustMap(([{ filters }, f]) =>
            this.api.fetchTimecards(f._id, filters)
                .pipe(
                    map(({ models, totalCount }) => TimecardsActions.fetchTimecardsSuccess({ models, isDone: models.length >= totalCount })),
                    catchError(e => of(TimecardsActions.fetchTimecardsFailed({ error: e })))
                )
        ),
        share()
    ));

    fetchTimecard$ = createEffect(() => this.actions$.pipe(
        ofType(TimecardsActions.fetchTimecard),
        exhaustMap(({ timecardId }) =>
            this.api.fetchTimecard(timecardId)
                .pipe(
                    map(model => {this.filterService.checkTimecard(model); return TimecardsActions.fetchTimecardSuccess({ model })}),
                    catchError(e => of(TimecardsActions.fetchTimecardFailed({ error: e })))
                )
        ),
        share()
    ));

    fetchNextTimecardsPage$ = createEffect(() => this.actions$.pipe(
        ofType(TimecardsActions.fetchNextTimecardsPage),
        withLatestFrom(this.accessor.timecardsIds$, this.facilitiesAccessor.activeFacility$),
        exhaustMap(([{ filters }, timecards, f]) =>
            this.api.fetchTimecards(f._id, filters, { skip: timecards.length, limit: TIMECARDS_PAGE_SIZE })
                .pipe(
                    map(({ models, totalCount }) => TimecardsActions.fetchNextTimecardsPageSuccess({ models, isDone: (models.length + timecards.length) >= totalCount })),
                    catchError(e => of(TimecardsActions.fetchNextTimecardsPageFailed({ error: e })))
                )
        ),
        share()
    ));

    fetchPage$ = createEffect(() => this.actions$.pipe(
        ofType(TimecardsActions.fetchPage),
        withLatestFrom(this.facilitiesAccessor.activeFacility$),
        exhaustMap(([{ filters, limit, page, sort }, f]) =>
            this.api.fetchTimecards(f._id, filters, { limit, skip: clamp(page, 0, Number.MAX_SAFE_INTEGER) * limit }, sort)
                .pipe(
                    map(({ models, totalCount }) =>
                    {
                        const pagesCount = Math.ceil(totalCount / limit);
                        return TimecardsActions.fetchPageSuccess({ models, limit, page, pagesCount, totalCount});
                    }),
                    catchError(e => of(TimecardsActions.fetchPageFailed({ error: e })))
                )
        ),
        share()
    ));

    approveTimecard$ = createEffect(() => this.actions$.pipe(
        ofType(TimecardsActions.approveTimecard),
        exhaustMap(({ timecardId }) =>
            this.api.approveTimecard(timecardId)
                .pipe(
                    map(model => {this.filterService.checkTimecard(model); return TimecardsActions.approveTimecardSuccess({ model })}),
                    catchError(e => of(TimecardsActions.approveTimecardFailed({ error: e })))
                )
        ),
        share()
    ));

    rejectTimecard$ = createEffect(() => this.actions$.pipe(
        ofType(TimecardsActions.rejectTimecard),
        exhaustMap(({ timecardId, reason, reasonText }) =>
            this.api.rejectTimecard(timecardId, reason, reasonText)
                .pipe(
                    map(model => {this.filterService.checkTimecard(model); return TimecardsActions.rejectTimecardSuccess({ model })}),
                    catchError(e => of(TimecardsActions.rejectTimecardFailed({ error: e })))
                )
        ),
        share()
    ));
}
