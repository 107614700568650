import { Component, ViewEncapsulation  } from '@angular/core';

import { FlowService } from './services/flow.service';
import { IconService } from './services/icon.service';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class AppComponent
{
    constructor(flow: FlowService, iconService:IconService)
    {
        flow.register();
        iconService.connectIcons();
    }
}
