import * as clone from 'clone';

import { FetchTimecardsDTO } from 'src/app/types/dto';

import { TimecardFiltersForm } from '../types/forms';

export class TimecardsFiltersAdapter {
  private _form: TimecardFiltersForm;
  private _dto: FetchTimecardsDTO;

  constructor(form: TimecardFiltersForm) {
    this._form = clone(form);
  }

  private _adapt() {
    return {
      ...(this._form.checkboxes.statuses?.includes('approved') ? { isApproved: this._form.checkboxes.statuses?.includes('approved') } : {}),
      ...(this._form.checkboxes.statuses?.includes('pending') ? { isPending: this._form.checkboxes.statuses?.includes('pending') } : {}),
      ...(this._form.checkboxes.statuses?.includes('rejected') ? { isRejected: this._form.checkboxes.statuses?.includes('rejected') } : {}),
      ...(this._form.checkboxes.statuses?.includes('inProgress') ? { inProgress: this._form.checkboxes.statuses?.includes('inProgress') } : {}),
      licenseTypes: this._form.checkboxes.licenses,
      shiftTypes: this._form.checkboxes.shiftTypes,
      ...(this._form.id ? { q: this._form.id } : {}),
      ...(!this._form.id && this._form.range.startTimeFrom ? { startTimeFrom: this._form.range.startTimeFrom } : {}),
      ...(!this._form.id && this._form.range.startTimeTo ? { startTimeTo: this._form.range.startTimeTo } : {}),
    } as FetchTimecardsDTO;
  }

  get dto() {
    if (!this._dto) this._dto = this._adapt();
    return this._dto;
  }
}
