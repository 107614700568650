import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DateTime } from 'luxon';
import { take } from 'rxjs/operators';
import { FacilityModel } from 'src/app/models/facility';
import { NurseModel } from 'src/app/models/nurse';
import { NurseService } from 'src/app/modules/manager/services/api/nurse.service';
import { MixpanelService } from 'src/app/services/mixpanel.service';

@Component({
  selector: 'app-history-dnr-modal',
  templateUrl: './history-dnr-modal.component.html',
  styleUrls: ['./history-dnr-modal.component.scss']
})
export class HistoryDnrModalComponent implements OnInit {
  @Input() nurse: NurseModel;
  @Input() facility: FacilityModel;
  @Output() onClose = new EventEmitter();
  
  headerModel = [
    {
      title: 'ACTION',
      flexValue: 1.5 
    },
    {
      title: 'Date',
      flexValue: 2 
    },
    {
      title: 'ACTION TAKEN BY',
      flexValue: 2
    },
    {
      title: 'ReasonS FOR ACTION',
      flexValue: 3 
    },
    {
      title: 'Occurred during ESHYFT shift?',
      flexValue: 1.5
    },
    {
      title: 'Reported to State?',
      flexValue: 1.5
    },
    {
      title: 'Additional details',
      flexValue: 4 
    },
  ];
  logs = [];
  showNoDNR = false;
  constructor(private nurseApi: NurseService,  private mixpanel: MixpanelService) { }

  ngOnInit(): void {
    this.nurseApi.fetchDnrHistory(this.nurse._id, this.facility._id).pipe(take(1)).subscribe(h=>{
      h.forEach(hlog => {

        let log = {
          action: 'DNR ' + hlog['action'].toLowerCase(),
          date:  DateTime.fromISO(hlog['date'], {
            zone: this.facility.timezone,
          }).toFormat('LL/dd/yyyy'),
          time: DateTime.fromISO(hlog['date'], {
            zone: this.facility.timezone,
          }).toFormat('h:mm a'),
          takenBy: {
            name: hlog['manager'].fullName,
            role: this.getRole(hlog)
          },
          reasons: this.getReasons(hlog),
          duringEshyft: this.getQuestionValue(hlog, 'Did this occur during a shift scheduled through ESHYFT?'),
          reportingToState: this.getQuestionValue(hlog, 'Are you reporting this incident to the State?'),
          additional: hlog['details'] ? hlog['details'] : '-'
        }
        this.logs.push(log);
      });
      
    },
    error=>{
      this.showNoDNR = true;
    });
    this.mixpanel.for('fm').track('HISTORY_DNR_OPEN', {nurse: this.nurse});
  }
  getRole(log){
    const rolesNames = {
      'facility-manager': 'Facility manager',
      'supervisor': 'Supervisor',
    }
    return log.manager.fmJobTitle ? log.manager.fmJobTitle : rolesNames[log.manager.position];
  }
  getQuestionValue(log, question){
    let value = '-';
    if(log.questions?.length){
      log.questions.forEach(element => {
        if(element.question === question){
          value = element.response;
        }
      });
    }
    return value;
  }
  getReasons(log){
    if(log['action'] === 'REMOVED'){
      return log.otherReason ? [log.otherReason] : ['-']
    }
    let updReasons = [];
    log['reasons'].forEach(element => {
      if(element != 'Other'){
        updReasons.push(element)
      }else{
        updReasons.push(element + ': ' + log.otherReason)
      }
    });
    
    return updReasons;
  }
}
