import * as clone from 'clone';

import { CreateShiftTemplateDTO } from "../types/dto";
import { CreateShiftTemplateForm } from "../types/forms";

export class CreateShiftTemplateAdapter
{
    private _form: CreateShiftTemplateForm;
    private _dto: CreateShiftTemplateDTO;

    constructor(form: CreateShiftTemplateForm)
    {
        this._form = clone(form);
    }

    private _adapt()
    {
        return {
            clockInTime: this._form.clockInTime,
            endTime: this._form.endTime,
            icon: this._form.icon,
            shiftType: this._form.icon === 'mobile' ? 'MOBILE' : 'CUSTOM', // TODO: check icon name
            startTime: this._form.startTime
        } as CreateShiftTemplateDTO;
    }

    get dto()
    {
        if(!this._dto)
            this._dto = this._adapt();

        return this._dto;
    }
}