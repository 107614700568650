import { AsyncSubject, Observable } from "rxjs";

export function toHot<T>(input: Observable<T>)
{
    const subject = new AsyncSubject<T>();

    input.subscribe(
        v => subject.next(v),
        e => subject.error(e),
        () => subject.complete()
    );

    return subject.asObservable();
}