import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';

import { asapScheduler } from 'rxjs';
import { switchMap, take } from 'rxjs/operators';

import { FacilitiesAccessor } from 'src/app/modules/manager/services/accessors/facilities.accessor';
import { State } from 'src/app/state/interfaces';

import { AppService } from 'src/app/services/app.service';
import { NotificationsEffects } from '../../../state/effects/notifications.effects';
import { NotificationsActions } from '../../../state/actions/notifications/notifications-action-types';

@Injectable()
export class NotificationsFacade {
  constructor(
    private app: AppService,
    private store: Store<State>,
    private accessor: FacilitiesAccessor,
    private effects: NotificationsEffects,
  ) {
    app.expose('facades', 'notifications', this);
  }

  get state() {
    return this.accessor;
  }

  setChatCountValue(chatsCount) {
    this.store.dispatch(NotificationsActions.setChatCountValue(chatsCount));
  }
  increaseChatsUnreadMessagesCount(inc) {
    this.store.dispatch(NotificationsActions.increaseChatsUnreadMessagesCount(inc));
  }
  decreaseChatsUnreadMessagesCount(dec) {
    this.store.dispatch(NotificationsActions.decreaseChatsUnreadMessagesCount(dec));
  }
  increaseShiftsUpdatedCount(id, type) {
    this.store.dispatch(NotificationsActions.increaseShiftsUpdatedCount({ payload: { id, type } }));
  }
  decreaseShiftsUpdatedCount(id) {
    this.store.dispatch(NotificationsActions.decreaseShiftsUpdatedCount(id));
  }
  fetchNotifications() {
    asapScheduler.schedule(() => this.store.dispatch(NotificationsActions.fetchNotifications()));

    return this.effects.fetchNotifications$.pipe(take(1));
  }
  readNotificationsByEntityId(entityID) {
    asapScheduler.schedule(() => this.store.dispatch(NotificationsActions.readNotifications({entityID})));

    return this.effects.readNotifications$.pipe(take(1));
  }
}
