import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";

import { ButtonDirective } from "./button/button.directive";
import { TooltipModule } from "./tooltip/tooltip.module";
import { CurrencyFormatDirective } from "./currency-format/currency-format.directive";


@NgModule({
    declarations: [
        ButtonDirective,
        CurrencyFormatDirective
    ],
    imports: [
        CommonModule,
        TooltipModule
    ],
    exports: [
        ButtonDirective,
        TooltipModule,
        CurrencyFormatDirective
    ]
})
export class DirectivesModule { }
