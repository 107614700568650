import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { filter, map, take } from 'rxjs/operators';

import { Chain } from 'src/app/classes/async-chain';
import { POSITIONS } from 'src/app/constants/positions';
import { AppService } from 'src/app/services/app.service';
import { UserFacade } from 'src/app/services/facades/user/user.facade';

import { FacilitiesFacade } from './facades/facilities/facilities.facade';
import { HolidaysFacade } from './facades/holidays/holidays.facade';
import { ServicedStatesFacade } from './facades/serviced-states/serviced-states.facade';
import { SocketService } from '../../../services/socket/socket.service';
import { SseService } from './sse.service';
import { MessagesService } from './messages.service';
import { AuthFacade } from 'src/app/services/facades/auth/auth.facade';
import { FacilitiesActions } from '../state/actions/facilities/facilities-action-types';

@Injectable()
export class FlowService
{
    private _gotProfile = new Chain({ debug: !this.app.isProduction, id: 'got_profile' })
        .add('sync', 'myFetchedFacilities', ()=> this.facilitiesFacade.fetchMyFacilities())
        .add('sync', '', ({ myFetchedFacilities }) =>{
            if(myFetchedFacilities.type != FacilitiesActions.fetchMyFacilitiesSuccess.type){
                this.messages.add(myFetchedFacilities.error.error.message, '', 'danger');
                setTimeout(() => {
                    this.authFacade.logout();
                }, 3000);

            }
        })
        .add('sync', 'position', () => this.userFacade.state.user$.pipe(filter(u => !!u), take(1), map(u => u.position)))
        .add('chain', (
            new Chain({ id: 'got_profile_manager' })
                .add('sync', 'myFacilities', () => {return this.facilitiesFacade.state.myFacilities$.pipe(take(1))})
                .add('sync', 'setActiveFacility', ({ got_profile_manager: { myFacilities } }) =>{
                  let userId;
                  this.userFacade.state.user$.pipe(take(1)).subscribe(u=>{userId = u._id});
                  let activeFacilities = JSON.parse(localStorage.getItem("activeFacilities"));
                  let activeFacilityId = Object.keys(myFacilities)[0];

                  if(Object.keys(myFacilities).length > 1){
                    activeFacilityId = activeFacilities.find(e=>e.userId == userId).activeFacilityId;
                  }
                  return this.facilitiesFacade.setActiveFacility(activeFacilityId)})
                .add('sync', 'facility', () => this.facilitiesFacade.state.activeFacility$.pipe(take(1)))
                .add('sync', 'state', ({ got_profile_manager: { facility } }) => this.servicedStatesFacade.fetchState(facility.address.state))
                .add('sync', '', ({ got_profile_manager: { state: { model: { state } } } }) => this.servicedStatesFacade.setActive(state))
                .add('sync', '', () => this.holidaysFacade.fetchHolidays())
            ),
            { condition: ({ position }) => POSITIONS.MANAGER.includes(position) }
        );

    private _activeFacilitySet = new Chain({ debug: !this.app.isProduction, id: 'active_facility_set' })
        .add('sync', '', () => this.socket.disconnect())
        .add('sync', 'facility', () => this.facilitiesFacade.state.activeFacility$.pipe(take(1)))
        .add('sync', '', ({ facility }) => this.socket.connect(facility._id));

    private _socketConnected = new Chain({ debug: !this.app.isProduction, id: 'socket_connected' })
        .add('sync', '', () => this.sseService.register());

    constructor(
        private app: AppService,
        private userFacade: UserFacade,
        private facilitiesFacade: FacilitiesFacade,
        private servicedStatesFacade: ServicedStatesFacade,
        private holidaysFacade: HolidaysFacade,
        private socket: SocketService,
        private sseService: SseService,
        private router: Router,
        private messages: MessagesService,
        private authFacade: AuthFacade,
    )
    { }

    register()
    {
        this._gotProfile.execute();
        this._activeFacilitySet.startOn(this.facilitiesFacade.effects.setActiveFacility$);
        this._socketConnected.startOn(this.socket.connected$);
    }
}
