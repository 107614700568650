<div class="disabled-banner" *ngIf="facility.deactivationStatus === 'Disabled'">
    <mat-icon [svgIcon]="'warning'"></mat-icon>
    <span class="banner-text">This facility: {{facility.name}} is disabled. If you have any questions please <a href="mailto:support@eshyft.com?subject=Facility status inquiry">contact support</a>.</span>
</div>
<div class="header-container" title="">
    <div class="title-block">
        <button fill="none" class="hamburger" (click)="onMenuClick()">
            <mat-icon>dehaze</mat-icon>
        </button>
        <span>{{ title }} {{(createShift && !showInstaShyft) ? ' regular shifts' : ''}}</span>
        <span class="insta" *ngIf="createShift && showInstaShyft"><mat-icon svgIcon="instaShift"></mat-icon> InstaSHYFT</span>
    </div>
    
    <div class="right-header">
        <button
            *ngIf="(buttonType !== 'published') && (facility.deactivationStatus != 'Disabled')"
            
            color="primary"
            fill="solid"
            class="create-button"
            (click)="onCreateClick()"
        >
        <mat-icon>add</mat-icon> Create new shifts
        </button>

        <div class="intercom">
            <button fill="none" class="intercom_button" (click)="openIntercom()">  
                <mat-icon [svgIcon]="'questionMarkCircle'" #intercomButton></mat-icon>
                <span class="intercom_support">Support</span>
            </button>
        </div>

    <!-- <button
        *ngIf="buttonType === 'published'"
        routerLink="/manager/shifts/applicants"
        color="primary"
        fill="solid"
    >
        Published shifts
    </button> -->
    </div>