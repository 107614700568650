import { Injectable } from '@angular/core';
import { take } from 'rxjs/operators';
import { FiltersAccessor } from './accessors/filters.accessor';

import { Timecard } from '../../../models/timecard';
import { Store } from '@ngrx/store';
import { State } from 'src/app/state/interfaces';
import { DateTime } from 'luxon';
import { ShiftsActions } from '../state/actions/shifts/shifts-action-types';
import { TimecardsActions } from '../state/actions/timecards/timecards-action-types';

@Injectable({
  providedIn: 'root',
})
export class FilterService {
  constructor(
    private filtersAccessor: FiltersAccessor,
    private store: Store<State>,
  ) {}

  checkShift(shift) {
    this.filtersAccessor.shiftsFilters$.pipe(take(1)).subscribe((f) => {
      let checkApplicantStatuses = this.onCheckApplicantStatuses(
        shift.applicants,
        f.checkboxes.applicantStatuses,
      );
      if (!checkApplicantStatuses) {
        this.store.dispatch(ShiftsActions.removeShiftFromStore({ shiftId: shift._id }));
      }
    });
  }
  checkShiftAfterEdit(shift) {
    this.filtersAccessor.shiftsFilters$.pipe(take(1)).subscribe((f) => {
      let checkDate = this.onCheckDate(
        f.range.startTimeFrom,
        f.range.startTimeTo,
        shift.shiftData.shiftFrom,
      );
      let checkLicense = this.onCheckLicense(
        shift.license,
        f.checkboxes.licenses,
      );
      if (!checkDate || !checkLicense) {
        this.store.dispatch(ShiftsActions.removeShiftFromStore({ shiftId: shift._id }));
      }
    });
  }
  checkTimecard(timecard) {
    let updTimecard = new Timecard(timecard);
    this.filtersAccessor.timecardsFilters$.pipe(take(1)).subscribe((f) => {
      let chekTimecardStatus = this.onChekTimecardStatus(
        updTimecard.status,
        f.checkboxes.statuses,
      );
      if (!chekTimecardStatus) {
        this.store.dispatch(
          TimecardsActions.removeTimecardFromStore({ timecardId: updTimecard._id }),
        );
      }
    });
  }
  onCheckDate(from, to, date) {
    const fromTimestamp = DateTime.fromFormat(from, 'yyyy-LL-dd HH:mm:ss').valueOf();
    const toTimestamp = DateTime.fromFormat(to, 'yyyy-LL-dd HH:mm:ss').valueOf();
    const dateTimestamp = DateTime.fromISO(date).valueOf();
    if((dateTimestamp > fromTimestamp) && (dateTimestamp < toTimestamp)){
      return true;
    }
    return false;
  }
  onCheckLicense(license, filters) {
    if(!filters.length){
      return true;
    }
    for(let i = 0; i < filters?.length; i++){
      if(filters[i] == license){
        return true;
      }
    }
    return false;
  }
  onCheckApplicantStatuses(applicants, filters) {
    if (!filters.length) {
      return true;
    }
    for(let i = 0; i < applicants.length; i++){
      for(let j = 0; j < filters.length; j++){
        if (applicants[i].status === filters[j]) {
          return true;
        }
      }
    }
    return false;
  }
  onChekTimecardStatus(timecardStatus, filters) {
    if (!filters.length) {
      return true;
    }
    for(let i = 0; i < filters.length; i++){
      if (timecardStatus === filters[i]) {
        return true;
      }
    }
    return false;
  }
}
