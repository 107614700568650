<div class="paginator-container">
  <div class="per-page-block">
    <div class="pagination-buttons-block">
      <button
        *ngFor="let item of itemsPerPage"
        (click)="onPerPageChange.emit(item)"
        fill="none"
        [ngClass]="{ active: item === pageSize }"
      >
        {{ item }}
      </button>
    </div>
    <span class="per-page-label">Items per page</span>
  </div>
  <div class="nav-block">
    <span class="nav-label"
      >Showing {{ length > 0 ? pageSize * activePage + 1 : 0 }}-{{
        pageSize * (activePage + 1) < length
          ? pageSize * (activePage + 1)
          : length
          ? length
          : 0
      }}
      of {{ length ? length : 0 }} results</span
    >
    <div class="nav-buttons-block">
      <button
        fill="none"
        [ngClass]="{ disabled: activePage === 0 }"
        [disabled]="activePage === 0"
        (click)="onPageClick.emit(activePage - 1); activePage = activePage - 1"
      >
        <mat-icon [svgIcon]="'mdCheveronLeft-01'"></mat-icon>
      </button>
      <button
        fill="none"
        [disabled]="length <= (activePage + 1) * pageSize"
        [ngClass]="{ disabled: length <= (activePage + 1) * pageSize }"
        class="right"
        (click)="onPageClick.emit(activePage + 1); activePage = activePage + 1"
      >
        <mat-icon [svgIcon]="'mdCheveronLeft-01'"></mat-icon>
      </button>
    </div>
  </div>
</div>
