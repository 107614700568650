import { createAction, props, union } from '@ngrx/store';
import { DnrModel } from 'src/app/models/dnr';

import { type } from 'src/app/state/actions/helpers/action-type';
import { AddNurseDTO } from './dto/add-nurse';
import { FetchDnrDTO } from './dto/fetch-dnr';
import { RemoveNurseDTO } from './dto/remove-nurse';

const t = type('dnr');

export const fetchDnr = createAction(t.pending('fetch_dnr'));
export const fetchDnrSuccess = createAction(t.success('fetch_dnr'), props<FetchDnrDTO.Success>());
export const fetchDnrFailed = createAction(t.failed('fetch_dnr'), props<FetchDnrDTO.Failed>());

export const addNurse = createAction(t.pending('add_nurse'), props<AddNurseDTO.Pending>());
export const addNurseSuccess = createAction(t.success('add_nurse'), props<AddNurseDTO.Success>());
export const addNurseFailed = createAction(t.failed('add_nurse'), props<AddNurseDTO.Failed>());

export const removeNurse = createAction(t.pending('remove_dnr'), props<RemoveNurseDTO.Pending>());
export const removeNurseSuccess = createAction(t.success('remove_dnr'), props<RemoveNurseDTO.Success>());
export const removeNurseFailed = createAction(t.failed('remove_dnr'), props<RemoveNurseDTO.Failed>());

export const syncDnrs = createAction(t.sync('sync_dnr'), props<any>());

const u = union({
    
    fetchDnr,
    fetchDnrSuccess,
    fetchDnrFailed,

    addNurse,
    addNurseSuccess,
    addNurseFailed,

    removeNurse,
    removeNurseSuccess,
    removeNurseFailed
});

export type Union = typeof u;
