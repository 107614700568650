import { Action, ActionReducer } from "@ngrx/store";

import { logoutSuccess } from '../actions/auth/auth.actions';
import { State } from '../interfaces/state';

export function clearState(initialState: State)
{   
    console.log('initialState',initialState)
    return (reducer: ActionReducer<any>) =>
    {
        return (state: State, action: Action) =>
        {
            switch (action.type)
            {
                case logoutSuccess.type: return reducer({ ...initialState }, action);
                default: return reducer(state, action);
            }
        };
    }
}