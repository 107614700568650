import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';

import { asapScheduler } from 'rxjs';
import { switchMap, take } from 'rxjs/operators';

import { toHot } from 'src/app/helpers/to-hot';
import { UserModel } from 'src/app/models/user';
import { UserAccessor } from 'src/app/services/accessors/user.accessor';
import { changeEmail, changePassword, getProfile, updateUser, uploadAvatar } from 'src/app/state/actions/user/user.actions';
import { UserEffects } from 'src/app/state/effects/user.effects';
import { State } from 'src/app/state/interfaces';
import { ChangeEmailForm, ChangePasswordForm, EditProfileForm } from 'src/app/types/forms';

import { AppService } from '../../app.service';

@Injectable({ providedIn: 'root' })
export class UserFacade
{
    constructor(
        app: AppService,
        private store: Store<State>,
        private accessor: UserAccessor,
        private userEffects: UserEffects
    )
    {
        app.expose('facades', 'user', this);
    }

    get state() { return this.accessor; }

    get effects() { return this.userEffects; }

    getProfile()
    {
        asapScheduler.schedule(() => this.store.dispatch(getProfile()));
        return this.effects.getProfile$.pipe(take(1));
    }

    edit(user: UserModel)
    {
        asapScheduler.schedule(() => this.store.dispatch(updateUser({ user })));
        return this.effects.updateUser$.pipe(take(1));
    }

    editProfile(form: EditProfileForm)
    {
        return toHot(this.accessor.user$
            .pipe(
                take(1),
                switchMap(u => this.edit({ ...u, name: { first: form.firstName, last: form.lastName } }))
            ));
    }

    changeEmail(form: ChangeEmailForm)
    {
        asapScheduler.schedule(() => this.store.dispatch(changeEmail({ dto: form })));
        return this.effects.changeEmail$.pipe(take(1));
    }

    changePassword(form: ChangePasswordForm)
    {
        asapScheduler.schedule(() => this.store.dispatch(changePassword({ dto: form })));
        return this.effects.changePassword$.pipe(take(1));
    }

    uploadAvatar(file: File)
    {
        asapScheduler.schedule(() => this.store.dispatch(uploadAvatar({ file })));
        return this.effects.uploadAvatar$.pipe(take(1));
    }
}
