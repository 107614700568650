import { createSelector } from "@ngrx/store";

import { selectModelsById } from "src/app/state/helpers/select-models-by-id";
import { State } from "src/app/state/interfaces";

import { ChatsState, CHATS_FEATURE_KEY, MANAGER_FEATURE_KEY } from "../interfaces";

const state = (s: State) => s[MANAGER_FEATURE_KEY][CHATS_FEATURE_KEY];

export const selectChatsIds = createSelector(state, ({ chats }) => chats);
export const selectChats = createSelector(state, ({ models, chats }) => selectModelsById(models, chats));

export const selectChat = createSelector(state, ({ models }: ChatsState, { id }: { id: string }) => models[id]);
export const selectChatByShift = createSelector(state, ({ models }: ChatsState, { id } : { id: string }) =>
    Object.values(models).find(c => c.shift._id === id)
);
export const selectPastChatsIds = createSelector(state, ({ past }) => past);
export const selectPastChats = createSelector(state, ({ models, past }) => selectModelsById(models, past));

export const selectMessagesForChat = createSelector(state, ({ messages }: ChatsState, { id }: { id: string }) => messages[id] || []);
