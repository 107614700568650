export const environment = {
  production: true,
  apiUrl: 'https://dev-api.eshyft.com/api/v2',
  socketUrl: 'https://dev-api.eshyft.com',
  loginRedirectUrl: 'https://dev-admin.eshyft.com/',
  sse: {
    chat: {
      CHAT_NEW_MESSAGE: 'CHAT_NEW_MESSAGE'
    },
    shifts: {
      SHIFTS_APPLICANT_CONFIRMED: 'SHIFTS_APPLICANT_CONFIRMED',
      SHIFTS_APPLICANT_DECLINED: 'SHIFTS_APPLICANT_DECLINED',
      SHIFTS_APPLICANT_DISABLED: 'SHIFTS_APPLICANT_DISABLED',
      SHIFT_APPLICANT_CALLED_OUT: 'SHIFT_APPLICANT_CALLED_OUT',
      SHIFT_APPLICANT_CANCELLED: 'SHIFT_APPLICANT_CANCELLED',
      SHIFT_APPLICANT_WITHDREW: 'SHIFT_APPLICANT_WITHDREW',
      SHIFTS_CANCELLED: 'SHIFTS_CANCELLED',
      SHIFTS_NEW_APPLICANT: 'SHIFTS_NEW_APPLICANT',
      SHIFT_NEW_POSTED: 'SHIFT_NEW_POSTED',
      SHIFTS_REMOVED: 'SHIFTS_REMOVED',
      SHIFTS_OVERTIME_CHANGED: 'SHIFTS_OVERTIME_CHANGED',
      SHIFTS_3D_SINCE_APPLICATION_MANAGER: 'SHIFTS_3D_SINCE_APPLICATION_MANAGER',
      SHIFTS_7D_SINCE_APPLICATION_MANAGER: 'SHIFTS_7D_SINCE_APPLICATION_MANAGER',
      SHIFTS_14D_SINCE_APPLICATION_MANAGER: 'SHIFTS_14D_SINCE_APPLICATION_MANAGER',
      SHIFTS_INSTASHYFT_TAKEN: 'SHIFTS_INSTASHYFT_TAKEN'
    },
    timecards: {
      TIMECARD_CREATED: 'TIMECARD_CREATED',
      TIMECARD_UPDATED: 'TIMECARD_UPDATED'
    },
    user: {
      SERVICE_USER_DISABLED: 'SERVICE_USER_DISABLED'
    },
    facility: {
      FACILITY_DEACTIVATION_STATUS_UPDATED: 'FACILITY_DEACTIVATION_STATUS_UPDATED'
    }
  },
  stripeToken: 'pk_test_kbadEwMIaYSTtcXDcywQh2pq00Vb2UGsub',
  mixpanel: {
    admin: '',
    fm: 'd308899aff7516420b11ac0e7aa915dc'
  },
  branchKey: 'key_live_imS7QiixoJYbI3A3060JyfmpvCagBL8T'
}
