export function type(group: string) {
    return {
        /**
         * sync is used to set front-end state where backend api is not involved (i.e. no pending/success/failed statuses)
         */
        sync: (action: string) => `[${group}] ${action}`.toUpperCase(),
        pending: (action: string) => `[${group}] ${action}_pending`.toUpperCase(),
        success: (action: string) => `[${group}] ${action}_success`.toUpperCase(),
        failed: (action: string) => `[${group}] ${action}_failed`.toUpperCase()
    };
}