import * as clone from 'clone';

import { ChatModel } from "./chat.model";

export class Chat implements ChatModel
{
    private _model: ChatModel;

    constructor(model: ChatModel)
    {
        this._model = clone(model);
    }

    get model() { return this.model; }

    get lastMessage() { return this._model.lastMessage; }

    get lastSeenByFacilityAt() { return this._model.lastSeenByFacilityAt; }

    get lastSeenByNurseAt() { return this._model.lastSeenByNurseAt; }

    get nurse() { return this._model.nurse; }

    get removedByFacilityAt() { return this._model.removedByFacilityAt; }

    get removedByNurseAt() { return this._model.removedByNurseAt; }

    get shift() { return this._model.shift; }

    get unreadCount() { return this._model.unreadCount; }

    get _id() { return this._model._id; }

    get facilityNurse() { return this._model.facilityNurse; }
}