export const SHIFTS_PAGE_SIZE = 50;
export const TIMECARDS_PAGE_SIZE = 50;
export const CHATS_PAGE_SIZE = 20;
export const MESSAGES_PAGE_SIZE = 100;
export const USERS_PAGE_SIZE = 50;
export const FACILITIES_PAGE_SIZE = 50;
export const STATE_LOADS_PAGE_SIZE = 50;
export const PAYROLL_PAGE_SIZE = 25;
export const INVOICING_PAGE_SIZE = 25;
export const LEADS_PAGE_SIZE = 50;
export const REPORTS_PAGE_SIZE = 50;
export const HOLIDAYS_PAGE_SIZE = 50;
export const NCNS_PAGE_SIZE = 50;
export const CALLOUTS_PAGE_SIZE = 50;
export const HISTORY_PAGE_SIZE = 500;
export const STRATEGIC_INVESTORS_PAGE_SIZE = 50;
export const NURSES_PAGE_SIZE = 50;

export const ADMIN_TABLE_SIZES  = [
    25, 50, 100, 200
];
