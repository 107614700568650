import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';

import { State } from 'src/app/state/interfaces';
import { selectUser } from 'src/app/state/selectors/user';

@Injectable({ providedIn: 'root' })
export class UserAccessor
{
    constructor(private store: Store<State>) { }

    get user$() { return this.store.select(selectUser); }
}
