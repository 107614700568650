import { ActionReducerMap, MetaReducer } from "@ngrx/store";

import { ManagerState } from '../interfaces';

import { chatsReducer, initialChatsState } from "./chats";
import { facilitiesReducer, initialFacilitiesState } from "./facilities";
import { filtersReducer, initialFiltersState } from "./filters";
import { holidaysReducer, initialHolidaysState } from "./holidays";
import { clearState } from "./meta";
import { initialNotificationsState, notificationsReducer } from "./notifications";
import { initialRemindersState, remindersReducer } from "./reminders";
import { initialServicedStatesState, servicedStatesReducer } from "./serviced-states";
import { initialShiftsState, shiftsReducer } from "./shifts";
import { initialTimecardsState, timecardsReducer } from "./timecards";
import { initialDnrState, dnrReducer } from "./dnr";
import { initialNursesState, nursesReducer } from "./nurses";

export const managerReducer: ActionReducerMap<ManagerState> = {
    chats: chatsReducer,
    facilities: facilitiesReducer,
    filters: filtersReducer,
    holidays: holidaysReducer,
    servicedStates: servicedStatesReducer,
    shifts: shiftsReducer,
    timecards: timecardsReducer,
    notifications: notificationsReducer,
    reminders: remindersReducer,
    dnr: dnrReducer,
    nurses: nursesReducer
};

export const initialManagerState: ManagerState = Object.freeze<ManagerState>({
    chats: initialChatsState,
    facilities:  initialFacilitiesState,
    filters:  initialFiltersState,
    holidays: initialHolidaysState,
    servicedStates: initialServicedStatesState,
    shifts: initialShiftsState,
    timecards: initialTimecardsState,
    notifications: initialNotificationsState,
    reminders: initialRemindersState,
    dnr: initialDnrState,
    nurses: initialNursesState
});

export const metaReducers: MetaReducer[] = [clearState(initialManagerState)];
