<span class="fss-label" *ngIf="label">{{label}} <span class="optional"> &nbsp;(optional)</span></span>
<mat-form-field appearance="outline" [formGroup]="formGroup" class="search-select-container">
    <mat-select [multiple]="true" [formControlName]="controlName" (openedChange)="toggledSelect($event)"
        [disableOptionCentering]="true" defaultTabIndex="0" [placeholder]="'Add other facilities'" #Select
        panelClass="search-select-panel" (selectionChange)="onSelect()" #select>
        <div [ngClass]="{ hidden: fControl.hasError('noResults') }">
            <div class="p-3" [formGroup]="searchForm">
                <input [placeholder]="'Search...'" type="text" (keyup.enter)="selectFirstOption(filteredFacilities[0])"
                    #searchInput class="search-input" formControlName="q" />
            </div>

            <hr />

            <!-- <mat-spinner *ngIf="isSearching" diameter="30" class="mx-auto"></mat-spinner> -->
            <span *ngIf="(!filteredFacilities || filteredFacilities.length === 0)" class="no-results">No results found</span>
        </div>
        <mat-select-trigger *ngIf="triggerText?.length">{{triggerText}}</mat-select-trigger>
        <mat-select-trigger *ngIf="!triggerText?.length"><span class="fake-placeholder">Add other facilities</span></mat-select-trigger>
        <mat-option *ngIf="(filteredFacilities && filteredFacilities.length > 0)" [value]="'all'" (click)="toggleAllSelection()">All facilities</mat-option>
        <mat-option class="hidden" *ngIf="!filteredFacilities || filteredFacilities.length === 0" disabled hidden></mat-option>

        <!-- <mat-option [ngClass]="{'hidden': (!results || results.length === 0) && searchInputValue}" *ngFor="let res of fControl.value" [value]="optionResult ? res[optionResult] : res">
          <ng-container>
            {{ res.fullName }}
          </ng-container>
        </mat-option> -->

        <mat-option *ngFor="let res of filteredFacilities; let i = index" [value]="optionResult ? res[optionResult] : res"
            [disabled]="disabledFacilityObj[res._id]?.disabled" customToolTip [contentTemplate]="disabledFacilityTooltip"
            [ngClass]="{disabled: disabledFacilityObj[res._id]?.disabled}"
            [offsetY]="-4"
            [offsetX]="-118">
            <ng-container>
                {{ res.name }}
            </ng-container>
            <ng-template #disabledFacilityTooltip>
                <div class="info-tooltip" *ngIf="disabledFacilityObj[res._id]?.tooltip">
                  <span>The nurse has previously been placed on a DNR status at this facility</span>
                </div>
            </ng-template>
        </mat-option>
    </mat-select>

    <mat-icon class="select-chevron icon" svgIcon="mdCheveronUp-01"
        [ngClass]="{ 'select-chevron-down': Select.panelOpen }" style="cursor: pointer"
        (click)="Select.open()"></mat-icon>
</mat-form-field>