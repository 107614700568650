import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DateTime } from 'luxon';
import { HolidayModel } from 'src/app/models/holiday';

@Component({
  selector: 'app-holiday-pay',
  templateUrl: './holiday-pay.component.html',
  styleUrls: ['./holiday-pay.component.scss']
})
export class HolidayPayComponent implements OnInit {
  @Input() holidayModel: HolidayModel;

  formattedStartDate: string = '';
  formattedEndDate: string = '';

  formattedStartTime: string = '';
  formattedEndTime: string = '';

  constructor() { }

  ngOnInit(): void {
    this.formattedStartDate = DateTime.fromFormat(
      this.holidayModel.start,
      'yyyy-MM-dd HH:mm',
    ).toFormat('LLLL dd, yyyy,');

    this.formattedEndDate = DateTime.fromFormat(
      this.holidayModel.end,
      'yyyy-MM-dd HH:mm',
    ).toFormat('LLLL dd, yyyy,');

    this.formattedStartTime = DateTime.fromFormat(
      this.holidayModel.start,
      'yyyy-MM-dd HH:mm',
    ).toFormat('h:mma').toLowerCase();

    this.formattedEndTime = DateTime.fromFormat(
      this.holidayModel.end,
      'yyyy-MM-dd HH:mm',
      ).toFormat('h:mma').toLowerCase();
  }

}
