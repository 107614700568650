import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-paginator',
  templateUrl: './paginator.component.html',
  styleUrls: ['./paginator.component.scss']
})
export class PaginatorComponent implements OnInit{
  @Input() length: number;
  @Input() pageSize: number;
  @Input() activePage: number;
  
  @Output() onPageClick = new EventEmitter();
  @Output() onPerPageChange = new EventEmitter();

  itemsPerPage = [50, 100, 500];
  constructor() { }

  ngOnInit(): void {
   
  }

}
