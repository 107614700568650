import { createReducer, on } from '@ngrx/store';

import { SHIFTS_PAGE_SIZE } from 'src/app/constants/pagination';
import { toRecord } from 'src/app/state/helpers/to-record';



import { NursesState } from '../interfaces';
import { NursesActions } from '../actions/nurses/nurses-action-types';

export const initialNursesState: NursesState = Object.freeze<NursesState>({
  models: {},
  pagination: {
    pagesCount: undefined,
    page: undefined,
    limit: SHIFTS_PAGE_SIZE,
  },
  nurses: [],
});

export const nursesReducer = createReducer(
  initialNursesState,
  on(NursesActions.fetchNursesSuccess, (state, { models }) => {
    return {
      ...state,
      models: { ...state.models, ...toRecord(models, (s) => s.nurse._id) },
      nurses: models.map((s) => s.nurse._id),
    }
  }),
  on(NursesActions.markAsTrustedSuccess, (state, { nurseId }) => {
    return {
      ...state,
      models: {
        ...state.models,
        [nurseId]: {
          ...state.models[nurseId],
          trusted: {
            isTrusted: true
          }
        }
      }
    }
  }),
  on(NursesActions.removeFromTrustedSuccess, (state, { nurseId }) => {
    return {
      ...state,
      models: {
        ...state.models,
        [nurseId]: {
          ...state.models[nurseId],
          trusted: {
            isTrusted: false
          }
        }
      }
    }
  }),
  on(NursesActions.fetchNurseSuccess, (state, { model }) => {
    return {
      ...state,
      models: {
        ...state.models,
        [model.nurse._id]: {
          ...model
        }
      }
    }
  }),
);


