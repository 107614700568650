import { Injectable } from '@angular/core';

import { environment } from 'src/environments/environment';

type Group = 'accessors' | 'api' | 'facades' | 'pages' | 'services';

@Injectable({ providedIn: 'root' })
export class AppService
{

    constructor()
    {
        this.expose('services', 'app', this);
    }
    
    get isProduction() { return environment.production }

    expose(group: Group, key: string, object: any)
    {
        if (this.isProduction) return;

        (window as any)[group] = { ...(window as any)[group], [key]: object };
    }
}
