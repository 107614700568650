import { map } from "rxjs/operators";

import { FacilityBindings, FacilityWithBindings } from "../../../bindings";
import { FacilitiesAccessor } from "../../accessors/facilities.accessor";

export class FacilitiesBinder
{
    constructor(
        private accessor: FacilitiesAccessor,
        private bindings: FacilityBindings
    ) { }

    get activeFacility$()
    {
        return this.accessor.activeFacility$
            .pipe(
                map(f => f && new FacilityWithBindings(f, this.bindings))
            );
    }

    get myFacilities$()
    {
        return this.accessor.myFacilities$
            .pipe(
                map(facilities =>
                    Object.values(facilities)
                    .filter(f => !!f)
                    .map(f => f && new FacilityWithBindings(f, this.bindings))
                )
            );
    }
}