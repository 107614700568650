import { createAction, props, union } from "@ngrx/store";

import { type } from "src/app/state/actions/helpers/action-type";
import { DeleteChatDTO } from "./dto/delete-chat";
import { FetchChatDTO } from "./dto/fetch-chat";

import { FetchChatsDTO } from "./dto/fetch-chats";
import { FetchChatByMetadataDTO } from "./dto/fetch-chats-by-metadata";
import { FetchMessagesDTO } from "./dto/fetch-messages";
import { FetchNextChatsPage } from "./dto/fetch-next-chats-page";
import { FetchNextMessagesPageDTO } from "./dto/fetch-next-messages-page";
import { FetchNextPastChatsDTO } from "./dto/fetch-next-past-chats-page";
import { FetchPastChatsDTO } from "./dto/fetch-past-chats";
import { ReadDTO } from "./dto/read";
import { SendMessageDTO } from "./dto/send-message";

const t = type('chat');

export const fetchChats = createAction(t.pending('fetch_chats'), props<FetchChatsDTO.Pending>());
export const fetchChatsSuccess = createAction(t.success('fetch_chats'), props<FetchChatsDTO.Success>());
export const fetchChatsFailed = createAction(t.failed('fetch_chats'), props<FetchChatsDTO.Failed>());

export const fetchNextChatsPage = createAction(t.pending('fetch_next_chats_page'), props<FetchChatsDTO.Pending>());
export const fetchNextChatsPageSuccess = createAction(t.success('fetch_next_chats_page'), props<FetchNextChatsPage.Success>());
export const fetchNextChatsPageFailed = createAction(t.failed('fetch_next_chats_page'), props<FetchNextChatsPage.Failed>());

export const fetchPastChats = createAction(t.pending('fetch_past_chats'), props<FetchPastChatsDTO.Pending>());
export const fetchPastChatsSuccess = createAction(t.success('fetch_past_chats'), props<FetchPastChatsDTO.Success>());
export const fetchPastChatsFailed = createAction(t.failed('fetch_past_chats'), props<FetchPastChatsDTO.Failed>());

export const fetchNextPastChatsPage = createAction(t.pending('fetch_next_past_chats_page'), props<FetchPastChatsDTO.Pending>())
export const fetchNextPastChatsPageSuccess = createAction(t.success('fetch_next_past_chats_page'), props<FetchNextPastChatsDTO.Success>())
export const fetchNextPastChatsPageFailed = createAction(t.failed('fetch_next_past_chats_page'), props<FetchNextPastChatsDTO.Failed>())

export const fetchChat = createAction(t.pending('fetch_chat'), props<FetchChatDTO.Pending>());
export const fetchChatSuccess = createAction(t.success('fetch_chat'), props<FetchChatDTO.Success>());
export const fetchChatFailed = createAction(t.failed('fetch_chat'), props<FetchChatDTO.Failed>());

export const fetchChatByMetadata = createAction(t.pending('fetch_chat_by_shift'), props<FetchChatByMetadataDTO.Pending>());
export const fetchChatByMetadataSuccess = createAction(t.success('fetch_chat_by_shift'), props<FetchChatByMetadataDTO.Success>());
export const fetchChatByMetadataFailed = createAction(t.failed('fetch_chat_by_shift'), props<FetchChatByMetadataDTO.Failed>());

export const deleteChat = createAction(t.pending('delete_chat'), props<DeleteChatDTO.Pending>());
export const deleteChatSuccess = createAction(t.success('delete_chat'), props<DeleteChatDTO.Success>());
export const deleteChatFailed = createAction(t.failed('delete_chat'), props<DeleteChatDTO.Failed>());

export const read = createAction(t.pending('read'), props<ReadDTO.Pending>());
export const readSuccess = createAction(t.success('read'), props<ReadDTO.Success>());
export const readFailed = createAction(t.failed('read'), props<ReadDTO.Failed>());

export const sendMessage = createAction(t.pending('send_message'), props<SendMessageDTO.Pending>());
export const sendMessageSuccess = createAction(t.success('send_message'), props<SendMessageDTO.Success>());
export const sendMessageFailed = createAction(t.failed('send_message'), props<SendMessageDTO.Failed>());

export const fetchMessages = createAction(t.pending('fetch_messages'), props<FetchMessagesDTO.Pending>());
export const fetchMessagesSuccess = createAction(t.success('fetch_messages'), props<FetchMessagesDTO.Success>());
export const fetchMessagesFailed = createAction(t.failed('fetch_messages'), props<FetchMessagesDTO.Failed>());

export const fetchNextMessagesPage = createAction(t.pending('fetch_next_messages_page'), props<FetchNextMessagesPageDTO.Pending>());
export const fetchNextMessagesPageSuccess = createAction(t.success('fetch_next_messages_page'), props<FetchNextMessagesPageDTO.Success>());
export const fetchNextMessagesPageFailed = createAction(t.failed('fetch_next_messages_page'), props<FetchNextMessagesPageDTO.Failed>());

const u = union({
    /* Fetch Chats */
    fetchChats,
    fetchChatsSuccess,
    fetchChatsFailed,

    /* Fetch Next Chats Page */
    fetchNextChatsPage,
    fetchNextChatsPageSuccess,
    fetchNextChatsPageFailed,

    /* Fetch Past Chats */
    fetchPastChats,
    fetchPastChatsSuccess,
    fetchPastChatsFailed,

    /* Fetch Next Past Chats Page */
    fetchNextPastChatsPage,
    fetchNextPastChatsPageSuccess,
    fetchNextPastChatsPageFailed,

    /* Fetch Chat */
    fetchChat,
    fetchChatSuccess,
    fetchChatFailed,

    /* Delete Chat */
    deleteChat,
    deleteChatSuccess,
    deleteChatFailed,

    /* Read */
    read,
    readSuccess,
    readFailed,

    /* Send Message */
    sendMessage,
    sendMessageSuccess,
    sendMessageFailed,

    /* Fetch Messages */
    fetchMessages,
    fetchMessagesSuccess,
    fetchMessagesFailed,

    /* Fetch Next Messages Page */
    fetchNextMessagesPage,
    fetchNextMessagesPageSuccess,
    fetchNextMessagesPageFailed,
});
export type Union = typeof u;
