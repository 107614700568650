import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';

import { of } from 'rxjs';
import {
  catchError,
  exhaustMap,
  map,
  share,
  switchMap,
  take,
  tap,
  withLatestFrom,
} from 'rxjs/operators';

import { FacilitiesService } from 'src/app/modules/manager/services/api/facilities.service';
import { MixpanelService } from 'src/app/services/mixpanel.service';
import { FacilitiesAccessor } from '../../services/accessors/facilities.accessor';
import { FacilitiesActions } from '../actions/facilities/facilities-action-types';

@Injectable({ providedIn: 'root' })
export class FacilitiesEffects {
  constructor(
    private actions$: Actions,
    private api: FacilitiesService,
    private accessor: FacilitiesAccessor,
    private mixpanel: MixpanelService
  ) {}

  fetchMyFacilities$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FacilitiesActions.fetchMyFacilities),
      exhaustMap(() =>
        this.api.fetchMyFacilities().pipe(
          map((models) => FacilitiesActions.fetchMyFacilitiesSuccess({ models })),
          catchError((e) => of(FacilitiesActions.fetchMyFacilitiesFailed({ error: e }))),
        ),
      ),
      share(),
    ),
  );
  fetchFacilityById$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FacilitiesActions.fetchFacilityById),
      exhaustMap(({id}) =>
        this.api.fetchFacility(id).pipe(
          map((model) => FacilitiesActions.fetchFacilityByIdSuccess({ model })),
          catchError((e) => of(FacilitiesActions.fetchFacilityByIdFailed({ error: e }))),
        ),
      ),
      share(),
    ),
  );

  editFacility$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FacilitiesActions.editFacility),
      exhaustMap(({ facility }) =>
        this.api.edit(facility._id, facility).pipe(
          map((model) => FacilitiesActions.editFacilitySuccess({ model })),
          catchError((e) => of(FacilitiesActions.editFacilityFailed({ error: e }))),
        ),
      ),
      share(),
    ),
  );

  addSupervisor$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FacilitiesActions.addSupervisor),
      withLatestFrom(this.accessor.activeFacility$),
      exhaustMap(([{ dto }, f]) =>
        this.api.addSupervisor(f._id, dto).pipe(
          map((model) => FacilitiesActions.addSupervisorSuccess({ model })),
          catchError((e) => of(FacilitiesActions.addSupervisorFailed({ error: e }))),
        ),
      ),
      share(),
    ),
  );

  requestChange$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FacilitiesActions.requestChange),
      withLatestFrom(this.accessor.activeFacility$),
      exhaustMap(([{ dto }, f]) =>
        this.api.requestChange(f._id, dto).pipe(
          map(() => FacilitiesActions.requestChangeSuccess()),
          catchError((e) => of(FacilitiesActions.requestChangeFailed({ error: e }))),
        ),
      ),
      share(),
    ),
  );

  createShiftTemplate$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FacilitiesActions.createShiftTemplate),
      withLatestFrom(this.accessor.activeFacility$),
      exhaustMap(([{ dto }, f]) =>
        this.api.createShiftTemplate(f._id, dto).pipe(
          map((model) => FacilitiesActions.createShiftTemplateSuccess({ model })),
          catchError((e) => of(FacilitiesActions.createShiftTemplateFailed({ error: e }))),
        ),
      ),
      share(),
    ),
  );
  changePaymentMethod$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FacilitiesActions.changePaymentMethod),
      exhaustMap(({ id, dto }) =>
        this.api.changePaymentMethod(id, dto).pipe(
          map((model) => FacilitiesActions.changePaymentMethodSuccess({ model })),
          catchError((e) => of(FacilitiesActions.changePaymentMethodFailed({ error: e }))),
        ),
      ),
      share(),
    ),
  );
  registerStripe$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FacilitiesActions.registerStripe),
      exhaustMap(({ id }) =>
        this.api.registerStripe(id).pipe(
          map((model) => FacilitiesActions.registerStripeSuccess({ model })),
          catchError((e) => of(FacilitiesActions.registerStripeFailed({ error: e }))),
        ),
      ),
      share(),
    ),
  );
  getStripe$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FacilitiesActions.getStripe),
      exhaustMap(({ id }) =>
        this.api.fetchFacilityStripe(id).pipe(
          map((model) => FacilitiesActions.getStripeSuccess({ model })),
          catchError((e) => of(FacilitiesActions.getStripeFailed({ error: e }))),
        ),
      ),
      share(),
    ),
  );
  editShiftTemplate$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FacilitiesActions.editShiftTemplate),
      exhaustMap(({ id, dto }) =>
        this.api.editShiftTemplate(id, dto).pipe(
          map((model) => FacilitiesActions.editShiftTemplateSuccess({ model })),
          catchError((e) => of(FacilitiesActions.editShiftTemplateFailed({ error: e }))),
        ),
      ),
      share(),
    ),
  );

  deleteShiftTemplate$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FacilitiesActions.deleteShiftTemplate),
      exhaustMap(({ id }) =>
        this.api.deleteShiftTemplate(id).pipe(
          map((model) => FacilitiesActions.deleteShiftTemplateSuccess({ model })),
          catchError((e) => of(FacilitiesActions.deleteShiftTemplateFailed({ error: e }))),
        ),
      ),
      share(),
    ),
  );

  uploadAvatar$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FacilitiesActions.uploadAvatar),
      withLatestFrom(this.accessor.activeFacility$),
      exhaustMap(([{ file }, f]) =>
        this.api.uploadAvatar(f._id, file).pipe(
          map((model) => FacilitiesActions.uploadAvatarSuccess({ model })),
          catchError((e) => of(FacilitiesActions.uploadAvatarFailed({ error: e }))),
        ),
      ),
      share(),
    ),
  );

  setActiveFacility$ = createEffect(() => this.actions$.pipe(
    ofType(FacilitiesActions.setActiveFacility),
    switchMap(() => this.accessor.activeFacility$.pipe(take(1))),
    tap(facility =>
    {
        this.mixpanel.onFacilitySelect(facility);
        this.mixpanel.for('fm').track('FACILITY_SELECT');
    })
  ), { dispatch: false }).pipe(share());
}
