<div class="hdm-block" *ngIf="logs.length || showNoDNR">
    <div class="title-block">
        <span>{{nurse.name.first}} {{nurse.name.last}}’s DNR history</span>
        <button fill="none" (click)="onClose.emit()">
            <mat-icon>close</mat-icon>
        </button>
    </div>
    <div class="table-block">
        <div class="table-header">
            <div class="header-cell" *ngFor="let h of headerModel" [style]="{flex: h.flexValue}">
                <span >{{h.title}}</span>
            </div>
        </div>
        <ng-container *ngIf="logs.length && !showNoDNR">
            <div class="table-body-block">
                <div class="row" *ngFor="let log of logs; let i = index;" [ngClass]="{even: (i + 1)%2==0}">
                    <div class="cell"><span>{{log.action}}</span></div>
                    <div class="cell date">
                        <div>
                            <span>{{log.date}}&nbsp;</span><span>{{log.time}}</span>
                        </div>
                    </div>
                    <div class="cell by"><span class="name">{{log.takenBy.name}}</span><span
                            class="role">{{log.takenBy.role}}</span></div>
                    <div class="cell reason">
                        <ng-container *ngIf="log.reasons.length === 1"><span>{{log.reasons[0]}}</span></ng-container>
                        <ng-container *ngIf="log.reasons.length > 1">
                            <ul>
                                <li *ngFor="let reason of log.reasons;">
                                    <span>{{reason}}</span>
                                </li>
                            </ul>
                        </ng-container>

                        <span>{{log.reason}}</span>
                    </div>
                    <div class="cell"><span>{{log.duringEshyft}}</span></div>
                    <div class="cell"><span>{{log.reportingToState}}</span></div>
                    <div class="cell"><span>{{log.additional}}</span></div>
                </div>
            </div>
        </ng-container>
        <ng-container *ngIf="showNoDNR">
            <div class="no-history-block">
                <img src="assets/img/no-logs.svg" alt="">
                <span>No DNR history</span>
            </div>
        </ng-container>

    </div>
</div>