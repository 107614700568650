import { createAction, props, union } from '@ngrx/store';

import { type } from 'src/app/state/actions/helpers/action-type';
import { ShiftFiltersForm, TimecardFiltersForm } from '../../../types/forms';

const t = type('filters');

export const setShiftsFilters = createAction(
  t.sync('set_shifts_filters'),
  props<{ filters: ShiftFiltersForm }>(),
);
export const setTimecardsFilters = createAction(
  t.sync('set_timecards_filters'),
  props<{ filters: TimecardFiltersForm }>(),
);
