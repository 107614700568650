import { Injectable } from '@angular/core';

import { asapScheduler, AsyncSubject, BehaviorSubject, scheduled, throwError } from 'rxjs';
import { filter, take, tap } from 'rxjs/operators';

import { Socket, io } from 'socket.io-client';

import { AuthAccessor } from 'src/app/services/accessors/auth.accessor';
import { AppService } from 'src/app/services/app.service';
import { environment } from 'src/environments/environment';

@Injectable()
export class SocketService
{
    private _socket: Socket;

    private _connected = new BehaviorSubject(false);

    constructor(app: AppService, private authAccessor: AuthAccessor)
    {
        app.expose('services', 'socket', this);
    }

    get connected$() { return this._connected.asObservable().pipe(filter(c => !!c)); }

    get disconnected$() { return this._connected.asObservable().pipe(filter(c => !c)); }

    private setup(facilityId?: string)
    {

      console.log(`%c SETUP SOCKETS!`, 'color:red');
        return this.authAccessor.token$
        .pipe(
            take(1),
            tap(token =>
            {

                this._socket = io(environment.socketUrl, {
                    autoConnect: false,
                    reconnectionDelay: 30000,
                    reconnectionDelayMax: 30000,
                    transports: ['websocket']
                });

                this._socket.on('connect', () => {
                    this._socket.emit('login', { token, facility: facilityId });
                    return this._connected.next(true);
                });
                this._socket.on('disconnect', () => this._connected.next(false));
            })
        );
    }

    connect(facilityId?: string)
    {
        if(this._socket && this._socket.connected) return throwError('Socket is already connected!');

        const subject = new AsyncSubject();

        this.setup(facilityId).subscribe(() =>
        {
            this._socket.open();
            subject.complete();
        });

        return subject.asObservable();
    }

    disconnect()
    {
        if(this._socket && this._socket.connected)
        {
            this._socket.removeAllListeners();
            this._socket.close();
        }

        return scheduled([], asapScheduler);
    }

    on(...args: Parameters<Socket['on']>)
    {
        this._socket.on(args[0], args[1]);
    }

    once(...args: Parameters<Socket['once']>)
    {
        this._socket.once(args[0], args[1]);
    }

    off(...args: Parameters<Socket['off']>)
    {
        this._socket.off(args[0], args[1]);
    }
}
