import { createAction, union, props } from '@ngrx/store';
import { ReminderModel } from 'src/app/models/reminder';

import { type } from 'src/app/state/actions/helpers/action-type';



const t = type('reminders');

export const addReminderIntoQueue = createAction(t.sync('add_reminder_into_queue'), props<{ reminder: ReminderModel }>());
export const removeFirstFromQueue = createAction(t.sync('remove_first_from_queue'));

