import { Injectable } from '@angular/core';
import { asapScheduler } from 'rxjs';
import { take } from 'rxjs/operators';
import { AppService } from 'src/app/services/app.service';
import { State } from 'src/app/state/interfaces';
import { Store } from '@ngrx/store';
import { DnrEffects } from '../../../state/effects/dnr.effects';
import { NursesActions } from '../../../state/actions/nurses/nurses-action-types';
import { NursesEffects } from '../../../state/effects/nurses.effects';
import { NursesFiltersDTO } from 'src/app/types/dto/fetch-nurses';
import { SortDTO } from 'src/app/types/dto';
import { NURSES_PAGE_SIZE } from 'src/app/constants/pagination';

@Injectable({ providedIn: 'root' })
export class NursesFacade {
  constructor(
    private app: AppService,
    private store: Store<State>,
    private effects: NursesEffects
  ) {
    app.expose('facades', 'facility', this);
  }
  fetchNurses(
    filters: NursesFiltersDTO,
    sort: SortDTO,
    page: number,
    limit = NURSES_PAGE_SIZE,) {
    asapScheduler.schedule(() => this.store.dispatch(NursesActions.fetchNurses({
      sort, filters, limit, page
    })));
    return this.effects.fetchNurses$.pipe(take(1));
  }
  fetchFacilityNurse(
    nurseId) {
    asapScheduler.schedule(() => this.store.dispatch(NursesActions.fetchNurse({
      nurseId
    })));
    return this.effects.fetchNurse$.pipe(take(1));
  }
  markAsTrusted(
    facilityId: string,
    nurseId: string
  ){
    asapScheduler.schedule(() => this.store.dispatch(NursesActions.markAsTrusted({
      facilityId, nurseId
    })));
    return this.effects.markAsTrusted$.pipe(take(1));
  }
  removeFromTrusted(
    facilityId: string,
    nurseId: string,
    brokenTrustReasons: string[]
  ){
    asapScheduler.schedule(() => this.store.dispatch(NursesActions.removeFromTrusted({
      facilityId, nurseId, brokenTrustReasons
    })));
    return this.effects.removeFromTrusted$.pipe(take(1));
  }
}
