import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';

@Component({
  selector: 'app-time-input',
  templateUrl: './time-input.component.html',
  styleUrls: ['./time-input.component.scss']
})
export class TimeInputComponent implements OnInit {
  @Input() showSeconds = false;

  @Output() save = new EventEmitter();

  timeForm = this.fb.group({
    h: [12, [Validators.required]],
    m: ['00', [Validators.required]],
    s: ['00', [Validators.required]],
  });
  am = true;
  constructor(private fb: UntypedFormBuilder) { }

  ngOnInit(): void {
  }
  onArrowClick(type, direction){
    const metaData = {
      'h': {
        min: 1,
        max: 12
      },
      'm': {
        min: 0,
        max: 59
      },
      's': {
        min: 0,
        max: 59
      },
    }
    let value = parseInt(this.timeForm.get(type).value);
    if(direction === '+'){
      if(value === metaData[type].max){
        this.timeForm.get(type).setValue(metaData[type].min)
      }else{
        this.timeForm.get(type).setValue(value + 1)
      }
    }else if(direction === '-'){
      if(value === metaData[type].min){
        this.timeForm.get(type).setValue(metaData[type].max)
      }else{
        this.timeForm.get(type).setValue(value - 1)
      }
    }
    this.formatNumber(type)
  }
  formatNumber(type){
    let value = parseInt(this.timeForm.get(type).value);
    const defaultTime = {
      h: {
        value: '12',
        min: 1,
        max: 12
      },
      m: {
        value: '00',
        min: 0,
        max: 59
      },
      s: {
        value: '00',
        min: 0,
        max: 59
      }
    }
    if(isNaN(this.timeForm.get(type).value)){
      this.timeForm.get(type).setValue(defaultTime[type].value);
    }else if((value > defaultTime[type].max) || (value < defaultTime[type].min)){
      this.timeForm.get(type).setValue(defaultTime[type].value);
    }else{
      if(value < 10){
        this.timeForm.get(type).setValue('0' + value)
      }
    }
  }
  onSave(){
    let time = this.timeForm.get('h').value + ':' +  this.timeForm.get('m').value;
    if(this.showSeconds){
      time += ':' + this.timeForm.get('s').value;
    }
    time += this.am ? ' AM' : ' PM';
    this.save.emit(time);
  }

}
