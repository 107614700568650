import { Injectable } from '@angular/core';
import { CanActivate, UrlTree, Router, CanActivateChild } from '@angular/router';

import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';

import { AuthFacade } from 'src/app/services/facades/auth/auth.facade';

@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate, CanActivateChild
{
    constructor(private authFacade: AuthFacade, private router: Router) { }

    canActivate(): Observable<boolean | UrlTree>
    {
        return this.authFacade.state.token$
            .pipe(
                map(t => !!t),
                tap(hasToken => !hasToken && this.router.navigateByUrl('/auth'))
            );
    }

    canActivateChild()
    {
        return this.canActivate();
    }
}
