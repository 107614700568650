import { createSelector } from '@ngrx/store';

import { State } from 'src/app/state/interfaces';
import { selectModelsById } from 'src/app/state/helpers/select-models-by-id';

import { MANAGER_FEATURE_KEY, REMINDERS_FEATURE_KEY } from '../interfaces';

const state = (s: State) => s[MANAGER_FEATURE_KEY][REMINDERS_FEATURE_KEY];

export const selectReminders = createSelector(state, (state) => state.models);
