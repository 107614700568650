export const TIMECARD_REJECTION_REASONS = [
    {
        "label": "Nurse never showed up for shift",
        "value": "never_showed_up"
    },
    {
        "label": "Wrong clock in time",
        "value": "wrong_clockin_time"
    },
    {
        "label": "Wrong clock out time",
        "value": "wrong_clockout_time"
    },
    {
        "label": "Other",
        "value": "other"
    }
];

export type TTimecardStatusKey = 'isApproved' | 'isManualPay' | 'isOfflinePayment' | 'isPaid' | 'isPresubmitted' | 'isRejected' | 'isSubmitted' | 'isSameDayPay' | 'isLatePayroll';

export const TIMECARD_STATUS_LABELS: Record<TTimecardStatusKey, string> = {
    "isPresubmitted": "In progress",
    "isSubmitted": "Pending",
    "isApproved": "Approved",
    "isRejected": "Rejected",
    "isPaid": "AutoPaid",
    "isOfflinePayment": "OfflinePayment",
    "isManualPay": "ManualPay",
    "isSameDayPay": "SameDayPay",
    "isLatePayroll": "LatePayroll"
}

export const TIMECARD_STATUS_TO_PERMISSION: Record<TTimecardStatusKey, string> = {
    "isPresubmitted": "inProgress",
    "isSubmitted": "pending",
    "isApproved": "approved",
    "isRejected": "rejected",
    "isPaid": "autoPaid",
    "isOfflinePayment": "offlinePayment",
    "isManualPay": "manualPay",
    "isSameDayPay": "sameDayPay",
    "isLatePayroll": "latePayroll"
};