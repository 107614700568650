import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, Validators } from '@angular/forms';
import { take } from 'rxjs';
import { FacilityModel } from 'src/app/models/facility';
import { NurseModel } from 'src/app/models/nurse';
import { NurseService } from 'src/app/modules/manager/services/api/nurse.service';
import { MixpanelService } from 'src/app/services/mixpanel.service';

@Component({
  selector: 'app-remove-dnr-modal',
  templateUrl: './remove-dnr-modal.component.html',
  styleUrls: ['./remove-dnr-modal.component.scss']
})
export class RemoveDnrModalComponent implements OnInit {
  @Input() nurse: NurseModel;
  @Input() facility: FacilityModel;

  @Output() onClose = new EventEmitter();
  @Output() removeNurseFromDnr = new EventEmitter();

  reasonsForm = this.fb.group({
    reason: new UntypedFormControl('', [Validators.required, Validators.minLength(10),Validators.maxLength(500)]),
    facilities: new UntypedFormControl([]),
  })
  facilities = [];
  constructor(private fb: UntypedFormBuilder, private mixpanel: MixpanelService, private nurseService: NurseService) { }

  ngOnInit(): void {
    this.mixpanel.for('fm').track('REMOVE_DNR_OPEN', {nurse: this.nurse});
    this.nurseService.fetchAllDnrByUser(this.nurse._id).pipe(take(1)).subscribe(allDnr => {
      allDnr.forEach(dnr=>{
        this.facilities.push(dnr.facility);
      });
    })
  }
  onRemoveClick(){
    this.mixpanel.for('fm').track('REMOVE_DNR_CONFIRM', {nurse: this.nurse});
    let facilities = this.reasonsForm.get('facilities').value;
    const index = facilities.indexOf('all');
    if (index > -1) {
      facilities.splice(index, 1);
    }
    facilities.push(this.facility._id);
    this.removeNurseFromDnr.emit({
      nurse: this.nurse._id,
      facilities,
      ...(this.reasonsForm.get('reason').value.length ? {reason: this.reasonsForm.get('reason').value} : {})
    })
  }
  onCloseModal(){
    this.mixpanel.for('fm').track('REMOVE_DNR_CANCEL', {nurse: this.nurse});
    this.onClose.emit(); 
  }
}
