<div class="row" *ngIf="type != 'reminder'">
  <div class="toast-block {{options.toastClass}}">
    <div class="icon-block"><mat-icon [svgIcon]="options['type']"></mat-icon></div>
    <div class="content-block">
      <div *ngIf="title" [class]="options.titleClass" [attr.aria-label]="title" class="custom-title">
        {{ title }}
      </div>
      <div *ngIf="message && options.enableHtml" role="alert" aria-live="polite" class="custom-message"
        [class]="options.messageClass" [innerHTML]="message">
      </div>
      <div *ngIf="message && !options.enableHtml" role="alert" aria-live="polite" class="custom-message"
        [class]="options.messageClass" [attr.aria-label]="message">
        {{ message }}
      </div>
      <div *ngIf="options['button']" class="btn-block">
        <a (click)="action($event)" class="btn action-btn">
          {{options['buttonText'] ? options['buttonText'] : 'View'}}
        </a>
        <a (click)="remove()" class="btn action-btn">
          Dismiss
        </a>
      </div>
    </div>
    <div class="close-block" *ngIf="!options['button']">
      <button fill="none" (click)="remove()"><mat-icon>close</mat-icon></button>
    </div>

  </div>
</div>


<div class="reminder-block" *ngIf="type === 'reminder'">
  <div class="avatar-block">
    <img src="{{reminderData.applicant.user.avatar}}" alt="">
  </div>
  <div class="info-block">
    <span class="title">{{reminderData.applicant.user.name.first + ' ' + reminderData.applicant.user.name.last}} </span>
    <span class="text">{{reminderData.text}}</span>
    <div class="buttons-block">
      <button style="margin-left: 0;" (click)="onReminderConfirm()">Confirm</button>
      <button fill="outline" color="border-base" (click)="onReminderDecline()">Decline</button>
      <button style="margin-right: 0;" fill="outline" color="border-base" (click)="onGoToShift()">View</button>
    </div>
  </div>
  <div class="close-btn-block">
    <button fill="none" (click)="onCloseReminder()"><mat-icon>close</mat-icon></button>
  </div>
</div>

