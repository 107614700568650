import { Injectable } from '@angular/core';

import { AsyncSubject, from, Observable } from 'rxjs';
import { withLatestFrom } from 'rxjs/operators';

import { AppService } from '../../../../services/app.service';
import { NgxSpinnerService } from "ngx-spinner";

@Injectable({ providedIn: 'root' })
export class LoaderService
{
    constructor(
        app: AppService,
        private loader: NgxSpinnerService
    )
    {
        app.expose('services', 'loader', this);
    }

    async create(message: string)
    {
        const l = await this.loader.show();

        return l;
    }
    async hide()
    {
        const l = await this.loader.hide();

        return l;
    }

}
