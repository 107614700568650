import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';

import { State } from 'src/app/state/interfaces';

import { selectPagination, selectTimecardIds, selectTimecards } from '../../state/selectors/timecards';

@Injectable()
export class TimecardsAccessor
{
    constructor(private store: Store<State>) { }

    get timecardsIds$() { return this.store.select(selectTimecardIds); }
    get timecards$() { return this.store.select(selectTimecards); }

    get pagination$() { return this.store.select(selectPagination); }
}
