<app-subheadered-content-wrapper [title]="title" *ngIf="facility">
    <div class="logs-container">
        <div class="filter-container">
            <div class="date-picker-block">
                <app-simple-range-datepicker [range]="filterForm"></app-simple-range-datepicker>
            </div>
            <div class="export-block">
                <button fill="none" (click)="onExport()"><mat-icon svgIcon="exportFm"></mat-icon> Export log</button>
            </div>
        </div>
        <div class="logs-table-block">
            <div class="table-header">
                <span (click)="onSortClick(h.sort)" *ngFor="let h of sortHeaderModel">{{h.title}}
                    <mat-icon
                        svgIcon="sort-01"
                        [ngClass]="{
                        active: sort.sort === h.sort,
                        desc: sort.sort === h.sort && sort.order === 'desc'
                        }"
                  ></mat-icon></span>
            </div>
            <div class="table-body-block">
                <div class="row" *ngFor="let log of logs">
                    <div class="cell first-cell"><span>{{log.action}}</span></div>
                    <div class="cell"><span>{{log.name}}</span></div>
                    <div class="cell"><span class="time">{{log.time}}</span></div>
                </div>
            </div>
        </div>
    </div>
</app-subheadered-content-wrapper>
