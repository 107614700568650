import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ManagerModel } from 'src/app/models/manager';
import { UserModel } from 'src/app/models/user';
import { MessagesService } from 'src/app/modules/manager/services/messages.service';
import { CreateManagerEmailPreferencesDTO } from 'src/app/types/dto/create-manager-email-preferences';
import { GetManagerEmailPreferencesDTO } from 'src/app/types/dto/get-manager-email-preferences';

import { environment } from 'src/environments/environment';


@Injectable({ providedIn: 'root' })
export class UserService
{
    private endpoint = environment.apiUrl;

    constructor(
        private http: HttpClient,
        private messages: MessagesService
    ) { }

    getProfile()
    {
        return this.http.get<ManagerModel>(`${this.endpoint}/auth/profile`);
    }

    edit(user: UserModel)
    {
        return this.http.put<UserModel>(`${this.endpoint}/users/${user._id}`, { ...user });
    }

    changeEmail(id: string, email: string, confirmation: string, password: string)
    {
        return this.http.post<UserModel>(`${this.endpoint}/users/${id}/change-email`, { email, confirmation, password })
            .pipe(this.messages.pipeHttpError());
    }

    changePassword(id: string, oldPassword: string, password: string, confirmation: string)
    {
        return this.http.post(`${this.endpoint}/users/${id}/change-password`, { oldPassword, password, confirmation })
            .pipe(this.messages.pipeHttpError());
    }

    uploadAvatar(file: File)
    {
        const formData = new FormData();
        formData.append('file', file, file.name);
        return this.http.post<UserModel>(`${this.endpoint}/users/avatar`, formData)
            .pipe(this.messages.pipeHttpError());
    }

    getFmEmailNotificationPreferencesByFacility(user: string, facility: string)
    {
        let fromObject = {
            user,
            facility
        };
        const params = new HttpParams({
            fromObject
        });
        return this.http.get<GetManagerEmailPreferencesDTO[]>(`${this.endpoint}/preferences/fm-email-notifications`, { params });
    }

    updateFmEmailNotificationPreferences(managerEmailPreferences: CreateManagerEmailPreferencesDTO[]) {
        return this.http.post<CreateManagerEmailPreferencesDTO[]>(`${this.endpoint}/preferences/fm-email-notifications`, managerEmailPreferences)    
            .pipe(this.messages.pipeHttpError());

    }

}
