import { Injectable } from "@angular/core";
import { FacilityModel } from "src/app/models/facility";
import { UserModel } from "src/app/models/user";

@Injectable({ providedIn: 'root' })
export class IntercomService {

    constructor() {}

    boot(user: UserModel, facilities: FacilityModel[]) {

      const facilityStates = Array.from(new Set((facilities?.map(f => f.address.state)) || [])).join(', ');

      (<any>window).Intercom('boot', {
        app_id: 'zeu1fvls',
        email: user.email,
        user_id: user._id,
        user_hash: user.hmacs['web'],
        name: user.fullName,
        phone: user.phone,
        avatar: {
            type: 'avatar',
            image_url: user.avatar
        },
        gender: user.gender,
        state: user.address?.state,
        city: user.address?.city,
        address: user.address?.addressLine,
        position: user.position,
        "Facility State": facilityStates,
        custom_launcher_selector: '#intercomButton',
        hide_default_launcher: true 
      });
    }

    update() {
      (<any>window).Intercom("update", {last_request_at: (new Date()).getTime()/1000});
    }

    shutdown() {
      (<any>window).Intercom('shutdown');
    }

    displayMessenger() {
      (<any>window).Intercom('show');
    }
    openArticle(articleId){
      (<any>window).Intercom('showArticle', articleId);
    }

}