import { Injectable } from '@angular/core';
import {
  CanActivate,
  CanActivateChild,
  Router,
  UrlTree,
} from '@angular/router';

import { Observable } from 'rxjs';
import { filter, map, switchMap, take, tap } from 'rxjs/operators';
import { UserModel } from 'src/app/models/user';

import { UserFacade } from 'src/app/services/facades/user/user.facade';
import { FacilitiesFacade } from '../services/facades/facilities/facilities.facade';
import { identifyClarityUser } from 'src/app/helpers/clarity-identify';

@Injectable({ providedIn: 'root' })
export class SelectFacilityGuard implements CanActivate, CanActivateChild {
  constructor(
    private userFacade: UserFacade,
    private facilitiesFacade: FacilitiesFacade,
    private router: Router,
  ) {}

  canActivate(): Observable<boolean | UrlTree> {
    let activeFacilities = this.IsJsonString(
      localStorage.getItem('activeFacilities'),
    )
      ? JSON.parse(localStorage.getItem('activeFacilities'))
      : '';
    return this.userFacade.state.user$.pipe(
      filter((u) => !!u),
      take(1),
      switchMap((u: UserModel) =>{
        identifyClarityUser(u._id);
        return this.facilitiesFacade.binder.myFacilities$.pipe(
          filter((f) => !!f && !!f.length),
          map((f) => {
            if (f.length > 1) {
              if (!activeFacilities) {
                return false;
              } else if (!activeFacilities.find((e) => e.userId == u._id)) {
                return false;
              } else if (
                !f.find(
                  (f) =>
                    f._id ==
                    activeFacilities.find((e) => e.userId == u._id)
                      .activeFacilityId,
                )
              ) {
                return false;
              }else if(
                f.find(
                  (f) =>
                    f._id ==
                    activeFacilities.find((e) => e.userId == u._id)
                      .activeFacilityId,
                ).deactivationStatus === 'Deactivated' 
              ){
                return false
              }
            }
            return true;
            
          }),
          tap(
            (activeFacility) =>
              !activeFacility &&
              this.router.navigateByUrl('/manager/facility-selection'),
          ),
        )}
      ),
    );
  }
  canActivateChild() {
    return this.canActivate();
  }
  IsJsonString(str) {
    try {
      JSON.parse(str);
    } catch (e) {
      return false;
    }
    return true;
  }
}
