import { CdkTextareaAutosize } from '@angular/cdk/text-field';
import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import {
  AbstractControl,
  ControlValueAccessor,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
  Validator,
  Validators,
} from '@angular/forms';
import { MatSelect } from '@angular/material/select';


export interface SelectData {
  id: string;
  text: string;
  addNew?: boolean;
}
export interface RateValidation {
  min: number,
  max: number,
}
export type CustomValidators = 'description' | 'required' | 'size' | 'email' | 'reason' | 'slots';
export interface MatchValidator {
  text: string;
  value: string;
}
export interface SelectIcons {
  icon: string,
  id: string,
  text: string,
  disabled: boolean,
  addNew?: boolean
}

@Component({
  selector: 'app-input',
  templateUrl: './input.component.html',
  styleUrls: ['./input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: InputComponent,
    },
    {
      provide: NG_VALIDATORS,
      multi: true,
      useExisting: InputComponent,
    },
  ],
})
export class InputComponent implements ControlValueAccessor, Validator, OnInit {
  @Input() label: string;
  @Input() type: 'date' | 'select' | 'money' | 'spin' | 'textArea' | 'text' | 'phone' | 'password' | 'customTime' | 'iconSelect';
  @Input() disabled = false;
  @Input() selectData: SelectData[];
  @Input() rateValid: RateValidation;
  @Input() placeholder: string = '';
  @Input() readonly: boolean = false;
  @Input() labelColor: 'text' | 'medium-shade' = 'medium-shade';
  @Input() labelSize = '1.4';
  @Input() validators: Array<CustomValidators> = [];
  @Input() matchValidator: MatchValidator;
  @Input() labelTooltipText: string;
  @Input() error: boolean = false;

  private _selectIconsObj: SelectIcons[];
    
  @Input() set selectIconsObj(value: SelectIcons[]) {
    this._selectIconsObj = value;
    this._selectIconsObj.map(o=>{
      let splittedText = o.text.split('·');
      this.iconsFormattedObj[o.id] = o;
      this.iconsFormattedObj[o.id].shiftName = splittedText[0];
      this.iconsFormattedObj[o.id].shiftTime = splittedText[1];
    });
  }
  get selectIconsObj(): SelectIcons[] {
    return this._selectIconsObj;
  }

  @Input() textAreaAutosize: boolean = false;
  @Input() widePadding: boolean = false;
  @Input() selectLicenses: boolean = false;
  @Input() maxlength: number = 1;
  @Output() addShiftTimeModal = new EventEmitter();
  @Input() set customShiftSelected(selectIcons: string) {
    this.writeValue(selectIcons);
  }

  @ViewChild('autosize') autosize: CdkTextareaAutosize;

  @Output() switchSubmit = new EventEmitter();
  @ViewChild('IconSelect') select: MatSelect;

  increment = {
    money: 0.25,
    spin: 1,
  };

  value = {
    money: '',
    date: '',
    text: '',
    spin: 0,
    select: '',
  };
  spinRange = {
    min: 1,
    max: 15,
  };
  errorString = '';
  selectIcons = ['icon-mobile', 'icon-custom'];
  defaultSelected = {
    id: '',
    text: '',
  };
  iconsFormattedObj: any = {};


  isValid = true;

  ngOnInit(){
    if(this.type === 'iconSelect'){
      this._selectIconsObj.map(o=>{
        let splittedText = o.text.split('·');
        this.iconsFormattedObj[o.id] = o;
        this.iconsFormattedObj[o.id].shiftName = splittedText[0];
        this.iconsFormattedObj[o.id].shiftTime = splittedText[1];
      });

    }
  }

  compareFn(s1: SelectIcons, s2: SelectIcons) {
    return s1 && s2 ? s1.id === s2.id : s1 === s2;
  }

  onChange = (value) => {};
  onTouched = () => {};

  touched = false;
  constructor() {}

  onSpinAdd() {
    this.markAsTouched();
    if (!this.disabled) {
      if (this.type === 'money') {
        if (parseFloat(this.value.money) < this.rateValid.max) {
          this.value.money = (
            parseFloat(this.value.money) + this.increment[this.type]
          ).toFixed(2);
        }
      } else if (parseInt(this.value[this.type]) < this.spinRange.max) {
        this.value[this.type] =
          parseInt(this.value[this.type]) + this.increment[this.type];
      }

      this.onChange(this.value[this.type]);
    }
  }
  onSpinRemove() {
    this.markAsTouched();

    if (!this.disabled) {
      if (this.type === 'money') {
        if (parseFloat(this.value.money) > this.rateValid.min) {
          this.value.money = (
            parseFloat(this.value.money) - this.increment[this.type]
          ).toFixed(2);
        }
      } else if (parseInt(this.value[this.type]) > this.spinRange.min) {
        this.value[this.type] =
          parseInt(this.value[this.type]) - this.increment[this.type];
      }

      this.onChange(this.value[this.type]);
    }
  }

  onDateChange(date) {
    this.onChange(date.toISOString());
  }
  onSelectChange(value) {
    this.onChange(value);
  }
  onTextChange(event) {
    this.value[this.type] = event.target.value;
    this.onChange(event.target.value);
  }
  writeValue(value: any) {
    this.value[this.type] = this.type === 'money' ? value.toFixed(2) : value;
    if (this.type === 'select') {
      this.defaultSelected = this.selectData.find((d) => {
        return d.id === this.value[this.type];
      });
    }
  }

  registerOnChange(onChange: any) {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched: any) {
    this.onTouched = onTouched;
  }

  markAsTouched() {
    if (!this.touched) {
      this.onTouched();
      this.touched = true;
    }
  }

  setDisabledState(disabled: boolean) {
    this.disabled = disabled;
  }
  onBlur(e) {
    if (this.type === 'customTime') {
      e.target.value = this.timeParse(e.target.value).toLowerCase();
      this.onChange(e.target.value);
    }
    if (this.type === 'spin') {
      if(!e.target.value || isNaN(e.target.value)){
        e.target.value = 1;
        this.value.spin = 1;
        this.onChange(e.target.value);
      }
    }
  }
  timeParse(t) {
    let meridiem = t.match(/(am|pm)/i);
    if (meridiem) {
      meridiem = meridiem[0];
    }
    let time = t.match(/(\d?\d)(.?:?-?)(\d?\d?)/);
    if (!time) {
      return '12:00 am';
    }
    let h = parseInt(time[1], 10);
    let m = parseInt(time[3], 10) || 0;
    if (meridiem?.toUpperCase() === 'PM') {
      h = h + 12;
    }
    if (h > 24) {
      time = t.match(/(\d)(\d?\d?)/);
      h = parseInt(time[1], 10);
      m = parseInt(time[2], 10) || 0;
    }
    if (h === 12) {
      h = 0;
    }
    if (h === 24) {
      h = 12;
    }
    let d = new Date();
    d.setHours(h);
    d.setMinutes(m);
    d.setSeconds(0, 0);

    let parsedDateString = d.toLocaleString('en-US', {
      hour: 'numeric',
      minute: 'numeric',
      hour12: true,
    });

    return parsedDateString;
  }
  validate(control: AbstractControl): ValidationErrors | null {
    let result = null;
    if (this.type === 'phone') {
      result = this.phoneValidator(control)
    }else if(this.type === 'money' || this.type === 'spin'){
      result = this.numberValidator(control);
      if (!result && (this.type === 'money')){
        result = this.moneyValidator(control);
      }
    }
    else if (
      this.type === 'text' ||
      this.type === 'password' ||
      this.type === 'textArea'
    ) {
      result = this.textValidator(control);
      if (!result && (this.type === 'password')) {
        result = this.passwordValidator;
      }
    }


    return result;

  }
  onSelectClick(select) {
    //let event = new MouseEvent('mousedown');
    select.size = 2;
  }
  phoneValidator(control: AbstractControl){
    if (!/^\d+$/.test(control.value)) {
      this.errorString = 'Invalid phone number input. Example input: 1222333444';
      this.switchSubmit.emit(false);
      this.isValid = false;
      return {
        wrongRate: {
          value: control.value
        }
      };
    } else {
      this.switchSubmit.emit(true);
      this.isValid = true;
    }
    return null;
  }
  numberValidator(control: AbstractControl){
    let slotsError = false;

    this.validators.map((v) => {
      if (v === 'slots' && parseInt(control.value) > 15) {
        slotsError = true;
      }
    });

    if(slotsError) {
      this.errorString = '*maximum nurse quantity per shift is 15';
      this.isValid = false;
      this.switchSubmit.emit(false); 
    }
    else if (isNaN(parseFloat(control.value))) {
      this.isValid = false;
      this.errorString =  'should be a number';
      this.switchSubmit.emit(false);
      return {
        wrongRate: {
          value: control.value,
        },
      };
    } 
    else {
      this.isValid = true;
      this.switchSubmit.emit(true);
    }

    return null;
  }
  moneyValidator(control: AbstractControl){
    const value = control.value
    if (
      parseFloat(value) > this.rateValid.max ||
      parseFloat(value) < this.rateValid.min
    ) {
      if(!this.rateValid.min && !this.rateValid.max){
        this.errorString = 'Select a position to see corresponding hourly rates';
      }else{
        this.errorString =
          'hourly default rate should be between $' +
          this.rateValid.min.toFixed(2) +
          ' and $' +
          this.rateValid.max.toFixed(2) +
          '.';

      }
      this.isValid = false;
      this.switchSubmit.emit(false);
      return {
        wrongRate: {
          value,
        },
      };
    } else {
      this.isValid = true;
      this.switchSubmit.emit(true);
    }
    return null;
  }
  textValidator(control: AbstractControl){
    const value = control.value;
    this.isValid = true;
    if (this.matchValidator && this.matchValidator.value !== value) {
      this.errorString = this.matchValidator.text;
      this.isValid = false;
    }
    this.validators.map((v) => {
      if (v === 'required' && value === '') {
        this.errorString = 'field is required';
        this.isValid = false;
      } else if (v === 'email' && Validators.email(control)) {
        this.errorString = 'provided email is invalid';
        this.isValid = false;
      } else if (v === 'size' && value.length > 500) {
        this.errorString = 'length should be  less than 500 symbols';
        this.isValid = false;
      } else if (v === 'reason' && value.length < 7) {
        this.errorString = 'reason is too short';
        this.isValid = false;
      } else if (v === 'reason' && value.length > 199) {
        this.errorString = 'reason is too long';
        this.isValid = false;
      } else if (v === 'description' && (value?.length > 280)) {
        this.errorString = 'description should be less than 280 characters';
        this.isValid = false;
      } else if (v === 'description' && (value?.length > 0) && (value?.length < 10)) {
        this.errorString = 'description should be at least 10 characters';
        this.isValid = false;
      }
    });
    if (!this.isValid) {
      return {
        error: {
          value,
        },
      };
    } else {
      return null;
    }
  }
  passwordValidator(control: AbstractControl){
    if (control.value.length < 8) {
      this.isValid = false;
      this.errorString = 'password must be at least 8 characters';
      this.switchSubmit.emit(false);
      return {
        wrongRate: {
          value: control.value
        },
      };
    } else {
      this.isValid = true;
      this.switchSubmit.emit(true);
    }
    return null;
  }

  showAddShiftTimeModal() {
    this.addShiftTimeModal.emit(true);
  }
}
