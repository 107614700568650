import * as clone from 'clone';

import { ShiftsFiltersDTO } from 'src/app/types/dto/fetch-shifts/shifts-filters';
import { getIsInstaShyftValue } from 'src/app/types/insta-status';

import { ScheduleFiltersForm } from '../types/forms/schedule-filters';

export class ScheduleFiltersAdapter {
  private _form: ScheduleFiltersForm;

  private _dto: ShiftsFiltersDTO;

  constructor(form: ScheduleFiltersForm) {
    this._form = clone(form);
  }

  private _adapt(): ShiftsFiltersDTO {
    const insta = getIsInstaShyftValue(this._form.checkboxes.insta);
    return {
      applicantStatuses: this._form.checkboxes.applicantStatuses,
      licenseTypes: this._form.checkboxes.licenses,
      shiftStatuses: this._form.checkboxes.shiftStatuses,
      shiftTypes: this._form.checkboxes.shiftTypes,
      startTimeFrom: this._form.range.startTimeFrom,
      startTimeTo: this._form.range.startTimeTo,
      scheduledApplicants: this._form.scheduledApplicants,
      isInstaShyft: insta
    } as ShiftsFiltersDTO;
  }

  get dto() {
    if (!this._dto) this._dto = this._adapt();

    return this._dto;
  }
}
