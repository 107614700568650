import { AsyncSubject, defer, zip } from "rxjs";

import { IExecutable } from "./iexecutable";

export class AsyncTask<ActionResult> implements IExecutable<ActionResult>
{
    constructor(
        private actions: IExecutable<ActionResult>[]
    )
    { }

    execute(input: Record<string, any>)
    {
        const subject = new AsyncSubject<any>();

        zip(...this.actions.map(a => defer(() => a.execute(input))))
        .subscribe(
            v => subject.next(v),
            e => subject.error(e),
            () => subject.complete()
        );

        return subject.asObservable();
    }
}