<link href="https://fonts.googleapis.com/icon?family=Material+Icons|Material+Icons+Outlined" rel="stylesheet" />

<div class="app-container">
    <div
        class="sidebar-container"
        [ngClass]="{ mobileMenu: mobileMenu }"
        *ngIf="router.url != '/manager/facility-selection'"
    >
        <button class="close-btn" fill="none" (click)="mobileMenu = false" *ngIf="mobileMenu">
            <mat-icon>close</mat-icon>
        </button>
        <div class="sidebar-logo-container">
            <img src="assets/img/eshyft-light.png" alt="eshyft-logo" />
        </div>
        <div class="sidebar-facility-container" *ngIf="selectedFacility">
            <div class="selected-facility-container" (click)="onDropDownClick()">
                <div class="avatar-block"><img [src]="selectedFacility.avatar" alt="" /></div>
                <div class="name-block">
                    <span class="manager-name">{{ user.fullName }}</span>
                    <span class="facility-name">{{ selectedFacility.name }}</span>
                </div>
                <div class="arrow-block">
                    <mat-icon *ngIf="!showDropDown">expand_more</mat-icon>
                    <mat-icon *ngIf="showDropDown">expand_less</mat-icon>
                </div>
            </div>
            <div class="facilities-dropdown-container" *ngIf="showDropDown">
                <ng-container [formGroup]="filterInputForm">
                    <input
                        type="text"
                        placeholder="Search for the facility..."
                        [ngClass]="{ 'with-selected': selectedFacility }"
                        formControlName="facilityName"
                        (input)="onFilterInputChange($event)"
                    />
                </ng-container>
                <div
                    class="facility-block"
                    (click)="onSelectFacility(facility)"
                    *ngFor="let facility of filteredFacilities"
                    [ngClass]="{active: facility._id === selectedFacility._id}"
                >
                    <img src="{{facility.avatar}}" alt="" [ngClass]="{deactivated: facility.deactivationStatus === 'Deactivated' }"/>
                    <mat-icon *ngIf="facility.deactivationStatus === 'Deactivated'" class="deactivated-icon">block</mat-icon>
                    <div class="name-block">
                        <span class="name" [ngClass]="{deactivated: facility.deactivationStatus === 'Deactivated' }">
                            {{ facility.name }}
                        </span>
                        <span *ngIf="(facility.deactivationStatus === 'Deactivated') || (facility.deactivationStatus === 'Disabled')" class="{{facility.deactivationStatus}}-label deactivation-status-label">{{facility.deactivationStatus}}</span>
                    </div>
                    <div class="icon" *ngIf="selectedFacility && (facility._id === selectedFacility._id)">
                        <mat-icon>done</mat-icon>
                    </div>
                </div>
            </div>
        </div>
        <div class="sidebar-menu-container">
            <a routerLink="/manager/dashboard" routerLinkActive="active">
                <div class="sidebar-menu-item">
                    <mat-icon class="menu-icon" svgIcon="dashboard-01"></mat-icon>
                    Dashboard
                </div>
            </a>
            <a routerLink="/manager/schedule" routerLinkActive="active">
                <div class="sidebar-menu-item">
                    <mat-icon class="menu-icon" svgIcon="schedule-01"></mat-icon>
                    Schedule
                </div>
            </a>
            <a routerLink="/manager/shifts" routerLinkActive="active">
                <div class="sidebar-menu-item">
                    <mat-icon class="menu-icon" svgIcon="shifts-01"></mat-icon>
                    Shifts
                    <ng-container *ngIf="shiftsUpdatedCount$ | async as shiftsUpdatedCount">
                        <app-badge *ngIf="shiftsUpdatedCount > 0" [text]="shiftsUpdatedCount + ''"></app-badge>
                    </ng-container>
                </div>
            </a>
            <a routerLink="/manager/chat" routerLinkActive="active">
                <div class="sidebar-menu-item">
                    <mat-icon class="menu-icon" svgIcon="chats-01"></mat-icon>
                    Chats
                    <ng-container *ngIf="chatsUnreadCount$ | async as chatsUnreadCount">
                        <app-badge *ngIf="chatsUnreadCount > 0" [text]="chatsUnreadCount + ''"></app-badge>
                    </ng-container>
                </div>
            </a>
            <a routerLink="/manager/timecards" routerLinkActive="active">
                <div class="sidebar-menu-item">
                    <mat-icon class="menu-icon" svgIcon="timecards-01"></mat-icon>
                    Timecards
                </div>
            </a>
            <a routerLink="/manager/nurse-directory" routerLinkActive="active">
                <div class="sidebar-menu-item">
                    <mat-icon class="menu-icon" svgIcon="nurseDirectory"></mat-icon>
                    Nurses
                </div>
            </a>
            <a routerLink="/manager/settings" routerLinkActive="active">
                <div class="sidebar-menu-item">
                    <mat-icon class="menu-icon" svgIcon="settings-01"></mat-icon>
                    Settings
                </div>
            </a>
            <a (click)="onLogOut()">
                <div class="sidebar-menu-item">
                    <mat-icon class="menu-icon" svgIcon="logout-01"></mat-icon>
                    Logout
                </div>
            </a>
        </div>
    </div>

    <div class="content-container" #contentContainer>
        <router-outlet></router-outlet>
    </div>
</div>
<ngx-spinner [type]="'ball-scale-multiple'" [bdColor]="'rgba(0, 0, 0, 0.3)'" [color]="'#fff'" [fullScreen]="true"></ngx-spinner>
<div #modal></div>
<div #popup></div>