<div class="input-block">
    <span *ngIf="label" class="label">{{ label }}</span>
    <input type="text" [(ngModel)]="formattedValue" (ngModelChange)="onInputChange()" appCurrencyFormat>
    <div *ngIf="withoutSlider" class="buttons-container">
        <button (click)="onSpinChange('+')" fill="none">
            <mat-icon class="chevron-up" svgIcon="mdCheveronUp-01"></mat-icon>
        </button>
        <button (click)="onSpinChange('-')" fill="none">
            <mat-icon class="chevron-down" svgIcon="mdCheveronUp-01"></mat-icon>
        </button>
    </div>
    <div *ngIf="!withoutSlider" class="slider-wrapper">
        <mat-slider
            class="slider-block"
            [disabled]="false"
            [invert]="false"
            [max]="inputRange.max"
            [min]="inputRange.min"
            [step]="1"
            [thumbLabel]="false"
            [(ngModel)]="value"
            [vertical]="false"
            aria-labelledby="example-name-label"
            (input)="onSliderChange($event)">
        </mat-slider>
    </div>
  </div>
  