import * as clone from 'clone';

import { FacilityModel } from "src/app/models/facility";

import { EditFacilityInformationForm } from "../types/forms";

export class EditFacilityInformationAdapter
{
    private _form: EditFacilityInformationForm;
    private _facility: FacilityModel;

    private _adaptedFacility: FacilityModel;

    constructor(form: EditFacilityInformationForm, facility: FacilityModel)
    {
        this._form = clone(form);
        this._facility = clone(facility);
    }

    private _adapt()
    {
        return {
            ...this._facility,
            contactData: {
                ...this._facility.contactData,
                phone: this._form.phoneNumber,
                website: this._form.website
            },
            description: this._form.description,
            displayName: this._form.displayName
        } as FacilityModel;
    }

    get model()
    {
        if(!this._adaptedFacility)
            this._adaptedFacility = this._adapt();
        
        return this._adaptedFacility;
    }
}