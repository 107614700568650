import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';

import { asapScheduler } from 'rxjs';
import { take } from 'rxjs/operators';
import { AppService } from 'src/app/services/app.service';

import { State } from 'src/app/state/interfaces';
import { ServicedStatesActions } from '../../../state/actions/serviced-states/serviced-states-action-types';

import { ServicedStatesEffects } from '../../../state/effects/serviced-states.effects';
import { ServicedStatesAccessor } from '../../accessors/serviced-states.accessor';

@Injectable({ providedIn: 'root' })
export class ServicedStatesFacade
{
    constructor(
        app: AppService,
        private store: Store<State>,
        private _effects: ServicedStatesEffects,
        private accessor: ServicedStatesAccessor
    )
    {
        app.expose('facades', 'servicedStates', this);
    }

    get effects() { return this._effects; }

    get state() { return this.accessor; }

    fetchState(state: string)
    {
        asapScheduler.schedule(() => this.store.dispatch(ServicedStatesActions.fetchState({ state })));
        return this.effects.fetchState$.pipe(take(1));
    }

    setActive(state: string)
    {
        this.store.dispatch(ServicedStatesActions.setActiveState({ state }));
    }
}
