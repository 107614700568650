import { createAction, props, union } from '@ngrx/store';

import { type } from '../helpers/action-type';
import { LoginDTO } from './dto/login';
import { RefreshTokenDTO } from './dto/refresh-token';
import { SetTokenDTO } from './dto/set-token';
import { ForgotPasswordDTO } from './dto/forgot-password';
import { ResetPasswordDTO } from './dto/reset-password';

const t = type('auth');

export const setToken = createAction(t.sync('set_token'), props<SetTokenDTO>());

export const logout = createAction(t.pending('logout'));
export const logoutSuccess = createAction(t.success('logout'));

export const refreshToken = createAction(t.pending('refresh_token'));
export const refreshTokenSuccess = createAction(t.success('refresh_token'));
export const refreshTokenFailed = createAction(t.failed('refresh_token'), props<RefreshTokenDTO.Failed>());

export const login = createAction(t.pending('login'), props<LoginDTO.Pending>());
export const loginSuccess = createAction(t.success('login'), props<LoginDTO.Success>());
export const loginFailed = createAction(t.failed('login'), props<LoginDTO.Failed>());

export const forgotPassword = createAction(t.pending('forgot_password'), props<ForgotPasswordDTO.Pending>());
export const forgotPasswordSuccess = createAction(t.success('forgot_password'));
export const forgotPasswordFailed = createAction(t.failed('forgot_password'), props<ForgotPasswordDTO.Failed>());

export const resetPassword = createAction(t.pending('reset_password'), props<ResetPasswordDTO.Pending>());
export const resetPasswordSuccess = createAction(t.success('reset_password'));
export const resetPasswordFailed = createAction(t.failed('reset_password'), props<ResetPasswordDTO.Failed>());


const u = union({
    setToken,
    logoutSuccess,
    logout,
    login,
    loginFailed,
    loginSuccess,
    refreshToken,
    refreshTokenSuccess,
    refreshTokenFailed
});

export type Union = typeof u;
