import { createReducer, on } from '@ngrx/store';
import { NotificationsActions } from '../actions/notifications/notifications-action-types';
import { NotificationsState } from '../interfaces';

export const initialNotificationsState: NotificationsState =
  Object.freeze<NotificationsState>({
    chatsCount: 0,
    shiftsCount: 0,
    updatedShifts: {},
  });

  export const notificationsReducer = createReducer(
  initialNotificationsState,
  on(NotificationsActions.setChatCountValue, (state, { chatsCount }) => ({
    ...state,
    chatsCount: chatsCount,
  })),
  on(NotificationsActions.increaseChatsUnreadMessagesCount, (state, { inc }) => ({
    ...state,
    chatsCount: state.chatsCount + inc,
  })),
  on(NotificationsActions.decreaseChatsUnreadMessagesCount, (state, { dec }) => ({
    ...state,
    chatsCount: state.chatsCount - dec,
  })),
  on(NotificationsActions.increaseShiftsUpdatedCount, (state, { payload }) => {
    let count = 1;
    if (
      state.updatedShifts[payload.id] &&
      state.updatedShifts[payload.id][payload.type]
    ) {
      count = state.updatedShifts[payload.id][payload.type] + 1;
    }
    return {
      ...state,
      shiftsCount: state.shiftsCount + 1,
      updatedShifts: {
        ...state.updatedShifts,
        [payload.id]: {
          ...state.updatedShifts[payload.id],
          [payload.type]: count,
        },
      },
    };
  }),
  on(NotificationsActions.decreaseShiftsUpdatedCount, (state, { id }) => {
    if (!state.updatedShifts[id]) {
      return { ...state };
    }
    let count = 0;
    Object.keys(state.updatedShifts[id]).map((k) => {
      count += state.updatedShifts[id][k];
    });
    let updatedShifts = { ...state.updatedShifts };
    delete updatedShifts[id];
    return {
      ...state,
      shiftsCount: state.shiftsCount - count,
      updatedShifts,
    };
  }),
  on(NotificationsActions.fetchNotificationsSuccess, (state, { model }) => {
    let types = {
      'SHIFT_NEW_APPLICATION': 'applied',
      'SHIFT_APPLICANT_CANCELLED': 'calledOut'
    }
    let updatedShifts = {};
    model.shifts.forEach(s=>{
      if(updatedShifts[s.entityID] &&
        updatedShifts[s.entityID][types[s.type]]){
          updatedShifts[s.entityID][types[s.type]] = updatedShifts[s.entityID][types[s.type]] + 1;
      }else{

        updatedShifts[s.entityID] = {
          [types[s.type]]: 1
        }
      }
    });
    return {...state,
      updatedShifts,
      shiftsCount: model.shifts.length,
      chatsCount: model.chats.unreadCount
    }
  })
);

