<div class="srdp-container">
  <span class="label">Period range</span>
  <div class="range-date-input-container">
    <span *ngIf="!range.controls['startTimeFrom'].value" class="placeholder" (click)="picker.open();">Select date
      <mat-icon
        svgIcon="date"
    ></mat-icon>
    </span>
    <mat-date-range-input [rangePicker]="picker" (click)="picker.open()">
      <input [readonly]="true" matStartDate [formControl]="$any(range).controls['startTimeFrom']"
        (dateChange)="dateChange('startTimeFrom', $event)" />
      <input [readonly]="true" matEndDate [formControl]="$any(range).controls['startTimeTo']"
        (dateChange)="dateChange('startTimeTo', $event)" />
    </mat-date-range-input>
    <mat-date-range-picker #picker></mat-date-range-picker>
  </div>
</div>