import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';

import { State } from 'src/app/state/interfaces';
import { selectNurse, selectNurses, selectNursesIds } from '../../state/selectors/nurses';
import { map } from 'rxjs/operators';

@Injectable()
export class NursesAccessor {
    constructor(private store: Store<State>) { }

    get nursesIds$() { return this.store.select(selectNursesIds); }
    get nurses$() { return this.store.select(selectNurses); }
    get nursesArr$() {
        return this.nurses$.pipe(
            map(nurses =>
                Object.values(nurses)
                    .filter(n => n != null)
                    .map(n => n)
            )
        );
    }
    nurse$(id: string) {
        return this.store.select(selectNurse, { id });
    }
}
