import { map } from "rxjs/operators";

import { ApplicantBindings, ShiftBindings, ShiftWithBindings } from "../../../bindings";
import { ShiftsAccessor } from "../../accessors/shifts.accessor";

export class ShiftsBinder
{
    constructor(
        private accessor: ShiftsAccessor,
        private bindings: ShiftBindings,
        private applicantBindings: ApplicantBindings
    )
    { }

    get shifts()
    {
        return this.accessor.shifts$
            .pipe(
                map(shifts =>
                    Object.values(shifts)
                    .filter(s => s != null)
                    .map(s => s && new ShiftWithBindings(s, this.bindings, this.applicantBindings))
                )
            );
    }
    getShiftWithBindings(shift){
        return new ShiftWithBindings(shift, this.bindings, this.applicantBindings)
    }
}