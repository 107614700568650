import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { HISTORY_PAGE_SIZE, NURSES_PAGE_SIZE } from 'src/app/constants/pagination';
import { parametrify } from 'src/app/helpers/parametrify';

import { FacilityModel } from 'src/app/models/facility';
import { FacilityStats } from 'src/app/models/facility/facility-stats.model';
import { PaginationDTO, SortDTO } from 'src/app/types/dto';
import { Name } from 'src/app/types/models/user';
import { environment } from 'src/environments/environment';
import {
  AddSupervisorDTO,
  CreateShiftTemplateDTO,
  RequestChangeDTO,
  InstashyftStatDTO,
  EditShiftTemplateDTO
} from '../../types/dto';
import { FacilityRateChangeHistory } from 'src/app/models/facility/facility-rate-change-history';
import { FacilityNurse } from 'src/app/models/facility/facility-nurse';
import { NursesFiltersDTO } from 'src/app/types/dto/fetch-nurses';
import { map } from 'rxjs/operators';
import { ManagerSettings } from 'src/app/models/facility/manager-settings';
import { FacilityNurseNotes } from 'src/app/models/facility/facility-nurse-notes';
import { NurseShiftPrefsRequest } from 'src/app/models/facility/nurse-shift-prefs-request';
import { FacilityNurseNotesMixedHistory } from 'src/app/models/facility/facility-nurse-notes-mixed-history';
import { FacilityNurseMixedNotes } from 'src/app/models/facility/facility-nurse-mixed-notes';

@Injectable({ providedIn: 'root' })
export class FacilitiesService {
  private readonly endpoint = environment.apiUrl;

  constructor(private readonly http: HttpClient) { }

  fetchMyFacilities() {
    const params = new HttpParams({
      fromObject: {
        populate: ['managers', 'supervisors', 'shiftTemplates'],
      },
    });
    return this.http.get<FacilityModel[]>(`${this.endpoint}/facilities/my`, {
      params,
    });
  }

  fetchFacility(id: string) {
    return this.http.get<FacilityModel>(`${this.endpoint}/facilities/${id}`);
  }

  edit(id: string, model: FacilityModel) {
    let managers = [];
    let supervisors = [];
    model.managers.map((m) => {
      managers.push(m._id);
    });
    model.supervisors.map((s) => {
      supervisors.push(s._id);
    });
    return this.http.put<FacilityModel>(`${this.endpoint}/facilities/${id}`, {
      ...model,
      managers,
      supervisors,
    });
  }

  addSupervisor(id: string, dto: AddSupervisorDTO) {
    return this.http.post<FacilityModel>(
      `${this.endpoint}/facilities/${id}/supervisor`,
      dto,
    );
  }

  requestChange(id: string, dto: RequestChangeDTO) {
    return this.http.post(
      `${this.endpoint}/facilities/${id}/request-change`,
      dto,
    );
  }

  createShiftTemplate(facilityId: string, dto: CreateShiftTemplateDTO) {
    return this.http.post<FacilityModel>(
      `${this.endpoint}/shift-templates/${facilityId}`,
      dto,
    );
  }

  deleteShiftTemplate(id: string) {
    return this.http.delete<FacilityModel>(
      `${this.endpoint}/shift-templates/${id}`,
    );
  }

  editShiftTemplate(id: string, dto: EditShiftTemplateDTO) {
    return this.http.put<FacilityModel>(
      `${this.endpoint}/shift-templates/${id}`,
      dto,
    );
  }

  uploadAvatar(id: string, file: File) {
    const formData = new FormData();
    formData.append('file', file);
    return this.http.post<FacilityModel>(
      `${this.endpoint}/facilities/${id}/avatar`,
      formData,
    );
  }
  changePaymentMethod(id: string, sourceToken: string) {
    return this.http.patch<FacilityModel>(
      `${this.endpoint}/facilities/${id}/payment-method`,
      { sourceToken },
    );
  }
  registerStripe(id: string) {
    return this.http.patch<FacilityModel>(
      `${this.endpoint}/facilities/${id}/register-stripe`,
      {},
    );
  }
  fetchFacilityStats(id: string) {
    return this.http.get<FacilityStats>(`${this.endpoint}/facilities/${id}/stats`);
  }
  fetchFacilityStripe(id: string) {
    return this.http.get(`${this.endpoint}/facilities/${id}/stripe`);
  }
  fetchFacilityHistory(id: string, sort: SortDTO, dateFrom?, dateTo?, csv?, tab?, fieldsChanged?, pagination: PaginationDTO = { skip: 0, limit: HISTORY_PAGE_SIZE }) {
    let params = new HttpParams({});

    params = new HttpParams({
      fromObject: {
        ...parametrify(pagination),
        ...parametrify(sort),
        ...(dateFrom ? { dateFrom } : {}),
        ...(dateTo ? { dateTo } : {}),
        ...(csv ? { csv } : {}),
        ...(tab ? { tab } : {}),
        ...(fieldsChanged ? { fieldsChanged } : {})
      },
    });
    let resType = {};
    if (csv) {
      resType['responseType'] = 'blob';
    }
    return this.http.get(`${this.endpoint}/facilities/${id}/history`, { params, ...resType });

  }

  getInstashyftStats(id: string) {
    return this.http.get<InstashyftStatDTO>(`${this.endpoint}/facilities/${id}/instashyft-stats`);
  }
  putInstashyftMessage(id: string, instaShyftDefault: boolean, doNotShowInstaShyftMessage: boolean) {
    return this.http.put(
      `${this.endpoint}/facilities/${id}/show-instashyft-message`,
      { doNotShowInstaShyftMessage, instaShyftDefault },
    );
  }
  fetchFacilityRateChangeHistory(id: string) {
    return this.http.get<FacilityRateChangeHistory[]>(`${this.endpoint}/facilities/${id}/rate-change-history`);
  }
  fetchFacilityNurses(id: string, sort: SortDTO, filters: NursesFiltersDTO, pagination: PaginationDTO = { skip: 0, limit: NURSES_PAGE_SIZE }) {
    let params = new HttpParams({});
    params = new HttpParams({
      fromObject: {
        ...parametrify(sort),
        ...parametrify(filters),
        ...parametrify(pagination),
      },
    });
    return this.http.get<FacilityNurse[]>(`${this.endpoint}/fm-api/facilities/${id}/nurses`, { params, observe: 'response' }).pipe(
      map(response => ({
        models: (response.body['models'] ? response.body['models'] : response.body),
        totalCount: parseInt(response.headers.get('X-Total-Count'))
      }))
    );
  }
  fetchFacilityNurse(facilityId: string, nurseId: string) {
    return this.http.get<FacilityNurse>(`${this.endpoint}/fm-api/facilities/${facilityId}/nurses/${nurseId}`).pipe(map(response => ({
      model: response
    })));
  }
  changeFacilityNurseTrustedStatus(facilityId: string, nurseId: string, isTrusted: boolean, brokenTrustReasons: string[] = []) {
    return this.http.put<FacilityNurse>(`${this.endpoint}/fm-api/facilities/${facilityId}/nurses/${nurseId}/is-trusted`, {
      isTrusted,
      ...(brokenTrustReasons.length ? { brokenTrustReasons } : {})
    }).pipe(map(response => ({
      success: true
    })))
  }
  fetchManagerSettings(facilityId: string) {
    return this.http.get<ManagerSettings>(`${this.endpoint}/fm-api/facilities/${facilityId}/managers/me`);
  }
  setManagerSettingsTrustedConfirmationOn(facilityId: string) {
    return this.http.patch(`${this.endpoint}/fm-api/facilities/${facilityId}/managers/me`, {
      "settings": {
        "isSetNurseAsTrustedConfirmationOn": true
      }
    });
  }
  fetchFacilityNurseNotes(facilityId: string, nurseId: string) {
    return this.http.get<FacilityNurseNotes[]>(`${this.endpoint}/fm-api/facilities/${facilityId}/nurses/${nurseId}/notes`);
  }
  fetchMixedNotesHistory(facilityId: string, nurseId: string) {
    const params = new HttpParams({
      fromObject: {
        ...parametrify({
          skip: 0,
          limit: 500
        }),
      }
    });
    return this.http.get<FacilityNurseNotesMixedHistory[]>(`${this.endpoint}/fm-api/facilities/${facilityId}/nurses/${nurseId}/mixed-notes-history`, { params });
  }
  fetchMixedNotes(facilityId: string, nurseId: string) {
    const params = new HttpParams({
      fromObject: {
        ...parametrify({
          skip: 0,
          limit: 500
        }),
      }
    });
    return this.http.get<FacilityNurseMixedNotes[]>(`${this.endpoint}/fm-api/facilities/${facilityId}/nurses/${nurseId}/mixed-notes`, { params });
  }
  addFacilityNurseNotes(facilityId: string, nurseId: string, text: string) {
    return this.http.post<FacilityNurseNotes>(`${this.endpoint}/fm-api/facilities/${facilityId}/nurses/${nurseId}/notes`, { text, entityType: "facility-nurse" });
  }
  editFacilityNurseNotes(facilityId: string, nurseId: string, noteId: string, text: string) {
    return this.http.patch<FacilityNurseNotes>(`${this.endpoint}/fm-api/facilities/${facilityId}/nurses/${nurseId}/notes/${noteId}`, { text });
  }
  deleteFacilityNurseNotes(facilityId: string, nurseId: string, noteId: string) {
    return this.http.delete<any>(`${this.endpoint}/fm-api/facilities/${facilityId}/nurses/${nurseId}/notes/${noteId}`);
  }
  fetchNurseShiftPrefsRequests(facilityId: string, nurseId: string) {
    return this.http.get<NurseShiftPrefsRequest[]>(`${this.endpoint}/fm-api/facilities/${facilityId}/nurses/${nurseId}/nurse-shift-prefs-requests`);
  }
  addNurseShiftPrefsRequests(facilityId: string, nurseId: string) {
    return this.http.post<NurseShiftPrefsRequest>(`${this.endpoint}/fm-api/facilities/${facilityId}/nurses/${nurseId}/nurse-shift-prefs-requests`, {});
  }
}
