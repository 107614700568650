import { Component, Input } from '@angular/core';

export type InstaTooltipType = 'appliedTooltip' | 'allowTooltip' | 'regularTooltip';

@Component({
  selector: 'app-instashyft-tooltip',
  templateUrl: './instashyft-tooltip.component.html',
  styleUrls: ['./instashyft-tooltip.component.scss']
})
export class InstashyftTooltipComponent {
  @Input() set appliedTooltip(appliedTooltip: boolean) {
    if(appliedTooltip) this.tooltipType = 'appliedTooltip';
  }
  @Input() set allowTooltip(allowTooltip: boolean) {
    if(allowTooltip) this.tooltipType = 'allowTooltip';
  }
  tooltipType: InstaTooltipType = 'regularTooltip';

  constructor() { }

}
