import { AsyncSubject, concat, defer, Observable, Subscription } from "rxjs";

import { IExecutable } from "./iexecutable";
import { SyncTask } from "./sync-task";
import { AsyncTask } from "./async-task";
import { ExecutionContext, Options } from "./execution-context";

type Action<T, R = any> = (input: T) => Observable<R> | Promise<R> | R;

type OptionalState<State> = {
    [Property in keyof State]?: State[Property];
}

export class Chain<State extends object = any> implements IExecutable<any, any>
{
    private _contexts: ExecutionContext<State, any>[] = [];
    private _state: OptionalState<State> = { };
    private _startOn: Subscription;

    private _debug: boolean = false;
    private _id: string;
    private _reducer: (key: keyof State, value: any) => void;

    constructor(options: { debug?: boolean, id?: string, reducer?: (key: keyof State, value: any) => void } = { })
    {
        if(options.debug) this._debug = options.debug;
        if(options.id) this._id = options.id;
        if(options.reducer) this._reducer = options.reducer;

        if(this._debug)
        {
            window['chains'] = { ...(window['chains'] || { }), [this._id || Math.random()]: this };
        }
    }

    private reduce(key: keyof State, value: any)
    {
        this._state[key] = value;
    }

    private addSyncTask(key: keyof State, action: Action<State>, opts: Options<State> = { })
    {
        let group: IExecutable<State[typeof key]>;
        group = new SyncTask(String(key), action, this.reduce.bind(this));
        this._contexts.push(new ExecutionContext(group, opts));
    }

    private addAsyncTask(actions: Record<keyof State, Action<State>>, opts: Options<State> = { })
    {
        let group: IExecutable<State[keyof State]>;

        const a = Object.keys(actions as Record<keyof State, Action<State>>)
            .map(k => new SyncTask(k, actions[k], this.reduce.bind(this)));
        group = new AsyncTask(a);

        this._contexts.push(new ExecutionContext(group, opts));
    }

    private addChain(chain: Chain, opts: Options<State> = { })
    {
        chain._reducer = this.reduce.bind(this);
        this._contexts.push(new ExecutionContext(chain, opts));
    }

    add(type: 'sync', key: keyof State, action: Action<State>, opts?: Options<State>) : Chain;
    add(type: 'async', actions: Record<keyof State, Action<State>>, opts?: Options<State>) : Chain;
    add(type: 'chain', chain: Chain, opts?: Options<State>) : Chain;
    add(...args: any[]) : Chain
    {
        switch(args[0])
        {
            case 'sync':
                this.addSyncTask(args[1], args[2], args[3]);
            break;
            case 'async':
                this.addAsyncTask(args[1], args[2]);
            break;
            case 'chain':
                this.addChain(args[1], args[2]);
            break;
        }

        return this;
    }

    startOn(input: Observable<any>)
    {
        if(this._startOn && this._startOn.unsubscribe)
            this._startOn.unsubscribe();

        this._startOn = input.subscribe(() => this.execute());
        
        return this;
    }

    execute(input?: State)
    {
        if(this._debug)
            console.log('Executing chain with id: ', this._id);

        const subject = new AsyncSubject<any>();

        concat(...this._contexts.map(c => defer(() => c.execute((input || this._state) as State))))
        .subscribe(
            v =>
            {       
                if(this._reducer)
                    this._reducer(this._id as keyof State, this._state);

                subject.next(v);
            },
            e => subject.error(e),
            () =>
            {
                if(this._debug)
                    console.log(`Chain with id: ${this._id} is complete`);

                subject.complete();
            }
        );

        return subject.asObservable();
    }
}
