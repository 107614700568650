
import { createReducer, on } from '@ngrx/store';

import { toRecord } from 'src/app/state/helpers/to-record';
import { FacilitiesActions } from '../actions/facilities/facilities-action-types';

import { FacilitiesState } from '../interfaces/facilities';

export const initialFacilitiesState: FacilitiesState = Object.freeze<FacilitiesState>({
    active: undefined,
    mine: [],
    models: {}
});

export const facilitiesReducer = createReducer(
    initialFacilitiesState,
    on(FacilitiesActions.fetchMyFacilitiesSuccess, (state, { models }) => ({
        ...state,
        models: { ...state.models, ...toRecord(models, f => f._id) },
        mine: models.map(f => f._id)
    })),
    on(FacilitiesActions.fetchFacilityByIdSuccess, (state, { model }) => ({
        ...state,
        ...state,
        models: {
            ...state.models,
            [model._id]: { ...model }
        }
    })),
    on(FacilitiesActions.setActiveFacility, (state, { id }) => ({
        ...state,
        active: id
    })),
    on(FacilitiesActions.editFacilitySuccess, (state, { model }) => ({
        ...state,
        models: { ...state.models, [model._id]: {...model, managers: state.models[model._id].managers, supervisors: state.models[model._id].supervisors} }
    })),
    on(FacilitiesActions.addSupervisorSuccess, (state, { model }) => ({
        ...state,
        models: {
            ...state.models,
            [model._id]: { ...state.models[model._id], supervisors: model.supervisors }
        }
    })),
    on(FacilitiesActions.uploadAvatarSuccess, (state, { model }) => ({
        ...state,
        models: {
            ...state.models,
            [model._id]: { ...state.models[model._id], avatar: model.avatar }
        }
    })),
    on(FacilitiesActions.createShiftTemplateSuccess, FacilitiesActions.editShiftTemplateSuccess, FacilitiesActions.deleteShiftTemplateSuccess, (state, { model }) => ({
        ...state,
        models: {
            ...state.models,
            [model._id]: { ...state.models[model._id], shiftTemplates: model.shiftTemplates }
        }
    }))
);
