import { createSelector } from '@ngrx/store';

import { State } from 'src/app/state/interfaces';
import { selectModelsById } from 'src/app/state/helpers/select-models-by-id';

import { MANAGER_FEATURE_KEY, NOTIFICATIONS_FEATURE_KEY } from '../interfaces';

const state = (s: State) => s[MANAGER_FEATURE_KEY][NOTIFICATIONS_FEATURE_KEY];


export const selectChatsCount = createSelector(state, (state) => state.chatsCount);
export const selectShiftsCount = createSelector(state, (state) => state.shiftsCount);
export const selectUpdatedShifts = createSelector(state, (state) => state.updatedShifts);

