export const DNR_REASONS = <const>[
    'Attendance & punctuality',
    'Clinical competency',
    'Attitude & cooperation',
    'Facility preference',
];
export type TDnrReason = typeof DNR_REASONS[number];

export interface AddToDnr {
    facilities: string[];
    nurse: string;
    details?: string;
    reasons: TDnrReason[];
    questions?: {
        question: string
        response: string
      }[],

}
