export const LICENSE_TYPES = ['CNA', 'LPN', 'RN', 'GNA', 'LNA', 'STNA', 'QMA', 'TMA', 'CMT', 'CMA'];

export const LICENSE_ORDERS = {
    CNA: 1,
    CMA: 2,
    CMT: 3,
    TMA: 4,
    STNA: 5,
    LNA: 6,
    GNA: 7,
    QMA: 8,
    LPN: 9,
    RN: 10
};
type ElementType < T extends ReadonlyArray < unknown > > = T extends ReadonlyArray<infer ElementType>  ? ElementType  : never;
export type LicenseElementType = ElementType<typeof LICENSE_TYPES>
