import { ActionReducerMap, MetaReducer } from "@ngrx/store";

import { State } from '../interfaces/state';

import { authReducer, initialAuthState } from "./auth";
import { clearState } from "./meta";
import { initialUserState, userReducer } from "./user";

export const rootReducer: ActionReducerMap<State> = {
    auth: authReducer,
    user: userReducer
};

export const initialRootState: State = Object.freeze<State>({
    auth: initialAuthState,
    user: initialUserState
});

export const metaReducers: MetaReducer[] = [clearState(initialRootState)];