import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';

import { selectShiftsFilters, selectTimecardsFilters } from 'src/app/modules/manager/state/selectors/filters';
import { State } from 'src/app/state/interfaces';

@Injectable()
export class FiltersAccessor
{
    constructor(private store: Store<State>) { }

    get shiftsFilters$() { return this.store.select(selectShiftsFilters); }
    get timecardsFilters$() { return this.store.select(selectTimecardsFilters); }


}
