<div class="bill-rate-container">
  <div class="left-block">
    <span class="text">
        Facility hourly bill rate
    </span>
    <mat-icon [svgIcon]="'info-2'" customToolTip [contentTemplate]="template" [offsetY]="-8"></mat-icon>
    <ng-template #template>
      <div class="tooltip-block">
        <span>Calculated using nurse hourly pay rate, payroll taxes, and feed. Visit the <a href="manager/settings/calculator">ESHYFT Calculator</a> for more information.</span>
      </div>  
    </ng-template>
  </div>
  <div class="right-block">
    <div class="line"></div>
    <span class="rate">${{billedHourlyRate}}</span>
  </div>

</div>