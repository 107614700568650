import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { ManagerGuard } from 'src/app/guards';
import { SelectFacilityGuard } from './guards/select-facility.guard';

import { ManagerComponent } from './manager.component';

const routes: Routes = [
    {
        path: '',
        canActivate: [ManagerGuard],
        component: ManagerComponent,
        
        children: [
            {
                path: 'dashboard',
                canActivate: [SelectFacilityGuard],
                loadChildren: () => import('./dashboard/dashboard.module').then(m => m.DashboardModule),
            },
            {
                path: 'schedule',
                canActivate: [SelectFacilityGuard],
                loadChildren: () => import('./schedule/schedule.module').then(m => m.ScheduleModule),
            },
            {
                path: 'shifts',
                canActivate: [SelectFacilityGuard],
                children: [
                    {
                        path: 'applicants',
                        loadChildren: () => import('./shifts/applicants/applicants.module').then(m => m.ApplicantsModule),
                    },
                    {
                        path: 'new',
                        loadChildren: () => import('./shifts/shifts-form/shifts-form.module').then(m => m.ShiftsFormModule)
                    },
                    {
                        path: '',
                        pathMatch: 'full',
                        redirectTo: 'applicants',
                    }
                ]
            },
            {
                path: 'chat',
                canActivate: [SelectFacilityGuard],
                loadChildren: () => import('./chat/chat.module').then(m => m.ChatModule)
            },
            {
                path: 'timecards',
                canActivate: [SelectFacilityGuard],
                loadChildren: () => import('./timecards/timecards.module').then(m => m.TimecardsModule)
            },
            {
                path: 'nurse-directory',
                canActivate: [SelectFacilityGuard],
                loadChildren: () => import('./nurse-directory/nurse-directory.module').then(m => m.NurseDirectoryModule)
            },
            {
                path: 'settings',
                canActivate: [SelectFacilityGuard],
                loadChildren: () => import('./settings/settings.module').then(m => m.SettingsModule)
            },
            {
                path: 'facility-selection',
                loadChildren: () => import('./facility-selection/facility-selection.module').then(m => m.FacilitySelectionModule)
            },
            {
                path: '',
                pathMatch: 'full',
                redirectTo: 'shifts/applicants',
            },
        ],
    },
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],
})
export class ManagerRoutingModule { }
