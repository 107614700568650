import * as clone from 'clone';

import { SendMessageDTO } from "../types/dto";
import { SendMessageForm } from "../types/forms/send-message";

export class SendMessageAdapter
{
    private _form: SendMessageForm;
    private _dto: SendMessageDTO;

    constructor(form: SendMessageForm)
    {
        this._form = clone(form);
    }

    private _adapt()
    {
        return {
            shift: this._form.shift,
            text: this._form.text,
            to: this._form.to
        } as SendMessageDTO;
    }

    get dto()
    {
        if(!this._dto)
            this._dto = this._adapt();

        return this._dto;
    }
}