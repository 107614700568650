import { createAction, props, union } from '@ngrx/store';

import { type } from 'src/app/state/actions/helpers/action-type';
import { AddSupervisorDTO } from './dto/add-supervisor';
import { CreateShiftTemplateDTO } from './dto/create-shift-template';
import { DeleteShiftTemplateDTO } from './dto/delete-shift-template';
import { EditFacilityDTO } from './dto/edit-facility';
import { EditShiftTemplateDTO } from './dto/edit-shift-template';
import { ChangePaymentMethodDTO } from './dto/change-payment-method';
import { RegisterStripeDTO } from './dto/register-stripe';

import { FetchMyFacilitiesDTO } from './dto/fetch-my-facilities';
import { FetchFacilityByIdDTO } from './dto/fetch-facility-by-id';

import { RequestChangeDTO } from './dto/request-change';
import { SetActiveFacilityDTO } from './dto/set-active-facility';
import { UploadAvatarDTO } from './dto/upload-avatar';
import { GetStripeDTO } from './dto/get-stripe';

const t = type('facilties');

export const fetchMyFacilities = createAction(t.pending('fetch_my_facilities'));
export const fetchMyFacilitiesSuccess = createAction(t.success('fetch_my_facilities'), props<FetchMyFacilitiesDTO.Success>());
export const fetchMyFacilitiesFailed = createAction(t.failed('fetch_my_facilities'), props<FetchMyFacilitiesDTO.Failed>());

export const fetchFacilityById = createAction(t.pending('fetch_facility_by_id'), props<FetchFacilityByIdDTO.Pending>());
export const fetchFacilityByIdSuccess = createAction(t.success('fetch_facility_by_id'), props<FetchFacilityByIdDTO.Success>());
export const fetchFacilityByIdFailed = createAction(t.failed('fetch_facility_by_id'), props<FetchFacilityByIdDTO.Failed>());


export const setActiveFacility = createAction(t.sync('set_active_facility'), props<SetActiveFacilityDTO>());

export const editFacility = createAction(t.pending('edit_facility'), props<EditFacilityDTO.Pending>());
export const editFacilitySuccess = createAction(t.success('edit_facility'), props<EditFacilityDTO.Success>());
export const editFacilityFailed = createAction(t.failed('edit_facility'), props<EditFacilityDTO.Failed>());

export const addSupervisor = createAction(t.pending('add_supervisor'), props<AddSupervisorDTO.Pending>());
export const addSupervisorSuccess = createAction(t.success('add_supervisor'), props<AddSupervisorDTO.Success>());
export const addSupervisorFailed = createAction(t.failed('add_supervisor'), props<AddSupervisorDTO.Failed>());

export const requestChange = createAction(t.pending('request_change'), props<RequestChangeDTO.Pending>());
export const requestChangeSuccess = createAction(t.success('request_change'));
export const requestChangeFailed = createAction(t.failed('request_change'), props<RequestChangeDTO.Failed>());

export const createShiftTemplate = createAction(t.pending('create_shift_template'), props<CreateShiftTemplateDTO.Pending>());
export const createShiftTemplateSuccess = createAction(t.success('create_shift_template'), props<CreateShiftTemplateDTO.Success>());
export const createShiftTemplateFailed = createAction(t.failed('create_shift_template'), props<CreateShiftTemplateDTO.Failed>());

export const editShiftTemplate = createAction(t.pending('edit_shift_template'), props<EditShiftTemplateDTO.Pending>());
export const editShiftTemplateSuccess = createAction(t.success('edit_shift_template'), props<EditShiftTemplateDTO.Success>());
export const editShiftTemplateFailed = createAction(t.failed('edit_shift_template'), props<EditShiftTemplateDTO.Failed>());

export const deleteShiftTemplate = createAction(t.pending('delete_shift_template'), props<DeleteShiftTemplateDTO.Pending>());
export const deleteShiftTemplateSuccess = createAction(t.success('delete_shift_template'), props<DeleteShiftTemplateDTO.Success>());
export const deleteShiftTemplateFailed = createAction(t.failed('delete_shift_template'), props<DeleteShiftTemplateDTO.Failed>());

export const uploadAvatar = createAction(t.pending('upload_avatar'), props<UploadAvatarDTO.Pending>());
export const uploadAvatarSuccess = createAction(t.success('upload_avatar'), props<UploadAvatarDTO.Success>());
export const uploadAvatarFailed = createAction(t.failed('upload_avatar'), props<UploadAvatarDTO.Failed>());

export const changePaymentMethod = createAction(t.pending('change_payment_method'), props<ChangePaymentMethodDTO.Pending>());
export const changePaymentMethodSuccess = createAction(t.success('change_payment_method'), props<ChangePaymentMethodDTO.Success>());
export const changePaymentMethodFailed = createAction(t.failed('change_payment_method'), props<ChangePaymentMethodDTO.Failed>());

export const registerStripe = createAction(t.pending('register_stripe'), props<RegisterStripeDTO.Pending>());
export const registerStripeSuccess = createAction(t.success('register_stripe'), props<RegisterStripeDTO.Success>());
export const registerStripeFailed = createAction(t.failed('register_stripe'), props<RegisterStripeDTO.Failed>());

export const getStripe = createAction(t.pending('get_stripe'), props<GetStripeDTO.Pending>());
export const getStripeSuccess = createAction(t.success('get_stripe'), props<GetStripeDTO.Success>());
export const getStripeFailed = createAction(t.failed('get_stripe'), props<GetStripeDTO.Failed>());

const u = union({
    /* Fetch My Facilities */
    fetchMyFacilities,
    fetchMyFacilitiesSuccess,
    fetchMyFacilitiesFailed,

    /* Set Active Facility */
    setActiveFacility,

    /* Edit Facility */
    editFacility,
    editFacilitySuccess,
    editFacilityFailed,

    /* Add Supervisor */
    addSupervisor,
    addSupervisorSuccess,
    addSupervisorFailed,

    /* Request Change */
    requestChange,
    requestChangeSuccess,
    requestChangeFailed,

    /* Create Shift Template */
    createShiftTemplate,
    createShiftTemplateSuccess,
    createShiftTemplateFailed,

    /* Delete Shift Template */
    deleteShiftTemplate,
    deleteShiftTemplateSuccess,
    deleteShiftTemplateFailed,

    /* Upload Avatar */
    uploadAvatar,
    uploadAvatarSuccess,
    uploadAvatarFailed,

    /* Edit Shift Template */
    editShiftTemplate,
    editShiftTemplateSuccess,
    editShiftTemplateFailed,
});

export type Union = typeof u;
