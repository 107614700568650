import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { ServicedStateModel } from 'src/app/models/serviced-state';
import { FacilityWithBindings } from '../../../bindings';
import { ShiftTemplate } from 'src/app/types/models/shift';
import { CalculateBilledHourlyRateService } from '../../../services/calculate-billed-hourly-rate.service';

@Component({
  selector: 'app-facility-bill-rate',
  templateUrl: './facility-bill-rate.component.html',
  styleUrls: ['./facility-bill-rate.component.scss']
})
export class FacilityBillRateComponent implements OnInit, OnChanges {
  @Input() facility: FacilityWithBindings;
  @Input() state: ServicedStateModel;

  @Input() hourlyRate: any;
  @Input() shift: ShiftTemplate;

  billedHourlyRate = '0.00';

  constructor(private calculateBilledHourlyRateService: CalculateBilledHourlyRateService) { }

  ngOnInit(): void {

  }
  ngOnChanges(changes: SimpleChanges): void {
    if(this.facility && this.state && this.hourlyRate){
      let paymentMethod = '';
      if(this.facility.noStripe){
        paymentMethod = 'offline';
      }else if(this.facility.stripeSourceType){
        paymentMethod = this.facility.stripeSourceType;
      }
      const processingFee = paymentMethod === 'bank' ? 0.01 : (paymentMethod === 'card' ? 0.035 : (paymentMethod === 'offline' ? (this.facility.processingFee ? (this.facility.processingFee / 100) : 0.01) : 0.01));
      this.billedHourlyRate = (this.calculateBilledHourlyRateService.calculate(this.state, parseFloat(this.hourlyRate), this.facility.feeCharged, processingFee)).toFixed(2);
      console.log('this.billedHourlyRate',this.billedHourlyRate);
    }
  }

}
