import { EventEmitter, Injectable } from '@angular/core';
import { DateTime } from 'luxon';
import { BehaviorSubject } from 'rxjs';
import { ServicedStateModel } from 'src/app/models/serviced-state';
import { ShiftTemplate } from 'src/app/types/models/shift';

@Injectable({
  providedIn: 'root'
})
export class CalculateBilledHourlyRateService {
  constructor() { }
  

  // calculate(shiftTemplate: ShiftTemplate, state: ServicedStateModel, hourlyRate: number, feeCharged: number, processingFee: number) {
    calculate(state: ServicedStateModel, hourlyRate: number, feeCharged: number, processingFee: number) {
    // let shiftLength = this.calculateShiftLength(shiftTemplate);

    const totalLoad = parseFloat((hourlyRate * (state.stateLoads.fica * 0.01 + state.stateLoads.workersComp * 0.01 + state.stateLoads.fui * 0.01 + state.stateLoads.sui * 0.01 + state.stateLoads.mandatorySick * 0.01 + state.stateLoads.mandatoryDisability * 0.01)).toFixed(2));
    const eshyftFee = parseFloat((hourlyRate * feeCharged / 100).toFixed(2));
    const processingFeeValue = parseFloat(((totalLoad + eshyftFee + hourlyRate) * processingFee).toFixed(2));
    const billedHourlyRate = hourlyRate + totalLoad + eshyftFee + processingFeeValue;
    return billedHourlyRate;
  }
  private calculateShiftLength(shiftTemplate: ShiftTemplate){
    let startTime = DateTime.fromFormat(shiftTemplate.startTime, "HH:mm");
    let endTime = DateTime.fromFormat(shiftTemplate.endTime, "HH:mm");
    if (endTime < startTime) {
      endTime = endTime.plus({ days: 1 });
    }
    let diff = endTime.diff(startTime, ['hours', 'minutes']);
    let hours = parseFloat((diff.hours + diff.minutes / 60).toFixed(2));
    return hours;
  }
}
