import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';


import { ManagerRoutingModule } from './manager-routing.module';
import { ManagerComponent } from './manager.component';
import { FacilitiesService } from './services/api/facilities.service';
import { ShiftsService } from './services/api/shifts.service';
import { NotificationsFacade } from './services/facades/notifications/notifications.facade';

import { FiltersFacade } from './services/facades/filters/filters.facade';
import { ShiftsFacade } from './services/facades/shifts/shifts.facade';
import { NotificationsAccessor } from './services/accessors/notifications.accessor';

import { ShiftsAccessor } from './services/accessors/shifts.accessor';
import { FlowService } from './services/flow.service';
import { managerReducer, metaReducers } from './state/reducers';
import { MANAGER_FEATURE_KEY } from './state/interfaces';
import { ServicedStatesFacade } from './services/facades/serviced-states/serviced-states.facade';
import { ServicedStatesService } from './services/api/serviced-states.service';
import { ServicedStatesAccessor } from './services/accessors/serviced-states.accessor';
import { TimecardsService } from './services/api/timecards.service';
import { TimecardsAccessor } from './services/accessors/timecards.accessor';
import { TimecardsFacade } from './services/facades/timecards/timecards.facade';
import { HolidaysService } from './services/api/holidays.service';
import { HolidaysFacade } from './services/facades/holidays/holidays.facade';
import { HolidaysAccessor } from './services/accessors/holidays.accessor';
import { SocketService } from '../../services/socket/socket.service';
import { ChatsService } from './services/api/chats.service';
import { ChatsFacade } from './services/facades/chats/chats.facade';
import { ChatsAccessor } from './services/accessors/chats.accessor';
import { FiltersAccessor } from './services/accessors/filters.accessor';
import { SseService } from './services/sse.service';
import { FilterService } from './services/filter.service';
import { IntercomService } from './services/intercom.service';
import { MatIconModule } from '@angular/material/icon';
import { SharedModule } from './shared/shared.module';
import { NgxSpinnerModule } from "ngx-spinner";
import { PendingChangesGuard } from './guards/pending-changes.guard';

import { NotificationsService } from './services/api/notifications.service';
import { SelectFacilityGuard } from './guards/select-facility.guard';
import { ReactiveFormsModule } from '@angular/forms';
import { RemindersAccessor } from './services/accessors/reminders.accessor';
import {LayoutModule} from '@angular/cdk/layout'; 
import { DnrAccessor } from './services/accessors/dnr.accessor';
import { NursesAccessor } from './services/accessors/nurses.accessor';


@NgModule({
    declarations: [ManagerComponent],
    imports: [
        CommonModule,

        ManagerRoutingModule,
        StoreModule.forFeature(MANAGER_FEATURE_KEY, managerReducer, { metaReducers }),
        MatIconModule,
        SharedModule,
        NgxSpinnerModule,
        ReactiveFormsModule,
        LayoutModule
    ],
    providers: [
        ChatsService,
        FlowService,
        FacilitiesService,
        FilterService,
        HolidaysService,
        ServicedStatesService,
        ShiftsService,
        SocketService,
        SseService,
        TimecardsService,
        IntercomService,

        NotificationsService,
        ChatsFacade,
        NotificationsFacade,

        FiltersFacade,
        HolidaysFacade,
        ServicedStatesFacade,
        ShiftsFacade,
        TimecardsFacade,

        ChatsAccessor,
        NotificationsAccessor,
        RemindersAccessor,

        FiltersAccessor,
        HolidaysAccessor,
        ServicedStatesAccessor,
        DnrAccessor,
        ShiftsAccessor,
        TimecardsAccessor,
        PendingChangesGuard,
        SelectFacilityGuard,
        NursesAccessor

    ]
})
export class ManagerModule { }
