import { Action, ActionReducer } from "@ngrx/store";
import { FacilitiesActions } from "../actions/facilities/facilities-action-types";

import { ManagerState } from "../interfaces";

export function clearState(initialState: ManagerState)
{
    return (reducer: ActionReducer<any>) =>
    {
        return (state: ManagerState, action: Action) =>
        {
            switch (action.type)
            {
                case FacilitiesActions.setActiveFacility.type: return reducer({
                    ...state,
                    chats: initialState.chats,
                    shifts: initialState.shifts,
                    timecards: initialState.timecards,
                    nurses: initialState.nurses
                }, action);
                default: return reducer(state, action);
            }
        };
    }
}
