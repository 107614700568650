<div class="time-input-container">
    <div class="inputs" [formGroup]="timeForm">
        <div class="input-block">
            <button fill="none" (click)="onArrowClick('h', '+')">
                <mat-icon class="cheveron-up-icon" svgIcon="mdCheveronUp-01"></mat-icon>
            </button>
            <input type="text" maxlength="2" formControlName="h" (blur)="formatNumber('h')">
            <button fill="none" (click)="onArrowClick('h', '-')">
                <mat-icon class="cheveron-down-icon" svgIcon="mdCheveronUp-01"></mat-icon>
            </button>
        </div>
        <span class="colon">:</span>
        <div class="input-block">
            <button fill="none" (click)="onArrowClick('m', '+')">
                <mat-icon class="cheveron-up-icon" svgIcon="mdCheveronUp-01"></mat-icon>
            </button>
            <input type="text" maxlength="2" formControlName="m" (blur)="formatNumber('m')">
            <button fill="none" (click)="onArrowClick('m', '-')">
                <mat-icon class="cheveron-down-icon" svgIcon="mdCheveronUp-01"></mat-icon>
            </button>
        </div>
        <ng-container *ngIf="showSeconds">
            <span class="colon">:</span>
            <div class="input-block">
                <button fill="none" (click)="onArrowClick('s', '+')">
                    <mat-icon class="cheveron-up-icon" svgIcon="mdCheveronUp-01"></mat-icon>
                </button>
                <input type="text" maxlength="2" formControlName="s" (blur)="formatNumber('s')">
                <button fill="none" (click)="onArrowClick('s', '-')">
                    <mat-icon class="cheveron-down-icon" svgIcon="mdCheveronUp-01"></mat-icon>
                </button>
            </div>
        </ng-container>
        <button class="meridian-btn" fill="none" (click)="am = !am">{{am ? 'AM' : 'PM'}}</button>
    </div>
    <div class="save-button-block">
        <button fill="none" (click)="onSave()">Save</button>
    </div>
</div>