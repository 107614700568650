import { createSelector } from "@ngrx/store";
import { State } from "src/app/state/interfaces";

import { MANAGER_FEATURE_KEY, SERVICED_STATES_FEATURE_KEY } from "../interfaces";

const state = (s: State) => s[MANAGER_FEATURE_KEY][SERVICED_STATES_FEATURE_KEY];

export const selectActiveState = createSelector(state, ({ models, active }) => models[active]);

export const selectState = createSelector(state, ({ models }, props: { state: string }) => models[props.state]);

export const selectActiveLicenses = createSelector(selectActiveState, ({ servicedLicenses }) => servicedLicenses);

export const selectLicensesForState = createSelector(state, ({ models }, props: { state: string }) => models[props.state]?.servicedLicenses);