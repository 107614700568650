import * as clone from 'clone';

import { EditShiftDTO } from "src/app/types/dto";

import { EditShiftForm } from "../types/forms/edit-shift/edit-shift-form";

export class EditShiftAdapter
{
    private _form: EditShiftForm;
    private _dto: EditShiftDTO;

    constructor(form: EditShiftForm)
    {
        this._form = clone(form);
    }

    private _adapt()
    {
        return {
            ...(this._form.date ? {date: this._form.date} : {}),
            ...(this._form.description ? {description: this._form.description} : {}),
            ...(this._form.license ? {license: this._form.license} : {}),
            ...(this._form.rate ? {rate: this._form.rate} : {}),
            ...(this._form.shiftTemplate ? {shiftTemplate: this._form.shiftTemplate} : {}),
            ...(this._form.shiftTemplateDefinition ? {shiftTemplateDefinition: this._form.shiftTemplateDefinition} : {}),
            ...(this._form.autoConfirmGroup ? {autoConfirmGroup: this._form.autoConfirmGroup} : {}),
            slots: this._form.slots,
        } as EditShiftDTO;
    }

    get dto()
    {
        if(!this._dto)
            this._dto = this._adapt();

        return this._dto;
    }
}