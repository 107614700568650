import { Component, Input } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import {
  MAT_DATE_FORMATS,
  DateAdapter,
  MAT_DATE_LOCALE,
} from '@angular/material/core';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import * as moment from 'moment';

import { MatDatepickerInputEvent } from '@angular/material/datepicker';

export const MY_FORMATS = {
  parse: {
    dateInput: 'LL',
  },
  display: {
    dateInput: 'DD MMM',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};
@Component({
  selector: 'app-range-datepicker',
  templateUrl: './range-datepicker.component.html',
  styleUrls: ['./range-datepicker.component.scss'],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE],
    },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
  ],
})
export class RangeDatepickerComponent {
  @Input() range: UntypedFormGroup;
  date = this.fb.group({
    startTimeFrom: {},
    startTimeTo: {},
  });

  constructor(private fb: UntypedFormBuilder) {}

  dateChange(type: string, event: MatDatepickerInputEvent<Date>) {
    if (event.value) {
      this.range.controls[type].setValue(event.value.toISOString());
    }
  }
  onArrowClick(dir) {
    let from, to;
    if(this.range.controls.startTimeFrom.value && this.range.controls.startTimeTo.value){
      from = moment(this.range.controls.startTimeFrom.value);
      to = moment(this.range.controls.startTimeTo.value);
    }else if(this.range.controls.startTimeFrom.value){
      from = moment(this.range.controls.startTimeFrom.value);
      to = moment(this.range.controls.startTimeFrom.value).add(1, 'year');;
    }else if(this.range.controls.startTimeTo.value){
      to = moment(this.range.controls.startTimeTo.value);
      from = moment(this.range.controls.startTimeTo.value).subtract(1, 'year');;
    }else{
      if (dir === 'left') {
        to = moment();
        from = moment().subtract(1, 'year');
      }else if(dir === 'right'){
        from = moment();
        to = moment().add(1, 'year');
      }
    }
    let length = to.unix() - from.unix();
    if (dir === 'left') {
      this.range.controls.startTimeTo.setValue(from.toISOString());
      this.range.controls.startTimeFrom.setValue(
        moment.unix(from.unix() - length).toISOString(),
      );
    } else if (dir === 'right') {
      this.range.controls.startTimeFrom.setValue(to.toISOString());
      this.range.controls.startTimeTo.setValue(
        moment.unix(to.unix() + length).toISOString(),
      );
    }
  }
}
