import { throwError } from "rxjs";

import { Applicant, ApplicantModel } from "src/app/models/applicant";

import { ApplicantBindings } from "./bindings";

export class ApplicantWithBindings extends Applicant
{
    private _bindings: ApplicantBindings;
    private _shiftId: string;

    constructor(model: ApplicantModel, shiftId: string, bindings: ApplicantBindings)
    {
        super(model);

        this._shiftId = shiftId;
        this._bindings = bindings;
    }

    get bindings() { return this._bindings; }

    get shiftId() { return this._shiftId; }

    cancel()
    {
        if(!this.bindings.cancel) return throwError(new Error('Cancel method is not bound!'));

        return this.bindings.cancel(this._shiftId, this.user._id);
    }

    confirm()
    {
        if(!this.bindings.confirm) return throwError(new Error('Confirm method is not bound!'));

        return this.bindings.confirm(this._shiftId, this.user._id);
    }

    decline()
    {
        if(!this.bindings.decline) return throwError(new Error('Decline method is not bound!'));

        return this.bindings.decline(this._shiftId, this.user._id);
    }
}