import { DateTime } from "luxon";

export function generateTimeLabel(lastClockedInAt, lastClockedOutAt, lastWorkedAt, timezone) {
  let timeLabel: {
    type: 'clockedOut' | 'clockedIn' | 'lastWorked' | 'none',
    time: string,
    text: string
  } = {
    type: 'none',
    time: '',
    text: ''
  }
  // if (!lastClockedInAt || !lastClockedOutAt || !lastWorkedAt) {
  //   timeLabel.type = 'none';
  //   return timeLabel;
  // }
  const now = DateTime.now().setZone(timezone);
  const clockedInAt = DateTime.fromISO(lastClockedInAt, { zone: timezone });
  const clockedOutAt = DateTime.fromISO(lastClockedOutAt, { zone: timezone });
  const workedAt = DateTime.fromISO(lastWorkedAt, { zone: timezone });
  if (lastClockedInAt && !lastClockedOutAt) {
    if (now.diff(clockedInAt, 'hours').hours < 17) {
      timeLabel.type = 'clockedIn';
      timeLabel.text = 'Clocked in at ';
      timeLabel.time = clockedInAt.toFormat('hh:mm a');
    } else {
      timeLabel.type = 'lastWorked';
      timeLabel.text = 'Last worked ';
      timeLabel.time = workedAt.toFormat('LL/dd/yy');
    }
    return timeLabel
  }
  if ((now < clockedOutAt || now.diff(clockedInAt, 'hours').hours < 17) && (clockedInAt > clockedOutAt)) {
    if (now.diff(clockedInAt, 'hours').hours < 17) {
      timeLabel.type = 'clockedIn';
      timeLabel.text = 'Clocked in at ';
      timeLabel.time = clockedInAt.toFormat('hh:mm a');
    } else {
      timeLabel.type = 'lastWorked';
      timeLabel.text = 'Last worked ';
      timeLabel.time = workedAt.toFormat('LL/dd/yy');
    }
  } else if (now.diff(clockedOutAt, 'hours').hours < 12) {
    timeLabel.type = 'clockedOut';
    timeLabel.text = 'Clocked out at ';
    timeLabel.time = clockedOutAt.toFormat('hh:mm a');
  } else if (lastWorkedAt) {
    timeLabel.type = 'lastWorked';
    timeLabel.text = 'Last worked ';
    timeLabel.time = workedAt.toFormat('LL/dd/yy');
  }
  return timeLabel;
}