declare const window: any;

export const identifyClarityUser = (userId: string) => {
      
    const isApiAvailable = typeof window?.clarity === "function";
  
    if (!isApiAvailable) {
        return;
    }

   //window.clarity("identify", userId.toString());
   window.clarity("set", "userId", userId.toString());
    
  };