import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';

import { of, pipe } from 'rxjs';
import {
  catchError,
  exhaustMap,
  filter,
  map,
  share,
  switchMap,
  take,
} from 'rxjs/operators';

import { FacilitiesAccessor } from '../../services/accessors/facilities.accessor';
import { NotificationsService } from '../../services/api/notifications.service';
import { NotificationsActions } from '../actions/notifications/notifications-action-types';


@Injectable()
export class NotificationsEffects {
  constructor(
    private actions$: Actions,
    private api: NotificationsService,
    private facilitiesAccessor: FacilitiesAccessor,
  ) {}

  fetchNotifications$ = createEffect(() =>
    this.actions$.pipe(
      ofType(NotificationsActions.fetchNotifications),
      exhaustMap(() =>
        this.facilitiesAccessor.activeFacility$.pipe(
          filter((v) => !!v),
          take(1),
          switchMap((f) =>
            this.api.fetchNotifications(f._id).pipe(
              map((model) => NotificationsActions.fetchNotificationsSuccess({ model })),
              catchError((e) => of(NotificationsActions.fetchNotificationsFailed({ error: e }))),
            ),
          ),
        ),
      ),
      share(),
    ),
  );

  readNotifications$ = createEffect(() =>
    this.actions$.pipe(
      ofType(NotificationsActions.readNotifications),
      exhaustMap(({ entityID }) =>
        this.api.readNotificationsByEntityId(entityID).pipe(
          map(() => NotificationsActions.readNotificationsSuccess()),
          catchError((e) => of(NotificationsActions.readNotificationsFailed({ error: e }))),
        ),
      ),
      share(),
    ),
  );
}
