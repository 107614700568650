import { Injectable, ViewContainerRef, ComponentRef } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class PopupService {
  private viewContainerRef: ViewContainerRef;

  private componentRef: ComponentRef<any>;

  public setViewContainerRef(vcr: ViewContainerRef) {
    this.viewContainerRef = vcr;
  }

  public openPopup(component: any, data?: any) {
    if (!this.viewContainerRef) {
      console.error('ViewContainerRef is not set!');
      return;
    }
    this.viewContainerRef.clear();
    this.componentRef = this.viewContainerRef.createComponent(component);
    if (data) {
      Object.assign(this.componentRef.instance, data);
    }
    if (this.componentRef.instance.closePopup) {
      this.componentRef.instance.closePopup.subscribe(() => {
        this.closePopup();
      });
    }
  }
  public closePopup() {
    if (this.componentRef) {
      this.componentRef.destroy();
    }
  }
}
