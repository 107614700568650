import { Action } from "@ngrx/store";

import { Observable, of, throwError } from "rxjs";
import { map, switchMap } from "rxjs/operators";

import { CollectionBuilder, Options } from "../classes/collection-builder";
import { FailedDTO } from "../state/actions/common";

export function collection
    <M, T extends Record<string, M>, C>
    (
        opts: Options,
        ctor: new (m: M, bindings?: object) => C,
        idMatcher: (m: M) => string,
        bindings?: object
    )
{
    return map((collection: T) =>
    {
        const cb = new CollectionBuilder(collection, ctor, idMatcher, opts);

        if(opts.classes && bindings)
            cb.classes(bindings);

        return cb.build();
    });
}

export function actionMap<S extends object, F extends object>(successType: string)
{
    return function(source$: Observable<Action>)
    {
        return source$.pipe(
            switchMap(a => a.type === successType ? of(a as S & Action) : throwError((a as FailedDTO & Action).error))
        );
    }
}