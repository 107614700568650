import { throwError } from 'rxjs';

import { ChatBindings } from './bindings';
import { Chat } from './../../../../models/chat/chat';
import { ChatModel } from './../../../../models/chat/chat.model';
import { toHot } from 'src/app/helpers/to-hot';

export class ChatWithBindings extends Chat {
  private _bindings: ChatBindings;

  constructor(model: ChatModel, bindings: ChatBindings) {
    super(model);

    this._bindings = bindings;
  }

  get bindings() {
    return this._bindings;
  }

  // delete()
  // {
  //     if(!this.bindings.delete) return throwError(ERRORS.METHOD_IS_NOT_BOUND);

  //     return toHot(this.bindings.delete(this._id));
  // }

  // read()
  // {
  //     if(!this.bindings.read) return throwError(ERRORS.METHOD_IS_NOT_BOUND);

  //     return toHot(this.bindings.read(this._id));
  // }

  // sendMessage(text: string, to: string) {
  //   if (!this.bindings.sendMessage) {
  //     //return throwError(ERRORS.METHOD_IS_NOT_BOUND);
  //     return null
  //   }

  //   return toHot(
  //     this.bindings.sendMessage({ text, shift: this.shift._id, to }),
  //   );
  // }
}
