import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Notifications } from 'src/app/models/notifications';

import { environment } from 'src/environments/environment';

@Injectable()
export class NotificationsService {
  private endpoint = environment.apiUrl;

  constructor(private readonly http: HttpClient) {}

  fetchNotifications(facility: string
  ) {
    const params = new HttpParams({
      fromObject: {
        facility,
        shiftNotifications: [
          'SHIFT_NEW_APPLICATION',
          'SHIFT_APPLICANT_CANCELLED'
        ]
      },
    });
    return this.http
      .get<Notifications>(`${this.endpoint}/notifications/updates`,{params});

  }
  readNotificationsByEntityId(entityID: string
    ) {
      return this.http
        .post(`${this.endpoint}/notifications/read-all`,{entityID});
    }
}
