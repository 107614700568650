import { createReducer, on } from '@ngrx/store';


import { toRecord } from 'src/app/state/helpers/to-record';
import { DnrActions } from '../actions/dnr/dnr-action-types';

import { DnrState } from '../interfaces';

export const initialDnrState: DnrState = Object.freeze<DnrState>({
  models: {},
});

export const dnrReducer = createReducer(
  initialDnrState,
  on(DnrActions.fetchDnrSuccess, (state, { models }) => {

    let updModels = models ? { ...toRecord(models, (s) => s.nurse) } : {};
    return {
    ...state,
    models: { ...updModels },
  }}),
  on(DnrActions.addNurseSuccess, (state, { models }) => {
    return {
    ...state,
    models: { ...state.models, ...toRecord(models, (s) => s.nurse) },
  }}),
  on(DnrActions.removeNurseSuccess, (state, { nurseId }) => {
    let models = { ...state.models };
    if(models[nurseId]){
      delete models[nurseId];
    }

    return {
    ...state, models,
  }}),

);
