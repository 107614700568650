import { createAction, props, union } from "@ngrx/store";

import { type } from "src/app/state/actions/helpers/action-type";

import { FetchHolidaysDTO } from "./dto/fetch-holidays";

const t = type('holidays');

export const fetchHolidays = createAction(t.pending('fetch_holidays'));
export const fetchHolidaysSuccess = createAction(t.success('fetch_holidays'), props<FetchHolidaysDTO.Success>());
export const fetchHolidaysFailed = createAction(t.failed('fetch_holidays'), props<FetchHolidaysDTO.Failed>());

const u = union({
    fetchHolidays,
    fetchHolidaysSuccess,
    fetchHolidaysFailed
});
export type Union = typeof u;