import { NurseModel } from './../../../../../models/nurse/nurse.model';
import {
  ApplicantWithBindings,
  ShiftWithBindings,
} from '../../../bindings';
import { NurseService } from '../../../services/api/nurse.service';

import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { catchError, filter, take } from 'rxjs/operators';

import { LoaderService } from '../../../services/ui/loader.service';
import { MessagesService } from '../../../services/messages.service';
import { ModalService } from '../../../services/ui/modal.service';
import { Subscription, throwError } from 'rxjs';
import { APPLICANT_STATUSES } from '../../../constants/applicant-statuses';
import { DateTime } from 'luxon';
import { NurseConfirmationService } from '../../../services/nurse-confirmation.service';
import { MixpanelService } from 'src/app/services/mixpanel.service';
import { FacilitiesAccessor } from '../../../services/accessors/facilities.accessor';
import { FacilityModel } from 'src/app/models/facility';
import { DnrFacade } from '../../../services/facades/dnr/dnr.facade';
import { formatPhoneForManager } from 'src/app/helpers/formatters';
import { UserAccessor } from 'src/app/services/accessors/user.accessor';
import { UserModel } from 'src/app/models/user';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { FacilityNurse } from 'src/app/models/facility/facility-nurse';
import { FacilitiesService } from '../../../services/api/facilities.service';
import { generateTimeLabel } from '../../../helpers/generateTimeLabel';
import { NursesFacade } from '../../../services/facades/nurses/nurses.facade';
import { NursesAccessor } from '../../../services/accessors/nurses.accessor';
import { ManagerSettings } from 'src/app/models/facility/manager-settings';
import { ManagerModel } from 'src/app/models/manager';
import { licensesSort } from 'src/app/helpers/licensesSort';
import { ShiftsService } from '../../../services/api/shifts.service';
import { ShiftModel } from 'src/app/models/shift';

export interface NurseNote {
  name: string;
  action: string;
  date: string;
  edited: boolean;
  text: string;
  my: boolean;
  showMoreModal: boolean;
  isEditing: boolean;
  avatar: string;
  id: string;
  nurse: NurseModel;
};
@Component({
  selector: 'app-nurse-profile',
  templateUrl: './nurse-profile.component.html',
  styleUrls: ['./nurse-profile.component.scss'],
})

export class NurseProfileComponent implements OnInit, OnDestroy {
  @Input() nurseId: string;
  @Input() applicant: ApplicantWithBindings;
  @Input() shift: ShiftWithBindings;
  @Input() timecardShift: ShiftModel;

  @Input() queryParams: {
    nurseId: string;
    facilityID: string;
    segment: string;
    flag: boolean;
  };
  @Input() fromNurseDirectory: boolean;

  @Output() closePopup = new EventEmitter();


  timeLabel: {
    type: 'clockedOut' | 'clockedIn' | 'lastWorked' | 'none',
    time: string,
    text: string
  } = {
      type: 'none',
      time: '',
      text: ''
    };

  statusModel = APPLICANT_STATUSES;
  profileInfo = {
    date: '',
    time: '',
  };
  oldShift = false;
  showModal = false;
  facility$ = this.facilitiesAccessor.activeFacility$;
  modalType = '';
  facility: FacilityModel;
  isDnr = false;
  dnrDate: string;
  licenses = [];
  distanceToFacility;
  activeTab = 'details';
  phone: string;
  weekDays = ['SUN', 'MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT'];
  addNote = false;
  manager: UserModel;
  notes: NurseNote[];
  editing = false;
  editingForm: UntypedFormGroup = this.fb.group({
    note: new UntypedFormControl('', [Validators.required, Validators.minLength(10), Validators.maxLength(280)]),
  });
  createNoteForm: UntypedFormGroup = this.fb.group({
    note: new UntypedFormControl('', [Validators.required, Validators.minLength(10), Validators.maxLength(280)]),
  });
  facilityNurse: FacilityNurse;
  nurse: NurseModel;
  lastShift = '';
  nextShift = '';
  showRemoveFromTrusted = false;
  nurseSub: Subscription;
  managerSettings: ManagerSettings;
  lastShiftPreferencesRequestDate = '';

  constructor(
    private router: Router,
    private nurseApi: NurseService,
    private messages: MessagesService,
    private loader: LoaderService,
    private modalService: ModalService,
    private nurseConfirmationService: NurseConfirmationService,
    private mixpanel: MixpanelService,
    private facilitiesAccessor: FacilitiesAccessor,
    private dnrFacade: DnrFacade,
    private userAccessor: UserAccessor,
    private fb: UntypedFormBuilder,
    private nursesFacade: NursesFacade,
    private nursesAccessor: NursesAccessor,
    private facilitiesService: FacilitiesService,
    private shiftsService: ShiftsService
  ) { }

  ngOnInit(): void {
    this.userAccessor.user$.pipe(take(1)).subscribe((u) => {

    });
    this.loader.create('Loading...');
    this.facility$.pipe(filter(f => !!f), take(1)).subscribe(f => {
      this.facility = f;
      this.facilitiesService.fetchManagerSettings(this.facility._id).pipe(take(1)).subscribe(ms => {
        this.managerSettings = ms;
      });
      this.nursesFacade.fetchFacilityNurse(this.nurseId).pipe(take(1)).subscribe(fn => {
        this.loader.hide();
        this.nurseSub = this.nursesAccessor.nurse$(this.nurseId).subscribe(n => {
          this.facilityNurse = n;
          this.nurse = this.facilityNurse.nurse;
          this.facilitiesService.fetchMixedNotes(this.facility._id, this.nurse._id).pipe(take(1)).subscribe(mixedNotes => {
            this.generateNotesArr(mixedNotes);
          });
          this.lastShift = this.facilityNurse.lastWorkedAt ? (DateTime.fromISO(this.facilityNurse.lastWorkedAt, { zone: this.facility.timezone }).toFormat('LL/dd/yy')) : '-';
          this.nextShift = this.facilityNurse.nextShiftAt ? (DateTime.fromISO(this.facilityNurse.nextShiftAt, { zone: this.facility.timezone }).toFormat('LL/dd/yy')) : '-';
          this.generateNurseLicensesArr();
          this.phone = formatPhoneForManager(this.facilityNurse.nurse.phone);
          this.getDnrStatus();
          if (this.facilityNurse.nurse.address) {
            this.distanceToFacility = this.calculateDistanceInMiles(this.facility.address.lat, this.facility.address.long, this.facilityNurse.nurse.address.lat, this.facilityNurse.nurse.address.long).toFixed(2);
          }
          this.timeLabel = generateTimeLabel(this.facilityNurse.lastClockedInAt, this.facilityNurse.lastClockedOutAt, this.facilityNurse.lastWorkedAt, this.facility.timezone);
          if (this.shift) {
            this.mixpanel.for('fm').track('NURSE_PROFILE_OPEN', { shift: this.shift, nurse: this.facilityNurse.nurse, });
            this.profileInfo.date = this.shift.shiftFrom.toFormat('ccc LL/dd/yyyy');
            this.profileInfo.time =
              this.shift.dateData.shiftFrom + ' - ' + this.shift.dateData.shiftTo;

            if (
              this.shift.shiftTo <
              DateTime.fromObject({
                zone: this.shift.facility.timezone,
              })
            ) {
              this.oldShift = true;
            } else {
              this.oldShift = false;
            }
          } else {
            this.mixpanel.for('fm').track('NURSE_PROFILE_OPEN', { nurse: this.facilityNurse.nurse, });
          }
        });
        this.generateLastShiftPreferencesRequestDate();
      });
    });



    this.userAccessor.user$.pipe(take(1)).subscribe(u => {
      this.manager = u;
    });

    if ((this.queryParams?.segment === 'availability') && this.queryParams.flag) {
      this.onShiftPreferencesTabOpen();
      this.queryParams.flag = false;
    }
  }
  calculateDistanceInMiles(lat1, lon1, lat2, lon2) {
    const R = 3959; // Radius of the Earth in miles
    const dLat = (lat2 - lat1) * Math.PI / 180; // Convert degrees to radians
    const dLon = (lon2 - lon1) * Math.PI / 180;
    const a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos(lat1 * Math.PI / 180) * Math.cos(lat2 * Math.PI / 180) *
      Math.sin(dLon / 2) * Math.sin(dLon / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    const distance = R * c;
    return distance;
  }
  generateNurseLicensesArr() {
    let licensesArr = []
    this.nurse.licenses.filter(l => l.state === this.facility.address.state).forEach(l => {
      licensesArr.push(l.type);
    });
    const sortedLicenses = licensesSort(licensesArr).reverse();
    sortedLicenses.forEach(license => {
      if (!this.licenses.find(l => (l === license))) {
        this.licenses.push(license);
      }
    });

  }
  getDnrStatus() {
    this.nurseApi.fetchDnrByUser(this.facilityNurse.nurse._id, this.facility._id).pipe(take(1)).subscribe(dnrInfo => {
      this.isDnr = true;
      this.dnrDate = DateTime.fromISO(dnrInfo['createdAt'], {
        zone: this.facility.timezone,
      }).toFormat('LL/dd/yyyy')
    },
      error => {
        this.isDnr = false;
      })
  }
  addNurseToDnr(e) {
    this.loader.create('Loading...');
    this.dnrFacade.addNurseToDNR(e).pipe(take(1)).subscribe(r => {
      this.loader.hide();
      if (!r['error']) {
        this.showModal = false;
        this.messages.add('Success! Your change has been updated.', '', 'success');
        this.getDnrStatus();
        if (this.facilityNurse.trusted?.isTrusted) {

          this.facilityNurse = {
            ...this.facilityNurse, trusted: { ...this.facilityNurse.trusted, isTrusted: false }
          }
        }
        this.facilitiesService.fetchMixedNotes(this.facility._id, this.nurse._id).pipe(take(1)).subscribe(mixedNotes => {
          this.generateNotesArr(mixedNotes);
        });
      }else{
        this.messages.add(r['error'].error.message, '', 'danger');
      }
    });
  }
  removeNurseFromDnr(e) {
    this.loader.create('Loading...');
    this.dnrFacade.removeNurseFromDNR(e).pipe(take(1)).subscribe(r => {
      this.loader.hide();
      if(!r['error']){
        this.showModal = false;
        this.messages.add('Success! Your change has been updated.', '', 'success');
        this.getDnrStatus();
        this.facilitiesService.fetchMixedNotes(this.facility._id, this.nurse._id).pipe(take(1)).subscribe(mixedNotes => {
          this.generateNotesArr(mixedNotes);
        });
      } else {
        this.messages.add(r['error'].error.message, '', 'danger');
      }
    });
  }
  goToChat() {
    if (this.fromNurseDirectory) {
      if (this.facilityNurse.firstAppliedShift) {
        this.shiftsService.fetchShift(this.facilityNurse.firstAppliedShift).pipe(take(1)).subscribe(shift => {
          this.router.navigate(['manager/chat'], {
            state: { shift, nurse: this.facilityNurse.nurse, hideShiftInfo: true },
          });
        });
      }
    } else {
      if (this.shift?.model) {
        this.router.navigate(['manager/chat'], {
          state: { shift: this.shift.model, nurse: this.facilityNurse.nurse },
        });
      } else if (this.timecardShift) {
        this.router.navigate(['manager/chat'], {
          state: { shift: this.timecardShift, nurse: this.facilityNurse.nurse },
        });
      }
    }
  }
  onCancel() {
    if (this.applicant.status === 'applied') {
      this.mixpanel.for('fm').track('NURSE_PROFILE_DECLINE_NURSE', { facility: this.facility, shift: this.shift, nurse: this.nurse });
      this.applicant.decline();
      this.closePopup.emit();
    } else {
      this.onNurseCancel(this.applicant);
    }
  }
  onNurseCancel(applicant) {
    if (applicant.status === 'applied') {
      applicant.decline();
    } else {
      let nowTimestamp = DateTime.fromObject({
        zone: this.shift.facility.timezone,
      }).valueOf();
      if (
        this.shift.shiftFrom.valueOf() - nowTimestamp < 7200000 && //7200000 = 2hours
        this.shift.shiftFrom.valueOf() - nowTimestamp > 0
      ) {
        this.modalService.showModal(
          'Confirm last minute cancellation',
          'Cancelling a nurse last minute will result in you <b>being billed for a 2-hour timecard at the shift’s hourly rate.</b> When a nurse is canceled 2 hours before a shift’s start time, a last minute cancellation timecard will be automatically created and billed for.',
          'Cancel the nurse',
          'Exit',
          'rn',
          'modal',
          true,
          'I understand that I will be billed for 2 hours for this last minute cancellation',
          '',
          true
        ).subscribe((r) => {
          if (r.action === 'firstAction') {
            applicant.cancel();
            this.closePopup.emit();
          }
        });
      } else {
        this.modalService.showModal(
          'Cancel nurse?',
          'Please confirm that you want to cancel this nurse.',
          'Cancel the nurse',
          'Exit',
        ).subscribe((r) => {
          if (r.action === 'firstAction') {
            applicant.cancel();
            this.closePopup.emit();
          }
        });
      }
    }
  }
  onConfirm(facility) {
    this.mixpanel.for('fm').track('NURSE_PROFILE_CONFIRM_NURSE', { facility: this.facility, shift: this.shift, nurse: this.nurse });
    this.nurseConfirmationService.onNurseConfirm(this.applicant, facility, this.shift).pipe(take(1)).subscribe(d => {
      if (d) {
        this.closePopup.emit();
      }
    })


  }
  sendAttachments() {
    this.loader.create('Loading...');
    this.nurseApi
      .sendNurseAttachments(this.facilityNurse.nurse._id)
      .pipe(
        take(1),
        catchError((e) => {
          if (!e.ok) {
            this.messages.add(e.error.message, '', 'danger');
          }
          this.loader.hide();
          return throwError(e);
        }),
      )
      .subscribe((d) => {
        this.messages.add(
          'An email with the nurse’s credentials has been sent.',
          '',
          'success',
        );
        this.loader.hide();
      });
  }
  notesOnMoreClick(event, i) {
    this.notes[i].showMoreModal = true;
  }
  notesOnMoreClose(i) {
    this.notes[i].showMoreModal = false;
  }
  onNoteEdit(i) {
    this.notesOnMoreClose(i);
    this.editing = true;
    this.notes[i].isEditing = true;
    this.editingForm.get('note').setValue(this.notes[i].text);
  }
  onCancelEdit(i) {
    this.editing = false;
    this.notes[i].isEditing = false;
  }
  onNoteSave(i) {
    this.loader.create('Loading...');
    this.onCancelEdit(i);
    this.facilitiesService.editFacilityNurseNotes(this.facility._id, this.nurse._id, this.notes[i].id, this.editingForm.value.note).pipe(take(1)).subscribe(r => {
      this.facilitiesService.fetchMixedNotes(this.facility._id, this.nurse._id).pipe(take(1)).subscribe(notes => {
        this.loader.hide();
        this.generateNotesArr(notes);
      })
    })
  }
  onNoteDelete(i) {
    this.loader.create('Loading...');
    this.notesOnMoreClose(i);
    this.facilitiesService.deleteFacilityNurseNotes(this.facility._id, this.nurse._id, this.notes[i].id).pipe(take(1)).subscribe(r => {
      this.facilitiesService.fetchMixedNotes(this.facility._id, this.nurse._id).pipe(take(1)).subscribe(notes => {
        this.loader.hide();
        this.generateNotesArr(notes);
      })
    })
  }

  onMarkAsTrusted() {
    if (!this.managerSettings?.settings?.isSetNurseAsTrustedConfirmationOn) {
      this.modalService
        .showModal(
          'Mark ' + this.nurse.name.first + ' as trusted',
          "Make it easier for your team to recognize outstanding nurses by marking them as trusted. <br><br> We’ll be adding more ways to easily schedule trusted nurses in the future.",
          'Confirm nurse as trusted',
          'Cancel',
          'cna',
          'modal',
          true,
          "Don't show this message again",
          'badgeCheck'
        )
        .subscribe((r) => {
          if (r.checkbox) {
            this.facilitiesService.setManagerSettingsTrustedConfirmationOn(this.facility._id).pipe(take(1)).subscribe(r => {
              this.facilitiesService.fetchManagerSettings(this.facility._id).pipe(take(1)).subscribe(ms => {
                this.managerSettings = ms;
              })
            })
          }
          if (r.action === 'firstAction') {
            this.mixpanel.for('fm').track('NURSES_MARK_AS_TRUSTED_PROFILE', { facility: this.facility, nurse: this.nurse });
            this.nursesFacade.markAsTrusted(this.facility._id, this.nurse._id).pipe(take(1)).subscribe(result => {
              this.facilitiesService.fetchMixedNotes(this.facility._id, this.nurse._id).pipe(take(1)).subscribe(mixedNotes => {
                this.generateNotesArr(mixedNotes);
              });
            });
          }
        });
    } else {
      this.nursesFacade.markAsTrusted(this.facility._id, this.nurse._id).pipe(take(1)).subscribe(result => {
      });
    }
  }
  removeFromTrusted(event) {
    let reasons = [];
    Object.keys(event).forEach(r => {
      if (r != 'other' && r != 'otherText') {
        if (event[r]) {
          reasons.push(r);
        }
      } else if ((r === 'otherText') && event[r].length) {
        reasons.push(event[r])
      }
    })
    this.mixpanel.for('fm').track('NURSES_UNMARK_AS_TRUSTED_PROFILE', { facility: this.facility, nurse: this.nurse, ndUntrustedReasons: reasons });
    this.nursesFacade.removeFromTrusted(this.facility._id, this.nurse._id, reasons).pipe(take(1)).subscribe(result => {
      this.facilitiesService.fetchMixedNotes(this.facility._id, this.nurse._id).pipe(take(1)).subscribe(mixedNotes => {
        this.generateNotesArr(mixedNotes);
      });
    });
  }
  onAddNote() {

    this.mixpanel.for('fm').track('NURSES_PROFILE_ADD_NOTE', { facility: this.facility, nurse: this.nurse });
    this.loader.create('Loading...');
    this.facilitiesService.addFacilityNurseNotes(this.facility._id, this.nurse._id, this.createNoteForm.get('note').value).pipe(take(1)).subscribe(r => {
      this.facilitiesService.fetchMixedNotes(this.facility._id, this.nurse._id).pipe(take(1)).subscribe(notes => {
        this.generateNotesArr(notes);
        this.loader.hide();
      })
      this.createNoteForm.get('note').setValue('');
      this.addNote = false;

    },
      error => {
        this.loader.hide();
      })
  }
  generateNotesArr(notesHistory) {
    const authorKeys = {
      addToDnr: 'manager',
      removeFromDnr: 'manager',
      addToTrusted: 'changedBy',
      removeFromTrusted: 'changedBy',
      addNote: 'author',
    }
    this.notes = [];
    notesHistory.forEach(n => {
      let notesType = this.generateNotesType(n);
      let note: NurseNote;
      note = {
        name: n.documentPayload[authorKeys[notesType]]?.fullName,
        action: notesType,
        date: DateTime.fromISO(n.createdAt).setZone(this.facility.timezone).toFormat('LLLL dd, yyyy \'at\' h:mm a'),
        edited: DateTime.fromISO(n.documentPayload.updatedAt) > DateTime.fromISO(n.documentPayload.createdAt),
        text: this.generateNotesText(n, notesType),
        showMoreModal: false,
        isEditing: false,
        my: n.documentPayload[authorKeys[notesType]]?._id === this.manager._id,
        avatar: n.documentPayload[authorKeys[notesType]]?.avatar,
        id: n.documentPayload?._id,
        nurse: n.nurse
      };
      if (!n.documentPayload.deletedAt) {
        this.notes.push(note);
      }
    })
  }
  generateNotesText(note, notesType) {
    const reasonsObj = {
      'attendance_and_punctuality': 'Attendance & punctuality',
      'attitude_and_cooperation': 'Attitude & cooperation',
      'facility_preference': 'Facility preference'
    }
    if (notesType === 'addNote') {
      return note.documentPayload.text;
    } else
      if (notesType === 'removeFromTrusted') {
        let text = '';
        if (note.documentPayload?.brokenTrustReasons) {
          note.documentPayload?.brokenTrustReasons.forEach(r => {
            text += (reasonsObj[r] ? reasonsObj[r] : r) + (r.slice(-1) != '.' ? '. ' : ' ');
          });
        }
        return text;
      }
      else if (notesType === 'addToDnr' || notesType === 'removeFromDnr') {
        let text = '';
        note.documentPayload.reasons.forEach(r => {
          text += r + (r.slice(-1) != '.' ? '. ' : ' ');
        });
        return text;
      }
    return '';
  }
  generateNotesType(note) {

    if (note.documentType === 'TrustedNursePayload') {
      if (note.documentPayload.isTrusted) {
        return 'addToTrusted';
      } else {
        return 'removeFromTrusted';
      }
    } else
      if (note.documentType === 'DnrPayload') {
        if (note.documentPayload.action === 'ADDED') {
          return 'addToDnr';
        } else {
          return 'removeFromDnr';
        }
      } else
        return 'addNote';
  }
  generateLastShiftPreferencesRequestDate() {
    this.facilitiesService.fetchNurseShiftPrefsRequests(this.facility._id, this.nurse._id).pipe(take(1)).subscribe(r => {
      if (r.length) {
        this.lastShiftPreferencesRequestDate = DateTime.fromISO(r[r.length - 1].requestedAt).toFormat('M/d/yyyy');
      }
    })
  }
  onRequestShiftPreferences() {
    this.loader.create('Loading...');
    this.mixpanel.for('fm').track('NURSES_AVAILABILITY_REQUEST', { facility: this.facility, nurse: this.nurse });
    this.facilitiesService.addNurseShiftPrefsRequests(this.facility._id, this.nurse._id).pipe(take(1)).subscribe(r => {
      this.loader.hide();
      this.messages.add('Success! Your change has been updated.', '', 'success');
      this.lastShiftPreferencesRequestDate = DateTime.fromISO(r.requestedAt).toFormat('M/d/yyyy');
    })

  }
  onShiftPreferencesTabOpen() {
    if (this.activeTab != 'shiftPreferences') {
      this.mixpanel.for('fm').track('NURSES_PROFILE_AVAILABILITY_TAB', { facility: this.facility, nurse: this.nurse });
    }
    this.activeTab = 'shiftPreferences';
  }
  onShiftNotesTabOpen() {
    if (this.activeTab != 'notes') {
      this.mixpanel.for('fm').track('NURSES_PROFILE_NOTES_TAB', { facility: this.facility, nurse: this.nurse });
    }
    this.activeTab = 'notes';
  }
  onTimeClick() {
    if (this.timeLabel.type === 'clockedIn') {
      this.router.navigate(['manager/shifts/applicants'], {
        state: { id: this.facilityNurse.lastClockedInShift },
      });
    } else if (this.timeLabel.type === 'clockedOut') {
      this.router.navigate(['manager/shifts/applicants'], {
        state: { id: this.facilityNurse.lastClockedOutShift },
      });
    } else if (this.timeLabel.type === 'lastWorked') {
      this.router.navigate(['manager/timecards'], {
        state: { id: this.facilityNurse.lastWorkedTimecard },
      });
    }
  }
  ngOnDestroy() {
    if (this.nurseSub) {
      this.nurseSub.unsubscribe();
    }
  }
}
