import { createSelector } from "@ngrx/store";
import { selectModelsById } from "src/app/state/helpers/select-models-by-id";
import { State } from "src/app/state/interfaces";

import { MANAGER_FEATURE_KEY, TIMECARDS_FEATURE_KEY } from "../interfaces";

const state = (s: State) => s[MANAGER_FEATURE_KEY][TIMECARDS_FEATURE_KEY];

export const selectTimecardIds = createSelector(state, ({ timecards }) => timecards);
export const selectTimecards = createSelector(state, ({ models, timecards }) => selectModelsById(models, timecards));

export const selectPagination = createSelector(state, ({ pagination }) => pagination);