import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';

import { asapScheduler } from 'rxjs';
import { switchMap, take } from 'rxjs/operators';

import { Chain } from 'src/app/classes/async-chain';
import { FacilitiesAccessor } from 'src/app/modules/manager/services/accessors/facilities.accessor';
import { AppService } from 'src/app/services/app.service';
import { FacilitiesEffects } from 'src/app/modules/manager/state/effects/facilities.effects';
import { State } from 'src/app/state/interfaces';
import { FacilityModel } from 'src/app/models/facility';
import { toHot } from 'src/app/helpers/to-hot';

import {
  AddSupervisorForm,
  CreateShiftTemplateForm,
  EditFacilityInformationForm,
  EditShiftTemplateForm,
  RequestChangeForm,
  ShiftRateDefaultsForm,
} from '../../../types/forms';
import { EditFacilityInformationAdapter } from '../../../adapters/edit-facility-information.adapter';
import { AddSupervisorAdapter } from '../../../adapters/add-supervisor.adapter';
import { RequestChangeAdapter } from '../../../adapters/request-change.adapter';
import { CreateShiftTemplateAdapter } from '../../../adapters/create-shift-template.adapter';
import { EditShiftTemplateAdapter } from '../../../adapters/shift-time-preferences.adapter';
import { ShiftRateDefaultsAdapter } from '../../../adapters/shift-rate-defaults.adapter';
import { FacilityBindings } from '../../../bindings';
import { MessagesService } from '../../messages.service';
import { LoaderService } from '../../ui/loader.service';
import { FacilitiesBinder } from './facilities.binder';
import { EditFacilityOvertimeForm } from '../../../types/forms/edit-facility-overtime';
import { EditFacilityOvertimeAdapter } from '../../../adapters/edit-facility-overtime.adapter';
import { EditFacilityInstashyftsForm } from '../../../types/forms/edit-facility-instashyfts/edit-facility-instashyfts-form';
import { EditFacilityInstashyftsAdapter } from '../../../adapters/edit-facility-instashyft.adapter';
import { FacilitiesActions } from '../../../state/actions/facilities/facilities-action-types';

type P<K extends keyof FacilityBindings> = Parameters<FacilityBindings[K]>;

@Injectable({ providedIn: 'root' })
export class FacilitiesFacade {
  private _addSupervisorChain = (...args: P<'addSupervisor'>) =>
    new Chain({ debug: !this.app.isProduction, id: 'add_supervisor' })
      .add('sync', 'loader', () => this.loader.create('Loading...'))
      .add('sync', 'addSupervisor', () => this.addSupervisor(args[0]))
      .add('sync', 'loader', () => this.loader.hide())
      .add('sync', '', ({ addSupervisor }) =>
        this.messages.add(
          addSupervisor.type === FacilitiesActions.addSupervisorSuccess.type
            ? 'Success! Your change has been updated.'
            : addSupervisor.error.error.message,
          '',
          addSupervisor.type === FacilitiesActions.addSupervisorSuccess.type
            ? 'success'
            : 'danger',
        ),
      );

  private _createShiftTemplateChain = (...args: P<'createShiftTemplate'>) =>
    new Chain({
      debug: !this.app.isProduction,
      id: 'create_shift_template',
    })
      .add('sync', 'loader', () => this.loader.create('Loading...'))
      .add('sync', 'createShiftTemplate', () =>
        this.createShiftTemplate(args[0]),
      )
      .add('sync', 'loader', () => this.loader.hide())
      .add('sync', '', ({ createShiftTemplate }) =>
        this.messages.add(
          createShiftTemplate.type === FacilitiesActions.createShiftTemplateSuccess.type
            ? 'Success! Your change has been updated.'
            : createShiftTemplate.error.error.message,
            '',
          createShiftTemplate.type === FacilitiesActions.createShiftTemplateSuccess.type
            ? 'success'
            : 'danger',
        ),
      );

  private _deleteShiftTemplateChain = (...args: P<'deleteShiftTemplate'>) =>
    new Chain({
      debug: !this.app.isProduction,
      id: 'delete_shift_template',
    })
      .add('sync', 'loader', () => this.loader.create('Loading...'))
      .add('sync', 'deleteShiftTemplate', () =>
        this.deleteShiftTemplate(args[0]),
      )
      .add('sync', 'loader', () => this.loader.hide())
      .add('sync', '', ({ deleteShiftTemplate }) =>
        this.messages.add(
          deleteShiftTemplate.type === FacilitiesActions.deleteShiftTemplateSuccess.type
            ? 'Success! Your change has been updated.'
            : deleteShiftTemplate.error.error.message,
          '',
          deleteShiftTemplate.type === FacilitiesActions.deleteShiftTemplateSuccess.type
            ? 'success'
            : 'danger',
        ),
      );

  private _editInformationChain = (...args: P<'editInformation'>) =>
    new Chain({ debug: !this.app.isProduction, id: 'edit_information' })
      .add('sync', 'loader', () => this.loader.create('Loading...'))
      .add('sync', 'editInformation', () => this.editInformation(args[0]))
      .add('sync', 'loader', () => this.loader.hide())
      .add('sync', '', ({ editInformation }) =>
        this.messages.add(
          editInformation.type === FacilitiesActions.editFacilitySuccess.type
            ? 'Success! Your change has been updated.'
            : editInformation.error.error.message,
          '',
          editInformation.type === FacilitiesActions.editFacilitySuccess.type
            ? 'success'
            : 'danger',
        ),
      );
      private _editOvertimeChain = (...args: P<'editOvertime'>) =>
    new Chain({ debug: !this.app.isProduction, id: 'edit_overtime' })
      .add('sync', 'loader', () => this.loader.create('Loading...'))
      .add('sync', 'editInformation', () => this.editOvertime(args[0]))
      .add('sync', 'loader', () => this.loader.hide())
      .add('sync', '', ({ editInformation }) =>
        this.messages.add(
          editInformation.type === FacilitiesActions.editFacilitySuccess.type
            ? 'Success! Your change has been updated.'
            : editInformation.error.error.message,
          '',
          editInformation.type === FacilitiesActions.editFacilitySuccess.type
            ? 'success'
            : 'danger',
        ),
      );

      private _editInstashyftsChain = (...args: P<'editInstashyfts'>) =>
      new Chain({ debug: !this.app.isProduction, id: 'edit_instashyfts' })
        .add('sync', 'loader', () => this.loader.create('Loading...'))
        .add('sync', 'editInformation', () => this.editInstashyfts(args[0]))
        .add('sync', 'loader', () => this.loader.hide())
        .add('sync', '', ({ editInformation }) =>
          this.messages.add(
            editInformation.type === FacilitiesActions.editFacilitySuccess.type
              ? 'Success! Your change has been updated.'
              : editInformation.error.error.message,
            '',
            editInformation.type === FacilitiesActions.editFacilitySuccess.type
              ? 'success'
              : 'danger',
          ),
        );

  private _editRateLimitsChain = (...args: P<'editRateLimits'>) =>
    new Chain({ debug: !this.app.isProduction, id: 'edit_rate_limits' })
      .add('sync', 'loader', () => this.loader.create('Loading...'))
      .add('sync', 'editRateLimits', () => this.editRateLimits(args[0]))
      .add('sync', 'loader', () => this.loader.hide())
      .add('sync', '', ({ editRateLimits }) =>
        this.messages.add(
          editRateLimits.type === FacilitiesActions.editFacilitySuccess.type
            ? 'Success! Your change has been updated.'
            : editRateLimits.error.error.message,
          '',
          editRateLimits.type === FacilitiesActions.editFacilitySuccess.type
            ? 'success'
            : 'danger',
        ),
      );

  private _editShiftTemplateChain = (...args: P<'editShiftTemplate'>) =>
    new Chain({ debug: !this.app.isProduction, id: 'edit_shift_template' }).add(
      'sync',
      'editShiftTemplate',
      () => this.editShiftTemplate(args[0], args[1]),
    );

  private _requestChangesChain = (...args: P<'requestChange'>) =>
    new Chain({ debug: !this.app.isProduction, id: 'request_change' })
      .add('sync', 'loader', () => this.loader.create('Loading...'))
      .add('sync', 'requestChange', () => this.requestChange(args[0]))
      .add('sync', 'loader', () => this.loader.hide())
      .add('sync', '', ({ requestChange }) =>
        this.messages.add(
          requestChange.type === FacilitiesActions.requestChangeSuccess.type
            ? 'Success! Your change has been requested.'
            : requestChange.error.error.message,
          '',
          requestChange.type === FacilitiesActions.requestChangeSuccess.type
            ? 'success'
            : 'danger',
        ),
      );

  private _uploadAvatarChain = (...args: P<'uploadAvatar'>) =>
    new Chain({ debug: !this.app.isProduction, id: 'request_change' })
      .add('sync', 'loader', () => this.loader.create('Loading...'))
      .add('sync', 'uploadAvatar', () => this.uploadAvatar(args[0]))
      .add('sync', 'loader', () => this.loader.hide())
      .add('sync', '', ({ uploadAvatar }) =>
        this.messages.add(
          uploadAvatar.type === FacilitiesActions.uploadAvatarSuccess.type
            ? 'Success! Your change has been updated.'
            : uploadAvatar.error.error.message,
          '',
          uploadAvatar.type === FacilitiesActions.uploadAvatarSuccess.type ? 'success' : 'danger',
        ),
      );

  private _binder = new FacilitiesBinder(this.accessor, {
    addSupervisor: (...args: P<'addSupervisor'>) =>
      this._addSupervisorChain(...args).execute(),
    createShiftTemplate: (...args: P<'createShiftTemplate'>) =>
      this._createShiftTemplateChain(...args).execute(),
    deleteShiftTemplate: (...args: P<'deleteShiftTemplate'>) =>
      this._deleteShiftTemplateChain(...args).execute(),
    editInformation: (...args: P<'editInformation'>) =>
      this._editInformationChain(...args).execute(),
    editOvertime: (...args: P<'editOvertime'>) =>
      this._editOvertimeChain(...args).execute(),
    editInstashyfts: (...args: P<'editInstashyfts'>) =>
    this._editInstashyftsChain(...args).execute(),
    editRateLimits: (...args: P<'editRateLimits'>) =>
      this._editRateLimitsChain(...args).execute(),
    editShiftTemplate: (...args: P<'editShiftTemplate'>) =>
      this._editShiftTemplateChain(...args).execute(),
    requestChange: (...args: P<'requestChange'>) =>
      this._requestChangesChain(...args).execute(),
    uploadAvatar: (...args: P<'uploadAvatar'>) =>
      this._uploadAvatarChain(...args).execute(),
  });

  constructor(
    private app: AppService,
    private store: Store<State>,
    private accessor: FacilitiesAccessor,
    private _effects: FacilitiesEffects,
    private messages: MessagesService,
    private loader: LoaderService,
  ) {
    app.expose('facades', 'facility', this);
  }

  get binder() {
    return this._binder;
  }

  get effects() {
    return this._effects;
  }

  get state() {
    return this.accessor;
  }

  fetchMyFacilities() {
    asapScheduler.schedule(() => this.store.dispatch(FacilitiesActions.fetchMyFacilities()));
    return this.effects.fetchMyFacilities$.pipe(take(1));
  }
  fetchFacilityById(id: string) {
    asapScheduler.schedule(() => this.store.dispatch(FacilitiesActions.fetchFacilityById({id})));
    return this.effects.fetchFacilityById$.pipe(take(1));
  }

  setActiveFacility(id: string) {
    this.store.dispatch(FacilitiesActions.setActiveFacility({ id }));
  }
  changePaymentMethod(id: string, token: string) {
    asapScheduler.schedule(() =>
      this.store.dispatch(FacilitiesActions.changePaymentMethod({ id, dto: token })),
    );
    return this.effects.changePaymentMethod$.pipe(take(1));
  }
  registerStripe(id: string) {
    asapScheduler.schedule(() => this.store.dispatch(FacilitiesActions.registerStripe({ id })));
    return this.effects.registerStripe$.pipe(take(1));
  }
  getStripe(id: string) {
    asapScheduler.schedule(() => this.store.dispatch(FacilitiesActions.getStripe({ id })));
    return this.effects.getStripe$.pipe(take(1));
  }
  private addSupervisor(form: AddSupervisorForm) {
    asapScheduler.schedule(() =>
      this.store.dispatch(
        FacilitiesActions.addSupervisor({ dto: new AddSupervisorAdapter(form).dto }),
      ),
    );
    return this.effects.addSupervisor$.pipe(take(1));
  }

  private createShiftTemplate(form: CreateShiftTemplateForm) {
    asapScheduler.schedule(() =>
      this.store.dispatch(
        FacilitiesActions.createShiftTemplate({ dto: new CreateShiftTemplateAdapter(form).dto }),
      ),
    );
    return this.effects.createShiftTemplate$.pipe(take(1));
  }

  private deleteShiftTemplate(id: string) {
    asapScheduler.schedule(() =>
      this.store.dispatch(FacilitiesActions.deleteShiftTemplate({ id })),
    );
    return this.effects.deleteShiftTemplate$.pipe(take(1));
  }

  private edit(facility: FacilityModel) {
    asapScheduler.schedule(() =>
      this.store.dispatch(FacilitiesActions.editFacility({ facility  })),
    );
    return this.effects.editFacility$.pipe(take(1));
  }

  private editInformation(form: EditFacilityInformationForm) {
    return toHot(
      this.accessor.activeFacility$.pipe(
        take(1),
        switchMap((f) =>
          this.edit(new EditFacilityInformationAdapter(form, f).model),
        ),
      ),
    );
  }
  private editOvertime(form: EditFacilityOvertimeForm) {
    return toHot(
      this.accessor.activeFacility$.pipe(
        take(1),
        switchMap((f) =>
          this.edit(new EditFacilityOvertimeAdapter(form, f).model),
        ),
      ),
    );
  }

  private editInstashyfts(form: EditFacilityInstashyftsForm) {
    return toHot(
      this.accessor.activeFacility$.pipe(
        take(1),
        switchMap((f) =>
          this.edit(new EditFacilityInstashyftsAdapter(form, f).model),
        ),
      ),
    );
  }

  private editRateLimits(form: ShiftRateDefaultsForm) {
    return toHot(
      this.accessor.activeFacility$.pipe(
        take(1),
        switchMap((f) =>
          this.edit(new ShiftRateDefaultsAdapter(form, f).model),
        ),
      ),
    );
  }

  editShiftTemplate(id: string, form: EditShiftTemplateForm) {
    asapScheduler.schedule(() =>
      this.store.dispatch(
        FacilitiesActions.editShiftTemplate({ id, dto: new EditShiftTemplateAdapter(form).dto }),
      ),
    );
    return this.effects.editShiftTemplate$.pipe(take(1));
  }

  private requestChange(form: RequestChangeForm) {
    asapScheduler.schedule(() =>
      this.store.dispatch(
        FacilitiesActions.requestChange({ dto: new RequestChangeAdapter(form).dto }),
      ),
    );
    return this.effects.requestChange$.pipe(take(1));
  }

  private uploadAvatar(file: File) {
    asapScheduler.schedule(() => this.store.dispatch(FacilitiesActions.uploadAvatar({ file })));
    return this.effects.uploadAvatar$.pipe(take(1));
  }
}
