import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EffectsModule as EM } from "@ngrx/effects";

import { AuthEffects } from './auth.effects';
import { UserEffects } from './user.effects';

@NgModule({
    declarations: [],
    imports: [
        CommonModule,
        EM.forRoot([AuthEffects, UserEffects])
    ],
    providers: [AuthEffects, UserEffects]
})
export class EffectsModule { }
