
import { createReducer, on } from '@ngrx/store';
import { ServicedStatesActions } from '../actions/serviced-states/serviced-states-action-types';

import { ServicedStatesState } from '../interfaces';

export const initialServicedStatesState: ServicedStatesState = Object.freeze<ServicedStatesState>({
    active: undefined,
    models: {}
});

export const servicedStatesReducer = createReducer(
    initialServicedStatesState,
    on(ServicedStatesActions.fetchStateSuccess, (state, { model }) => {
        let sortedLicenses = JSON.parse(JSON.stringify(model.servicedLicenses));
        sortedLicenses.sort((a, b) => a.name.localeCompare(b.name))

        return ({
        ...state,
        models: { ...state.models, [model.state]: {...model, servicedLicenses: sortedLicenses} }
    })}),
    on(ServicedStatesActions.setActiveState, (state, { state: s }) => ({
        ...state,
        active: s
    }))
);
