import * as clone from 'clone';

import { FacilityModel } from "src/app/models/facility";
import { License } from 'src/app/types/license';

import { ShiftRateDefaultsForm } from "../types/forms";

export class ShiftRateDefaultsAdapter
{
    private _form: ShiftRateDefaultsForm;
    private _facility: FacilityModel;

    private _model: FacilityModel;

    constructor(form: ShiftRateDefaultsForm, facility: FacilityModel)
    {
        this._form = clone(form);
        this._facility = clone(facility);
    }

    private _adapt()
    {
        return {
            ...this._facility,
            rateLimits: {
                weekday: {
                    ...this._facility.rateLimits.weekday,
                    ...(
                        Object.keys(this._form)
                        .reduce((p, c: License) => ({
                            ...p,
                            [c]: {
                                min: this._form[c].weekday.min,
                                max: this._form[c].weekday.max
                            }
                        }), { })
                    )
                },
                weekend: {
                    ...this._facility.rateLimits.weekend,
                    ...(
                        Object.keys(this._form)
                        .reduce((p, c: License) => ({
                            ...p,
                            [c]: {
                                min: this._form[c].weekend.min,
                                max: this._form[c].weekend.max
                            }
                        }), { })
                    )
                }
            },
            defaultRateLimits: {
                weekday: {
                    ...this._facility.defaultRateLimits.weekday,
                    ...(
                        Object.keys(this._form)
                        .reduce((p, c: License) => ({ ...p, [c]: this._form[c].weekday.default }), { })
                    )
                },
                weekend: {
                    ...this._facility.defaultRateLimits.weekend,
                    ...(
                        Object.keys(this._form)
                        .reduce((p, c: License) => ({ ...p, [c]: this._form[c].weekend.default }), { })
                    )
                }
            }
        } as FacilityModel;
    }

    get model()
    {
        if(!this._model)
            this._model = this._adapt();

        return this._model;
    }
}