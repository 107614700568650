<div class="atdm-block" [formGroup]="reasonsForm">
    <div class="atdm-header">
        <span class="title">Why are you DNRing {{nurse.name.first}}?</span>
        <span class="text">
            Please select reasons why you want to DNR <span class="atdm-name">{{ nurse.name.first }} </span> at <span class="atdm-name">{{facility.name}}</span>. We’ll let them know they were DNR’d and include the reasons.
        </span>
    </div>
    <div class="atdm-content">
        <div class="reasons">
            <div class="reason" [ngClass]="{active: reasonsForm.get('reasons').get('Attendance & punctuality').value}" (click)="onFormItemClick('Attendance & punctuality')">
                <div class="reason-icon">
                    <img src="../../../../../../assets/img/remove_from_trusted_reasons/Attendance-icon.svg" alt="">
                </div>
                <div class="reason-text">
                    <span class="reason-name">Attendance & punctuality</span>
                    <span class="reason-label">Refusing assignment, no call no show, arriving late, excessive callouts, etc.</span>
                </div>
            </div>
            <div class="reason" [ngClass]="{active: reasonsForm.get('reasons').get('Clinical competency').value}" (click)="onFormItemClick('Clinical competency')">
                <div class="reason-icon">
                    <img src="../../../../../../assets/img/remove_from_trusted_reasons/Clinical-competency.svg" alt="">
                </div>
                <div class="reason-text">
                    <span class="reason-name">Clinical competency</span>
                    <span class="reason-label">Abandoning mid-assignment, documentation error, abuse, medication error, etc.</span>
                </div>
            </div>
            <div class="reason" [ngClass]="{active: reasonsForm.get('reasons').get('Attitude & cooperation').value}" (click)="onFormItemClick('Attitude & cooperation')">
                <div class="reason-icon">
                    <img src="../../../../../../assets/img/remove_from_trusted_reasons/Attitude.svg" alt="">
                </div>
                <div class="reason-text">
                    <span class="reason-name">Attitude & cooperation</span>
                    <span class="reason-label">Unprofessional behavior, being on the phone, sleeping, etc.</span>
                </div>
            </div>
            <div class="reason" [ngClass]="{active: reasonsForm.get('reasons').get('Facility preference').value}" (click)="onFormItemClick('Facility preference')">
                <div class="reason-icon">
                    <img src="../../../../../../assets/img/remove_from_trusted_reasons/Facility-preference.svg" alt="">
                </div>
                <div class="reason-text">
                    <span class="reason-name">Facility preference</span>
                    <span class="reason-label">Conflict of interest, contractual agreement, reason not provided.</span>
                </div>
            </div>
        </div>
        <div class="radio-block" *ngIf="showRadio">
            <div class="radio-row">
                <span class="radio-text">
                    Did this occur during a shift scheduled through ESHYFT?
                </span>
                <div class="radio-group">
                    <div class="radio" (click)="setRadioValue('duringEshyft', true)">
                        <input type="radio" formControlName="duringEshyft" [value]="true">
                        <span class="radio-label">Yes</span>
                    </div>
                    <div class="radio" (click)="setRadioValue('duringEshyft', false)">
                        <input type="radio" formControlName="duringEshyft" [value]="false">
                        <span class="radio-label">No</span>
                    </div>
                </div>
            </div>
            <div class="radio-row">
                <span class="radio-text">
                    Are you reporting this incident to the State?
                </span>
                <div class="radio-group">
                    <div class="radio" (click)="setRadioValue('reportingToState', true)">
                        <input type="radio" formControlName="reportingToState" [value]="true">
                        <span class="radio-label">Yes</span>
                    </div>
                    <div class="radio" (click)="setRadioValue('reportingToState', false)">
                        <input type="radio" formControlName="reportingToState" [value]="false">
                        <span class="radio-label">No</span>
                    </div>
                </div>
            </div>
        </div>
        <div class="input-block" >  
            <span class="label">Please provide an explanation for the DNR <span class="optional">(optional)</span></span>
            <span class="label italic">Details will not be shared with {{nurse.name.first}}.</span>
            <!-- to do: change to input component -->
            <input type="text" formControlName="details" [ngClass]="{error: (!reasonsForm.get('details').valid && reasonsForm.get('details').dirty)}">
            <span class="error" *ngIf="!reasonsForm.get('details').valid && reasonsForm.get('details').dirty">Details must be at least 10 and at most 500 characters</span>
        </div>
        <div class="upcoming-shifts-block" *ngIf="upcomingShifts || upcomingShiftsInSelectedFacility">
            <mat-icon svgIcon="warning"></mat-icon>
            <span *ngIf="facilities.length > 1">This nurse has shifts in applied/confirmed status at one or more of the selected facilities if you DNR this nurse they will be removed from all the shifts from those facilities.</span>
            <span *ngIf="facilities.length === 1">This nurse has shifts in applied/confirmed status at your facility. If you DNR them, they will be removed from all shifts.</span>
        </div>
        <app-facility-search-select
            [label]="'Select more facilities to DNR the nurse from'"
            [results]="facilities"
            [formGroup]="reasonsForm"
            [controlName]="'facilities'"
            [selectedFacilityId]="facility._id"
            [alreadyDnrFacilities]="alreadyDnrFacilities"
            optionKey="_id"
            optionResult="_id"
            *ngIf="facilities.length > 1"
        ></app-facility-search-select>
        <div class="checkbox-block">
            <input type="checkbox" formControlName="dnrAcknow">
            <span class="checkbox-label">I understand that if details are not provided, the DNR will be set, but no further action will be taken by ESHYFT.</span>
        </div>
    </div>
    <div class="atdm-footer">
        <div class="buttons-block">
            <button fill="outline" color="border-base" (click)="onCloseModal();">Cancel</button>
            <button color="danger" (click)="onDnrClick()" [ngClass]="{disabled: !reasonsForm.valid}"  [disabled]="!reasonsForm.valid">DNR nurse</button>
        </div>
    </div>

</div>
