import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { take } from 'rxjs/operators';
import { FacilityModel } from 'src/app/models/facility';
import { MixpanelService } from 'src/app/services/mixpanel.service';
import { FacilitiesAccessor } from '../../../services/accessors/facilities.accessor';
import { ChildToParentDataService } from '../../../services/child-to-parent-data.service';
import { IntercomService } from '../../../services/intercom.service';
import { UserAccessor } from 'src/app/services/accessors/user.accessor';
import { UserModel } from 'src/app/models/user';
import { PopupService } from '../../../services/ui/popup.service';
import { CreateShiftPopupComponent } from './create-shift-popup/create-shift-popup.component';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit, OnDestroy {
  @Input() title: string;
  @Input() buttonType: string;
  @Input() createShift: boolean = false;
  @Input() showInstaShyft: boolean = false;

  facility: FacilityModel;
  user: UserModel;
  facilitySubscription: Subscription = new Subscription();
  constructor(
    private childDataService: ChildToParentDataService, 
    private mixpanel: MixpanelService, 
    private router: Router, 
    private facilitiesAccessor: FacilitiesAccessor, 
    private userAccessor: UserAccessor, 
    private intercomService: IntercomService,
    private popupService: PopupService) {}

  ngOnInit() {
    this.facilitySubscription.add(
      this.facilitiesAccessor.activeFacility$.subscribe((f) => {
        this.facility = f;
      }),
    );
    this.userAccessor.user$.pipe(take(1)).subscribe(u => this.user = u);
    this.childDataService.data$.subscribe();
    
  }
  onMenuClick() {
    this.childDataService.changeData(true);
  }
  onCreateClick(){
    this.mixpanel.for('fm').track('CREATE_SHIFT_CLICK');
    
    this.popupService.openPopup(CreateShiftPopupComponent, { facility: this.facility });
    
    // this.router.navigate(['/manager/shifts/new']);
  } 

  openIntercom() {
    this.mixpanel.for('fm').track('OPEN_INTERCOM_FM_WEB');
    this.intercomService.displayMessenger();
  }
  ngOnDestroy() {
    this.facilitySubscription.unsubscribe();
  }
}
