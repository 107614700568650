import { Observable } from "rxjs";
import { map } from "rxjs/operators";

import { ChatBindings, ChatWithBindings } from "../../../bindings";

import { ChatsAccessor } from "../../accessors/chats.accessor";
import { collection } from "src/app/helpers/rx-operators";

type Options = { type?: 'array' | 'record', classes?: boolean };

export class ChatsBinder
{
    constructor(
        private accessor: ChatsAccessor,
        private bindings: ChatBindings,
    )
    { }

    get chats()
    {
        return this.accessor.chats$
            .pipe(
                map(chats =>{
                    return Object.values(chats)
                    .filter(s => s != null)
                    .map(s => s && new ChatWithBindings(s, this.bindings))}
                )
            );
    }
    get pastChats()
    {
        return this.accessor.pastChats$
            .pipe(
                map(chats =>{
                    return Object.values(chats)
                    .filter(s => s != null)
                    .map(s => s && new ChatWithBindings(s, this.bindings))}
                )
            );
    }

}
