import { DateTime } from 'luxon';
import { Injectable, ViewContainerRef } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';

import { AppService } from 'src/app/services/app.service';
import { ChatMessageModel } from 'src/app/models/chat';
import { environment } from 'src/environments/environment';
import { MessagesService } from './messages.service';
import { SocketService } from '../../../services/socket/socket.service';
import { ShiftsFacade } from './facades/shifts/shifts.facade';
import { State } from 'src/app/state/interfaces';
import { NotificationsFacade } from './facades/notifications/notifications.facade';
import { ShiftsAccessor } from './accessors/shifts.accessor';
import { filter, take } from 'rxjs/operators';
import { FacilitiesAccessor } from './accessors/facilities.accessor';
import { TimecardsFacade } from './facades/timecards/timecards.facade';
import { ModalService } from './ui/modal.service';
import { AuthFacade } from 'src/app/services/facades/auth/auth.facade';
import { ChatsFacade } from './facades/chats/chats.facade';
import { FacilitiesFacade } from './facades/facilities/facilities.facade';

type NewMessage = { facility: string; message: ChatMessageModel };

@Injectable()
export class SseService {
  constructor(
    app: AppService,
    private socket: SocketService,
    private store: Store<State>,
    private messages: MessagesService,
    private router: Router,
    private shiftsFacade: ShiftsFacade,
    private notificationsFacade: NotificationsFacade,
    private shiftsAccessor: ShiftsAccessor,
    private facilitiesAccessor: FacilitiesAccessor,
    private timecardsFacade: TimecardsFacade,
    private modalService: ModalService,
    private authFacade: AuthFacade,
    private chatsFacade: ChatsFacade,
    private facilitiesFacade: FacilitiesFacade
  ) {
    app.expose('facades', 'sse', this);
  }

  register() {
    const chats = environment.sse.chat;
    const shifts = environment.sse.shifts;
    const timecards = environment.sse.timecards;
    const user = environment.sse.user;
    const facility = environment.sse.facility;

    this.socket.on(chats.CHAT_NEW_MESSAGE, (payload: NewMessage) => {
      console.log(chats.CHAT_NEW_MESSAGE, payload);
      this.notificationsFacade.increaseChatsUnreadMessagesCount({ inc: 1 });
      this.chatsFacade.fetchChat(payload.message.thread);
      this.chatsFacade.fetchMessages(payload.message.thread);
      // this.store.dispatch(sendMessageSuccess({ message: payload.message }));
      // this.messages.add('New chat message', 'action', () => {
      //   this.router.navigate(['manager/chat'], {
      //     state: { threadId: payload.message.thread },
      //   });
      // });
    });
    this.socket.on(shifts.SHIFTS_3D_SINCE_APPLICATION_MANAGER, (payload) => {
      console.log(shifts.SHIFTS_3D_SINCE_APPLICATION_MANAGER, payload);


      this.shiftsFacade.addReminderIntoQueue(
        this.generateReminderObj('SHIFTS_3D_SINCE_APPLICATION_MANAGER', payload)
      );
    });
    this.socket.on(shifts.SHIFTS_7D_SINCE_APPLICATION_MANAGER, (payload) => {
      console.log(shifts.SHIFTS_7D_SINCE_APPLICATION_MANAGER, payload);
      this.shiftsFacade.addReminderIntoQueue(
        this.generateReminderObj('SHIFTS_7D_SINCE_APPLICATION_MANAGER', payload)
      );
    });
    this.socket.on(shifts.SHIFTS_14D_SINCE_APPLICATION_MANAGER, (payload) => {
      console.log(shifts.SHIFTS_14D_SINCE_APPLICATION_MANAGER, payload);
      this.shiftsFacade.addReminderIntoQueue(
        this.generateReminderObj('SHIFTS_14D_SINCE_APPLICATION_MANAGER', payload)
      );
    });

    this.socket.on(shifts.SHIFTS_NEW_APPLICANT, (payload) => {
      console.log(shifts.SHIFTS_NEW_APPLICANT, payload);
      this.shiftsFacade.fetchShift(payload.shifts._id);
      this.notificationsFacade.increaseShiftsUpdatedCount(
        payload.shifts._id,
        'applied',
      );
      this.facilitiesAccessor.activeFacility$
        .pipe(
          filter((f) => !!f),
          take(1),
        )
        .subscribe((f) => {
          if (f) {
            let date = this.getFormatedDate(
              payload.shifts.shiftData.shiftFrom,
              f.timezone,
            ); //example - October 13th 2021
            let time =
              DateTime.fromISO(payload.shifts.shiftData.shiftFrom, {
                zone: f.timezone,
              }).toFormat('h:mma') +
              ' - ' +
              DateTime.fromISO(payload.shifts.shiftData.shiftTo, {
                zone: f.timezone,
              }).toFormat('h:mma');
            this.messages.add(
              'Applicant ' +
                payload.applicant.fullName +
                ' has applied to your shift on ' +
                date +
                ' at ' +
                time +
                '.',
              '',
              'info',
              () => {
                this.router.navigate(['/manager/facility-selection']).then(() => {
                  this.router.navigate(['manager/shifts/applicants'], {
                    state: { id: payload.shifts._id },
                  });
                });
              },
              true,
            );
          }
        });
    });
    this.socket.on(shifts.SHIFT_APPLICANT_CALLED_OUT, (payload) => {
      console.log(shifts.SHIFT_APPLICANT_CALLED_OUT, payload);
      this.shiftsFacade.fetchShift(payload.shifts._id);
      this.notificationsFacade.increaseShiftsUpdatedCount(
        payload.shifts._id,
        'calledOut',
      );
      this.facilitiesAccessor.activeFacility$
        .pipe(
          filter((f) => !!f),
          take(1),
        )
        .subscribe((f) => {
          if (f) {
            let date = this.getFormatedDate(
              payload.shifts.shiftData.shiftFrom,
              f.timezone,
            ); //example - October 13th 2021

            let time =
              DateTime.fromISO(payload.shifts.shiftData.shiftFrom, {
                zone: f.timezone,
              }).toFormat('h:mma') +
              ' - ' +
              DateTime.fromISO(payload.shifts.shiftData.shiftTo, {
                zone: f.timezone,
              }).toFormat('h:mma');
            this.messages.add(
              'Uh-oh, applicant called out of their shift on ' +
                date +
                ' at ' +
                time +
                '.',
              '',
              'warning',
              () => {
                this.router.navigate(['/manager/facility-selection']).then(() => {
                  this.router.navigate(['manager/shifts/applicants'], {
                    state: { id: payload.shifts._id },
                  });
                });
              },
              true,
            );
          }
        });
    });
    this.socket.on(shifts.SHIFT_APPLICANT_WITHDREW, (payload) => {
      console.log(shifts.SHIFT_APPLICANT_WITHDREW, payload);
      //this.notificationsFacade.increaseShiftsUpdatedCount({id: payload.shifts._id});
      this.shiftsFacade.fetchShift(payload.shifts._id);
      this.facilitiesAccessor.activeFacility$
        .pipe(
          filter((f) => !!f),
          take(1),
        )
        .subscribe((f) => {
          if (f) {
            let date = this.getFormatedDate(
              payload.shifts.shiftData.shiftFrom,
              f.timezone,
            ); //example - October 13th 2021

            let time =
              DateTime.fromISO(payload.shifts.shiftData.shiftFrom, {
                zone: f.timezone,
              }).toFormat('h:mma') +
              ' - ' +
              DateTime.fromISO(payload.shifts.shiftData.shiftTo, {
                zone: f.timezone,
              }).toFormat('h:mma');

            this.messages.add(
              'Applicant ' +
                payload.applicant.fullName +
                ' withdrew their application from your shift on ' +
                date +
                ' at ' +
                time +
                '.',
              '',
              'warning',
              () => {
                this.router.navigate(['/manager/facility-selection']).then(() => {
                  this.router.navigate(['manager/shifts/applicants'], {
                    state: { id: payload.shifts._id },
                  });
                });
              },
              true,
            );
          }
        });
      console.log('payload,', payload);
    });
    this.socket.on(shifts.SHIFTS_INSTASHYFT_TAKEN, (payload) => {
      console.log(shifts.SHIFTS_INSTASHYFT_TAKEN, payload);
      this.shiftsFacade.fetchShift(payload.shifts);
    });
    this.socket.on(timecards.TIMECARD_UPDATED, (payload) => {
      console.log(timecards.TIMECARD_UPDATED, payload);
      this.timecardsFacade.fetchTimecard(payload.timecard._id);
    });
    this.socket.on(timecards.TIMECARD_CREATED, (payload) =>
      console.log(timecards.TIMECARD_CREATED, payload),
    );
    this.socket.on(shifts.SHIFTS_APPLICANT_DISABLED, (payload) => {
      console.log(shifts.SHIFTS_APPLICANT_DISABLED, payload);
      payload.shifts.forEach((s) => {
        this.shiftsFacade.updateApplicantStatusToSuspended(
          payload.applicant._id,
          s._id,
        );
        const date = this.getFormatedDate(
          s.shiftData.shiftFrom,
          s.facility.timezone,
        ); //example - October 13th 2021

        const time =
          DateTime.fromISO(s.shiftData.shiftFrom, {
            zone: s.facility.timezone,
          }).toFormat('h:mma') +
          ' - ' +
          DateTime.fromISO(s.shiftData.shiftTo, {
            zone: s.facility.timezone,
          }).toFormat('h:mma');
        if (s.status === 'confirmed') {
          this.messages.add(
            payload.applicant.name.first +
              payload.applicant.name.last +
              ', ' +
              s.license +
              ' has been suspended. They’ve been cancelled for their shift at ' +
              s.facility.name +
              ' on  ' +
              date +
              ' at ' +
              time,

            '',
            'warning',
            () => {
              this.router.navigate(['/manager/facility-selection']).then(() => {
                  this.router.navigate(['manager/shifts/applicants'], {
                    state: { id: s._id },
                  });
                });
            },
            true,
          );
        }
      });
    });
    this.socket.on(shifts.SHIFTS_OVERTIME_CHANGED, (payload) => {
      this.facilitiesAccessor.activeFacility$
        .pipe(
          filter((f) => !!f),
          take(1),
        )
        .subscribe((f) => {
          if (f._id === payload.facility) {
            this.shiftsAccessor.shifts$.pipe(take(1)).subscribe((shifts) => {
              let shiftsIds = [];
              payload.shifts.forEach((s) => {
                if (shifts[s._id]) {
                  shiftsIds.push(s._id);
                }
              });
              this.fetchShiftsOneByOne(shiftsIds, 1);
            });
          }
        });
      console.log(shifts.SHIFTS_OVERTIME_CHANGED, payload);
    });
    this.socket.on(user.SERVICE_USER_DISABLED, (payload) => {
      this.modalService
        .openModal(
          'Your account has been suspended',
          'Your access to ESHYFT has been suspended. Please contact us at support@eshyft.com to restore it.',
          'Okay',
          null,
          'cna',
          'submit',
        )
        .pipe(take(1))
        .pipe(take(1))
        .subscribe((r) => {
          this.authFacade.logout().pipe(take(1)).subscribe();
        });
    });
    this.socket.on(facility.FACILITY_DEACTIVATION_STATUS_UPDATED, (payload) => {
      console.log('FACILITY_DEACTIVATION_STATUS_UPDATED',payload);
      if(payload.facilityModel.deactivationStatus === 'Deactivated'){
        this.authFacade.logout().pipe(take(1)).subscribe();
        this.messages.add('The facility has been deactivated. If you have any questions please, please contact support.', '', 'danger', ()=>{
          window.location.href = 'mailto:support@eshyft.com';
        }, true, 'Contact support');
      }else{
        this.facilitiesFacade.fetchFacilityById(payload.facility).pipe(take(1)).subscribe(r=>{
          let url = this.router.url;
          this.router.navigate(['/manager/facility-selection']).then(() => {
            this.router.navigate([url]);
          });
        })
      }
    });
    console.log('SSE Registered!');
  }


  fetchShiftsOneByOne(shiftsIds, i) {
    if (shiftsIds.length < i) {
      return;
    }
    this.shiftsFacade
      .fetchShift(shiftsIds[i - 1])
      .pipe(take(1))
      .subscribe((shiftData) => {
        this.fetchShiftsOneByOne(shiftsIds, i + 1);
      });
  }
  getNumberSuffix(num) {
    const th = 'th';
    const rd = 'rd';
    const nd = 'nd';
    const st = 'st';

    if (num === 11 || num === 12 || num === 13) return th;

    let lastDigit = num.toString().slice(-1);

    switch (lastDigit) {
      case '1':
        return st;
      case '2':
        return nd;
      case '3':
        return rd;
      default:
        return th;
    }
  }
  getFormatedDate(date, timezone) {
    return (
      DateTime.fromISO(date, {
        zone: timezone,
      }).toFormat('LLLL d') +
      this.getNumberSuffix(
        DateTime.fromISO(date, {
          zone: timezone,
        }).toFormat('d'),
      ) +
      ' ' +
      DateTime.fromISO(date, {
        zone: timezone,
      }).toFormat('yyyy', { timeZone: timezone })
    );
  }
  generateReminderObj(event, payload){
    const timeText = {
      'SHIFTS_3D_SINCE_APPLICATION_MANAGER': '72 hours',
      'SHIFTS_7D_SINCE_APPLICATION_MANAGER': '7 days',
      'SHIFTS_14D_SINCE_APPLICATION_MANAGER': '14 days',
    }
    let date = DateTime.fromISO(payload.shifts.shiftData.shiftFrom, {
      zone: payload.shifts.facility.timezone,
    }).toFormat('LL/dd/yyyy')
    let time =
      DateTime.fromISO(payload.shifts.shiftData.shiftFrom, {
        zone: payload.shifts.facility.timezone,
      }).toFormat('h:mma') +
      ' - ' +
      DateTime.fromISO(payload.shifts.shiftData.shiftTo, {
        zone: payload.shifts.facility.timezone,
      }).toFormat('h:mma');
    const reminderObj = {
      facility: payload.facility,
      applicant: payload.applicant,
      shift: payload.shifts,
      text: 'It’s been ' + timeText[event] + ' since ' + payload.applicant.user.name.first + ' applied to your ' + payload.shifts.license + ' shift on ' +
      date + ' at ' + time +
      '. Please confirm or decline this applicant.',
    };
    return reminderObj
  }
}
