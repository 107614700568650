import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';

import { selectMyFacilities, selectActiveFacility, selectFacility } from 'src/app/modules/manager/state/selectors/facilities';
import { State } from 'src/app/state/interfaces';

@Injectable({ providedIn: 'root' })
export class FacilitiesAccessor
{
    constructor(private store: Store<State>) { }

    get activeFacility$() { return this.store.select(selectActiveFacility); }

    get myFacilities$() { return this.store.select(selectMyFacilities); }
    facility$(id: string) {
        return this.store.select(selectFacility, { id });
    }
}
