import { ChatsState, CHATS_FEATURE_KEY } from "./chats";
import { DnrState, DNR_FEATURE_KEY } from "./dnr";
import { FacilitiesState, FACILITIES_FEATURE_KEY } from "./facilities";
import { FiltersState, FILTERS_FEATURE_KEY } from "./filters";
import { HolidaysState, HOLIDAYS_FEATURE_KEY } from "./holidays";
import { NotificationsState, NOTIFICATIONS_FEATURE_KEY} from './notifications';
import { NURSES_FEATURE_KEY, NursesState } from "./nurses";
import { RemindersState, REMINDERS_FEATURE_KEY } from "./reminders";
import { ServicedStatesState, SERVICED_STATES_FEATURE_KEY } from "./serviced-states";
import { ShiftsState, SHIFTS_FEATURE_KEY } from "./shifts";
import { TimecardsState, TIMECARDS_FEATURE_KEY } from "./timecards";


export interface ManagerState
{
    [CHATS_FEATURE_KEY]: ChatsState;
    [FACILITIES_FEATURE_KEY]: FacilitiesState;
    [HOLIDAYS_FEATURE_KEY]: HolidaysState;
    [SERVICED_STATES_FEATURE_KEY]: ServicedStatesState;
    [SHIFTS_FEATURE_KEY]: ShiftsState;
    [TIMECARDS_FEATURE_KEY]: TimecardsState;
    [NOTIFICATIONS_FEATURE_KEY]: NotificationsState;
    [FILTERS_FEATURE_KEY]: FiltersState;
    [REMINDERS_FEATURE_KEY]: RemindersState;
    [DNR_FEATURE_KEY]: DnrState;
    [NURSES_FEATURE_KEY]: NursesState;
}

export const MANAGER_FEATURE_KEY = 'manager';
