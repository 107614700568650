import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';

import { of } from 'rxjs';
import {
  catchError,
  exhaustMap,
  filter,
  map,
  share,
  switchMap,
  take,
  tap,
} from 'rxjs/operators';
import { FacilitiesAccessor } from '../../services/accessors/facilities.accessor';

import { NursesActions } from '../actions/nurses/nurses-action-types';
import { FacilitiesService } from '../../services/api/facilities.service';
import { clamp } from 'src/app/helpers/math';



@Injectable({ providedIn: 'root' })
export class NursesEffects {
  constructor(
    private actions$: Actions,
    private api: FacilitiesService,
    private facilitiesAccessor: FacilitiesAccessor
  ) { }

  fetchNurses$ = createEffect(() =>
    this.actions$.pipe(
      ofType(NursesActions.fetchNurses),
      exhaustMap(({ sort, filters, limit, page }) =>
        this.facilitiesAccessor.activeFacility$.pipe(
          filter((v) => !!v),
          take(1),
          switchMap((f) =>
            this.api.fetchFacilityNurses(
              f._id,
              sort,
              filters,
              { limit, skip: clamp(page, 0, Number.MAX_SAFE_INTEGER) * limit }
            ).pipe(
              map(({ models, totalCount }) => {
                return NursesActions.fetchNursesSuccess({ models, totalCount });
              }),
              catchError((e) => of(NursesActions.fetchNursesFailed({ error: e }))),
            ),
          ),
        ),
      ),
      share(),
    ),
  );
  fetchNurse$ = createEffect(() =>
    this.actions$.pipe(
      ofType(NursesActions.fetchNurse),
      exhaustMap(({ nurseId }) =>
        this.facilitiesAccessor.activeFacility$.pipe(
          filter((v) => !!v),
          take(1),
          switchMap((f) =>
            this.api.fetchFacilityNurse(
              f._id,
              nurseId
            ).pipe(
              map(({ model }) => {
                return NursesActions.fetchNurseSuccess({ model });
              }),
              catchError((e) => of(NursesActions.fetchNurseFailed({ error: e }))),
            ),
          ),
        ),
      ),
      share(),
    ),
  );
  markAsTrusted$ = createEffect(() =>
    this.actions$.pipe(
      ofType(NursesActions.markAsTrusted),
      exhaustMap(({ facilityId, nurseId }) =>
        this.facilitiesAccessor.activeFacility$.pipe(
          filter((v) => !!v),
          take(1),
          switchMap((f) =>
            this.api.changeFacilityNurseTrustedStatus(
              facilityId, nurseId, true
            ).pipe(
              map(({ }) => {
                return NursesActions.markAsTrustedSuccess({nurseId});
              }),
              catchError((e) => of(NursesActions.markAsTrustedFailed({ error: e }))),
            ),
          ),
        ),
      ),
      share(),
    ),
  );
  removeFromTrusted$ = createEffect(() =>
    this.actions$.pipe(
      ofType(NursesActions.removeFromTrusted),
      exhaustMap(({ facilityId, nurseId, brokenTrustReasons }) =>
        this.facilitiesAccessor.activeFacility$.pipe(
          filter((v) => !!v),
          take(1),
          switchMap((f) =>
            this.api.changeFacilityNurseTrustedStatus(
              facilityId, nurseId, false, brokenTrustReasons
            ).pipe(
              map(({ }) => {
                return NursesActions.removeFromTrustedSuccess({nurseId});
              }),
              catchError((e) => of(NursesActions.removeFromTrustedFailed({ error: e }))),
            ),
          ),
        ),
      ),
      share(),
    ),
  );
}
