import { throwError } from "rxjs";

import { toHot } from "src/app/helpers/to-hot";
import { Shift, ShiftModel } from "src/app/models/shift";

import { EditShiftForm } from "../../types/forms";

import { ApplicantWithBindings } from "./applicant/applicant-with-bindings";
import { ApplicantBindings } from "./applicant/bindings";
import { ShiftBindings } from "./bindings";

export class ShiftWithBindings extends Shift
{
    private _bindings: ShiftBindings;

    constructor(model: ShiftModel, bindings: ShiftBindings, applicantBindings: ApplicantBindings)
    {
        super(model);

        this._bindings = bindings;

        this._applicantsObjects = this.applicants.map(a => new ApplicantWithBindings(a, this._id, applicantBindings));
    }

    get bindings() { return this._bindings; }
    
    getApplicantsObjects(){
      return this._applicantsObjects;
    }
    getApplicantObjectById(id: string){
        return this._applicantsObjects.find(a=>{return a.model.user._id === id});
    }
    delete()
    {
        if(!this.bindings.delete) return throwError(new Error('Delete method is not bound!'));

        return toHot(this.bindings.delete(this._id));
    }

    edit(form: EditShiftForm)
    {
        if(!this.bindings.edit) return throwError(new Error('Edit method is not bound!'));

        return toHot(this.bindings.edit(this._id, form));
    }
}
