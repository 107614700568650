import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { FacilityWithBindings } from '../../../bindings';
import { FacilitiesFacade } from '../../../services/facades/facilities/facilities.facade';
import { Subscription } from 'rxjs';
import { DateTime } from 'luxon';
import { MixpanelService } from 'src/app/services/mixpanel.service';

@Component({
  selector: 'app-shift-time-modal',
  templateUrl: './shift-time-modal.component.html',
  styleUrls: ['./shift-time-modal.component.scss']
})
export class ShiftTimeModalComponent implements OnInit {

  @Input() timezone: string;
  @Output() onClose = new EventEmitter();
  @Output() onSave = new EventEmitter();

  newShiftForm = this.fb.group({
    clockInTime: [5, Validators.compose([Validators.required, Validators.min(0), Validators.max(120), Validators.pattern(/^(?:0|[1-9][0-9]?|1[01][0-9]|120)$/)])],
    endTime: ['2:00 pm', Validators.required],
    startTime: ['6:00 am', Validators.required],
    icon: ['timer'],
    shiftType: ['Custom'],
    shiftName: ['New shift time'],
    isTemp: [true],
    id: ['CustomId' + Math.floor(Date.now() * Math.random())]
  }, { validators: this.shiftTimeRangeValidator.bind(this)});

  facility: FacilityWithBindings;
  facilitySubscription: Subscription = new Subscription();
  facility$ = this.facilitiesFacade.binder.activeFacility$;
  constructor(private fb: FormBuilder, private facilitiesFacade: FacilitiesFacade, private mixpanel: MixpanelService,) { }

  ngOnInit(): void {
    this.facilitySubscription.add(
      this.facility$.subscribe((f) => {
        this.facility = f;
      }),
    );
  }

  cancel() {
    this.onClose.emit(false);
  }

  ngOnDestroy() {
    this.facilitySubscription.unsubscribe();
  }

  onSaveNewShiftClick() {
    this.mixpanel.for('fm').track('CREATE_SHIFTS_NEW_SHIFT_TIME', {newShiftForm: this.getNewShiftData()});
    this.onSave.emit(this.newShiftForm.value);
  }

  getNewShiftData() {
    let shiftForm = this.newShiftForm.value;
    return {
     shiftStartTime: shiftForm.startTime,
     shiftEndTime: shiftForm.endTime,
     clockInTime: shiftForm.clockInTime
    }
  }

  formattingFormValue(shiftForm) {
    const shiftValue = shiftForm;
    const from = DateTime.fromFormat(shiftValue.startTime, 'h:mm a');
    const to = DateTime.fromFormat(shiftValue.endTime, 'h:mm a');
    if (from.isValid && to.isValid) {
      shiftValue.startTime = from.toFormat('HH:mm');
      shiftValue.endTime = to.toFormat('HH:mm');
    }

    shiftValue.clockInTime = parseInt(shiftValue.clockInTime);
    return shiftValue;
  }
  
  shiftTimeRangeValidator(form: UntypedFormGroup)
  {
    const shiftValue = this.formattingFormValue(form.value);
    const startTime = shiftValue.startTime;
    const endTime = shiftValue.endTime;

    const todayFormatted = DateTime.fromObject({
      zone: this.facility?.timezone,
    }).toFormat('yyyy-MM-dd');
    const shiftStartTimestamp = DateTime.fromFormat(
      todayFormatted + ' ' + startTime,
      'yyyy-MM-dd HH:mm',
      { zone: this.facility?.timezone },
    ).valueOf();
    let shiftEnd = DateTime.fromFormat(
      todayFormatted + ' ' + endTime,
      'yyyy-MM-dd HH:mm',
      { zone: this.facility?.timezone },
    );
    let shiftEndTimestamp;
    if (shiftStartTimestamp > shiftEnd.valueOf()) {
      shiftEnd = shiftEnd.plus({ day: 1 });
      
    }
    shiftEndTimestamp = shiftEnd.valueOf();
    if (
      shiftEndTimestamp - shiftStartTimestamp < 7200000 ||
      shiftEndTimestamp - shiftStartTimestamp > 57600000
    ) {
      //7200000 == 2h
      //57600000 == 16h
      form.controls.startTime.setErrors({ shiftTimeRange: true });
      form.controls.endTime.setErrors({ shiftTimeRange: true });
      return  { shiftTimeRange: true };
    }
    else {
      form.controls.startTime.setErrors(null);
      form.controls.endTime.setErrors(null);
      return null;
    }
  }
}
