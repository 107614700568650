import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EffectsModule as EM } from "@ngrx/effects";

import { ChatsEffects } from './chats.effects';
import { FacilitiesEffects } from './facilities.effects';
import { HolidaysEffects } from './holidays.effects';
import { ServicedStatesEffects } from './serviced-states.effects';
import { ShiftsEffects } from './shifts.effects';
import { TimecardsEffects } from './timecards.effects';
import { NotificationsEffects } from './notifications.effects';
import { DnrEffects } from './dnr.effects';
import { NursesEffects } from './nurses.effects';

@NgModule({
    declarations: [],
    imports: [
        CommonModule,
        EM.forFeature([
            ChatsEffects,
            FacilitiesEffects,
            HolidaysEffects,
            ServicedStatesEffects,
            ShiftsEffects,
            TimecardsEffects,
            NotificationsEffects,
            DnrEffects,
            NursesEffects
        ])
    ],
    providers: [
        ChatsEffects,
        
        HolidaysEffects,
        ServicedStatesEffects,
        ShiftsEffects,
        TimecardsEffects,
        NotificationsEffects,
        DnrEffects,
        NursesEffects
    ]
})
export class EffectsModule { }
