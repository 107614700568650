import { LICENSE_ORDERS } from "../constants/licenseTypes";

export function licensesSort(licenses)
{
    return [...licenses].sort((a, b)=>{
        if(LICENSE_ORDERS[a] > LICENSE_ORDERS[b] ){
          return 1
        }
        if(LICENSE_ORDERS[a] < LICENSE_ORDERS[b] ){
          return -1
        }
        return 0;
  
      })
}

export function licensesObjSort(licenses)
{
    return licenses.sort((a, b)=>{
        if(LICENSE_ORDERS[a.id] > LICENSE_ORDERS[b.id] ){
          return 1
        }
        if(LICENSE_ORDERS[a.id] < LICENSE_ORDERS[b.id] ){
          return -1
        }
        return 0;
  
      })
}

