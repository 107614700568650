import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpErrorResponse } from '@angular/common/http';

import { Observable, throwError } from 'rxjs';
import { catchError, switchMap, take, withLatestFrom } from 'rxjs/operators';

import { AuthFacade } from 'src/app/services/facades/auth/auth.facade';

@Injectable({ providedIn: 'root' })
export class AuthInterceptor implements HttpInterceptor
{
    constructor(private auth: AuthFacade) { }

    attachHeaders(req: HttpRequest<any>, token: string = '') : HttpRequest<any>
    {
        return req.clone({ setHeaders: {
            'Authorization': `Bearer ${token}`
        }, withCredentials: true });
    }

    handleUnauthorizedErrorResponse(error: HttpErrorResponse, req: HttpRequest<any>, next: HttpHandler) : Observable<HttpEvent<any>>
    {
        if(req.url.endsWith('/auth/refresh'))
        {
            this.auth.logout();
            return throwError(error);
        }
        else
        {
            return this.auth.refreshToken()
            .pipe(
                withLatestFrom(this.auth.state.token$),
                switchMap(([_, token]) =>
                {
                    req = this.attachHeaders(req, token);
                    return next.handle(req);
                }),
                catchError(e =>
                {
                    if(e instanceof HttpErrorResponse && e.status === 401)
                        this.auth.logout();
                    
                    return throwError(e);
                })
            );
        }
    }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>>
    {
        return this.auth.state.token$
        .pipe(
            take(1),
            switchMap(token =>
            {
                req = this.attachHeaders(req, token);
                return next.handle(req)
                .pipe(
                    catchError(e =>
                    {
                        if(e instanceof HttpErrorResponse && e.status === 401 && token)
                            return this.handleUnauthorizedErrorResponse(e, req, next);
                        else return throwError(e);
                    })
                );
            })
        );
    }
}
