import { SortDTO } from './../../../../../types/dto/sort';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { SortingOptions } from '../../../types/sorting';

@Component({
  selector: 'app-sorting-subheader',
  templateUrl: './sorting-subheader.component.html',
  styleUrls: ['./sorting-subheader.component.scss'],
})
export class SortingSubheaderComponent implements OnInit {
  @Input() sortOptions: Array<SortingOptions>;
  @Input() defaultSortValue: string;
  @Input() timecard: boolean = false;
  @Input() withPadding: boolean = false;

  public active: SortDTO = {
    order: 'asc',
    sort: ''
  };
  sortOptionsText = [
    
  ]
  @Output() submitFilterForm = new EventEmitter();
  constructor() {}

  ngOnInit(): void {
    this.onSortClick(this.defaultSortValue);
    this.sortOptions.forEach((s)=>{
      this.sortOptionsText.push(this.splitText(s.name));
    })
  }
  splitText(text) {
    let words = text.split(" ");
    let lastWord = words.pop();
    let remainingText = words.join(" ");
    return {
      'firstPart': remainingText,
      'secondPart': lastWord
    }
  }
  onSortClick(name) {
    if (!name) {
      return;
    }
    let newActive: SortDTO = {
      order: 'asc',
      sort: ''
    }
    if (this.active.sort !== name) {
      newActive.order = 'asc';
    } else {
      newActive.order = this.active.order === 'asc' ? 'desc' : 'asc';
    }
    newActive.sort = name;
    this.active = newActive;
    this.submitFilterForm.emit(this.active);
  }
}
