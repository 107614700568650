import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';

import { State } from 'src/app/state/interfaces';

import { selectActiveLicenses, selectActiveState, selectLicensesForState, selectState } from '../../state/selectors/serviced-states';

@Injectable()
export class ServicedStatesAccessor
{
    constructor(private store: Store<State>) { }

    get activeState() { return this.store.select(selectActiveState); }

    get activeLicenses() { return this.store.select(selectActiveLicenses); }

    selectState(state: string)
    {
        return this.store.select(selectState, { state });
    }

    selectLicensesForState(state: string)
    {
        return this.store.select(selectLicensesForState, { state });
    }
}
