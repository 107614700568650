import { Component, Input, OnInit, TemplateRef } from '@angular/core';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'app-select-input',
  templateUrl: './select-input.component.html',
  styleUrls: ['./select-input.component.scss']
})
export class SelectInputComponent<T> implements OnInit {
  @Input() values: any[] = [];
  @Input() key: string = '';
  @Input() displayKey: string = '';
  @Input() control: FormControl = new FormControl();
  @Input() disabled: boolean = false;
  @Input() editShift: boolean = false;
  
  @Input() optionTemplateRef: TemplateRef<T>;
  @Input() selectedTemplateRef: TemplateRef<T>;

  constructor() { }

  ngOnInit(): void {
  }
  getValue(item: any): any {
    return item && typeof item === 'object' ? item[this.key] : item;
  }


  compareValues(item1: any, item2: any): boolean {
    const value1 = this.getValue(item1);
    const value2 = this.getValue(item2);
    return value1 === value2;
  }
}
