import { createAction, props, union } from '@ngrx/store';

import { type } from 'src/app/state/actions/helpers/action-type';
import { FetchNursesDTO } from './dto/fetch-nurses';
import { MarkAsTrustedDTO } from './dto/mark-as-trusted';
import { RemoveFromTrustedDTO } from './dto/remove-from-trusted';
import { FetchNurseDTO } from './dto/fetch-nurse';

const t = type('nurses');

export const fetchNurses = createAction(t.pending('fetch_nurses'), props<FetchNursesDTO.Pending>());
export const fetchNursesSuccess = createAction(t.success('fetch_nurses'), props<FetchNursesDTO.Success>());
export const fetchNursesFailed = createAction(t.failed('fetch_nurses'), props<FetchNursesDTO.Failed>());

export const fetchNurse = createAction(t.pending('fetch_nurse'), props<FetchNurseDTO.Pending>());
export const fetchNurseSuccess = createAction(t.success('fetch_nurse'), props<FetchNurseDTO.Success>());
export const fetchNurseFailed = createAction(t.failed('fetch_nurse'), props<FetchNurseDTO.Failed>());

export const markAsTrusted = createAction(t.pending('mark_as_trusted'), props<MarkAsTrustedDTO.Pending>());
export const markAsTrustedSuccess = createAction(t.success('mark_as_trusted'), props<MarkAsTrustedDTO.Success>());
export const markAsTrustedFailed = createAction(t.failed('mark_as_trusted'), props<MarkAsTrustedDTO.Failed>());

export const removeFromTrusted = createAction(t.pending('remove_from_trusted'), props<RemoveFromTrustedDTO.Pending>());
export const removeFromTrustedSuccess = createAction(t.success('remove_from_trusted'), props<RemoveFromTrustedDTO.Success>());
export const removeFromTrustedFailed = createAction(t.failed('remove_from_trusted'), props<RemoveFromTrustedDTO.Failed>());
