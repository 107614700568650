import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { take } from 'rxjs/operators';
import { FacilityModel } from 'src/app/models/facility';
import { NurseModel } from 'src/app/models/nurse';
import { FacilitiesAccessor } from 'src/app/modules/manager/services/accessors/facilities.accessor';
import { NurseService } from 'src/app/modules/manager/services/api/nurse.service';
import { FacilitiesFacade } from 'src/app/modules/manager/services/facades/facilities/facilities.facade';
import { MixpanelService } from 'src/app/services/mixpanel.service';
import { AddToDnr, DNR_REASONS } from 'src/app/types/forms/add-nurse-to-dnr-form';

@Component({
  selector: 'app-add-to-dnr-modal',
  templateUrl: './add-to-dnr-modal.component.html',
  styleUrls: ['./add-to-dnr-modal.component.scss']
})
export class AddToDnrModalComponent implements OnInit {
  @Input() nurse: NurseModel;
  @Input() facility: FacilityModel;
  @Output() onClose = new EventEmitter();
  @Output() addNurseToDnr = new EventEmitter();

  upcomingShifts = false;
  upcomingShiftsInSelectedFacility = false;

  showRadio = false;
  reasonsForm = this.fb.group({
    reasons: new UntypedFormGroup({
      'Attendance & punctuality': new UntypedFormControl(false),
      'Clinical competency': new UntypedFormControl(false),
      'Attitude & cooperation': new UntypedFormControl(false),
      'Facility preference': new UntypedFormControl(false),
    },),
    details: new UntypedFormControl('', [Validators.minLength(10), Validators.maxLength(500)]),
    duringEshyft: new UntypedFormControl('',[Validators.required]),
    reportingToState: new UntypedFormControl('',[Validators.required]),
    dnrAcknow: new UntypedFormControl(false, [Validators.required, Validators.requiredTrue]),
    facilities: new UntypedFormControl([]),
  });
  facilities = [];
  facilitiesWithFutureShifts = {};
  reasons = {
    'Attendance & punctuality': false,
    'Clinical competency': false,
    'Attitude & cooperation': false,
    'Facility preference': false,
  }
  alreadyDnrFacilities: string[] = [
  ]

  private atLeastOneValidator = () => {
    return (controlGroup) => {
      let controls = controlGroup.controls;
      if (controls) {
        let theOne = Object.keys(controls).find(key => controls[key].value);
        if (!theOne) {
          return {
            atLeastOneRequired: {
              text: 'At least one should be selected'
            }
          }
        }
      }
      return null;
    };
  };
  constructor(
    private fb: UntypedFormBuilder,
    private nurseService: NurseService,
    private mixpanel: MixpanelService,
    private facilitiesAccessor: FacilitiesAccessor,
  ) { }

  ngOnInit(): void {
    this.reasonsForm.get('reasons').setValidators([this.atLeastOneValidator()]);
    
    this.mixpanel.for('fm').track('ADD_DNR_OPEN', { nurse: this.nurse });
    this.facilitiesAccessor.myFacilities$.pipe(take(1)).subscribe(f => {
      this.facilities = this.sortByAlphabeticalOrder(Object.values(f));

    });
    this.nurseService.checkUpcomingShiftsInAllFacilities(this.nurse._id).pipe(take(1)).subscribe(r=>{
      
      r.forEach(f=>{
        this.facilitiesWithFutureShifts[f._id] = f;
      });
      if(this.facilitiesWithFutureShifts[this.facility._id]){
        this.upcomingShiftsInSelectedFacility = true;
      }
      console.log('this.facilitiesWithFutureShifts',this.facilitiesWithFutureShifts)
    });
    this.nurseService.fetchAllDnrByUser(this.nurse._id).pipe(take(1)).subscribe(allDnr => {
      let alreadyDnrFacilitiesArr = [];
      allDnr.forEach(dnr => {

        alreadyDnrFacilitiesArr.push(dnr.facility['_id']);
      })
      this.alreadyDnrFacilities = [ ...alreadyDnrFacilitiesArr];
    });
      this.reasonsForm.get('facilities').valueChanges.subscribe(() => {
        let upcomingShifts = false;
        this.reasonsForm.get('facilities').value.forEach(fId=>{
          if(this.facilitiesWithFutureShifts[fId]){
            upcomingShifts = true;
          }
        });
        this.upcomingShifts = upcomingShifts;
      });
    }
  sortByAlphabeticalOrder(facilities){
    return facilities.sort((a, b) => a.name.localeCompare(b.name))
  }


  onDnrClick() {
    let reasons = this.reasonsForm.get('reasons').value;
    let reasonsArr = [];
    Object.keys(reasons).forEach(k => {
      if (reasons[k]) {
        reasonsArr.push(k)
      }
    });
    let facilities = this.reasonsForm.get('facilities').value;
    this.alreadyDnrFacilities.forEach(fId => {
      const index = facilities.indexOf(fId);
      if (index > -1) {
        facilities.splice(index, 1);
      }
    });
    const index = facilities.indexOf('all');
    if (index > -1) {
      facilities.splice(index, 1);
    }
    facilities.push(this.facility._id);
    const questions = [
      {
        question: 'Did this occur during a shift scheduled through ESHYFT?',
        response: this.reasonsForm.get('duringEshyft').value ? 'Yes' : 'No'
      },
      {
        question: 'Are you reporting this incident to the State?',
        response: this.reasonsForm.get('reportingToState').value ? 'Yes' : 'No'
      },
    ];
    let formData: AddToDnr = {
      facilities,
      nurse: this.nurse._id,
      ...(this.reasonsForm.get('details').value.length ? {details: this.reasonsForm.get('details').value} : {}),
      reasons: reasonsArr,
      ...(this.showRadio ? {questions} : {}),
    }
    console.log('formData',formData)
    this.mixpanel.for('fm').track('ADD_DNR_CONFIRM', {nurse: this.nurse});
    this.addNurseToDnr.emit(formData)
  }
  onFormItemClick(reason) {
    this.reasonsForm.get('reasons').get(reason).setValue(!this.reasonsForm.get('reasons').get(reason).value);
    if (this.reasonsForm.get('reasons').value['Attendance & punctuality'] || this.reasonsForm.get('reasons').value['Clinical competency'] || this.reasonsForm.get('reasons').value['Attitude & cooperation']) {
      this.showRadio = true;
      this.reasonsForm.get('duringEshyft').setValidators([Validators.required]);
      this.reasonsForm.get('reportingToState').setValidators([Validators.required]);
    } else {
      this.showRadio = false;
      this.reasonsForm.get('duringEshyft').setValidators(null);
      this.reasonsForm.get('reportingToState').setValidators(null);
    }
    this.reasonsForm.get('duringEshyft').updateValueAndValidity();
    this.reasonsForm.get('reportingToState').updateValueAndValidity();
  }
  onCloseModal() {
    this.mixpanel.for('fm').track('ADD_DNR_CANCEL', { nurse: this.nurse });
    this.onClose.emit();
  }
  setRadioValue(controlName, value){
    this.reasonsForm.get(controlName).setValue(value);
  }
  
}
