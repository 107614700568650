import { createReducer, on } from '@ngrx/store';

import { AuthState } from '../interfaces/auth';
import { setToken, logoutSuccess, loginSuccess, Union } from '../actions/auth/auth.actions';

export const initialAuthState: AuthState = Object.freeze<AuthState>({
    token: 'NOT_SET',
    remember: false
});

const reducer = createReducer(
    initialAuthState,
    on(setToken, (state, { token }) => ({ ...state, token })),
    on(logoutSuccess, state => ({ ...state, token: null })),
    on(loginSuccess, (state, {remember} ) => ({ ...state,  remember})),
    
);


export const authReducer = (state: AuthState, action: Union) => reducer(state, action);