import { createSelector } from '@ngrx/store';

import { State } from 'src/app/state/interfaces';

import { MANAGER_FEATURE_KEY, FILTERS_FEATURE_KEY } from '../interfaces';

const state = (s: State) => s[MANAGER_FEATURE_KEY][FILTERS_FEATURE_KEY];


export const selectShiftsFilters = createSelector(state, (state) => state.shifts);
export const selectTimecardsFilters = createSelector(state, (state) => state.timecards);


