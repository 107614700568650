import { AfterViewInit, Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, Validators } from '@angular/forms';

@Component({
  selector: 'app-slider-input',
  templateUrl: './slider-input.component.html',
  styleUrls: ['./slider-input.component.scss']
})
export class SliderInputComponent implements OnInit {
  @Input() sliderControl: UntypedFormControl;
  @Input() withoutSlider: boolean = false;
  
  @Input() label: string;
  
  inputRange = {
    min: 0,
    max: 100
  };
  formattedValue: string;
  value = 0;
  constructor(private fb: UntypedFormBuilder) { }

  ngOnInit(): void {
    this.formattedValue = '$' + this.sliderControl.value + '/hr';
    this.value = this.sliderControl.value;
    
  }
  onSliderChange(event){
    this.value = event.value;
    this.formattedValue = '$' + this.value + '/hr';
    this.sliderControl.setValue(this.value);
  }
  onInputChange(){
    this.value = parseFloat(this.formattedValue.replace(/[^\d.]/g, ''));
    this.sliderControl.setValue(this.value);
  }
  onSpinChange(type){
    let tValue = parseFloat(this.formattedValue.replace(/[^\d.]/g, ''));
    if(type === '+'){
      tValue += 0.25;
    }else if((type === '-') && (tValue >= 0.25)){
      tValue -= 0.25;
    }
    this.value = parseFloat(tValue.toFixed(2));
    this.formattedValue = '$' + this.value.toFixed(2) + '/hr';
    this.sliderControl.setValue(this.value);
  }
}
