import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';


@Component({
  selector: 'app-side-popup',
  templateUrl: './side-popup.component.html',
  styleUrls: ['./side-popup.component.scss'],
})
export class SidePopupComponent {
  @Input() title: string;

  @Output() onClose = new EventEmitter();

  constructor() {}

}