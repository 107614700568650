import { throwError } from "rxjs";

import { toHot } from "src/app/helpers/to-hot";
import { Timecard, TimecardModel } from "src/app/models/timecard";

import { TimecardBindings } from "./bindings";

export class TimecardWithBindings extends Timecard
{
    private _bindings: TimecardBindings;

    constructor(model: TimecardModel, bindings: TimecardBindings)
    {
        super(model);

        this._bindings = bindings;
    }

    get bindings() { return this._bindings; }

    approve()
    {
        if(!this.bindings.approve) return throwError('Approve method is not bound!');

        return toHot(this.bindings.approve(this._id));
    }

    reject(reason: string, reasonText: string)
    {
        if(!this.bindings.reject) return throwError('Reject method is not bound!');

        return toHot(this.bindings.reject(this._id, reason, reasonText));
    }
}
