import { createReducer, on } from "@ngrx/store";

import { toRecord } from "src/app/state/helpers/to-record";
import { ChatActions } from "../actions/chat/chat-action-types";

import { ChatsState } from "../interfaces";

export const initialChatsState: ChatsState = Object.freeze<ChatsState>({
    chats: [],
    messages: { },
    models: { },
    past: []
});

export const chatsReducer = createReducer(
    initialChatsState,
    on(ChatActions.fetchChatsSuccess, (state, { models }) => ({
        ...state,
        models: { ...state.models, ...toRecord(models, c => c._id) },
        chats: models.map(c => c._id)
    })),
    on(ChatActions.fetchNextChatsPageSuccess, (state, { models }) => ({
        ...state,
        models: { ...state.models, ...toRecord(models, c => c._id) },
        chats: state.chats.concat(models.map(c => c._id))
    })),
    on(ChatActions.fetchPastChatsSuccess, (state, { models }) => ({
        ...state,
        models: { ...state.models, ...toRecord(models, c => c._id) },
        past: models.map(c => c._id)
    })),
    on(ChatActions.fetchNextPastChatsPageSuccess, (state, { models }) => ({
        ...state,
        models: { ...state.models, ...toRecord(models, c => c._id) },
        past: state.past.concat(models.map(c => c._id))
    })),
    on(ChatActions.fetchChatSuccess, (state, { model }) => { // TODO: test it
        let chats = [ ...state.chats];
        if(!chats.find(v=>{return v === model._id})){
          chats.push(model._id);
        }
        return {
          ...state,
          models: { ...state.models, [model._id]: model },
          chats,
        }
    }),
    on(ChatActions.deleteChatSuccess, (state, { id }) => ({
        ...state,
        models: { ...state.models, [id]: null },
        chats: state.chats.filter(c => c !== id),
        past: state.past.filter(c => c !== id)
    })),
    on(ChatActions.readSuccess, (state, { model }) => ({
        ...state,
        models: { ...state.models, [model._id]: {...state.models[model._id], unreadCount: 0} }
    })),
    on(ChatActions.sendMessageSuccess, (state, { message }) => {return ({
        ...state,
        messages: {
            ...state.messages,
            [message.thread]: (state.messages[message.thread] || []).concat(message)
        }
    })}),
    on(ChatActions.fetchMessagesSuccess, (state, { chatId, models }) => ({
        ...state,
        messages: {
            ...state.messages,
            [chatId]: models
        }
    })),
    on(ChatActions.fetchNextMessagesPageSuccess, (state, { chatId, models }) => ({
        ...state,
        messages: {
            ...state.messages,
            [chatId]: (state.messages[chatId] || []).concat(models)
        }
    })),
    on(ChatActions.fetchPastChatsFailed, (state, { error }) => {
      if (error.status === 404) {
        return {
          ...state,
          past: []
        }
      }
      return {
        ...state,
      };
    }),
    on(ChatActions.fetchChatsFailed, (state, { error }) => {
      if (error.status === 404) {
        return {
          ...state,
          chats: []
        }
      }
      return {
        ...state,
      };
    }),
);
