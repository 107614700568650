import { EventEmitter, Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { FacilityModel } from 'src/app/models/facility';
import { MessagesService } from './messages.service';
import { FacilitiesFacade } from './facades/facilities/facilities.facade';
import { take } from 'rxjs/operators';
import { UserFacade } from 'src/app/services/facades/user/user.facade';
import { NotificationsFacade } from './facades/notifications/notifications.facade';
import { ServicedStatesFacade } from './facades/serviced-states/serviced-states.facade';
import { NavigationExtras, Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class SelectFacilityService {
  constructor(
    private messages: MessagesService,
    private facilitiesFacade: FacilitiesFacade,
    private userFacade: UserFacade,
    private notificationsFacade: NotificationsFacade,
    private servicedStatesFacade: ServicedStatesFacade,
    public router: Router,
  ) { }

  selectFacility(facility: FacilityModel, withParams = false) {
    if (facility.deactivationStatus === 'Deactivated') {
      this.messages.add('The facility has been deactivated. If you have any questions please, please contact support.', '', 'danger', () => {
        window.location.href = 'mailto:support@eshyft.com?subject=Facility status inquiry';
      }, true, 'Contact support');
      return;
    }
    this.facilitiesFacade.setActiveFacility(facility._id);

    let activeFacilities = JSON.parse(localStorage.getItem('activeFacilities'));
    let userId;
    this.userFacade.state.user$.pipe(take(1)).subscribe((u) => {
      userId = u._id;
    });
    activeFacilities.find((e) => e.userId == userId).activeFacilityId =
      facility._id;
    localStorage.setItem('activeFacilities', JSON.stringify(activeFacilities));
    this.notificationsFacade.fetchNotifications().pipe(take(1)).subscribe();
    this.servicedStatesFacade.fetchState(facility.address.state).pipe(take(1)).subscribe(s => {
      if (s) {
        this.servicedStatesFacade.setActive(s['model'].state);
      }
      this.reloadPage(withParams);
    })


  }
  reloadPage(withParams) {
    const currentUrl = this.router.url;
    const urlTree = this.router.parseUrl(currentUrl);
    const queryParams = urlTree.queryParams;
    const navigationExtras: NavigationExtras = {
      queryParams: queryParams,
      queryParamsHandling: "merge",
    };
    this.router.navigateByUrl('/manager/facility-selection').then(() => {
      if(withParams){
        this.router.navigateByUrl(currentUrl, navigationExtras);
      }else{
        
        const emptyUrl = currentUrl.replace(/\?.*$/, '');
        this.router.navigateByUrl(emptyUrl);
      }
    });
  }
}
