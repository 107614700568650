import { TIMECARD_REJECTION_REASONS, TIMECARD_STATUS_LABELS, TIMECARD_STATUS_TO_PERMISSION, TTimecardStatusKey } from "src/app/constants/timecard";
import { FacilityTotals } from "src/app/types/models/timecard/facility-totals";
import { MealBreak } from "src/app/types/models/timecard/meal-break";

import { NurseModel } from "../nurse";
import { ShiftModel } from "../shift";
import { ManagerModel } from "../manager"
import { UserModel } from "../user";
import * as moment from "moment";
import { Moment } from "moment-timezone";
import { FacilityNurse } from "../facility/facility-nurse";

export interface TimecardModel
{
    _id: string;
    approvedAt: string;
    approvedBy: ManagerModel;
    baseEarnings: number;
    createdBy: string;
    deletedAt: string;
    deletedBy: UserModel;
    deletedReason: string;
    duration: number;
    earningsManager: number;
    earningsNurse: number;
    endTime?: string;
    facilityTotals: FacilityTotals;
    hourlyRateManager: number;
    hourlyRateNurse: number;
    isApproved: boolean;
    isAutoApproved: boolean;
    isAutoStopped: boolean;
    isCreatedManually: boolean;
    isHoliday: boolean;
    isManualPay: boolean;
    isOfflinePayment: boolean;
    isOvertime: boolean;
    isPaid: boolean;
    isPresubmitted: boolean;
    isRejected: boolean;
    isSameDayPay: boolean;
    isSubmitted: boolean;
    isLatePayroll: boolean;
    lateCancellation: boolean;
    nurse: NurseModel;
    mealBreaks: MealBreak[];
    paidAt: string;
    rejectedAt: string;
    rejectReason: string;
    rejectReasonOther: string;
    shift: ShiftModel;
    startTime: string;
    submittedAt: string;
    submitProblem: string;
    history?: any;
    isResubmitted?: boolean;
    clockOutData?: {
      supervisorFirstName: string;
      supervisorLastName: string;
      supervisorJobTitle?: string;
      isWithinFacilityClockInRadius?: boolean;
      geoPoint?: {
        coordinates: number[];
      }
    }
    createdAt?: string;
    facilityNurse: FacilityNurse
}

/**
 * @param model
 * @returns duration of all meal breaks in **minutes**
 */
export function getMealBreaksDuration(model: TimecardModel)
{
    if (!model.mealBreaks?.length) return 0;
    return model.mealBreaks.reduce((p, c) => p + (c.isTaken ? (c.duration || 0) : 0), 0);
}
export function getMealBreaksCount(model: TimecardModel)
{
    if (!model.mealBreaks?.length) return 'No meal break';
    const duration = (model.mealBreaks.reduce((sum, a) => {
      if (a['isTaken'] === true) {
        sum += a['duration'] ? a['duration'] : 0;
      }
      return sum;
    }, 0));
    return (duration == 30) ? '1 meal break' : '2 meal breaks'
}

/**
 * @param model
 * @returns timecard duration minus meal breaks in **hours**
 */
export function getDurationMinusMealBreaks(model: TimecardModel)
{
    return model.duration - getMealBreaksDuration(model) / 60;
}
export function getShiftRateNurse(model: TimecardModel)
{
  return model.isHoliday ? model.shift.shiftData.rate * 1.5 : model.shift.shiftData.rate;
}
export function getOutsideClockOutRadius(model: TimecardModel)
{
  return model.clockOutData?.hasOwnProperty('isWithinFacilityClockInRadius') ?
    (model.clockOutData.isWithinFacilityClockInRadius ? 'TRUE' : 'FALSE') : '-'
}
export function getCoordinates(model: TimecardModel)
{
  if(model.clockOutData?.geoPoint?.coordinates){
    return model.clockOutData?.geoPoint?.coordinates[1] + ', ' + model.clockOutData?.geoPoint?.coordinates[0];
  }
  return '-'
}

/**
 * @param model
 * @returns adjusted total cost
 */
export function getBilledHourlyRate(model: TimecardModel)
{
    if (!model.facilityTotals?.totalCost) return 0;
    const duration = getDurationMinusMealBreaks(model);
    if (duration === 0) return 0;
    return model.facilityTotals.totalCost / duration;
}

export function getRejectionReasonLabel(model: TimecardModel)
{
    const rejectReason = TIMECARD_REJECTION_REASONS.find(r => r.value === model.rejectReason);
    return rejectReason ? rejectReason.label : model.rejectReasonOther;
}

export function getTimecardApprovedLabel(model: TimecardModel)
{
  if (model.isAutoApproved) return 'Auto-approval: weekly';
    else if (model.lateCancellation) return 'Auto-approval: late cancellation';
    else if (model.approvedBy?.position)
    {
        switch (model.approvedBy.position)
        {
            case 'admin': return 'ESHYFT admin';
            case 'facility-manager': return 'Facility manager';
            case 'supervisor': return 'Supervisor';
        }
    }
    else return '-';
}
export function getTimecardApprovedNameLabel(model: TimecardModel)
{
  return model.approvedBy ? (model.approvedBy.name.first + ' ' + model.approvedBy.name.last) : '-';

}
export function getTimecardStatus(model: TimecardModel): TTimecardStatusKey
{
    if (model.isOfflinePayment) return 'isOfflinePayment';
    else if (model.isPaid) return 'isPaid';
    else if (model.isManualPay) return 'isManualPay';
    else if (model.isSameDayPay) return 'isSameDayPay';
    else if (model.isLatePayroll) return 'isLatePayroll';
    else if (model.isRejected) return 'isRejected';
    else if (model.isApproved) return 'isApproved';
    else if (model.isSubmitted) return 'isSubmitted';
    else if (model.isPresubmitted) return 'isPresubmitted';
    else return 'isPresubmitted';
}

export function isLatePayrollEditingPeriod(model:TimecardModel) 
{
  let tz = "America/New_York";
  let createdDate = moment.tz(model.createdAt, tz);
  let currentDateTime = moment().tz(tz);
  const previousMonday = getLatePayrollStartDate(currentDateTime);
  const nextMonday = getLatePayrollEndDate(previousMonday);
  if(!createdDate.isBetween(previousMonday, nextMonday, "seconds", "[]")) {
    return false;
  }
  return true;
}

export function getLatePayrollStartDate(currentDateTime: Moment)
{
  const monBefore3 = currentDateTime.day() == 1 && currentDateTime.hour() < 15;
  const previousMonday = currentDateTime.day(monBefore3 || currentDateTime.day() < 1 ? -6 : 1); //if it's Monday before 3 or Sunday, go back to the Monday of the previous week, otherwise use current Monday
  return previousMonday.clone().set({ hour: 15, minute: 0, second: 0 });
}

export function getLatePayrollEndDate(startDate: Moment)
{
  return startDate.clone().add(7, 'days').subtract(1, 'second');
}

export function getTimecardStatusClass(model: TimecardModel)
{
    if (model.isOfflinePayment) return "pill-is-offline-payment";
    else if (model.isPaid) return "pill-is-paid";
    else if (model.isManualPay) return "pill-is-manual-pay";
    else if (model.isSameDayPay) return "pill-is-same-day-pay";
    else if (model.isLatePayroll) return "pill-is-late-payroll";
    else if (model.isRejected) return "pill-is-rejected";
    else if (model.isApproved) return "pill-is-approved";
    else if (model.isSubmitted) return "pill-is-submitted";
    else if (model.isPresubmitted) return "pill-is-presubmitted";
    else return "pill-is-presubmitted";
}

export function getTimecardPermissionByStatus(model: TimecardModel)
{
    return TIMECARD_STATUS_TO_PERMISSION[getTimecardStatus(model)];
}

export function getTimecardStatusLabel(model: TimecardModel)
{
    return TIMECARD_STATUS_LABELS[getTimecardStatus(model)];
}


export const HELPERS = {
    getBilledHourlyRate,
    getDurationMinusMealBreaks,
    getMealBreaksDuration,
    getRejectionReasonLabel,
    getTimecardApprovedLabel,
    getTimecardPermissionByStatus,
    getTimecardStatus,
    getTimecardStatusClass,
    getTimecardStatusLabel
};
