import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { parametrify } from 'src/app/helpers/parametrify';

import { ServicedStateModel } from 'src/app/models/serviced-state';
import { environment } from 'src/environments/environment';

@Injectable()
export class ServicedStatesService {
  private endpoint = environment.apiUrl;

  constructor(private http: HttpClient) {}

  fetchState(state: string) {
    return this.http.get<ServicedStateModel>(
      `${this.endpoint}/serviced-states/state/${state}`,
    );
  }
  fetchStates() {
    const params = new HttpParams({
      fromObject: {
        ...parametrify({ skip: 0, limit: 100, sort: 'state', order: 'asc' }),
      },
    });
    return this.http.get<ServicedStateModel[]>(
      `${this.endpoint}/serviced-states`,
      { params },
    );
  }
}
