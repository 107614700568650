import { ChatMessageModel, ChatModel } from "src/app/models/chat";

export interface ChatsState
{
    chats: string[];
    messages: Record<string, ChatMessageModel[]>;
    models: Record<string, ChatModel>;
    past: string[];
}

export const CHATS_FEATURE_KEY = 'chats';