import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { FacilityModel } from 'src/app/models/facility';
import { MixpanelService } from 'src/app/services/mixpanel.service';

@Component({
  selector: 'app-create-shift-popup',
  templateUrl: './create-shift-popup.component.html',
  styleUrls: ['./create-shift-popup.component.scss']
})
export class CreateShiftPopupComponent implements OnInit {
  @Input() facility: FacilityModel;
  @Input() schedule = false;
  @Output() closePopup = new EventEmitter();
  @Output() selectShiftType = new EventEmitter();

  items: {
    label: string;
    icon: string;
    iconBlockClass: string;
    badgeType: string;
    itemName: string;
    itemNameClass: string;
    disabled: boolean;
    bottomLabel: string;
  }[] = [];

  constructor(private router: Router, private mixpanel: MixpanelService) { }

  ngOnInit(): void {
    console.log('facility',this.facility);
    this.generatePopupItems();
  }
  generatePopupItems(){
    this.items = [];
    if(this.facility.instaShyftsDisabled){
      this.items.push(this.generateRegularItem());
      this.items.push({
        label: '',
        icon: 'lightningBolt',
        iconBlockClass: 'disabled',
        badgeType: 'disabled',
        itemName: 'InstaSHYFTs',
        itemNameClass: 'disabled',
        disabled: true,
        bottomLabel: 'Nurses who apply are instantly confirmed'
      });
    }else{
      if(this.facility.instaShyftDefault){
        this.items.push({
          label: 'Continue with InstaSHYFTs ',
          icon: 'instaShift',
          iconBlockClass: 'insta',
          badgeType: 'default',
          itemName: 'InstaSHYFTs',
          itemNameClass: 'insta',
          disabled: false,
          bottomLabel: 'Nurses who apply are instantly confirmed'
        });
        this.items.push(this.generateRegularItem('More ways to create shifts'));
      }else{
        this.items.push(this.generateRegularItem());
        this.items.push({
          label: '',
          icon: 'instaShift',
          iconBlockClass: 'insta',
          badgeType: 'favorite',
          itemName: 'InstaSHYFTs',
          itemNameClass: 'insta',
          disabled: false,
          bottomLabel: 'Nurses who apply are instantly confirmed'
        });
      }
    }
  }
  generateRegularItem(label = ''){
    return {
      label,
      icon: 'shifts-01',
      iconBlockClass: 'regular',
      badgeType: '',
      itemName: 'Regular shifts',
      itemNameClass: 'regular',
      disabled: false,
      bottomLabel: 'Nurses who apply need to be confirmed  by your team'
    }
  }
  goToSettingsClick(){
    this.router.navigate(['/manager/settings/instashyfts']);
    this.closePopup.emit();
  }
  onItemClick(item){
    if(!this.schedule){
      if(!item.disabled){
        if(item.itemName === 'Regular shifts'){
          this.mixpanel.for('fm').track('CREATE_REGULAR_SHIFT_SELECTOR', { facility: this.facility });
          this.router.navigate(['/manager/shifts/new'], { queryParams: { type: 'regular'}});
        }else if(item.itemName === 'InstaSHYFTs'){
          this.mixpanel.for('fm').track('CREATE_INSTASHIFT_SELECTOR', { facility: this.facility });
          this.router.navigate(['/manager/shifts/new'], { queryParams: { type: 'insta'}});
        }
        this.closePopup.emit();
      }
    }else{
      let type = 'regular';
      if(item.itemName === 'Regular shifts'){
        type = 'regular';
      }else if(item.itemName === 'InstaSHYFTs'){
        type = 'insta';
      }
      this.selectShiftType.emit(type);
      this.closePopup.emit();
    }
  }
}
