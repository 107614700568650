<div class="create-shift-popup-wrapper" (click)="closePopup.emit()">
    <div class="create-shift-popup" (keydown.Space)="$event.stopImmediatePropagation();" (click)="$event.stopPropagation()">
        <button class="close-btn" fill="none" (click)="closePopup.emit()"><mat-icon>close</mat-icon></button>
        <div class="csp-content-block">
            <span class="csp-title">
                Which shifts would you like to create?
            </span>

            <div class="csp-item-block" *ngFor="let item of items">
                <span class="csp-item-label" *ngIf="item.label">{{item.label}}</span>
                <div class="csp-item" (click)="onItemClick(item)">
                    <div class="icon-block" [class]="item.iconBlockClass">
                        <mat-icon [svgIcon]="item.icon" [ngClass]="{regular: item.icon === 'shifts-01', 'disabled-icon': item.disabled}"></mat-icon>
                    </div>
                    <div class="label-block">
                        <div class="top">
                            <span class="item-name" [class]="item.itemNameClass">{{item.itemName}}</span>
                            <div class="badge disabled" *ngIf="item.badgeType === 'disabled'"><span>Disabled</span></div>
                            <div class="badge favorite" *ngIf="item.badgeType === 'favorite'"><span>Nurse's favorite shift type</span></div>
                            <div class="badge default" *ngIf="item.badgeType === 'default'"><span>·</span><span>Default</span></div>

                        </div>
                        <span class="bottom-label">{{item.bottomLabel}}</span>
                        <button fill="none" *ngIf="item.disabled" (click)="goToSettingsClick()" class="goto-button">Go to settings to enable</button>
                    </div>
                    <div class="cheveron-block" *ngIf="!item.disabled"><mat-icon svgIcon="mdCheveronUp-01"></mat-icon></div>
                </div>
            </div>
            





            <div class="csp-item-block">
                <div class="csp-item">
                    <div class="icon-block">
                        <mat-icon svgIcon="userAdd"></mat-icon>
                    </div>
                    <div class="label-block">
                        <div class="top">
                            <span class="item-name">Invite a nurse</span>
                            <div class="badge"><span>Coming soon!</span></div>
                        </div>
                        <span class="bottom-label">Invite a specific nurse to block booking or a single shift</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>