import { createSelector } from '@ngrx/store';

import { State } from 'src/app/state/interfaces';
import { selectModelsById } from 'src/app/state/helpers/select-models-by-id';

import { MANAGER_FEATURE_KEY, SHIFTS_FEATURE_KEY, ShiftsState } from '../interfaces';

const state = (s: State) => s[MANAGER_FEATURE_KEY][SHIFTS_FEATURE_KEY];

export const selectShiftIds = createSelector(state, (state) => state.shifts);
export const selectShifts = createSelector(state, (state) => selectModelsById(state.models, state.shifts));
export const selectShift = createSelector(state, ({ models }: ShiftsState, { id }: { id: string }) => models[id]);

export const selectPagination = createSelector(state, (state) => state.pagination);