<div class="range-date-input-container">
  <button fill="none" class="arrow-btn left" (click)="onArrowClick('left')">
    <mat-icon svgIcon="mdCheveronLeft-01"></mat-icon>
  </button>
  <mat-date-range-input [rangePicker]="picker" (click)="picker.open()">
    <input
      [readonly]="true"
      matStartDate
      [formControl]="$any(range).controls['startTimeFrom']"
      (dateChange)="dateChange('startTimeFrom', $event)"
    />
    <input
      [readonly]="true"
      matEndDate
      [formControl]="$any(range).controls['startTimeTo']"
      (dateChange)="dateChange('startTimeTo', $event)"
    />
  </mat-date-range-input>
  <button fill="none" class="arrow-btn right" (click)="onArrowClick('right')">
    <mat-icon svgIcon="mdCheveronLeft-01"></mat-icon>
  </button>
  <mat-date-range-picker #picker></mat-date-range-picker>
</div>
