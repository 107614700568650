<mat-select [formControl]="control" #select class="select-input" [disabled]="disabled"
    [panelClass]="editShift ? 'edit-shift-input-panel' : 'select-input-panel'" [disableOptionCentering]="true">
    <mat-select-trigger>
        <ng-container *ngTemplateOutlet="selectedTemplateRef"></ng-container>
    </mat-select-trigger>

    <mat-option *ngFor="let item of values" [value]="key ? item[this.key] : item" class="select-mat-option">
        <ng-container *ngTemplateOutlet="optionTemplateRef; context: { $implicit: item }"></ng-container>
    </mat-option>
</mat-select>

<ng-container *ngIf="select.panelOpen then chevronUp else chevronDown" matSuffix></ng-container>
<ng-template #chevronDown>
    <mat-icon matSuffix class="custom-chevron chevron-down" [ngClass]="{disabled: disabled}" (click)="select.open()"
        svgIcon="mdCheveronUp-01"></mat-icon>
</ng-template>
<ng-template #chevronUp>
    <mat-icon matSuffix class="custom-chevron chevron-up" [ngClass]="{disabled: disabled}" (click)="select.open()"
        svgIcon="mdCheveronUp-01"></mat-icon>
</ng-template>