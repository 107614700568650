import {
  animate,
  keyframes,
  state,
  style,
  transition,
  trigger
} from '@angular/animations';
import { Component } from '@angular/core';
import { Router } from '@angular/router';

import { Toast, ToastrService, ToastPackage } from 'ngx-toastr';
import { take } from 'rxjs/operators';
import { ApplicantModel } from 'src/app/models/applicant';

import { ReminderModel } from 'src/app/models/reminder';
import { ApplicantBindings, ApplicantWithBindings, ShiftWithBindings } from '../../../bindings';
import { ShiftsFacade } from '../../../services/facades/shifts/shifts.facade';
import { NurseConfirmationService } from '../../../services/nurse-confirmation.service';

@Component({
  selector: '[action-toast-component]',
  styleUrls: [`./action-toast.component.scss`],
  templateUrl: `./action-toast.component.html`,
  animations: [
    trigger('flyInOut', [
      state('inactive', style({
        display: 'none',
        opacity: 0
      })),
      transition('inactive => active', animate('400ms ease-out', keyframes([
        style({
          opacity: 0,
        }),
        style({
          opacity: 1,
        })
      ]))),
      transition('active => removed', animate('400ms ease-out', keyframes([
        style({
          opacity: 1,
        }),
        style({
          transform: 'translate3d(10%, 0, 0) skewX(10deg)',
          opacity: 0,
        }),
      ]))),
    ]),
  ],
  preserveWhitespaces: false,
})
export class ActionToastComponent extends Toast {
 
  reminderData: ReminderModel;
  type: string;
  applicant;
  shiftWithBindings: ShiftWithBindings;

  // constructor is only necessary when not using AoT
  constructor(
    protected toastrService: ToastrService,
    public toastPackage: ToastPackage,
    public shiftsFacade: ShiftsFacade,
    public nurseConfirmationService: NurseConfirmationService,
    private router: Router,
    
  ) {
    super(toastrService, toastPackage);
    this.type = this.toastPackage.config['type'];
    if(this.type === 'reminder'){
      this.reminderData = this.toastPackage.config['reminderData'];
      this.shiftWithBindings = this.shiftsFacade.binder.getShiftWithBindings(this.reminderData.shift);
      this.applicant = this.shiftWithBindings.getApplicantObjectById(this.reminderData.applicant.user._id);
    }
  }

  action(event: Event) {
    event.stopPropagation();
    this.toastPackage.triggerAction();
    return false;
  }
  onCloseReminder(){
    this.showNextReminder();
    this.remove();
  }
  onReminderConfirm(){
    this.nurseConfirmationService.onNurseConfirm(this.applicant, this.reminderData.shift.facility, this.shiftWithBindings).pipe(take(1)).subscribe();
    this.onCloseReminder();
  }
  onReminderDecline(){
    this.applicant.decline();
    this.onCloseReminder();
  }
  onGoToShift(){
    this.router.navigate(['/manager/facility-selection']).then(() => {
      this.router.navigate(['manager/shifts/applicants'], {
        state: { id: this.reminderData.shift._id },
      });

    });
    
    this.onCloseReminder();
  }
  showNextReminder(){
    this.shiftsFacade.showNextReminder();
  }
}
