import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { map } from 'rxjs/operators';

import { TIMECARDS_PAGE_SIZE } from 'src/app/constants/pagination';
import { parametrify } from 'src/app/helpers/parametrify';
import { TimecardModel } from 'src/app/models/timecard';
import { FetchTimecardsDTO, PaginationDTO, SortDTO } from 'src/app/types/dto';
import { environment } from 'src/environments/environment';

@Injectable()
export class TimecardsService
{
    private endpoint = environment.apiUrl;

    constructor(private http: HttpClient) { }

    fetchTimecards(
        facilityId: string,
        filters: FetchTimecardsDTO,
        pagination: PaginationDTO = { skip: 0, limit: TIMECARDS_PAGE_SIZE },
        sort: SortDTO = { sort: '_id', order: 'desc' }
    )
    {
        const params = new HttpParams({
            fromObject: {
                facilities: [facilityId],
                ...parametrify(filters),
                ...parametrify(pagination),
                ...parametrify(sort),
                populate: ['shift.facility', 'nurse', 'approvedBy', 'shift.holiday', 'shift.shiftData.shiftTemplate'],
            }
        });

        return this.http.get<TimecardModel[]>(`${this.endpoint}/timecards`, { params, observe: 'response' })
            .pipe(
                map(response => ({
                    models: response.body,
                    totalCount: parseInt(response.headers.get('X-Total-Count'))
                }))
            );
    }
    fetchTimecard(timecardId: string)
    {
        const params = new HttpParams({
            fromObject: {
                populate: ['shift.facility', 'nurse', 'approvedBy', 'shift.holiday'],
            }
        });
        return this.http.get<TimecardModel>(`${this.endpoint}/timecards/${timecardId}`, {params });
    }
    approveTimecard(timecardId: string)
    {
        return this.http.post<TimecardModel>(`${this.endpoint}/timecards/${timecardId}/approve`, { });
    }

    rejectTimecard(timecardId: string, rejectReason: string, reasonText: string)
    {
        let params = {
            rejectReason,
            ...(reasonText ? {rejectReasonOther: reasonText} : {}),
        }
        return this.http.post<TimecardModel>(`${this.endpoint}/timecards/${timecardId}/reject`, params);
    }
    getTimecardHistory(id: string) {
      return this.http.get(`${this.endpoint}/timecards/${id}/history`);
  }
}
