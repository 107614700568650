import { DateTime } from 'luxon';
import * as clone from 'clone';

import { License } from "../../types/license";
import { ShiftData } from "../../types/models/shift";
import { ApplicantModel } from '../applicant';
import { Applicant } from '../applicant/applicant';
import { FacilityModel } from "../facility";
import { ManagerModel } from "../manager/manager.model";

import { ShiftModel } from './shift.model';
import { HolidayModel } from '../holiday';

export class Shift implements ShiftModel
{
    protected _model: ShiftModel;

    protected _shiftFrom: DateTime;
    protected _shiftTo: DateTime;
    protected _shiftDateData: {
        day: string,
        month: string,
        date: string,
        shiftFrom: string,
        shiftTo: string,
        fullDateFormatted: string,
        dateForPrint: string,
    };
    protected _applicantsObjects: Applicant[];

    constructor(model: ShiftModel)
    {
        this._model = clone(model);

        this._shiftFrom = DateTime.fromISO(model.shiftData?.shiftFrom, { zone: model.facility?.timezone });
        this._shiftTo = DateTime.fromISO(model.shiftData?.shiftTo, { zone: model.facility?.timezone });

        this._applicantsObjects = this.applicants?.map(a => new Applicant(a));

        this._shiftDateData = {
            day: this._shiftFrom.toFormat('EEE'),
            month: this._shiftFrom.toFormat('MMM'),
            date: this._shiftFrom.toFormat('dd'),
            shiftFrom: this._shiftFrom.toFormat('h:mma'),
            shiftTo: this._shiftTo.toFormat('h:mma'),
            fullDateFormatted: this._shiftFrom.toFormat('EEEE, MMMM dd, yyyy'),
            dateForPrint: this._shiftFrom.toFormat('ccc LL/dd/yyyy', {timeZone: this.facility?.timezone}),
        };
    }

    get model() { return clone(this._model); }

    get applicants() : ApplicantModel[] { return this._model.applicants; }

    get clockInTime() : number { return this._model.clockInTime; }

    get createdBy() : ManagerModel { return this._model.createdBy; }

    get description() : string { return this._model.description; }

    get facility() : FacilityModel { return this._model.facility; }

    get isCancelled() : boolean { return this._model.isCancelled; }

    get isHoliday() : boolean { return this._model.isHoliday; }

    get holiday() : HolidayModel { return this._model.holiday; }

    get isUrgent() : boolean { return this._model.isUrgent; }

    get lateWithdrawalAttempt() : boolean { return this._model.lateWithdrawalAttempt; }

    get license() : License { return this._model.license; }

    get publishedTime() : string { return this._model.publishedTime; }

    get shiftData() : ShiftData { return this._model.shiftData; }

    get slots() : number { return this._model.slots; }

    get _id() : string { return this._model._id; }

    get id() : string { return this._id; }

    get oncallShift() : boolean { return this._model.oncallShift; }

    get isInstaShyft(): boolean { return this._model.isInstaShyft; }

    // Helpers

    get shiftFrom() { return this._shiftFrom; }

    get shiftTo() { return this._shiftTo; }

    get dateData() { return this._shiftDateData; }

    get timespan() { return `${this._shiftDateData.shiftFrom} - ${this._shiftDateData.shiftTo}`; }

    get autoConfirmGroups() { return this._model.autoConfirmGroups; }
    
}
