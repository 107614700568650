<div class="filter-container">
  <form [formGroup]="filterForm">
    <div class="search-block">
      <span class="input-label">Search by ID</span>
      <input formControlName="id" type="text" placeholder="Enter {{type}} ID" />
    </div>
    <div class="date-block">
      <span class="input-label">Period range</span>
      <app-range-datepicker
        [range]="rangeForm"
      ></app-range-datepicker>
    </div>
    <div class="checkboxes-block">
      <app-checkboxes-filter
        [checkboxesGroupModel]="checkboxesGroupModel"
        [form]="checkboxesForm"
        [checkboxesData]="checkboxesData"
      ></app-checkboxes-filter>
    </div>
    <button
      class="clear-btn"
      (click)="clearFilterForm.emit()"
      fill="none"
      *ngIf="showClearBtn"
    >
      <mat-icon class="close">close</mat-icon>
      <span>Clear All</span>
    </button>
    <div class="print-block">
      <button
        class="print-btn"
        (click)="printShifts.emit()"
        fill="none"
        *ngIf="showPrintBtn"
      >
        <mat-icon svgIcon="mdPrinter-01"></mat-icon>
        <span>Print</span>
      </button>
    </div>
  </form>
</div>
