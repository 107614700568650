<div class="modal-substrate" (click)="onModalClose($event)">
    <div class="modal-block" (click)="$event.stopPropagation()">
      
      <span class="title">Remove {{nurseData.nurse.name.first}} from trusted</span>
      <span class="text">
        {{nurseData.nurse.name.first}} will no longer show up as a trusted nurse for your facility.
      </span>
      <div class="reasons-block">
        <span class="reasons-title">Select reason <span class="optional"> (optional)</span></span>
        <span class="reasons-label">This information will be saved in the Notes section of the nurse’s profile and won’t be shared with {{nurseData.nurse.name.first}}.</span>
        <div class="reasons" [formGroup]="form">
            <div class="reason" [ngClass]="{active: form.get('attendance_and_punctuality').value}" (click)="onFormItemClick('attendance_and_punctuality')">
                <div class="reason-icon">
                    <img src="../../../../../../assets/img/remove_from_trusted_reasons/Attendance-icon.svg" alt="">
                </div>
                <div class="reason-text">
                    <span class="reason-name">Attendance & punctuality</span>
                    <span class="reason-label">Refusing assignment, no call no show, arriving late, excessive callouts, etc.</span>
                </div>
            </div>
            <!-- <div class="reason" [ngClass]="{active: form.get('clinical_competency').value}" (click)="onFormItemClick('clinical_competency')">
                <div class="reason-icon">
                    <img src="../../../../../../assets/img/remove_from_trusted_reasons/Clinical-competency.svg" alt="">
                </div>
                <div class="reason-text">
                    <span class="reason-name">Clinical competency</span>
                    <span class="reason-label">Abandoning mid-assignment, documentation error, abuse, medication error, etc.</span>
                </div>
            </div> -->
            <div class="reason" [ngClass]="{active: form.get('attitude_and_cooperation').value}" (click)="onFormItemClick('attitude_and_cooperation')">
                <div class="reason-icon">
                    <img src="../../../../../../assets/img/remove_from_trusted_reasons/Attitude.svg" alt="">
                </div>
                <div class="reason-text">
                    <span class="reason-name">Attitude & cooperation</span>
                    <span class="reason-label">Unprofessional behavior, being on the phone, sleeping, etc.</span>
                </div>
            </div>
            <div class="reason" [ngClass]="{active: form.get('facility_preference').value}" (click)="onFormItemClick('facility_preference')">
                <div class="reason-icon">
                    <img src="../../../../../../assets/img/remove_from_trusted_reasons/Facility-preference.svg" alt="">
                </div>
                <div class="reason-text">
                    <span class="reason-name">Facility preference</span>
                    <span class="reason-label">Conflict of interest, contractual agreement.</span>
                </div>
            </div>
            <!-- <div class="reason other" [ngClass]="{active: form.get('other').value}" (click)="onFormItemClick('other')">
                <div class="reason-text">
                    <span class="reason-name">Other</span>
                </div>
            </div>
            <input type="text" class="other-text-input" formControlName="otherText" placeholder="Enter other reason" *ngIf="form.get('other').value" [ngClass]="{'error-input': form.get('other').value && !form.get('otherText').valid}">
            <span class="error" *ngIf="form.get('other').value && !form.get('otherText').valid">Reason must be at least 10 characters</span> -->
        </div>
      </div>
      <div class="did-you-know-block">
        <span class="bold">Did you know?</span>
        <span>&nbsp;If you are going to DNR a nurse, they will be automatically removed from trusted.</span>
      </div>

      <div class="buttons-block">
        <button (click)="onModalClose($event)" fill="outline" color="border-base">Cancel</button>
        <button color="primary" (click)="onRemoveClick($event);" [ngClass]="{disabled: (form.get('other').value && !form.get('otherText').valid)}" [disabled]="(form.get('other').value && !form.get('otherText').valid)">Remove from trusted</button>
      </div>
  
    </div>
  </div>