import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";

import { of } from "rxjs";
import { catchError, exhaustMap, map, share, withLatestFrom } from "rxjs/operators";
import { UserAccessor } from "src/app/services/accessors/user.accessor";

import { UserService } from "src/app/services/api/user.service";
import { MixpanelService } from "src/app/services/mixpanel.service";
import { environment } from "src/environments/environment";

import { changeEmail, changeEmailFailed, changeEmailSuccess, changePassword, changePasswordFailed, changePasswordSuccess, getProfile, getProfileFailed, getProfileSuccess, updateUser, updateUserFailed, updateUserSuccess, uploadAvatar, uploadAvatarFailed, uploadAvatarSuccess } from "../actions/user/user.actions";

@Injectable()
export class UserEffects
{
    constructor(
        private actions$: Actions,
        private api: UserService,
        private accessor: UserAccessor,
        private mixpanel: MixpanelService
    ) { }

    getProfile$ = createEffect(() => this.actions$.pipe(
        ofType(getProfile),
        exhaustMap(() =>
            this.api.getProfile()
                .pipe(
                    map(user => (
                        user.position !== 'admin' && (
                            this.mixpanel.optIn(environment.mixpanel.fm, 'fm'),
                            this.mixpanel.onLogin(user)
                        ),
                        getProfileSuccess({ user })
                    )),
                    catchError(e => of(getProfileFailed({ error: e })))
                )
        ),
        share()
    ));

    updateUser$ = createEffect(() => this.actions$.pipe(
        ofType(updateUser),
        exhaustMap(({ user }) =>
            this.api.edit(user)
                .pipe(
                    map(model => updateUserSuccess({ model })),
                    catchError(e => of(updateUserFailed({ error: e })))
                )
        ),
        share()
    ));

    changeEmail$ = createEffect(() => this.actions$.pipe(
        ofType(changeEmail),
        withLatestFrom(this.accessor.user$),
        exhaustMap(([{ dto: { confirmation, email, password } }, user]) =>
            this.api.changeEmail(user._id, email, confirmation, password)
                .pipe(
                    map(model => changeEmailSuccess({ model })),
                    catchError(e => of(changeEmailFailed({ error: e })))
                )
        ),
        share()
    ));

    changePassword$ = createEffect(() => this.actions$.pipe(
        ofType(changePassword),
        withLatestFrom(this.accessor.user$),
        exhaustMap(([{ dto: { confirmation, oldPassword, password } }, user]) =>
            this.api.changePassword(user._id, oldPassword, password, confirmation)
                .pipe(
                    map(() => changePasswordSuccess()),
                    catchError(e => of(changePasswordFailed({ error: e })))
                )
        ),
        share()
    ));

    uploadAvatar$ = createEffect(() => this.actions$.pipe(
        ofType(uploadAvatar),
        exhaustMap(({ file }) =>
            this.api.uploadAvatar(file)
                .pipe(
                    map(model => uploadAvatarSuccess({ model })),
                    catchError(e => of(uploadAvatarFailed({ error: e })))
                )
        ),
        share()
    ));
}