import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';

import { asapScheduler } from 'rxjs';
import { take } from 'rxjs/operators';

import * as AuthActions from 'src/app/state/actions/auth/auth.actions';
import { AuthEffects } from 'src/app/state/effects/auth.effects';
import { State } from 'src/app/state/interfaces';
import { AuthAccessor } from 'src/app/services/accessors/auth.accessor';
import { AppService } from 'src/app/services/app.service';
import { ForgotPasswordDTO } from 'src/app/state/actions/auth/dto/forgot-password';
import { ResetPasswordDTO } from 'src/app/state/actions/auth/dto/reset-password';
import { actionMap } from 'src/app/helpers/rx-operators';
import { SocketService } from '../../socket/socket.service';
import { ToastService } from 'src/app/modules/manager/services/ui/toast.service';

@Injectable({ providedIn: 'root' })
export class AuthFacade
{
    constructor(
        app: AppService,
        private store: Store<State>,
        private authEffects: AuthEffects,
        private authAccessor: AuthAccessor,
        private socket: SocketService,
        private toast: ToastService
    )
    {
        app.expose('facades', 'auth', this);
    }

    get state() { return this.authAccessor; }

    setToken(token: string)
    {
        this.store.dispatch(AuthActions.setToken({ token }));
    }

    login(email: string, password: string, remember: boolean)
    {
        asapScheduler.schedule(() => this.store.dispatch(AuthActions.login({ email, password, remember })));
        return this.authEffects.login$.pipe(take(1));
    }
    forgotPassword(email: string)
    {
        asapScheduler.schedule(() => this.store.dispatch(AuthActions.forgotPassword({ email })));
        return this.authEffects.forgotPassword$
            .pipe(
                take(1)
            );
    }

    resetPassword(_id: string, password: string, token: string)
    {
      asapScheduler.schedule(() => this.store.dispatch(AuthActions.resetPassword({ _id, password, token })));
      return this.authEffects.resetPassword$.pipe(take(1));
    }
    refreshToken()
    {
        this.store.dispatch(AuthActions.refreshToken());
        return this.authEffects.refreshToken$.pipe(take(1));
    }

    // forgotPassword(email: string)
    // {
    //     asapScheduler.schedule(() => this.store.dispatch(AuthActions.forgotPassword({ email })));
    //     return this.authEffects.forgotPassword$.pipe(take(1));
    // }

    // resetPassword(password: string, token: string)
    // {
    //     asapScheduler.schedule(() => this.store.dispatch(AuthActions.resetPassword({ password, token })));
    //     return this.authEffects.resetPassword$.pipe(take(1));
    // }

    logout()
    {
        this.socket.disconnect();
        this.toast.clearToaster();
        asapScheduler.schedule(() => this.store.dispatch(AuthActions.logout()));
        return this.authEffects.logout$.pipe(take(1));
    }
}
