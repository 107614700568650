<div class="rdm-block" [formGroup]="reasonsForm">
    <span class="title">Why are you removing {{nurse.name.first}} from DNR?</span>
    <span class="text">{{nurse.name.first}} will be able to view and apply to shifts at <b>{{facility.name}}</b> after
        they are removed from DNR. We’ll let them know they were removed from DNR.</span>
    <div class="input-block">
        <span class="label">Reason for removing DNR</span>
        <input type="text" formControlName="reason"
            [ngClass]="{error: (!reasonsForm.get('reason').valid && reasonsForm.get('reason').dirty)}">
        <span class="error" *ngIf="!reasonsForm.get('reason').valid && reasonsForm.get('reason').dirty">Details must be at least 10 and at most 500 characters</span>

    </div>
    <app-facility-search-select
        [results]="facilities"
        [label]="'Select more facilities to remove the DNR from'"
        [formGroup]="reasonsForm"
        [controlName]="'facilities'"
        [selectedFacilityId]="facility._id"
        [alreadyDnrFacilities]="[]"
        optionKey="_id"
        optionResult="_id"
        *ngIf="facilities.length > 1"
    ></app-facility-search-select>
    <div class="buttons-block">
        <button fill="outline" color="border-base" (click)="onCloseModal()">Cancel</button>
        <button (click)="onRemoveClick()" [ngClass]="{disabled: !reasonsForm.valid}"  [disabled]="!reasonsForm.valid">Remove DNR</button>
    </div>
</div>