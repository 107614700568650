import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';

import { State } from 'src/app/state/interfaces';
import { selectDnr } from '../../state/selectors/dnr';




@Injectable()
export class DnrAccessor
{
    constructor(private store: Store<State>) { }

    get dnr() { return this.store.select(selectDnr); }

}
