import { createAction, props, union } from '@ngrx/store';

import { type } from 'src/app/state/actions/helpers/action-type';

import { ChangeEmailDTO } from './dto/change-email';
import { ChangePasswordDTO } from './dto/change-password';
import { GetProfileDTO } from './dto/get-profile';
import { UpdateUserDTO } from './dto/update-user';
import { UploadAvatarDTO } from './dto/upload-avatar';

const t = type('user');

export const getProfile = createAction(t.pending('get_profile'));
export const getProfileSuccess = createAction(t.success('get_profile'), props<GetProfileDTO.Success>());
export const getProfileFailed = createAction(t.failed('get_profile'), props<GetProfileDTO.Failed>());

export const updateUser = createAction(t.pending('update_user'), props<UpdateUserDTO.Pending>());
export const updateUserSuccess = createAction(t.success('update_user'), props<UpdateUserDTO.Success>());
export const updateUserFailed = createAction(t.failed('update_user'), props<UpdateUserDTO.Failed>());

export const uploadAvatar = createAction(t.pending('upload_avatar'), props<UploadAvatarDTO.Pending>());
export const uploadAvatarSuccess = createAction(t.success('upload_avatar'), props<UploadAvatarDTO.Success>());
export const uploadAvatarFailed = createAction(t.failed('upload_avatar'), props<UploadAvatarDTO.Failed>());

export const changeEmail = createAction(t.pending('change_email'), props<ChangeEmailDTO.Pending>());
export const changeEmailSuccess = createAction(t.success('change_email'), props<ChangeEmailDTO.Success>());
export const changeEmailFailed = createAction(t.failed('change_email'), props<ChangeEmailDTO.Failed>());

export const changePassword = createAction(t.pending('change_password'), props<ChangePasswordDTO.Pending>());
export const changePasswordSuccess = createAction(t.success('change_password'));
export const changePasswordFailed = createAction(t.failed('change_password'), props<ChangePasswordDTO.Failed>());

const u = union({
    /* Get Profile */
    getProfile,
    getProfileSuccess,
    getProfileFailed,

    /* Update User */
    updateUser,
    updateUserSuccess,
    updateUserFailed,

    /* Upload Avatar */
    uploadAvatar,
    uploadAvatarSuccess,
    uploadAvatarFailed,

    /* Change Email */
    changeEmail,
    changeEmailSuccess,
    changeEmailFailed,

    /* Change Password */
    changePassword,
    changePasswordSuccess,
    changePasswordFailed,
});

export type Union = typeof u;