import { createReducer, on } from "@ngrx/store";
import { toRecord } from "src/app/state/helpers/to-record";
import { HolidaysActions } from "../actions/holidays/notifications-action-types";

import { HolidaysState } from "../interfaces";

export const initialHolidaysState: HolidaysState = Object.freeze<HolidaysState>({
    models: { },
    holidays: []
});

export const holidaysReducer = createReducer(
    initialHolidaysState,
    on(HolidaysActions.fetchHolidaysSuccess, (state, { models }) => ({
        ...state,
        models: models ? toRecord(models, h => h._id): {},
        holidays: models ? models.map(h => h._id): []
    }))
);
