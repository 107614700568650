import * as clone from 'clone';

import { CreateShiftsDTO } from 'src/app/types/dto';

import { CreateShiftsForm } from '../types/forms';

export class CreateShiftsAdapter {
  private _facilityId: string;
  private _form: CreateShiftsForm;

  private _dto: CreateShiftsDTO;

  constructor(form: CreateShiftsForm, facilityId: string) {
    this._facilityId = facilityId;
    this._form = clone(form);
  }

  private _adaptDto() {
    return {
      facility: this._facilityId,
      shifts: this._form.map((f) => ({
        date: f.date,
        license: f.license,
        rate: f.rate,
        shiftTemplate: f.shiftTemplate,
        slots: f.slots,
        shiftTemplateDefinition: f.shiftTemplateDefinition,
        ...(!f.isInstaShyft ? {autoConfirmGroup: f.autoConfirmGroup} : {}),
        ...(f.isInstaShyft ? {isInstaShyft: f.isInstaShyft} : {}),
        ...(f.description ? {description: f.description} : {}),
      })),
    } as CreateShiftsDTO;
  }

  get dto() {
    if (!this._dto) this._dto = this._adaptDto();

    return this._dto;
  }
}
