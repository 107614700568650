import { Injectable } from '@angular/core';

import * as mixpanel from 'mixpanel-browser';

import { environment } from 'src/environments/environment';

import { ApplicantModel } from '../models/applicant';
import { FacilityModel } from '../models/facility';
import { NurseModel } from '../models/nurse';
import { ShiftModel } from '../models/shift';
import { TimecardModel } from '../models/timecard';
import { UserModel } from '../models/user';
import { CreateShiftsForm } from '../modules/manager/types/forms';

import { AppService } from './app.service';
import { ManagerModel } from '../models/manager';

type TInstanceName = 'admin' | 'fm';

@Injectable({ providedIn: 'root' })
export class MixpanelService {
    private _instances: Partial<Record<TInstanceName, mixpanel.Mixpanel>> = {
        admin: undefined,
        fm: undefined
    };

    mixpanel = mixpanel;

    constructor(app: AppService) {
        app.expose('services', 'mixpanel', this);
    }

    private getInstance(name: TInstanceName) {
        if (!this._instances[name])
            throw new Error(`Mixpanel Instance ${name} is not inited!`);

        return this._instances[name];
    }

    private track(instance: mixpanel.Mixpanel, event: TEventName, payload: IPayload = {}) {
        const eventName = EVENT_OPTIONS[event];
        let eventPayload = {};

        if (payload.shift) {
            eventPayload = {
                'Shift ID': payload.shift._id,
                'Shift date and start time': payload.shift.shiftData.shiftFrom,
                'Shift license': payload.shift.license,
            };
        }

        if (payload.applicant) {
            eventPayload['User ID (Nurse)'] = payload.applicant.user._id;
            eventPayload['Nurse license'] = payload.applicant.user.licenses.map(l => l.type).join(', ');
        }

        if (payload.nurse) {
            eventPayload['User ID (Nurse)'] = payload.nurse._id;
            eventPayload['Nurse license'] = payload.nurse.licenses.map(l => l.type).join(', ');
        }

        if (payload.overtime != null)
            eventPayload['Overtime (true/false)'] = payload.overtime;

        if (payload.createShiftsForm != null)
            eventPayload['Shifts data'] = payload.createShiftsForm;

        if (payload.queueShiftsForm != null)
            eventPayload['Shift data'] = payload.queueShiftsForm;

        if(payload.newShiftForm != null)
            eventPayload['New shift time data'] = payload.newShiftForm;

        if (payload.timecard)
            eventPayload['Timecard ID'] = payload.timecard._id;

        if (payload.chatTransitionPoint)
            eventPayload['Transition point'] = payload.chatTransitionPoint;

        if (payload.timecardRejectionReason) {
            eventPayload['Rejection reason'] = payload.timecardRejectionReason['reason'];
            eventPayload['Rejection reason text'] = payload.timecardRejectionReason['reasonText'];
            eventPayload['Hourly rate'] = payload.shift.shiftData.rate;
            eventPayload['Nurse name'] = payload.nurse.fullName;

        }
        if (payload.facility) {
            eventPayload = {
                ...eventPayload,
                'Facility ID': payload.facility._id,
                'State of Facility Address': payload.facility.address.state,
            };
        }
        if (payload.ndFilters)
            eventPayload['Button clicks'] = payload.ndFilters;
        if (payload.ndSort)
            eventPayload['Button clicks'] = payload.ndSort;
        if (payload.ndUntrustedReasons)
            eventPayload['Button clicks'] = payload.ndUntrustedReasons;
        instance.track(eventName, eventPayload);
    }

    optIn(token: string, name: TInstanceName) {
        this._instances[name] = mixpanel.init(token, { /*debug: true,*/ ignore_dnt: true }, name);
        const instance = this.getInstance(name);

        instance.register_once({
            'Platform': 'web',
            'Environment': environment.production ? 'Production' : 'Development'
        });
    }

    onLogin(user: UserModel) {
        const instanceName: TInstanceName = user.position == 'admin' ? 'admin' : 'fm';
        const instance = this.getInstance(instanceName);

        instance.identify(user._id);

        instance.people.set({
            $email: user.email,
            $first_name: user.name.first,
            $last_name: user.name.last,
        });

        instance.register({
            'User ID (Facility manager user)': user._id,
            'FM full name': user.fullName
        });
    }

    onLogout(instanceName?: TInstanceName) {
        if (instanceName) {
            this.getInstance(instanceName).reset();
            this._instances[instanceName] = undefined;
        }
        else {
            this._instances.admin?.reset();
            this._instances.fm?.reset();

            this._instances.admin = undefined;
            this._instances.fm = undefined;
        }
    }

    onFacilitySelect(facility: FacilityModel) {
        const instance = this.getInstance('fm');

        instance.register({
            'Facility name': facility.name,
            'Facility ID': facility._id,
            'State of Facility Address': facility.address.state
        });
    }

    for(name: TInstanceName) {
        const instance = this.getInstance(name);
        return {
            track: (event: TEventName, payload: IPayload = {}) => this.track(instance, event, payload)
        };
    }
}

const EVENT_OPTIONS = {
    LOGIN: 'Login',
    FACILITY_SELECT: 'Facility selection',

    DASHBOARD_PAGE_OPEN: 'Access FM Dashboard',
    DASHBOARD_PAGE_SHIFT_PENDING_CLICK: 'Dashboard Shift pending button',
    DASHBOARD_PAGE_TC_PENDING_CLICK: 'Dashboard TC pending button',
    DASHBOARD_PAGE_UPCOMING_SHIFT_CLICK: 'Dashboard Upcoming Shift button',
    CREATE_SHIFT_CLICK: 'Create shift',
    DASHBOARD_PAGE_UPCOMING_WEEK_CLICK: 'Dashboard upcoming week',

    SCHEDULE_PAGE_OPEN: 'Access FM Schedule tab',
    SCHEDULE_PAGE_FILTERS_CHANGE: 'Schedule tab filters',

    SHIFTS_PAGE_OPEN: 'Access FM Shifts tab',
    SHIFTS_PAGE_ROW_CLICK: 'View open shift details',
    SHIFTS_PAGE_CONFIRM_NURSE: 'Confirm nurse',
    SHIFTS_PAGE_DECLINE_NURSE: 'Decline nurse',
    SHIFTS_PAGE_EDIT_SHIFT: 'Applied shift edit',
    SHIFTS_PAGE_DELETE_SHIFT: 'Applied shift delete',

    CHATS_PAGE_OPEN: 'View Chats section',
    CHATS_PAGE_SEND_MESSAGE: 'Send chat message',
    CHATS_PAGE_OPENED_FROM_TIMECARD: 'Timecard Chat Access Point',
    CHATS_PAGE_OPENED_FROM_SHIFTS: 'Shift section Chat Access Point',

    TIMECARDS_PAGE_OPEN: 'View Timecard section',
    TIMECARDS_PAGE_APPROVE_TIMECARD: 'Approve timecard',
    TIMECARDS_PAGE_REJECT_TIMECARD: 'Time card rejection reason',
    TIMECARDS_PAGE_VIEW_SHIFT: 'View associated shift details',
    TIMECARDS_PAGE_VIEW_PENDING_TIMECARD: 'View pending timecard',
    TIMECARDS_PAGE_VIEW_REJECTED_TIMECARD: 'View rejected timecard',
    TIMECARDS_PAGE_VIEW_APPROVED_TIMECARD: 'View approved timecard',

    SETTINGS_PAGE_LOGIN_DETAILS_OPEN: 'Settings Login details',
    SETTINGS_PAGE_UPDATE_PROFILE: 'settings login update profile',
    SETTINGS_PAGE_CHANGE_PASSWORD: 'settings login change password',
    SETTINGS_PAGE_UPDATE_EMAIL: 'settings login update email',
    SETTINGS_PAGE_FACILITY_AND_MANAGERS_OPEN: 'Settings facility & managers page',
    SETTINGS_PAGE_UPDATE_FACILITY_IMAGE: 'Settings FM update image',
    SETTINGS_PAGE_UPDATE_FACILITY_INFO: 'Settings FM facility info',
    SETTINGS_PAGE_REQUEST_CHANGE: 'Settings FM request change',
    SETTINGS_PAGE_ADD_SUPERVISOR: 'Settings FM add a Sup',
    SETTINGS_PAGE_EMAIL_REQUEST: 'Settings FM email request',
    SETTINGS_PAGE_TIME_PREFS_OPEN: 'Settings shift time preference page',
    SETTINGS_PAGE_TIME_PREFS_REMOVE: 'Settings remove shift time preference page',
    SETTINGS_PAGE_TIME_PREFS_ADD: 'Settings add shift time preference page',
    SETTINGS_PAGE_RATES_OPEN: 'Settings shift rates page',
    SETTINGS_PAGE_RATES_SAVE: 'Settings shift rates save',
    SETTINGS_PAGE_OVERTIME_OPEN: 'Settings overtime page',
    SETTINGS_PAGE_OVERTIME_UPDATE: 'Settings overtime update',
    SETTINGS_PAGE_PAYMENT_METHOD_OPEN: 'Settings payment method page',
    SETTINGS_PAGE_PAYMENT_METHOD_UPDATE: 'Settings payment method update',
    SETTINGS_PAGE_CALCULATOR_OPEN: 'Settings shift calc page',
    SETTINGS_PAGE_INSTASHYFTS_OPEN: 'Settings InstaSHYFT page',
    SETTINGS_PAGE_INSTASHYFTS_UPDATE: 'Settings InstaSHYFT update',

    CREATE_SHIFTS_PAGE_ADD_TO_QUEUE_CLICK: 'Add reg shift to queue',
    CREATE_SHIFTS_PAGE_ADD_TO_QUEUE_CLICK_INSTA: 'Add InstaSHYFT to queue',
    CREATE_SHIFTS_PAGE_PUBLISH_CLICK: 'Publish reg shift',
    CREATE_SHIFTS_PAGE_PUBLISH_CLICK_INSTA: 'Publish InstaSHYFT',
    CREATE_SHIFTS_NEW_SHIFT_TIME: 'FM Web add custom shift from the create shift page',
    CREATE_REGULAR_SHIFT_SELECTOR: 'Create regular shift selector',
    CREATE_INSTASHIFT_SELECTOR: 'Create InstaSHYFT selector',
    

    OVERTIME_MODAL_CONFIRM: 'Overtime rate confirm',
    OVERTIME_MODAL_CANCEL: 'Overtime rate cancel',
    HOLIDAY_MODAL_CONFIRM: 'Holiday rate confirm',
    HOLIDAY_MODAL_CANCEL: 'Holiday rate cancel',
    HOLIDAY_AND_OVERTIME_MODAL_CONFIRM: 'Holiday & overtime rate confirm',
    HOLIDAY_AND_OVERTIME_MODAL_CANCEL: 'Holiday & overtime rate cancel',
    LAST_MINUTE_CANCELLATION_MODAL_CANCEL: 'Last minute cancelation',

    CANCEL_NURSE: 'Cancel nurse',
    NURSE_PROFILE_OPEN: 'View nurse profile',
    
    NURSE_PROFILE_CONFIRM_NURSE: 'Confirm a nurse via profile tab',
    NURSE_PROFILE_DECLINE_NURSE: 'Decline a nurse via profile tab',

    ADD_DNR_OPEN: 'Open DNR pop up',
    ADD_DNR_CONFIRM: 'Confirm DNR',
    ADD_DNR_CANCEL: 'Cancel the DNR',
    REMOVE_DNR_OPEN: 'Open remove DNR Pop-up',
    REMOVE_DNR_CONFIRM: 'Confirm remove DNR',
    REMOVE_DNR_CANCEL: 'Cancel the  remove DNR',
    HISTORY_DNR_OPEN: 'View DNR history log',

    OPEN_INTERCOM_FM_WEB: 'Open Intercom FM Web',

    NURSES_PAGE_OPEN: 'Access FM Nurse tab',
    NURSES_APPLY_FILTER: 'ND apply filter',
    NURSES_APPLY_SORT: 'Nurse tab Sort by',
    NURSES_SHOW_PROFILE_AVATAR: 'Access nurse profile via avatar',
    NURSES_SHOW_PROFILE_BUTTON: 'Access nurse profile via “view profile” button',
    NURSES_SHOW_SHORT_CUT_MENU: 'Access ND short cut menu',
    NURSES_MARK_AS_TRUSTED: 'ND mark as trusted',
    NURSES_UNMARK_AS_TRUSTED: 'ND un mark as trusted',
    NURSES_MARK_AS_TRUSTED_PROFILE: 'ND mark as trusted from profile',
    NURSES_UNMARK_AS_TRUSTED_PROFILE: 'ND un mark as trusted from profile',
    NURSES_PROFILE_AVAILABILITY_TAB: 'Access nurse profile availability tab',
    NURSES_AVAILABILITY_REQUEST: 'Nurse profile availability request',
    NURSES_PROFILE_NOTES_TAB: 'Access nurse profile note tab',
    NURSES_PROFILE_ADD_NOTE: 'Nurse profile note added',
};

type TEventName = keyof typeof EVENT_OPTIONS;

interface IPayload {
    applicant?: ApplicantModel;
    nurse?: NurseModel;
    overtime?: boolean;
    shift?: ShiftModel;
    timecard?: TimecardModel;
    chatTransitionPoint?: 'timecards' | 'shifts';
    createShiftsForm?: CreateShiftsForm;
    queueShiftsForm?: any;
    timecardRejectionReason?: any;
    facility?: FacilityModel;
    ndFilters?: any;
    ndSort?: string;
    ndUntrustedReasons?: any;
    newShiftForm?: any;
}
