import { createAction, props, union } from '@ngrx/store';

import { type } from 'src/app/state/actions/helpers/action-type';
import { FetchNotificationsDTO } from './dto/fetch-notifications';
import { IncreaseShiftsUpdatedCountDTO } from './dto/increase-shifts-updated-count';
import { ReadNotificationsDTO } from './dto/read-notifications';

const t = type('notifications');

export const setChatCountValue = createAction(
  t.sync('set_chat_count_value'),
  props<{ chatsCount: number }>(),
);
export const increaseChatsUnreadMessagesCount = createAction(
  t.sync('inc_chat_count_value'),
  props<{ inc: number }>(),
);
export const decreaseChatsUnreadMessagesCount = createAction(
  t.sync('dec_chat_count_value'),
  props<{ dec: number }>(),
);
export const increaseShiftsUpdatedCount = createAction(
  t.sync('inc_shift_count_value'),
  props<IncreaseShiftsUpdatedCountDTO.Sync>(),
);
export const decreaseShiftsUpdatedCount = createAction(
  t.sync('dec_shift_count_value'),
  props<{ id: string }>(),
);

export const fetchNotifications = createAction(
  t.pending('fetch_notifications'),
);
export const fetchNotificationsSuccess = createAction(
  t.success('fetch_notifications'),
  props<FetchNotificationsDTO.Success>(),
);
export const fetchNotificationsFailed = createAction(
  t.failed('fetch_notifications'),
  props<FetchNotificationsDTO.Failed>(),
);
export const readNotifications = createAction(
  t.pending('read_notifications'),
  props<ReadNotificationsDTO.Pending>(),
);
export const readNotificationsSuccess = createAction(
  t.success('read_notifications')
);
export const readNotificationsFailed = createAction(
  t.failed('read_notifications'),
  props<ReadNotificationsDTO.Failed>(),
);
