import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';

import { State } from 'src/app/state/interfaces';
import { selectReminders } from '../../state/selectors/reminders';

@Injectable()
export class RemindersAccessor
{
    constructor(private store: Store<State>) { }
    get reminders$() { return this.store.select(selectReminders); }
}
