import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
  selector: '[appCurrencyFormat]'
})
export class CurrencyFormatDirective {
  

  constructor(private el: ElementRef) {}

  @HostListener('input', ['$event'])
  onInputChange(event: Event) {
    let input = this.el.nativeElement;
    let value = input.value.replace(/[^0-9.]/g, '');
    if (value) {
      const parts = value.split('.');
      if (parts.length > 2) {
        value = parts[0] + '.' + parts[1].slice(0, 2);
      }
      input.value = `$${value}/hr`;
      
    } else {
      input.value = '';
    }
  }
}
