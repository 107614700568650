import { createReducer, on } from '@ngrx/store';

import { changeEmailSuccess, getProfileSuccess, Union, updateUserSuccess, uploadAvatarSuccess } from '../actions/user/user.actions';
import { UserState } from '../interfaces';

export const initialUserState: UserState = Object.freeze<UserState>({
    user: undefined
});

const reducer = createReducer(
    initialUserState,
    on(getProfileSuccess, (state, { user }) => ({ ...state, user })),
    on(changeEmailSuccess, updateUserSuccess, uploadAvatarSuccess, (state, { model }) => ({ ...state, user: model }))
);


export const userReducer = (state: UserState, action: Union) => reducer(state, action);