import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FacilityModel } from 'src/app/models/facility';
import { NurseModel } from 'src/app/models/nurse';

@Component({
  selector: 'app-dnr-modal',
  templateUrl: './dnr-modal.component.html',
  styleUrls: ['./dnr-modal.component.scss']
})
export class DnrModalComponent implements OnInit {
  @Input() nurse: NurseModel;
  @Input() facility: FacilityModel;
  @Input() type: string;
  
  @Output() onClose = new EventEmitter();
  @Output() addNurseToDnr = new EventEmitter();
  @Output() removeNurseFromDnr = new EventEmitter();
  
  
constructor() { }

  ngOnInit(): void {
  }

}
