import { ApplicantStatus } from 'src/app/types/applicant-status';
import { InstaStatus } from 'src/app/types/insta-status';
import { License } from 'src/app/types/license';
import { ShiftStatus } from 'src/app/types/shift-status';
import { ShiftType } from 'src/app/types/shift-type';
export interface ShiftFiltersCheckboxes {
  applicantStatuses?: ApplicantStatus[];
  licenses: License[];
  shiftStatuses?: ShiftStatus[];
  shiftTypes?: ShiftType[];
  insta?: InstaStatus[];
}
export interface ShiftFiltersRange {
  startTimeFrom: string;
  startTimeTo: string;
}
export interface ShiftFiltersForm {
  checkboxes: ShiftFiltersCheckboxes;
  id?: string;
  range: ShiftFiltersRange
}

export function defaultFilters(): ShiftFiltersForm {
  return {
    checkboxes: {
      applicantStatuses: [],
      licenses: [],
      shiftStatuses: [],
      shiftTypes: [],
      insta: []
    },
    id: undefined,
    range: {
      startTimeFrom: new Date().toISOString(),
      startTimeTo: new Date(
        new Date().setDate(new Date().getDate() + 14),
      ).toISOString(),
    },
  };
}
