import { Injectable } from '@angular/core';
import { asapScheduler } from 'rxjs';
import { take } from 'rxjs/operators';
import { AppService } from 'src/app/services/app.service';
import { State } from 'src/app/state/interfaces';
import { Store } from '@ngrx/store';
import { DnrEffects } from '../../../state/effects/dnr.effects';
import { AddToDnr } from 'src/app/types/forms/add-nurse-to-dnr-form';
import { RemoveFromDnr } from 'src/app/types/forms/remove-nurse-from-dnr-form';
import { DnrActions } from '../../../state/actions/dnr/dnr-action-types';

@Injectable({ providedIn: 'root' })
export class DnrFacade {
  constructor(
    private app: AppService,
    private store: Store<State>,
    private effects: DnrEffects
  ) {
    app.expose('facades', 'facility', this);
  }
  fetchDnr() {
    asapScheduler.schedule(() => this.store.dispatch(DnrActions.fetchDnr()));
    return this.effects.fetchDnr$.pipe(take(1));
  }
  addNurseToDNR(form: AddToDnr){
    asapScheduler.schedule(() => this.store.dispatch(DnrActions.addNurse({form})));
    return this.effects.addNurse$.pipe(take(1));
  }
  removeNurseFromDNR(form: RemoveFromDnr){
    asapScheduler.schedule(() => this.store.dispatch(DnrActions.removeNurse({form})));
    return this.effects.removeNurse$.pipe(take(1));
  }
  syncDnr(dnrs) {
    asapScheduler.schedule(() => this.store.dispatch(DnrActions.syncDnrs({dnrs})));
    return this.effects.syncDnrs$.pipe(take(1));
  }
}
