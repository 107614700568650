import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";


import { ToolTipRendererDirective } from "./tooltip.directive";

@NgModule({
    declarations: [
        ToolTipRendererDirective
    ],
    imports: [
        CommonModule
    ],
    exports: [
        ToolTipRendererDirective
    ]
})
export class TooltipModule { }
