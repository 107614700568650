import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';

import { FacilitiesAccessor } from 'src/app/modules/manager/services/accessors/facilities.accessor';
import { State } from 'src/app/state/interfaces';

import { AppService } from 'src/app/services/app.service';
import { FiltersActions } from '../../../state/actions/filters/filters-action-types';

@Injectable()
export class FiltersFacade {
  constructor(
    private app: AppService,
    private store: Store<State>,
    private accessor: FacilitiesAccessor,
  ) {
    app.expose('facades', 'notifications', this);
  }

  get state() {
    return this.accessor;
  }

  setFilters(type, filters){
    if(type === 'shifts'){
      this.store.dispatch(FiltersActions.setShiftsFilters({filters}));
    }else if(type === 'timecards'){
      this.store.dispatch(FiltersActions.setTimecardsFilters({filters}));
    }
  }
}
