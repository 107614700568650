import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, Validators } from '@angular/forms';
import { FacilityNurse } from 'src/app/models/facility/facility-nurse';

@Component({
  selector: 'app-remove-from-trusted-popup',
  templateUrl: './remove-from-trusted-popup.component.html',
  styleUrls: ['./remove-from-trusted-popup.component.scss']
})
export class RemoveFromTrustedPopupComponent implements OnInit {
  @Input() nurseData: FacilityNurse;

  @Output() modalClose = new EventEmitter();
  @Output() onRemove = new EventEmitter();

  constructor(private fb: UntypedFormBuilder) { }
  form = this.fb.group({
      attendance_and_punctuality : new UntypedFormControl(false),
      clinical_competency : new UntypedFormControl(false),
      attitude_and_cooperation : new UntypedFormControl(false),
      facility_preference : new UntypedFormControl(false),
      other : new UntypedFormControl(false),
      otherText: new UntypedFormControl('', [Validators.required, Validators.minLength(10)]),
    })
  ngOnInit(): void {
  }
  onRemoveClick(e){
    this.onRemove.emit(this.form.value);
    this.modalClose.emit();
  }
  onModalClose(e){
    this.modalClose.emit();
  }
  onFormItemClick(item){
    this.form.get(item).setValue(!this.form.get(item).value);
  }
}
