import { Position } from "../types/models/user";

export const POSITIONS: Record<'ADMIN' | 'MANAGER' | 'SUPERVISOR', Position[]> = {
    ADMIN: ["admin"],
    MANAGER: ["facility-manager", "supervisor"],
    SUPERVISOR: ["supervisor"]
};

export const POSITION_NAMES = {
    'admin': "Admin",
    'facility-manager': "Facility Manager",
    'supervisor': "Supervisor",
    'nurse': "Nurse"
}