import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';

import { selectPagination, selectShift, selectShiftIds, selectShifts } from 'src/app/modules/manager/state/selectors/shifts';
import { State } from 'src/app/state/interfaces';

@Injectable()
export class ShiftsAccessor
{
    constructor(private store: Store<State>) { }

    get shiftIds$() { return this.store.select(selectShiftIds); }
    get shifts$() { return this.store.select(selectShifts); }

    get pagination$() { return this.store.select(selectPagination); }
    shift$(id: string) {
        return this.store.select(selectShift, { id });
    }
}
