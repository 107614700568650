import { createAction, props } from "@ngrx/store";

import { type } from "src/app/state/actions/helpers/action-type";

import { FetchStateDTO } from "./dto/fetch-state";
import { SetActiveStateDTO } from "./dto/set-active-state";

const t = type('serviced-states');

export const fetchState = createAction(t.pending('fetch_state'), props<FetchStateDTO.Pending>());
export const fetchStateSuccess = createAction(t.success('fetch_state'), props<FetchStateDTO.Success>());
export const fetchStateFailed = createAction(t.failed('fetch_state'), props<FetchStateDTO.Failed>());

export const setActiveState = createAction(t.sync('set_active_state'), props<SetActiveStateDTO>());
