<div class="checkboxes-container">
  <div *ngIf="showFlag" class="checkboxes-substrate" (click)="onClick()" #trigger="cdkOverlayOrigin" cdkOverlayOrigin></div>
  <button fill="none" [ngClass]="{'create-shift': createShift}" class="text-btn" (click)="onClick()" #trigger="cdkOverlayOrigin" cdkOverlayOrigin><mat-icon svgIcon="filter-01"></mat-icon><span style="white-space: nowrap;"> Filters {{selectedCategories.length > 0 ? '/ ' + selectedCategories.length : ''}}</span></button>
  <button fill="none" [ngClass]="{'create-shift': createShift}" class="chevron-btn" (click)="onClick()" #trigger="cdkOverlayOrigin" cdkOverlayOrigin>
    <ng-container *ngIf="showFlag"><mat-icon class="cheveron-up-icon" svgIcon="mdCheveronUp-01"></mat-icon></ng-container>
    <ng-container *ngIf="!showFlag"><mat-icon class="cheveron-down-icon" svgIcon="mdCheveronUp-01"></mat-icon></ng-container>
  </button>
  <ng-template
      cdkConnectedOverlay
      [cdkConnectedOverlayOrigin]="trigger"
      [cdkConnectedOverlayOpen]="showFlag"
      [cdkConnectedOverlayOffsetY]="(createShift && !largeScreen) ? -8 : 8"
      [cdkConnectedOverlayPositions]="(createShift && !largeScreen) ? [{ originX: 'start', originY: 'top', overlayX: 'start', overlayY: 'bottom' }] : [{ originX: 'center', originY: 'bottom', overlayX: 'center', overlayY: 'top' }]"
      (overlayOutsideClick)="showFlag = false"
    >
    <div class="checkboxes-wrapper" [ngClass]="{'create-shift': createShift}">
    
      <div class="list-container" *ngFor="let item of checkboxesGroupModel">
        <span class="title">{{ item.title }} {{form.controls[item.type].value && form.controls[item.type].value.length > 0 ? '/ ' + form.controls[item.type].value.length : ''}}</span>
        <ul>
          <li
            *ngFor="let row of item.list"
            (click)="onCheckClick(item.type, row.value)"
            [ngClass]="{'selected-item': checkboxesData[item.type][row.value]}"
          >
            <input
              type="checkbox"
              [(ngModel)]="checkboxesData[item.type][row.value]"
              (change)="onToggle(item.type)"
            />
            <span [class]="row.class">
              <mat-icon *ngIf="row.icon" [svgIcon]="row.icon"></mat-icon>
              {{row.text}}
            </span>
          </li>
        </ul>
      </div>
    </div>
  </ng-template>
</div>
