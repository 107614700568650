import * as clone from 'clone';

import { ShiftsFiltersDTO } from 'src/app/types/dto/fetch-shifts/shifts-filters';
import { getIsInstaShyftValue } from 'src/app/types/insta-status';

import { ShiftFiltersForm } from '../types/forms';

export class ShiftsFiltersAdapter {
  private _form: ShiftFiltersForm;

  private _dto: ShiftsFiltersDTO;

  constructor(form: ShiftFiltersForm) {
    this._form = clone(form);
  }

  private _adapt(): ShiftsFiltersDTO {
    const insta = getIsInstaShyftValue(this._form.checkboxes.insta);
    return {
      applicantStatuses: this._form.checkboxes.applicantStatuses,
      licenseTypes: this._form.checkboxes.licenses,
      shiftStatuses: this._form.checkboxes.shiftStatuses,
      shiftTypes: this._form.checkboxes.shiftTypes,
      isInstaShyft: insta,
      ...(!this._form.id && this._form.range.startTimeFrom ? {startTimeFrom: this._form.range.startTimeFrom} : {}),
      ...(!this._form.id && this._form.range.startTimeTo ? {startTimeTo: this._form.range.startTimeTo} : {}),
      ...(this._form.id ? {q: this._form.id} : {}),
    } as ShiftsFiltersDTO;
  }

  get dto() {
    if (!this._dto) this._dto = this._adapt();

    return this._dto;
  }
}
