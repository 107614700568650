<div class="sort-container" [ngClass]="{timecard: timecard, 'with-padding': withPadding}">
  <div
    class="sort-block"
    *ngFor="let item of sortOptions; let i = index"
    [ngStyle]="{ flex: item.flexPriority }"
    (click)="onSortClick(item.sortOption)"
  >
    <span>{{ sortOptionsText[i].firstPart }}
      <span>
        {{ ' ' + sortOptionsText[i].secondPart }}
        <mat-icon
          svgIcon="info-2"
          *ngIf="item.tooltip"
          class="tooltip-icon"
          customToolTip [contentTemplate]="template"
          [offsetX]="-94"
          [offsetY]="-8"
        ></mat-icon>
      </span>
    </span>
    <mat-icon
      svgIcon="sort-01"
      *ngIf="item.sortOption"
      [ngClass]="{
        active: item.sortOption === active.sort,
        desc: item.sortOption === active.sort && active.order === 'desc'
      }"
    ></mat-icon>
    
    <ng-template #template>
      <ng-container *ngIf="item.tooltip">
        <div class="sorting-tooltip-container">
          <span>{{item.tooltip}}</span>
        </div>
      </ng-container>
    </ng-template>
  </div>
</div>
