import { createSelector } from '@ngrx/store';

import { State } from 'src/app/state/interfaces';
import { selectModelsById } from 'src/app/state/helpers/select-models-by-id';

import { MANAGER_FEATURE_KEY, NURSES_FEATURE_KEY, NursesState } from '../interfaces';

const state = (s: State) => s[MANAGER_FEATURE_KEY][NURSES_FEATURE_KEY];

export const selectNursesIds = createSelector(state, (state) => state.nurses);
export const selectNurses = createSelector(state, (state) => selectModelsById(state.models, state.nurses));
export const selectNurse = createSelector(state, ({ models }: NursesState, { id }: { id: string }) => models[id]);