import { SortDTO } from './../../../../../types/dto/sort';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';

import { asapScheduler } from 'rxjs';
import { subscribeOn, take } from 'rxjs/operators';

import { Chain } from 'src/app/classes/async-chain';
import { SHIFTS_PAGE_SIZE } from 'src/app/constants/pagination';
import { ShiftsFiltersAdapter } from 'src/app/modules/manager/adapters/shifts-filters.adapter';
import { ShiftsAccessor } from 'src/app/modules/manager/services/accessors/shifts.accessor';
import { ShiftsEffects } from 'src/app/modules/manager/state/effects/shifts.effects';

import { AppService } from 'src/app/services/app.service';
import { State } from 'src/app/state/interfaces';
import { ShiftFiltersForm } from 'src/app/modules/manager/types/forms';

import { CreateShiftsAdapter } from '../../../adapters/create-shifts.adapter';
import { CreateShiftsForm } from '../../../types/forms';
import { FacilitiesAccessor } from '../../accessors/facilities.accessor';
import { EditShiftForm } from '../../../types/forms/edit-shift/edit-shift-form';
import { EditShiftAdapter } from '../../../adapters/edit-shift.adapter';
import { ApplicantBindings, ShiftBindings } from '../../../bindings';
import { MessagesService } from '../../messages.service';
import { LoaderService } from '../../ui/loader.service';
import { ShiftsBinder } from './shifts.binder';
import { ScheduleFiltersForm } from '../../../types/forms/schedule-filters';
import { ScheduleFiltersAdapter } from '../../../adapters/schedule-filters.adapter';
import { RemindersAccessor } from '../../accessors/reminders.accessor';
import { ShiftsActions } from '../../../state/actions/shifts/shifts-action-types';
import { ReminderActions } from '../../../state/actions/reminder/reminder-action-types';

type PS<K extends keyof ShiftBindings> = Parameters<ShiftBindings[K]>;
type PA<K extends keyof ApplicantBindings> = Parameters<ApplicantBindings[K]>;

@Injectable()
export class ShiftsFacade {
  private _deleteChain = (...args: PS<'delete'>) =>
    new Chain({ debug: !this.app.isProduction, id: 'delete_shift' })
      .add('sync', 'loader', () => this.loader.create('Loading...'))
      .add('sync', 'deleteShift', () => this.deleteShift(args[0]))
      .add('sync', 'loader', () => this.loader.hide())
      .add('sync', '', ({ deleteShift }) => {
        return this.messages.add(
          deleteShift.type === ShiftsActions.deleteShiftSuccess.type
            ? 'Success! Your change has been updated.'
            : deleteShift.error.error.message,
          '',
          deleteShift.type === ShiftsActions.deleteShiftSuccess.type ? 'success' : 'danger',
        );
      });
  private _editChain = (...args: PS<'edit'>) =>
    new Chain({ debug: !this.app.isProduction, id: 'edit_shift' })
      .add('sync', 'loader', () => this.loader.create('Loading...'))
      .add('sync', 'edit', () => this.editShift(args[0], args[1]))
      .add('sync', 'loader', () => this.loader.hide())
      .add('sync', '', ({ edit }) =>
        this.messages.add(
          edit.type === ShiftsActions.editShiftSuccess.type
            ? 'Success! Your change has been updated.'
            : edit.error.error.message,
          '',
          edit.type === ShiftsActions.editShiftSuccess.type ? 'success' : 'danger',
        ),
      );

  private _cancelChain = (...args: PA<'cancel'>) =>
    new Chain({ debug: !this.app.isProduction, id: 'cancel_applicant' })
      .add('sync', 'loader', () => this.loader.create('Loading...'))
      .add('sync', 'cancel', () => this.cancelApplicant(args[0], args[1]))
      .add('sync', 'loader', () => this.loader.hide())
      .add('sync', '', ({ cancel }) =>
        this.messages.add(
          cancel.type === ShiftsActions.cancelApplicantSuccess.type
            ? 'Success! Your change has been updated.'
            : cancel.error.error.message,
          '',
          cancel.type === ShiftsActions.cancelApplicantSuccess.type ? 'success' : 'danger',
        ),
      );

  private _confirmChain = (...args: PA<'confirm'>) =>
    new Chain({ debug: !this.app.isProduction, id: 'confirm_applicant' })
      .add('sync', 'loader', () => this.loader.create('Loading...'))
      .add('sync', 'confirm', () => this.confirmApplicant(args[0], args[1]))
      .add('sync', 'loader', () => this.loader.hide())
      .add('sync', '', ({ confirm }) =>
        this.messages.add(
          confirm.type === ShiftsActions.confirmApplicantSuccess.type
            ? 'Success! Your change has been updated.'
            : confirm.error.error.message,
          '',
          confirm.type === ShiftsActions.confirmApplicantSuccess.type ? 'success' : 'danger',
        ),
      );

  private _declineChain = (...args: PA<'decline'>) =>
    new Chain({ debug: !this.app.isProduction, id: 'decline_applicant' })
      .add('sync', 'loader', () => this.loader.create('Loading...'))
      .add('sync', 'decline', () => this.declineApplicant(args[0], args[1]))
      .add('sync', 'loader', () => this.loader.hide())
      .add('sync', '', ({ decline }) =>
        this.messages.add(
          decline.type === ShiftsActions.declineApplicantSuccess.type
            ? 'Success! Your change has been updated.'
            : decline.error.error.message,
          '',
          decline.type === ShiftsActions.declineApplicantSuccess.type ? 'success' : 'danger',
        ),
      );

  private _binder: ShiftsBinder = new ShiftsBinder(
    this.accessor,
    {
      delete: (...args: PS<'delete'>) => this._deleteChain(...args).execute(),
      edit: (...args: PS<'edit'>) => this._editChain(...args).execute(),
    },
    {
      cancel: (...args: PA<'cancel'>) => this._cancelChain(...args).execute(),
      confirm: (...args: PA<'confirm'>) =>
        this._confirmChain(...args).execute(),
      decline: (...args: PA<'decline'>) =>
        this._declineChain(...args).execute(),
    },
  );

  constructor(
    private app: AppService,
    private store: Store<State>,
    private accessor: ShiftsAccessor,
    private facilitiesAccessor: FacilitiesAccessor,
    private remindersAccessor: RemindersAccessor,
    private _effects: ShiftsEffects,
    private messages: MessagesService,
    private loader: LoaderService,
  ) {
    app.expose('facades', 'shifts', this);
  }

  get binder() {
    return this._binder;
  }

  get effects() {
    return this._effects;
  }

  get state() {
    return this.accessor;
  }

  createShifts(form: CreateShiftsForm) {
    this.facilitiesAccessor.activeFacility$
      .pipe(take(1), subscribeOn(asapScheduler))
      .subscribe((f) =>
        this.store.dispatch(
          ShiftsActions.createShifts({ dto: new CreateShiftsAdapter(form, f._id).dto }),
        ),
      );

    return this.effects.createShifts$.pipe(take(1));
  }

  fetchShifts(filters: ShiftFiltersForm, sort: SortDTO) {
    asapScheduler.schedule(() =>
      this.store.dispatch(
        ShiftsActions.fetchShifts({ filters: new ShiftsFiltersAdapter(filters).dto, sort }),
      ),
    );

    return this.effects.fetchShifts$.pipe(take(1));
  }
  fetchShiftsForSchedule(filters: ScheduleFiltersForm, sort: SortDTO) {
    asapScheduler.schedule(() =>
      this.store.dispatch(
        ShiftsActions.fetchShiftsForSchedule({ filters: new ScheduleFiltersAdapter(filters).dto, sort }),
      ),
    );

    return this.effects.fetchShiftsForSchedule$.pipe(take(1));
  }
  fetchShift(shiftId: string) {
    asapScheduler.schedule(() => this.store.dispatch(ShiftsActions.fetchShift({ shiftId })));

    return this.effects.fetchShift$.pipe(take(1));
  }
  fetchNextShiftsPage(filters: ShiftFiltersForm) {
    asapScheduler.schedule(() =>
      this.store.dispatch(
        ShiftsActions.fetchNextShiftsPage({ filters: new ShiftsFiltersAdapter(filters).dto }),
      ),
    );

    return this.effects.fetchShifts$.pipe(take(1));
  }
  updateApplicantStatusToSuspended(applicantId, shiftId) {
    this.store.dispatch(
      ShiftsActions.updateApplicantStatusToSuspended({ applicantId, shiftId }),
    );
  }
  fetchPage(
    filters: ShiftFiltersForm,
    sort: SortDTO,
    page: number,
    limit = SHIFTS_PAGE_SIZE,
    withDnrs = false
  ) {
    asapScheduler.schedule(() =>
      this.store.dispatch(
        ShiftsActions.fetchPage({
          filters: new ShiftsFiltersAdapter(filters).dto,
          limit,
          page,
          sort,
          withDnrs
        }),
      ),
    );

    return this.effects.fetchPage$.pipe(take(1));
  }
  fetchScheduledApplicants(
    startTimeFrom: string,
    startTimeTo: string,
    q?: string,
  ) {
    asapScheduler.schedule(() =>
      this.store.dispatch(
        ShiftsActions.fetchScheduledApplicants({ startTimeFrom, startTimeTo, q}),
      ),
    );
    return this.effects.fetchScheduledApplicants$.pipe(take(1));
  }

  addReminderIntoQueue(reminder) {
    this.remindersAccessor.reminders$.pipe(take(1)).subscribe(r=>{
      if(!r.length){
        this.messages.addReminder(reminder)
      }
      this.store.dispatch(
        ReminderActions.addReminderIntoQueue({ reminder }),
      );
    });
  }
  showNextReminder(){
    this.remindersAccessor.reminders$.pipe(take(1)).subscribe(r=>{
      if(r.length > 1){
        this.messages.addReminder(r[1])
      }
      this.store.dispatch(
        ReminderActions.removeFirstFromQueue()
      );
    });
  }
  private cancelApplicant(shiftId: string, applicantId: string) {
    asapScheduler.schedule(() =>
      this.store.dispatch(ShiftsActions.cancelApplicant({ shiftId, applicantId })),
    );
    return this.effects.cancelApplicant$.pipe(take(1));
  }

  private confirmApplicant(shiftId: string, applicantId: string) {
    asapScheduler.schedule(() =>
      this.store.dispatch(ShiftsActions.confirmApplicant({ shiftId, applicantId })),
    );
    return this.effects.confirmApplicant$.pipe(take(1));
  }

  private declineApplicant(shiftId: string, applicantId: string) {
    asapScheduler.schedule(() =>
      this.store.dispatch(ShiftsActions.declineApplicant({ shiftId, applicantId })),
    );
    return this.effects.declineApplicant$.pipe(take(1));
  }

  private deleteShift(shiftId: string) {
    asapScheduler.schedule(() => this.store.dispatch(ShiftsActions.deleteShift({ shiftId })));
    return this.effects.deleteShift$.pipe(take(1));
  }

  private editShift(shiftId: string, form: EditShiftForm) {
    asapScheduler.schedule(() => {
      const dto = new EditShiftAdapter(form).dto;
      this.store.dispatch(ShiftsActions.editShift({ id: shiftId, dto }));
    });

    return this.effects.editShift$.pipe(take(1));
  }
}
