export type Options = { type?: 'array' | 'record', classes?: boolean };

export class CollectionBuilder<Model, Class>
{
    private readonly _defaultOptions: Options = {
        type: 'record',
        classes: false
    };

    private _transformations: Set<'array' | 'record'> = new Set();
    private _classes: boolean = false;
    private _bindings: object = { };

    constructor(
        private collection: Record<string, Model> | Model[],
        private ctor: new (m: Model, bindings?: object) => Class,
        private idMatcher: (m: Model) => string,
        opts?: Options
    )
    {
        opts = { ...this._defaultOptions, ...opts };

        if(opts.type && opts.type === 'array') this.array();
        if(opts.type && opts.type === 'record') this.record();
        if(opts.classes) this.classes();
    }

    private toArray(col: Record<string, Model>)
    {
        return Object.values(col);
    }

    private toClasses(col: Record<string, Model> | Model[])
    {
        if(col instanceof Array)
            return col.map(m => new this.ctor(m, this._bindings));
        else
            return Object.keys(col).reduce<Record<string, Class>>((p, c) => ({ ...p, [c]: new this.ctor(col[c], this._bindings) }), { });
    }

    private toRecord(col: Model[])
    {
        return col.reduce<Record<string, Model>>((p, c) => ({ ...p, [this.idMatcher(c)]: c }), { });
    }

    array()
    {
        if(this._transformations.has('record'))
            this._transformations.delete('record');
        
        this._transformations.add('array');
        return this;
    }

    classes(bindings: object = { })
    {
        this._classes = true;
        this._bindings = bindings;
        return this;
    }

    record()
    {
        if(this._transformations.has('array'))
            this._transformations.delete('array');

        this._transformations.add('record');
        return this;
    }

    build() : Record<string, Model | Class> | Array<Model | Class>
    {
        let result: Model[] | Record<string, Model> = Object.assign({ }, this.collection);

        this._transformations.forEach(t =>
        {
            switch(t)
            {
                case 'array':
                {
                    if(!(result instanceof Array))
                        result = this.toArray(result as Record<string, Model>);
                }
                break;

                case 'record':
                    if(result instanceof Array)
                        result = this.toRecord(result as Model[]);
                break;
            }
        });

        if(this._classes)
            return this.toClasses(result);

        else return result;
    }
}