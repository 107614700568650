import { throwError } from "rxjs";

import { toHot } from "src/app/helpers/to-hot";
import { Facility, FacilityModel } from "src/app/models/facility";

import { AddSupervisorForm, CreateShiftTemplateForm, EditFacilityInformationForm, ShiftRateDefaultsForm, EditShiftTemplateForm, RequestChangeForm } from "../../types/forms";

import { FacilityBindings } from "./bindings";

export class FacilityWithBindings extends Facility
{
    constructor(model: FacilityModel, private _bindings: FacilityBindings)
    {
        super(model);
    }
    
    get bindings() { return this._bindings; }

    addSupervisor(form: AddSupervisorForm)
    {
        if(!this.bindings.addSupervisor) return throwError('AddSupervisor method is not bound!');

        return toHot(this.bindings.addSupervisor(form));
    }

    createShiftTemplate(form: CreateShiftTemplateForm)
	{
        if(!this.bindings.createShiftTemplate) return throwError('CreateShiftTemplate method is not bound!');

        return toHot(this.bindings.createShiftTemplate(form));
	}

    deleteShiftTemplate(id: string)
	{
        if(!this.bindings.deleteShiftTemplate) return throwError('DeleteShiftTemplate method is not bound!');

        return toHot(this.bindings.deleteShiftTemplate(id));
	}

    editInformation(form: EditFacilityInformationForm)
	{
        if(!this.bindings.editInformation) return throwError('EditInformation method is not bound!');

        return toHot(this.bindings.editInformation(form));
	}

    editRateLimits(form: ShiftRateDefaultsForm)
	{
        if(!this.bindings.editRateLimits) return throwError('EditRateLimits method is not bound!');

        return toHot(this.bindings.editRateLimits(form));
	}

    editShiftTemplate(id: string, form: EditShiftTemplateForm)
	{
        if(!this.bindings.editShiftTemplate) return throwError('EditShiftTemplate method is not bound!');

        return toHot(this.bindings.editShiftTemplate(id, form));
	}

    requestChange(form: RequestChangeForm)
	{
        if(!this.bindings.requestChange) return throwError('RequestChange method is not bound!');

        return toHot(this.bindings.requestChange(form));
	}

    uploadAvatar(file: File)
	{
        if(!this.bindings.uploadAvatar) return throwError('UploadAvatar method is not bound!');

        return toHot(this.bindings.uploadAvatar(file));
	}
}