import { createReducer, on } from '@ngrx/store';

import { TIMECARDS_PAGE_SIZE } from 'src/app/constants/pagination';
import { toRecord } from 'src/app/state/helpers/to-record';
import { TimecardsActions } from '../actions/timecards/timecards-action-types';

import { TimecardsState } from '../interfaces';

export const initialTimecardsState: TimecardsState =
  Object.freeze<TimecardsState>({
    models: {},
    pagination: {
      limit: TIMECARDS_PAGE_SIZE,
      page: undefined,
      pagesCount: undefined,
    },
    timecards: [],
  });

  export const timecardsReducer = createReducer(
  initialTimecardsState,
  on(TimecardsActions.fetchTimecardsSuccess, (state, { models }) => ({
    ...state,
    models: { ...state.models, ...toRecord(models, (s) => s._id) },
    timecards: models.map((t) => t._id),
  })),
  on(TimecardsActions.fetchNextTimecardsPageSuccess, (state, { models }) => ({
    ...state,
    models: { ...state.models, ...toRecord(models, (t) => t._id) },
    timecards: state.timecards.concat(models.map((t) => t._id)),
  })),
  on(
    TimecardsActions.fetchPageSuccess,
    (state, { limit, models, page, pagesCount }) => ({
      ...state,
      models: { ...state.models, ...toRecord(models, (t) => t._id) },
      pagination: { limit, page, pagesCount },
      timecards: models.map((t) => t._id),
    }),
  ),
  on(TimecardsActions.fetchTimecardSuccess, (state, { model }) => ({
    ...state,
    models: { ...state.models, [model._id]: model },
  })),
  on(TimecardsActions.approveTimecardSuccess, TimecardsActions.rejectTimecardSuccess, (state, { model }) => ({
    ...state,
    models: { ...state.models, [model._id]: model },
  })),
  on(TimecardsActions.fetchPageFailed, (state, { error }) => {
    if (error.status === 404) {
      state = initialTimecardsState;
    }

    return {
      ...state,
    };
  }),
  on(TimecardsActions.removeTimecardFromStore, (state, { timecardId }) => {
    return ({
    ...state,
    models: { ...state.models, [timecardId]: null },
    timecards: state.timecards.filter((s) => s !== timecardId),
  })}),
);
