import { createSelector } from '@ngrx/store';

import { selectModelsById } from 'src/app/state/helpers/select-models-by-id';
import { State } from 'src/app/state/interfaces';

import { FACILITIES_FEATURE_KEY, FacilitiesState, MANAGER_FEATURE_KEY } from '../interfaces';

const state = (s: State) => s[MANAGER_FEATURE_KEY][FACILITIES_FEATURE_KEY];

export const selectMyFacilities = createSelector(state, (state) => state ? selectModelsById(state.models, state.mine) : { });

export const selectActiveFacility = createSelector(state, (state) => state ? state.models[state.active] : undefined);

export const selectFacility = createSelector(state, ({ models }: FacilitiesState, { id }: { id: string }) => models[id]);

