import { map } from "rxjs/operators";

import { TimecardBindings, TimecardWithBindings } from "../../../bindings";
import { TimecardsAccessor } from "../../accessors/timecards.accessor";

export class TimecardsBinder
{
    constructor(
        private accessor: TimecardsAccessor,
        private bindings: TimecardBindings
    )
    { }

    get timecards()
    {
        return this.accessor.timecards$
            .pipe(
                map(timecards =>
                    Object.values(timecards)
                    .filter(t => t != null)
                    .map(t => t && new TimecardWithBindings(t, this.bindings))
                )
            );
    }
}