import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-subheadered-content-wrapper',
  templateUrl: './subheadered-content-wrapper.component.html',
  styleUrls: ['./subheadered-content-wrapper.component.scss']
})
export class SubheaderedContentWrapperComponent implements OnInit {
  @Input() title: string;
  constructor() { }

  ngOnInit(): void {
  }

}
