<div class="hp-wrapper">
  <div class="hp-block">
    <div class="line">
      <div class="dot"></div>
      <span class="hp-name">{{ holidayModel.name }}</span>
    </div>
    <div class="line">
      <span class="text">Holiday pay starts&nbsp;{{formattedStartDate}}&nbsp;<span  style="font-weight: bold;"> {{formattedStartTime}}</span></span>
    </div>
    <div class="line">
      <span class="text">and ends&nbsp;{{formattedEndDate}}&nbsp;<span style="font-weight: bold;"> {{formattedEndTime}}</span></span>
    </div>
  </div>
</div>
