import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule,  HTTP_INTERCEPTORS} from '@angular/common/http';
import { CookieService } from 'ngx-cookie-service';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { EffectsModule } from './state/effects/effects.module';
import {EffectsModule as ManagerEffectsModule} from './modules/manager/state/effects/effects.module';

import { State } from './state/interfaces';
import { NgxMaskModule } from "ngx-mask";
import { rootReducer, metaReducers } from './state/reducers';
import { environment } from '../environments/environment';
import { AuthInterceptor } from './services/interceptors/auth.interceptor';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ToastrModule } from 'ngx-toastr';
import { ActionToastComponent } from './modules/manager/shared/components/action-toast/action-toast.component';
import { ManagerModule } from './modules/manager/manager.module';
import { ModalService } from './modules/manager/services/ui/modal.service';

@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    StoreModule.forRoot<State>(rootReducer, { metaReducers }),
    StoreDevtoolsModule.instrument({ maxAge: 25, logOnly: environment.production }),
    EffectsModule,
    NgxMaskModule.forRoot(),
    ManagerEffectsModule,
    BrowserAnimationsModule,
    ToastrModule.forRoot({
      toastComponent: ActionToastComponent
    }),
    ManagerModule
  ],
  providers: [
    CookieService,
    ModalService,
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
