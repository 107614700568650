import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";

import { of } from "rxjs";
import { catchError, exhaustMap, map, share } from "rxjs/operators";

import { ServicedStatesService } from "../../services/api/serviced-states.service";
import { ServicedStatesActions } from "../actions/serviced-states/serviced-states-action-types";

@Injectable()
export class ServicedStatesEffects
{
    constructor(
        private actions$: Actions,
        private api: ServicedStatesService
    ) { }

    fetchState$ = createEffect(() => this.actions$.pipe(
        ofType(ServicedStatesActions.fetchState),
        exhaustMap(({ state }) =>
            this.api.fetchState(state)
                .pipe(
                    map(model => ServicedStatesActions.fetchStateSuccess({ model })),
                    catchError(e => of(ServicedStatesActions.fetchStateFailed({ error: e })))
                )
        ),
        share()
    ));
}
