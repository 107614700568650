import { asyncScheduler, AsyncSubject, of, scheduled, throwError } from "rxjs";
import { catchError } from "rxjs/operators";

import { IExecutable } from "./iexecutable";

export type Options<State> = {
    abortOnFail?: boolean;
    condition?: (input: State) => boolean;
};

export class ExecutionContext<State extends Record<string, any> = any, Result = any> implements IExecutable<Result>
{
    constructor(
        private _executable: IExecutable<Result>,
        private _options: Options<State> = { }
    )
    { }

    execute(input: State)
    {
        if(this._options.condition && !this._options.condition(input))
            return scheduled([], asyncScheduler);

        const subject = new AsyncSubject();
        
        this._executable.execute(input)
        .pipe(
            catchError(e =>
            {
                if(this._options.abortOnFail) return throwError(e);
                else return of(e);
            })
        )
        .subscribe(
            v => subject.next(v),
            e => subject.error(e),
            () => subject.complete()
        );

        return subject.asObservable();
    }
}
