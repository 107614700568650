import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { BehaviorSubject, from, Observable, Subject, throwError, zip } from 'rxjs';
import { catchError, exhaustMap, filter, map, mergeMap, tap } from 'rxjs/operators';

import { AppService } from '../../../services/app.service';
import { ToastService } from './ui/toast.service';
export type MessageType = 'danger' | 'success' | 'info' | 'warning' | 'reminder';
export type Message = {
    message: string,
    title: string,
    type?: MessageType,
    action?: () => any,
    button?: boolean,
    buttonText?: string
}

@Injectable({ providedIn: 'root' })
export class MessagesService {
    private _queue = new Subject<Message>();
    
    constructor(
        app: AppService,
        private toast: ToastService
    ) {
        app.expose('services', 'messages', this);
            this._queue.subscribe((m)=>{
                this.toast.create(m.message, m.title, m.type, m.button, m.action, m.buttonText)
            });
    }

    private addToQueue(message: Message) {
        this._queue.next(message);
    }

    serverError(error: HttpErrorResponse) {
        try {
            if (error.status < 400 || error.status >= 500) return;

            const message = error.error.message;
            this.add(message, '', 'danger');
        }
        catch (e) { console.log('Server Error: ', e); }
    }

    add(message: string, title: string, type: MessageType = 'danger', action?: () => any, button: boolean = false, buttonText?: string) {    
        this.addToQueue({ button, message, title, type, action, buttonText });
    }
    addReminder(reminderData){
        this.toast.createReminder(reminderData);
    }
    pipeHttpError<T>(): (source: Observable<T>) => Observable<T> {
        return catchError(e => {
            this.serverError(e);
            return throwError(e);
        });
    }
}
