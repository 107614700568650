import { asapScheduler, AsyncSubject, from, Observable, scheduled } from "rxjs";

import { IExecutable } from "./iexecutable";

export class SyncTask<ActionResult> implements IExecutable<ActionResult>
{
    static readonly type = 'sync';

    private _action: (input: Record<string, any>) => Observable<ActionResult> | Promise<ActionResult> | ActionResult;

    constructor(
        private resultKey: string,
        action: (input: Record<string, any>) => Observable<ActionResult>,
        private resultReducer: (key: string, value: ActionResult | Error) => void
    )
    {
        this._action = (input: Record<string, any>) => action(input);
    }

    execute(input: Record<string, any>)
    {
        const subject = new AsyncSubject<any>();

        const result = this._action(input);
        let obs: Observable<any>;

        if (result instanceof Observable) obs = result;
        else if (result instanceof Promise) obs = from(result);
        else obs = scheduled([result], asapScheduler);

        obs.subscribe(
            r =>
            {
                this.resultReducer(this.resultKey, r);
                subject.next(r);
            },
            e => subject.error(e),
            () => subject.complete()
        );

        return subject.asObservable();
    }
}