import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';

import { of } from 'rxjs';
import {
  catchError,
  exhaustMap,
  filter,
  map,
  share,
  switchMap,
  take,
} from 'rxjs/operators';
import { FacilitiesAccessor } from '../../services/accessors/facilities.accessor';

import { NurseService } from '../../services/api/nurse.service';
import { DnrActions } from '../actions/dnr/dnr-action-types';


@Injectable({ providedIn: 'root' })
export class DnrEffects {
  constructor(
    private actions$: Actions,
    private api: NurseService,
    private facilitiesAccessor: FacilitiesAccessor
  ) {}

  fetchDnr$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DnrActions.fetchDnr),
      exhaustMap(() =>
        this.facilitiesAccessor.activeFacility$.pipe(
          filter((v) => !!v),
          take(1),
          switchMap((f) =>
            this.api.fetchDnrUsers(f._id).pipe(
              map((models) => DnrActions.fetchDnrSuccess({models})),
              catchError((e) => of(DnrActions.fetchDnrFailed({ error: e }))),
            ),
          ),
        ),
      ),
      share(),
    ),
  );

  addNurse$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DnrActions.addNurse),
      exhaustMap(({ form }) =>
        this.api.addNurseToDnr(form).pipe(
          map((models) => {
            return DnrActions.addNurseSuccess({ models });
          }),
          catchError((e) => of(DnrActions.addNurseFailed({ error: e }))),
        ),
      ),
      share(),
    ),
  );
  removeNurse$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DnrActions.removeNurse),
      exhaustMap(({ form }) =>
        this.api.removeNurseFromDnr(form).pipe(
          map(() => {
            return DnrActions.removeNurseSuccess({nurseId: form.nurse});
          }),
          catchError((e) => of(DnrActions.removeNurseFailed({ error: e }))),
        ),
      ),
      share(),
    ),
  );
  syncDnrs$ = createEffect(() =>
  this.actions$.pipe(
    ofType(DnrActions.syncDnrs),
    map(({ dnrs }) =>
      {
        return DnrActions.fetchDnrSuccess({models: dnrs})
      }
    ),
    share(),
  ),
);
}
