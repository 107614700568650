import { Injectable } from '@angular/core';
import { CanActivate, CanActivateChild, Router, UrlTree } from '@angular/router';

import { Observable } from 'rxjs';
import { filter, map, tap } from 'rxjs/operators';

import { POSITIONS } from 'src/app/constants/positions';
import { UserFacade } from 'src/app/services/facades/user/user.facade';

@Injectable({ providedIn: 'root' })
export class ManagerGuard implements CanActivate, CanActivateChild
{
    constructor(private userFacade: UserFacade, private router: Router) { }

    canActivate(): Observable<boolean | UrlTree>
    {
        return this.userFacade.state.user$
            .pipe(
                filter(u => !!u),
                map(u => POSITIONS.MANAGER.includes(u.position)),
            );
    }

    canActivateChild()
    {
        return this.canActivate();
    }
}
