import { ApplicantModel } from "./applicant.model";

export class Applicant implements ApplicantModel
{
    protected _model: ApplicantModel;

    constructor(model: ApplicantModel)
    {
        this._model = model
    }

    get model() { return this._model; }

    get isInOvertime() { return this.model.isInOvertime; }

    get isReturning() { return this.model.isReturning; }

    get status() { return this.model.status; }

    get updatedAt() { return this.model.updatedAt; }

    get user() { return this.model.user; }

    get confirmedBy() { return this.model.confirmedBy; }

    get confirmedAt() { return this.model.confirmedAt; }

    get appliedAt() { return this.model.appliedAt; }

    get facilityNurse() { return this.model.facilityNurse; }
}
