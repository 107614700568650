import { Directive, HostBinding, Input } from '@angular/core';

@Directive({ selector: 'button' })
export class ButtonDirective
{
    @Input() color: string = 'primary';
    @Input() fill: 'clear' | 'none' | 'outline' | 'outline-mono' | 'solid' = 'solid';

    constructor() { }

    @HostBinding('class')
    get klass()
    {
        switch (this.fill)
        {
            case 'clear': return 'es-button clear';
            case 'outline': return 'es-button outline';
            case 'outline-mono': return 'es-button outline-mono';
            case 'solid': return 'es-button solid';
            default: return '';
        }
    }

    @HostBinding('style.--background-color')
    get backgroundColor()
    {
        switch(this.fill)
        {
            case 'outline':
            case 'outline-mono':
            case 'solid': return `var(--color-${this.color})`;
            default: return null;
        }
    }

    @HostBinding('style.--color')
    get kolor()
    {
        switch(this.fill)
        {
            case 'clear': return `var(--color-${this.color})`;
            case 'solid': return `var(--color-${this.color}-contrast)`;
            default: return null;
        }
    }
}
