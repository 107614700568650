import { Component, Input, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { DateTime } from 'luxon';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { SortDTO } from 'src/app/types/dto';
import { FacilityWithBindings } from '../../../bindings';

interface SortTableHeader {
  title: string;
  sort: string;
}

interface ActivityLog {
  action: string;
  name: string;
  time: DateTime;
}

@Component({
  selector: 'app-activity-logs',
  templateUrl: './activity-logs.component.html',
  styleUrls: ['./activity-logs.component.scss']
})
export class ActivityLogsComponent implements OnInit {

  filterForm: UntypedFormGroup;
  sort: SortDTO = { order: 'desc', sort: 'date' };
  @Input() title: string;
  @Input() set sortStatusModel(sortStatusModel: { title: string, sort: string }) {
    this.sortHeaderModel = [
      sortStatusModel,
      {
        title: 'Modified by',
        sort: 'user.fullName'
      },
      {
        title: 'Time & date modified',
        sort: 'date'
      },
    ]
  }
  @Input() onSaveText: string;
  @Input() facility: FacilityWithBindings;
  @Output() sortChanged: Subject<{ sort:SortDTO, filterForm: UntypedFormGroup }> = new Subject<{ sort:SortDTO, filterForm: UntypedFormGroup }>();
  @Input() logs: ActivityLog[] = [];
  @Output() export: Subject<SortDTO> = new Subject<SortDTO>();
  sortHeaderModel: SortTableHeader[];


  constructor(private fb: UntypedFormBuilder) { 
    this.filterForm = this.fb.group({
      startTimeFrom: new UntypedFormControl(),
      startTimeTo: new UntypedFormControl(),
    })
  }

  ngOnInit(): void {
    this.filterForm.valueChanges.pipe(debounceTime(100)).subscribe((x) => {
      if (x.startTimeFrom && x.startTimeTo) {
        this.sortChanged.next({ sort: this.sort, filterForm: this.filterForm });
      }
    });
  }

  onExport() {
    this.export.next(this.sort);
  }

  onSortClick(option){
    if (!option) {
      return;
    }
    let newActive: SortDTO = {
      order: 'asc',
      sort: ''
    }
    if (this.sort.sort !== option) {
      newActive.order = 'asc';
    } else {
      newActive.order = this.sort.order === 'asc' ? 'desc' : 'asc';
    }
    newActive.sort = option;
    this.sort = newActive;
    this.sortChanged.next({ sort: this.sort, filterForm: this.filterForm });
  }

}
